import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import {MobileAdminContext} from './../../../../../Contexts/MobileAdminContext'

export default function LotName() {

    let {
        location,
        nameMenu,
        setLotName,
        setNameAnchor,
        anchor,
        clearAnchors,
        lotName
    } = useContext(MobileAdminContext)

    let nameHeader
    if (lotName && lotName === 'WestChapel') {
        nameHeader = 'West Chapel'
    } else if (lotName && lotName !== 'WestChapel') {
        nameHeader = lotName
    } else {
        nameHeader = 'Lot Name'
    }

    const nameMenuItemStyle = {
        padding: '0',
        margin: '0',
        minHeight: '2px',
        minWidth: '30vw',
        fontSize: '2rem'
    }
    const nameButtonStyle = {
        width: '70vw',
        marginTop: '3px',
        fontSize: '3rem',
        textTransform: 'none'
    }
    if (location === 'Knollwood') {
        return (
            <div>
                <Button 
                    style={nameButtonStyle}
                    aria-controls="location-menu" aria-haspopup="true"
                    onClick={event => setNameAnchor(event.currentTarget) }>
                    {nameHeader}
                </Button>
                <Menu
                    MenuListProps={{ disablePadding: true }}
                    style={{ zIndex: 1000030}}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    id="location-menu"
                    anchorEl={anchor}
                    keepMounted
                    open={nameMenu}
                    onClose={() => clearAnchors() }>
                    <div className='m-ac-knollwood-category'>Garden of:</div>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Cross')}>
                        <div className='m-ac-name-item'>Cross</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Faith')}>
                        <div className='m-ac-name-item'>Faith</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Gethsemane')}>
                        <div className='m-ac-name-item'>Gethsemane</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Last Supper')}>
                        <div className='m-ac-name-item'>Last Supper</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Meditation')}>
                        <div className='m-ac-name-item'>Meditation</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Peace')}>
                        <div className='m-ac-name-item'>Peace</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Remembrance')}>
                        <div className='m-ac-name-item'>Remembrance</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Saviour')}>
                        <div className='m-ac-name-item'>Saviour</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Sunset')}>
                        <div className='m-ac-name-item'>Sunset</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Word')}>
                        <div className='m-ac-name-item'>Word</div>
                    </MenuItem>
                    <div className='m-ac-knollwood-category'>Veterans:</div>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Honor')}>
                        <div className='m-ac-name-item'>Honor</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Memorial')}>
                        <div className='m-ac-name-item'>Memorial</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Valor')}>
                        <div className='m-ac-name-item'>Valor</div>
                    </MenuItem>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('Victory')}>
                        <div className='m-ac-name-item'>Victory</div>
                    </MenuItem>
                    <div className='m-ac-knollwood-category'>Urn:</div>
                    <MenuItem style={nameMenuItemStyle} onClick={() => setLotName('WestChapel')}>
                        <div className='m-ac-name-item'>West Chapel</div>
                    </MenuItem>
                </Menu>
            </div>
        )
    } else {
        return (
            <div>
                
            </div>
        )

    }
}