import React, {useContext} from 'react'

import TextField from '@material-ui/core/TextField'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import CancelIcon from '@material-ui/icons/Cancel'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {InventoryContext} from '../../../../Contexts/InventoryContext'

export default function MobileContact() {

    let {
        showingContact,
        showingMessage,
        showMessage,
        closeContactAndMessage,
        location,
        handleChange,
        detailsSection,
        detailsNumber,
        detailsName,
        detailsOtherLocation,
        sendingMessage,
        cancelSendingMessage,
        sendMessage,
        message,
        finishSendingMessage,
        messageSent
    } = useContext(InventoryContext)

    let today = new Date()
    let day = today.getDate().toString()
    let month = (today.getMonth() + 1).toString()
    let year = today.getFullYear().toString()
    let todaysDate = month.concat('-',day,'-',year)

    function handleSendMessage() {
        sendMessage()

        firebase.firestore().collection('Messages')
        .add({
            body: message,
            date: todaysDate
        })
        .then(() => {
            setTimeout(() => {
                finishSendingMessage()
            }, 800)
        })
        .then(() => {
            setTimeout(() => {
                cancelSendingMessage()
            }, 7000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    let defaultMessage


    let beershebaCheck
    if (location === 'Interfaith') {
        if (detailsSection === '1') {
            beershebaCheck = 'Beersheba'
        } else if (detailsSection === '2') {
            beershebaCheck = 'Beersheba II'
        } else if (detailsSection === '3') {
            beershebaCheck = 'Beersheba III'
        } 
    } else {
        beershebaCheck = detailsSection
    }

    if (location === 'Sharon' || location === 'Interfaith') {
        defaultMessage = `Hello, my name is (Name) and I am interested in lot ${location}-${beershebaCheck}-${detailsNumber}. You can contact me at (Phone Number) or (Email). Thank you`
    } else if (location === 'Knollwood') {
        defaultMessage = `Hello, my name is (Name) and I am interested in lot ${location}-${detailsName}-${detailsNumber}. You can contact me at (Phone Number) or (Email). Thank you`
    } else if (location === 'Estate' || location === 'Other') {
        defaultMessage = `Hello, my name is (Name) and I am interested in lot ${location}-${detailsOtherLocation}. You can contact me at (Phone Number) or (Email). Thank you`
    } else if (location === 'Shara') {
        defaultMessage = `Hello, my name is (Name) and I am interested in lots at Shara. You can contact me at (Phone Number) or (Email). Thank you`

    }

    const messageStyle = {
        marginTop: '7vh',
        zIndex: '50000',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        height: '90vh'
    }
    const contactStyle = {
        fontSize: '10vw',
        color: 'black'
    }
    const messageButtonStyle = {
        backgroundColor: 'rgb(150, 208, 231)',
        textTransform: 'none'
    }
    const exitStyle = {
        color: 'black',
        fontSize: '60px'
    }
    const exitButtonStyle = {
        padding: '0'
    }
    const submitStyle = {
        fontSize: '1.5rem'
    }
    let contactContent, messageContent

    if (messageSent) {
        messageContent = (
            <div className='t-details-successful-message'>
                Thank you for leaving a message. 
                <br/>
                The Lot Broker will be in touch with you at his earliest convenience.
            </div>
        )
    } else {
        messageContent = (
            <div>
                <div className="t-details-contact-or">
                    Or
                </div>
                <Button
                    style={messageButtonStyle}
                    onClick={() => showMessage()}>
                    <div className='t-details-button-style'>
                        Message The Lot Broker
                    </div>
                </Button>
            </div>
        )
    }

    if (showingMessage) {
        contactContent = ( 
            <div className="t-details-contact-container">
                    <div className="t-details-contact-header">
                        <div className="t-details-contact-header-item">
                            Message The Lot Broker
                        </div>
                        <Button
                            disabled={sendingMessage}
                            style={exitButtonStyle}
                            onClick={() => closeContactAndMessage() }>
                            <CancelIcon style={exitStyle}/>
                        </Button>
                    </div>
                    <div className="t-details-contact-message-body">
                        <TextField
                            disabled={sendingMessage}
                            color='secondary'
                            fullWidth
                            multiline
                            rows="5"
                            rowsMax={5}
                            name='message'
                            inputProps={{ style: { fontSize: '1.7rem', lineHeight: '1.4'} }}
                            defaultValue={defaultMessage}
                            variant="outlined"
                            onChange={(event) => { handleChange(event) }}/>
                    </div>
                    <div className="t-details-contact-message-submit">
                        <Button
                            style={submitStyle}
                            disabled={sendingMessage}
                            variant='contained'
                            color='secondary'
                            onClick={() => handleSendMessage() }
                        >
                            Submit
                        </Button>
                        <Button
                            style={submitStyle}
                            disabled={sendingMessage}
                            variant='contained'
                            color='default'
                            onClick={() => cancelSendingMessage() }
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
        )
    } else {
        contactContent = (
            <div className="t-details-contact-container">
                    <div className="t-details-contact-header">
                        <div className="t-details-contact-header-item">
                            Contact The Lot Broker
                        </div>
                        <Button
                            style={exitButtonStyle}
                            onClick={() => closeContactAndMessage() }>
                            <CancelIcon style={exitStyle}/>
                        </Button>
                    </div>
                    <div className="t-details-contact-brand">
                        Contact The Lot Broker at any time at
                    </div>
                    <a href="mailto:GLevine@TheLotBroker.com" style={{textDecoration: 'none'}} className="t-details-contact-item">
                        <div className="t-details-contact-icon">
                            <EmailIcon style={contactStyle} />
                        </div>
                        <div className="t-details-contact-info">
                            GLevine@TheLotBroker.com
                        </div>
                    </a>
                    <a href="tel:6176403143" style={{textDecoration: 'none'}} className="t-details-contact-item">
                        <div className="t-details-contact-icon">
                            <PhoneIcon style={contactStyle} />
                        </div>
                        <div className="t-details-contact-info">
                            (617)640-3143
                        </div>
                    </a>
                    {messageContent}
                </div>
        )
    }
    return (
        <Backdrop
            style={messageStyle}
            open={showingContact}
        >
            <div className='t-details-backdrop-container'>
                {contactContent}
            </div>
        </Backdrop>
    )
}