import React from 'react'

import AdminLotFilterLocation from './AdminLotFilterLocation'
import AdminLotFilterName from './AdminLotFilterName'
import AdminLotFilterSection from './AdminLotFilterSection'

export default function AdminLotFilterBar() {
    return (
        <div className='context-lot-filter-container'>
            <AdminLotFilterLocation />
            <AdminLotFilterName />
            <AdminLotFilterSection />
        </div>
    )
}