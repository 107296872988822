import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function EditButtons() {

    let {
        startEditing,
        cancelEditing,
        editing,
        
        setTemporaryLocation,
        setTemporarySection,
        setTemporaryName,
        setTemporaryNumber,
        setTemporarySpaces,
        setTemporaryPrice,
        setTemporaryComments,
        setTemporaryOtherLocation,
        clearTemporaryValues,
        revertValues,

        detailsLocation,
        detailsSection,
        detailsNumber,
        detailsName,
        detailsSpaces,
        detailsPrice,
        detailsOtherLocation,
        detailsComments,

        savingEdits,
        changingLotSold,
    } = useContext(MobileAdminContext)

    const cancelButtonStyle = {
        padding: '0',
        minHeight: '0',
        width: '100%'
    }
    const editButtonStyle = {
        padding: '0',
        minHeight: '0',
        width: '100%'
    }

    function handleStartEditing() {
        startEditing()

        setTemporaryLocation(detailsLocation)
        setTemporarySection(detailsSection)
        setTemporaryName(detailsName)
        setTemporaryNumber(detailsNumber)
        setTemporarySpaces(detailsSpaces)
        setTemporaryPrice(detailsPrice)
        setTemporaryComments(detailsComments)
        setTemporaryOtherLocation(detailsOtherLocation)

    }
    function handleCancelEditing() {
        revertValues()

        cancelEditing()
        setTimeout(() => {
            clearTemporaryValues()
        }, 100)
    }
    let disabledCheck
    if (savingEdits || changingLotSold) {
        disabledCheck = true
    } else {
        disabledCheck = false
    }

    if (editing) {
        return (
            <div className='t-ac-edit-buttons-container'>
                <Button   
                    variant='contained'
                    disabled={savingEdits}
                    style={cancelButtonStyle}
                    onClick={() => handleCancelEditing()} >
                        <div className='t-ac-cancel-changes-button'>
                            Cancel
                        </div>
                </Button>
            </div>
        )
    } else {
        return (
            <div className='t-ac-edit-buttons-container'>
                <Button 
                    disabled={disabledCheck}
                    variant='contained'
                    color='default'
                    style={editButtonStyle}
                    onClick={() => {
                        handleStartEditing()
                    }} >
                        <div className='t-ac-start-changes-button'>
                            Edit Lot Details
                        </div>
                </Button>
            </div>
        )
    }
}