import React, {useContext} from 'react'

import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone'
import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {AdminContext} from './../../../../Contexts/AdminContext'

export default function MessageCard(props) {
    let {
        body,
        date,
        id
    } = props

    let {
        clearAdminMessages,
        addMessage
    } = useContext(AdminContext)

    function handleDelete() {
        firebase.firestore().collection('Messages').doc(id)
        .delete()
        .then(() => {
            clearAdminMessages()
        })
        .then(() => {
            firebase.firestore().collection('Messages').get()
            .then(snapshot => {
                snapshot.forEach(msg => {
                    let message = {
                        body: msg.data().body,
                        date: msg.data().date,
                        id: msg.id
                    }
                    addMessage(message)
                })
            })
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    return (
        <div className='d-a-message-card-container'>
            <div className="d-a-m-c-body">
                <div className="d-a-m-c-body-fix">
                    {body}
                </div>
            </div>
            <div className="d-a-m-c-date">
                {date}
            </div>
            <div className="d-a-m-c-delete">
                <Button
                    onClick={() => {
                        handleDelete()
                    }}
                >
                    <DeleteTwoToneIcon />
                </Button>
            </div>
        </div>
    )
}