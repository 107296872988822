import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'

import {MobileAdminContext} from './../../../../../Contexts/MobileAdminContext'

export default function AdminHome() {

    let {
        setAdminView,
        messagesLoaded,
        numberOfMessages,
        testimonialsLoaded,
        numberOfTestimonials
    } = useContext(MobileAdminContext)

    const adminButtonStyle = {
        padding: '0',
        textTransform: 'none',
        borderRadius: '15px'
    }

    let messagesCheck, testimonialsCheck
    if (messagesLoaded) {
        messagesCheck = (
            <div className='m-ac-messages-update-check'>
                ({numberOfMessages})
            </div>
        )
    } else {
        messagesCheck = ( '' )
    }
    if (testimonialsLoaded) {
        testimonialsCheck = (
            <div className='m-ac-messages-update-check'>
                ({numberOfTestimonials})
            </div>
        )
    } else {
        testimonialsCheck = ( '' )
    }

    return (
        <div className='m-ac-home-button-container'>
            <Button
                style={adminButtonStyle}
                onClick={() => setAdminView('Add New Lot')}>
                    <div className='m-ac-home-button'>
                        Add New Lot
                    </div>
            </Button>
            <Button
                style={adminButtonStyle}
                onClick={() => setAdminView('Lots')}>
                    <div className='m-ac-home-button'>
                        Lots
                    </div>
            </Button>
            <Button
                style={adminButtonStyle}
                onClick={() => setAdminView('Messages')}>
                    <div className='m-ac-home-button'>
                        Messages{messagesCheck}
                    </div>
            </Button>
            <Button
                style={adminButtonStyle}
                onClick={() => setAdminView('News')}>
                    <div className='m-ac-home-button'>
                        News
                    </div>
            </Button>
            <Button
                style={adminButtonStyle}
                onClick={() => setAdminView('Testimonials')}>
                    <div className='m-ac-home-button'>
                        Testimonials{testimonialsCheck}
                    </div>
            </Button>
            <Button
                style={adminButtonStyle}
                onClick={() => setAdminView('Gallery')}>
                    <div className='m-ac-home-button'>
                        Gallery
                    </div>
            </Button>

        </div>
    )
}