import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function LocationFilter() {

    let {
        loading,
        filterLocation,
        anchor,
        setFilterLocation,
        setFilterAnchor,
        filterMenu,
        clearAnchors,
        finishLoadingLots,
        clearLotList,
        addToLotList
    } = useContext(MobileAdminContext)

    let locationHeader
    if (filterLocation) {
        locationHeader = filterLocation
    } else {
        locationHeader = 'Location'
    }

    const menuItemStyle = {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        textAlign: 'center',
        fontSize: '2.5rem',
        textDecoration: 'none',
        // width: '40vw',
    }
    const menuStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        zIndex: '1000'
    }

    const filterButtonStyle = {
        textTransform: 'none',
        fontSize: '2.5rem',
        color: 'white',
        paddingLeft: '25px',
        paddingRight: '25px',
        // backgroundColor: 'white',
        paddingTop: '3px',
        paddingBottom: '3px'
    }
    function handleFilterLocation(location) {
        clearLotList()
        setFilterLocation(location)

        if (location === 'Sharon' || location === 'Interfaith') {
            firebase.firestore().collection(location).orderBy('priority').orderBy('section').orderBy('lotNumber')
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let newLot = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addToLotList(newLot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    finishLoadingLots()
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Knollwood') {
            firebase.firestore().collection('Knollwood').orderBy('lotName').orderBy('lotNumber')
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let newLot = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addToLotList(newLot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    finishLoadingLots()
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'sold') {
            firebase.firestore().collection('sold').where('location', '==', 'Sharon').orderBy('priority').orderBy('section')
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let newLot = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addToLotList(newLot)
                })
            })
            .then(() => {
                firebase.firestore().collection('sold').where('location', '==', 'Interfaith').orderBy('priority').orderBy('section')
                .get()
                .then((snapshot) => {
                    snapshot.forEach(lot => {
                        let newLot = {
                            lotNumber: lot.data().lotNumber,
                            location: lot.data().location,
                            section: lot.data().section,
                            lotName: lot.data().lotName,
                            otherLocation: lot.data().otherLocation,
                            price: lot.data().price,
                            lotId: lot.id,
                            spaces: lot.data().spaces
                        }
                        addToLotList(newLot)
                    })
                })
            })
            .then(() => {
                firebase.firestore().collection('sold').where('location', '==', 'Knollwood').orderBy('lotName').orderBy('lotNumber')
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let newLot = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addToLotList(newLot)
                })
            })
            })
            .then(() => {
                setTimeout(() => {
                    finishLoadingLots()
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else {
            firebase.firestore().collection(location)
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let newLot = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addToLotList(newLot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    finishLoadingLots()
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
        }


        setTimeout(() => {
            finishLoadingLots()
        }, 2500)
    }
    return (
        <div>
            <Button
                style={filterButtonStyle}
                disabled={loading}
                aria-controls="location-menu" 
                aria-haspopup="true" 
                onClick={event => {
                    setFilterAnchor(event.currentTarget)
                }}>
                    {locationHeader}
            </Button>
            <Menu
                disabled={loading}
                transition={1000}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="view-location-menu"
                anchorEl={anchor}
                keepMounted
                open={filterMenu}
                style={menuStyle}
                MenuListProps={{ disablePadding: true}}
                onClose={() => { clearAnchors() }}>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                        handleFilterLocation('Sharon')
                }}>Sharon</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    handleFilterLocation('Interfaith')
                }}>Interfaith</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    handleFilterLocation('Knollwood')
                }}>Knollwood</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    handleFilterLocation('Shara')
                }}>Shara Tfilo</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    handleFilterLocation('Estate')
                }}>Estates</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    handleFilterLocation('Other')
                }}>Other</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    handleFilterLocation('sold')
                }}>Sold</MenuItem>
            </Menu>
        </div>
    )
}