import React, {useContext} from 'react'

import {InventoryContext} from './../../../Contexts/InventoryContext'

import InventoryHeader from './InventoryHeader'
import InventoryCard from './InventoryCard'
export default function InventoryView() {
    let {
        visibleLots,
        location
    } = useContext(InventoryContext)

    let listContent, footerMessage
    
    if (location === 'Sharon' || location === 'Interfaith') {
        listContent = visibleLots.map(lot => {
            return (
                <InventoryCard 
                    key={lot.id}
                    id={lot.id}
                    location={lot.location}
                    price={lot.price}
                    section={lot.section}
                    spaces={lot.spaces}
                    lotNumber={lot.lotNumber}
                />
            )
        })
    } else if (location === 'Knollwood') {
        listContent = visibleLots.map(lot => {
            return (
                <InventoryCard 
                    key={lot.id}
                    id={lot.id}
                    price={lot.price}
                    lotName={lot.lotName}
                    spaces={lot.spaces}
                    lotNumber={lot.lotNumber}
                />
            )
        })
    } else if (location === 'Estate' || location === 'Other') {
        listContent = visibleLots.map(lot => {
            return (
                <InventoryCard 
                    key={lot.id}
                    id={lot.id}
                    price={lot.price}
                    otherLocation={lot.otherLocation}
                    spaces={lot.spaces}
                />
            )
        })
    } else if (location === 'sold') {
        listContent = visibleLots.map(lot => {
            return (
                <InventoryCard 
                    key={lot.id}
                    id={lot.id}
                    lotLocation={lot.location}
                    price={lot.price}
                    lotNumber={lot.lotNumber}
                    spaces={lot.spaces}
                    lotName={lot.lotName}
                    section={lot.section}
                    otherLocation={lot.otherLocation}
                />
            )
        })
    } else if (location === 'Shara') {
        listContent = visibleLots.map(lot => {
            return (
                <InventoryCard 
                    key={lot.id}
                    id={lot.id}
                    price={lot.price}
                    spaces={lot.spaces}
                />
            )
        })
    }
    location === 'sold'
        ? footerMessage = ( '' )
        : footerMessage = (
            <div className='d-inventory-lotview-footer'>
                Click on a Lot for more details
            </div>
        )
    return (
        <div className='d-inventory-lotview-container'>
            <div className='d-inventory-lotview-header'>
                <InventoryHeader />
            </div>
            <div className='d-inventory-lotview-lots'>
                {listContent}
            </div>
            {footerMessage}
        </div>
    )
}