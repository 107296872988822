import React, {createContext} from 'react'

import firebase from 'firebase/app'
import 'firebase/firestore'

export const AboutContext = createContext()

export class AboutContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showAbout: true,
            showNews: false,
            showFaq: null,
            showLegal: false,
            toggleLegal: () => {
                this.setState({
                    showAbout: false,
                    showNews: false,
                    showFaq: null,
                    showLegal: true
                })
            },
            toggleAbout: () => {
                this.setState({
                    showAbout: true,
                    showNews: false,
                    showFaq: null,
                    showLegal: false
                })
            },
            toggleNews: () => {
                this.setState({
                    showAbout: false,
                    showNews: true,
                    showFaq: null,
                    showLegal: false
                })
            },
            toggleFaq: (faq) => {
                this.setState({
                    showAbout: false,
                    showNews: false,
                    showFaq: faq,
                    showLegal: false
                })
            },
            newsListings: [],
            loaded: false
        }
    }

    // component did mount get news
    componentDidMount() {
        firebase.firestore().collection('News').orderBy('date', "asc")
        .get()
        .then(snapshot => {
            snapshot.forEach(news => {
                let newsItem = {
                    title: news.data().title,
                    content: news.data().content,
                    date: news.data().date,
                    id: news.id,
                }
                this.setState({
                    newsListings: [...this.state.newsListings, newsItem]
                })
            })
        })
        .then(() => {
            this.setState({
                loaded: true
            })
        })
        .catch(err => {
            console.error(err.code)
        })
    }



    render() {
        return (
            <AboutContext.Provider value={this.state} >
                {this.props.children}
            </AboutContext.Provider>
        )
    }
}