import React, {useContext} from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import LinearProgress from '@material-ui/core/LinearProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {AdminContext} from './../../../../Contexts/AdminContext'

export default function AddNewsBackdrop() {

    let {
        showingAddNews,
        hideAddNews,
        handleChange,
        addNews,
        addingNews,
        addedNews,
        newsContent,
        newsTitle,
        clearNewsList,
        addToNewsList,
        successfullyAddedNews
    } = useContext(AdminContext)

    let newsFeedback, titleCheck, contentCheck, newsAddContent, newsButtonContent

    newsContent
        ? contentCheck = newsContent
        : contentCheck = ''

    newsTitle
    ? titleCheck = newsTitle
    : titleCheck = ''

    successfullyAddedNews
        ? newsAddContent = (
            <div className='d-a-news-backdrop-body'>
                News Added Successfully!
            </div>
        )
        : newsAddContent = (
            <div className='d-a-news-backdrop-body'>
                    <TextField 
                        disabled={addingNews}
                        name='newsTitle'
                        value={titleCheck}
                        label="News Title"
                        variant="outlined"
                        color='secondary'
                        fullWidth
                        onChange={(event) => {
                            handleChange(event)
                        }}
                    />
                    <TextField 
                        disabled={addingNews}
                        name='newsContent'
                        value={contentCheck}
                        label="News Content"
                        color='secondary'
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={6}
                        rowsMax={6}
                        onChange={(event) => {
                            handleChange(event)
                        }}
                    />
                </div>

        )
    successfullyAddedNews
        ? newsButtonContent = ( 
            <div className='d-a-news-backdrop-submit'>

            </div>
         )
        : newsButtonContent = (
            <div className='d-a-news-backdrop-submit'>
                    <Button
                        disabled={addingNews}
                        variant='contained'
                        color='secondary'
                        onClick={() => { handleAddNews() }}>
                        Submit
                    </Button>
                    <Button onClick={() => { hideAddNews() }} variant='contained' disabled={addingNews}>
                        Cancel
                    </Button>
                </div>
        )
    const style = {
        color: 'white',
        fontSize: '5vh'
    }
    const style2 = {
        backgroundColor: 'skyblue',
        height: '100%',
        width: '100%'
    }

    function handleAddNews() {
        addNews()
        clearNewsList()

        let today = new Date()
        let day = today.getDate().toString()
        let month = (today.getMonth() + 1).toString()
        let year = today.getFullYear().toString()
        let todaysDate = month.concat('-',day,'-',year)


        firebase.firestore().collection('News')
        .add({
            title: newsTitle,
            content: newsContent,
            date: todaysDate
        })
        .then(() => {
            firebase.firestore().collection('News')
            .get()
            .then(snapshot => {
                snapshot.forEach(news => {
                    let newNews = {
                        title: news.data().title,
                        content: news.data().content,
                        date: news.data().date,
                        id: news.id
                    }
                    addToNewsList(newNews)
                })
            })
        })
        .then(() => {
            setTimeout(() => {
                addedNews()
            }, 1500)
        })
        .then(() => {
            setTimeout(() => {
                hideAddNews()
            }, 5500)
        })
        .catch(err => {
            console.error(err.code)
        })
    }



    addingNews
        ? newsFeedback = ( <LinearProgress style={style2}/> )
        : newsFeedback = ( '' )

    return (
        <Backdrop
            open={showingAddNews}
            style={{ zIndex: 2000}}>
            <div className='d-a-news-backdrop-container'>
                <div className='d-a-news-backdrop-header'>
                    <div className='d-a-news-backdrop-header-item1'>
                        Add News
                    </div>
                    <div className='d-a-news-backdrop-header-item'>
                        <Button onClick={() => { hideAddNews() }} disabled={addingNews}>
                            <CancelIcon style={style}/>
                        </Button>
                    </div>
                </div>
                {newsAddContent}
                {newsButtonContent}
                <div className='d-a-news-backdrop-feedback'>
                    {newsFeedback}
                </div>
            </div>
        </Backdrop>
    )
}