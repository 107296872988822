import React from 'react'
import {
    isMobileOnly, 
    isTablet 
} from 'react-device-detect'

import LoginDesktop from './LoginDesktop'
import LoginMobile from './LoginMobile'
import LoginTablet from './LoginTablet'

export default function Login() {
    if (isMobileOnly) {
        return ( <LoginMobile />)
    } else if (isTablet) {
        return ( <LoginTablet /> )
    } else {
        return ( <LoginDesktop /> )
    }
}