import React, {useContext} from 'react'

// import CircularProgress from '@material-ui/core/CircularProgress'

import {GalleryContext} from './../../Contexts/GalleryContext'

export default function NewShara() {

    let {
        loaded,
        sharaGallery
    } = useContext(GalleryContext)


    let galleryContent

    if (loaded) {

        galleryContent = sharaGallery.map(item => {
            return (
                <div 
                    className='d-gallery-updated-item' 
                    key={Math.random()} 
                    style={{ 
                        // flexShrink: '0',
                        height: '600px',
                        width: '100%',
                        backgroundImage: `url(${item.address})`, 
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                    
                </div>
    
            )
        })
        
    } else {
        galleryContent = (
            ''
        // <CircularProgress color='secondary' size={160}/>
        )
    }

    return (

        <div className='d-gallery-updated-container'>
            {galleryContent}
        </div>
    )
}