import React from 'react'

import firebase from 'firebase/app'
import 'firebase/firestore'

import AdminGalleryCard from './AdminGalleryCard'

export default class AdminGallery extends React.Component {
    constructor() {
        super()

        this.state = {
            galleryCards: []
        }
    }
    componentDidMount() {
        const db = firebase.firestore().collection('Gallery')
        db
        .orderBy('order')
        .get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                let galleryItem = {
                    header: doc.data().header,
                    body: doc.data().body,
                    imageId: doc.data().imageId,
                    order: doc.data().order,
                    galleryId: doc.id,
                    imageUrl: doc.data().imageUrl
                }
                this.setState({
                    galleryCards: [...this.state.galleryCards, galleryItem]
                })
            })
        })
        .catch(err => {
            console.error(err)
        })
    }

    render() {
        let { galleryCards } = this.state
        let adminGalleryContent
        galleryCards.length
            ? adminGalleryContent = (
                galleryCards.map(card => {
                    return (
                        <AdminGalleryCard 
                            key={card.galleryId}
                            galleryId={card.galleryId}
                            header={card.header}
                            body={card.body}
                            imageId={card.imageId}
                            order={card.order}
                            imageUrl={card.imageUrl}
                        />
                    )
                })
            )
            : adminGalleryContent = ( 'Loading...' )
        
        return (
            <div className='admin-d-gallery-container'>
                {adminGalleryContent}
            </div>
        )
    }
}