import React, {useContext} from 'react'

import NewsCard from './NewsCard'

import {AboutContext} from './../../Contexts/AboutContext'

export default function News() {
    let {
        loaded,
        newsListings
    } = useContext(AboutContext)

    let newsContent
    if (loaded) {
        newsContent = newsListings.map(news => {
            return (
                <NewsCard 
                    key={news.id}
                    date={news.date}
                    content={news.content}
                    title={news.title}
                />
            )
        })
    } else {
        newsContent = 'Loading news...'
    }

    return (
        <div className='m-about-news-container'>
            {newsContent}
            <div className="m-scroll-fix">

            </div>
        </div>
    )
}