import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { AdminContext } from './../../../../../Contexts/AdminContext'

export default function LotCardAdditionalMenu() {
    let { 
        editingCard,
        setNameAnchor,
        nameMenu,
        anchor,
        clearAnchors,
        lotName,
        setLotName,
        location,
        otherLocation,
        handleChange
    } = useContext(AdminContext)

    let nameHeader

    lotName
        ? nameHeader = ( <div className='new-admin-d-location-choice'>{lotName}</div> )
        : nameHeader = ( <div className='new-admin-d-location-choice'>Lot Name</div> )

    if (location === 'Knollwood') {
        return (
            <div className="new-admin-d-add-lot-backdrop-name">
                <div className="new-admin-d-add-lot-backdrop-location-menu">
                <Button 
                    aria-controls="location-menu"
                    aria-haspopup="true"
                    disabled={(!editingCard)}
                    onClick={event => {
                        setNameAnchor(event.currentTarget)
                    }}>
                    {nameHeader}
                </Button>
                <Menu
                    style={{ zIndex: 1000030}}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    id="location-menu"
                    anchorEl={anchor}
                    keepMounted
                    open={nameMenu}
                    onClose={() => clearAnchors() }>
                    <div className='new-admin-d-knollwood-category'>Garden of:</div>
                    <MenuItem onClick={() => setLotName('Cross') }>Cross</MenuItem>
                    <MenuItem onClick={() => setLotName('Faith') }>Faith</MenuItem>
                    <MenuItem onClick={() => setLotName('Gethsemane') }>Gethsemane</MenuItem>
                    <MenuItem onClick={() => setLotName('Last Supper') }>Last Supper</MenuItem>
                    <MenuItem onClick={() => setLotName('Meditation') }>Meditation</MenuItem>
                    <MenuItem onClick={() => setLotName('Peace') }>Peace</MenuItem>
                    <MenuItem onClick={() => setLotName('Remembrance') }>Remembrance</MenuItem>
                    <MenuItem onClick={() => setLotName('Saviour') }>Saviour</MenuItem>
                    <MenuItem onClick={() => setLotName('Sunset') }>Sunset</MenuItem>
                    <MenuItem onClick={() => setLotName('Word') }>Word</MenuItem>
                    <div className='new-admin-d-knollwood-category'>Veterans:</div>
                    <MenuItem onClick={() => setLotName('Honor') }>Honor</MenuItem>
                    <MenuItem onClick={() => setLotName('Memorial') }>Memorial</MenuItem>
                    <MenuItem onClick={() => setLotName('Valor') }>Valor</MenuItem>
                    <MenuItem onClick={() => setLotName('Victory') }>Victory</MenuItem>
                </Menu>
            </div>
        </div>
        )
    } else if (location === 'Sharon' || location === 'Interfaith' || location === 'Shara') {
        return (
            <div></div>
        )
    } else if (location === 'Estate' || location === 'Other') {
        return (
            <TextField 
                disabled={(!editingCard)}
                color='secondary'
                name='otherLocation'
                type='text'
                defaultValue={otherLocation}
                label='Location'
                variant='outlined'
                onChange={event => {
                    handleChange(event)
                }}/>
        )
    }
}