import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'

import MessageCard from './MessageCard'
import DeleteConfirmation from './DeleteConfirmation'

import {AdminContext} from './../../../../Contexts/AdminContext'

export default function AdminMessages() {

    let {
        adminMessages,
        messagesLoaded,
        showingDeleteConfirmation,
        showDeleteAll
    } = useContext(AdminContext)

    let adminMessageContent, deleteAllContent, yes, annoying = false

    if (annoying) {
        return yes
    }
    messagesLoaded
        ? adminMessageContent = (
            adminMessages.map(msg => {
                return (
                    <MessageCard 
                        key={msg.id}
                        body={msg.body}
                        date={msg.date}
                        id={msg.id}
                        />
                )
            })
        ) : adminMessageContent = (
            <div className='d-a-messages-none'>
                Loading messages...
            </div>
        )
        messagesLoaded && adminMessages.length < 1
            ? adminMessageContent = (
                <div className='d-a-messages-none'>
                    No new messages
                </div>
            )
            : yes = ''

    showingDeleteConfirmation
        ? deleteAllContent = ( <DeleteConfirmation /> )
        : deleteAllContent = ( '' )

    return (
        <div className='d-a-messages-container'>
            <div className='d-a-messages-header'>
                <div className="d-a-messages-header-item">
                    Messages
                </div>
                <div className="d-a-messages-header-item">
                    <Button
                        disabled={adminMessages.length < 1}
                        onClick={() => {
                            showDeleteAll()
                        }}
                    >
                        <div className='d-a-delete-all-button'>
                            Delete All
                        </div>
                    </Button>
                </div>
            </div>
            <div className="d-a-messages-content-header">
                <div className="d-a-messages-content-body">
                    Message
                </div>
                <div className="d-a-messages-content-date">
                    Date Sent
                </div>
                <div className="d-a-messages-content-delete">
                    Delete   
                </div>
            </div>
            <div className="d-a-messages-content-container">
                {adminMessageContent}
                {deleteAllContent}
            </div>
            {annoying}
        </div>
    )
}