import React, { 
    // useContext 
} from 'react'

export default function LotCardDivider() {

    return(
        <div className='admin-final-d-lot-card-divider-container'>
        </div>
    )
}