import React, {useContext} from 'react'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import firebase from 'firebase/app'
import 'firebase/auth'

import {GlobalContext} from './../../../../Contexts/GlobalContext'

export default function LoginMobile() {

    let {
        handleChange,
        email,
        password,
        error,
        setError,
        loggedIn,
        loading,
        startLoading,
        catchError
    } = useContext(GlobalContext)

    const formStyle = {
        width: '60vw',
        marginBottom: '2vh',
        backgroundColor: 'rgba(150, 208, 231, 0.849)',
        padding: '4px',
        borderRadius: '3px',
        borderBottomColor: 'yellow'

    }
    const buttonStyle = {
        backgroundColor: 'rgba(150, 208, 231, 0.849)',
        padding: '0',
        textTransform: 'none',
        fontSize: '1.4rem'
    }
    const loadingStyle = {
        color: 'white',
        padding: '5px'
    }

    function handleSignIn() {
        if (email && password) {
            setError('')
            startLoading()
            firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                setTimeout(() => {
                    loggedIn()
                }, 1700)
            })
            .catch((err) => {
                catchError(err.message)
            })
        } else if (email) {
            setError('Please enter your password')
        } else if (password) {
            setError('Please enter your email')
        } else {
            setError('Please enter login credentials')
        }
    }

    let headerMessage, buttonContent
    if (error) {
        headerMessage = (
            <div className='m-login-error'>
                {error}
            </div>
        )
        buttonContent = 'Login'
    } else if (loading) {
        headerMessage = 'Signing in...'
        buttonContent = ( <CircularProgress style={loadingStyle} size={30}/>)
    } else {
        headerMessage = 'Admin Login'
        buttonContent = 'Login'
    }
    return (
        <div className='m-admin-container'>
            <div className="m-login-container">
                <div className="m-login-header">
                    {headerMessage}
                </div>
                <form
                    className='m-login-form'
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSignIn()
                    }}
                >
                    <TextField 
                        style={formStyle}
                        disabled={loading}
                        name='email'
                        placeholder='Email'
                        type='email'
                        onChange={e => handleChange(e)}/>
                    <TextField 
                        style={formStyle}
                        disabled={loading}
                        name='password'
                        placeholder='Password'
                        type='password'
                        onChange={e => handleChange(e)}/>
                    <Button
                        style={buttonStyle}
                        disabled={loading}
                        onClick={() => handleSignIn()}>
                        {buttonContent}
                    </Button>
                </form>

            </div>
        </div>
    )
}