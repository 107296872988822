import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { AdminContext } from './../../../../Contexts/AdminContext'

import firebase from 'firebase/app'
import 'firebase/firestore'
export default function AdminLotFilterName() {

    let {
        addLotToVisibleList,
        stopLoadingLocation,
        startLoadingLocation,
        loadingLocation,
        setFilterName,
        filterNameMenu,
        filterName,
        // setFilterLocation,
        filterLocation,
        clearAnchors,
        anchor,
        // filterLocationMenu,
        setFilterNameAnchor,
        // clearNameFilter
    } = useContext(AdminContext)

    let disableNameCheck, nameFilterHeader
    filterLocation !== 'Knollwood' || loadingLocation
        ? disableNameCheck = true
        : disableNameCheck = false
    
    filterName
        ? nameFilterHeader = filterName
        : filterLocation === 'Knollwood'
            ? nameFilterHeader = 'Lot Name'
            : nameFilterHeader = ''

    function handleFilterByName(nameOfLot) {
        // need to clear current lot list
        startLoadingLocation()
        setFilterName(nameOfLot)

        firebase.firestore().collection('Knollwood').where('lotName', '==', nameOfLot)
        .get()
        .then(snapshot => {
            snapshot.forEach(lot => {
                // add lot details to been seen and needed for
                let lotDetails = {
                    lotNumber: lot.data().lotNumber,
                    location: lot.data().location,
                    section: lot.data().section,
                    lotName: lot.data().lotName,
                    otherLocation: lot.data().otherLocation,
                    price: lot.data().price,
                    lotId: lot.id,
                    spaces: lot.data().spaces
                }
                addLotToVisibleList(lotDetails)
            })
        })
        .then(() => {
            setTimeout(() => {
                stopLoadingLocation()
            }, 700)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    if (filterLocation === 'Knollwood') {
        return (
            <div className='context-lot-filter-name'>
                <Button aria-controls="location-menu" aria-haspopup="true"
                    disabled={disableNameCheck}
                    onClick={event => {
                        setFilterNameAnchor(event.currentTarget)
                    }}>
                        <div className='context-lot-filter-font'>
                            {nameFilterHeader}
                        </div>
                </Button>
                <Menu
                    style={{ zIndex: 1000030}}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    id="location-menu"
                    anchorEl={anchor}
                    keepMounted
                    open={filterNameMenu}
                    onClose={() => {
                        clearAnchors()
                    }}>
                    <div className='new-admin-d-knollwood-category'>Garden of:</div>
                    <MenuItem onClick={() => handleFilterByName('Cross') }>Cross</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Faith') }>Faith</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Gethsemane') }>Gethsemane</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Last Supper') }>Last Supper</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Meditation') }>Meditation</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Peace') }>Peace</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Remembrance') }>Remembrance</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Saviour') }>Saviour</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Sunset') }>Sunset</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Word') }>Word</MenuItem>
                    <div className='new-admin-d-knollwood-category'>Veterans:</div>
                    <MenuItem onClick={() => handleFilterByName('Honor') }>Honor</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Memorial') }>Memorial</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Valor') }>Valor</MenuItem>
                    <MenuItem onClick={() => handleFilterByName('Victory') }>Victory</MenuItem>
                </Menu>
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
}