import React, { useContext } from 'react'

// import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/Textfield'
import Button from '@material-ui/core/Button'

import CommentsSection from './CommentsSection'
import SubmitNewLotButton from './SubmitNewLotButton'

import { AdminContext } from './../../../../Contexts/AdminContext'

export default function SharonForm() {
    let {
        handleChange,
        clearNewLotAdd
    } = useContext(AdminContext)

    return (
        <div className='new-admin-d-location-sharon-form'>
            <div className="new-admin-d-location-sharon-form-row">
                <div className="new-admin-d-location-sharon-form-item">
                    <TextField
                        color='secondary'
                        name='section'
                        className=""
                        type="text"
                        label="Section"
                        variant="outlined" 
                        onChange={event => {
                            handleChange(event)
                        }}/>
                </div>
                <div className="new-admin-d-location-sharon-form-item">
                    <TextField
                        color='secondary'
                        name='lotNumber'
                        className=""
                        type="text"
                        label="Number"
                        variant="outlined" 
                        onChange={event => {
                    handleChange(event)
                }}/>
                </div>
            </div>
            <div className="new-admin-d-location-sharon-form-row">
                <div className="new-admin-d-location-sharon-form-item">
                    <TextField
                        color='secondary'
                        name='spaces'
                        className=""
                        type="text"
                        label="Spaces"
                        variant="outlined" 
                        onChange={event => {
                    handleChange(event)
                }}/>
                </div>
                <div className="new-admin-d-location-sharon-form-item">
                    <TextField
                        // InputProps={{
                        //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        //   }}
                        color='secondary'
                        name='price'
                        placeholder='0'
                        type="number"
                        label="Price"
                        variant="outlined" 
                        onChange={event => {
                    handleChange(event)
                }}/>
                </div>
            </div>
            <div className="new-admin-d-location-sharon-form-comment">
                <CommentsSection />
            </div>
            <div className='new-admin-d-lot-submit-row'>
                <div className='new-admin-d-lot-submit-row-item'>
                    <Button 
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            clearNewLotAdd()
                        }}
                    >
                        Clear
                    </Button>
                </div>
                <div className='new-admin-d-lot-submit-row-item'>
                    <SubmitNewLotButton />
                </div>
            </div>
        </div>
    )
}