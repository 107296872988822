import React from 'react'
import { isMobileOnly, isTablet, isIPad13 } from 'react-device-detect'

import NavDesktop from './NavDesktop'
import NavMobile from './NavMobile'
import NavTablet from './NavTablet'

export default function Nav () {
    if (isMobileOnly) {
        return ( <NavMobile /> )
    } else if (isTablet || isIPad13) {
        return ( <NavTablet /> )
    } else {
        return ( <NavDesktop /> )
    }
}