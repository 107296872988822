import React from 'react'

import {AdminContextProvider} from './../../../Contexts/AdminContext'

import HomeDesktopAdmin from './../../../Home/Desktop/HomeDesktopAdmin'
export default function LoggedIn() {

    return (
        <AdminContextProvider>
            <div className='home-d-container'>
                <HomeDesktopAdmin />
            </div>
        </AdminContextProvider>
    )
}