import React, {useContext} from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import DetailsHeader from './DetailsHeader'
import DetailsGallery from './DetailsGallery'
import DetailsDetails from './DetailsDetails'
import DetailsFooter from './DetailsFooter'

import { InventoryContext } from './../../../Contexts/InventoryContext'

export default function DetailsCard() {

    let {
        showingDetails,
        loadingDetails
    } = useContext(InventoryContext)

    let detailsContent

    loadingDetails
        ? detailsContent = (
                <CircularProgress color='primary' size={170}/>
        )
        : detailsContent = (
            <div className='d-inventory-backdrop-container'>
                <DetailsHeader />
                <DetailsGallery />
                <DetailsDetails />
                <DetailsFooter />
            </div>
        )



    return (
        <Backdrop 
            transitionDuration={1100}
            style={{zIndex: 1000000}}
            open={showingDetails}>
                    {detailsContent}
        </Backdrop>
    )
}