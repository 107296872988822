import React, { useContext } from 'react'

import TextField from '@material-ui/core/Textfield'
import Button from '@material-ui/core/Button'

import {MobileAdminContext} from './../../../../../Contexts/MobileAdminContext'

export default function Comments() {

    let {
        location,
        lotName,
        handleChange,
        addingLot,
        showAddComment,
        section,
        showingAddComment
    } = useContext(MobileAdminContext)

    const commentsSpacing = {
        marginTop: '15px',
        marginBottom: '15px',
        textTransform: 'none',
        width: '100%'
    }

    let commentContent

    if (showingAddComment) {
        commentContent = (
            <TextField
                disabled={addingLot}
                style={commentsSpacing}
                fullWidth
                color='secondary'
                name='comments'
                type="text"
                rowsMax={3}
                multiline
                label="Comments"
                variant="outlined" 
                onChange={event => handleChange(event) }/>
        )
    } else {
        commentContent = (
            <Button
                disabled={addingLot}
                style={commentsSpacing}
                onClick={() => showAddComment() }>
                    <div className='m-ac-add-comment-button'>
                        Add comment
                    </div>
            </Button>
        )
    }

    if (location === null || (location === 'Knollwood' && lotName === null) || (location === 'Interfaith' && section === null)) {
        return (
            <div>
    
            </div>
        )
    } else {
        return (
            <div className='m-ac-add-lot-comment'>
                {commentContent}
            </div>
        )
    }
}