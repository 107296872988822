import React, {useContext} from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import {GalleryContext} from '../../Contexts/GalleryContext'

export default function SharaMobile() {
    let {
        loaded,
        sharaGallery
    } = useContext(GalleryContext)

    const loadingStyle = {
        color: 'rgba(150, 208, 231, 0.849)',
        marginTop: '150px'
    }

    if (loaded) {
        return (
            sharaGallery.map(item => {
                return (
                    <div 
                        className='t-gallery-scroll-item' 
                        key={Math.random()} 
                        style={{ 
                            marginBottom: '5px',
                            flexShrink: '0',
                            height: '55vh',
                            width: '100%',
                            backgroundImage: `url(${item.address})`, 
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'}}>
                    </div>
                )
            })
        )
    } else {
        return ( <CircularProgress style={loadingStyle} size={150}/> )
    }
}