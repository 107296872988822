import React, { useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import { AdminContext } from './../../../../../Contexts/AdminContext'

export default function LotCardLotNumber() {
    let { 
        location,
        lotNumber,
        editingCard,
        handleChange
    } = useContext(AdminContext)

    let lotNumberValue 
    if (lotNumber) {
        lotNumberValue = lotNumber
    } else {
        lotNumberValue = ''
    }

    let availabilityCheck
    editingCard && (location === 'Sharon' || location === 'Knollwood' || location === 'Interfaith')
        ? availabilityCheck = true
        : availabilityCheck = false
    return (
        <TextField 
            disabled={(!availabilityCheck)}
            color='secondary'
            name='lotNumber'
            type='text'
            value={lotNumberValue}
            label='Lot Number'
            variant='outlined'
            onChange={event => {
                handleChange(event)
            }}/>
    )

}