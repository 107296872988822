import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

import CircularProgress from '@material-ui/core/CircularProgress'

import NewsCard from './NewsCard'
export default function AllNews() {

    let {
        newsLoaded,
        newsArticles
    } = useContext(MobileAdminContext)


    const loadingStyle = {
        marginTop: '50px'
    }

    if (newsLoaded) {
        return (
            <div className='m-ac-all-news-container'>
                {newsArticles.map(news => {
                    return (
                        <NewsCard
                            content={news.content}
                            date={news.date}
                            title={news.title}
                            id={news.id}
                            key={news.id}
                            />
                    )
                })}
                <div className="m-scroll-fix"></div>
                <div className="m-scroll-fix"></div>
            </div>
        )
    } else {
        return (
            <CircularProgress style={loadingStyle} size={140}/>
        )
    }
}