import React, { useContext } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

import WhiteLogo from './../../../../../../StyleNew/logoWhite.png'

import DateInfo from './DateInfo'
import SoldButton from './SoldButton'
import DeleteLotButton from './DeleteLotButton'
import DetailsContent from './DetailsContent'
import DetailsHeader from './DetailsHeader'
import DetailsPicture from './DetailsPicture'
import {MobileAdminContext} from './../../../../../Contexts/MobileAdminContext'

export default function ViewLotDetails() {

    let {
        // location,
        viewingIndividualLot,
        loadingLot
        } = useContext(MobileAdminContext)

    const backdropStyle = {
        marginTop: '7vh',
        zIndex: '100000',
        backgroundColor: 'rgb(41, 87, 105)',
        height: '90vh'
    }
    const spinnerStyle = {
        color: 'white',
        position: 'relative'
    }
    let feedback
    if (loadingLot) {
        feedback = (
            <div className='t-ac-details-loading-feedback'>
                <CircularProgress style={spinnerStyle} size={140}/>
                <img src={WhiteLogo} alt='logo' className='m-inventory-logo'/>
            </div>
        )
    } else {
        feedback = (
            <div className='t-ac-individual-lot-container'>
                <DetailsHeader />
                <DetailsPicture />
                <DetailsContent />
                <SoldButton />
                <DeleteLotButton />
                <DateInfo />
                <div className="m-scroll-fix">
                    
                </div>
            </div>
        )
    }
    return (
        <Backdrop
            open={viewingIndividualLot}
            style={backdropStyle}
            transitionDuration={400}>     
           {feedback}
        </Backdrop>
    )


}