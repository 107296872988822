import React, {useContext} from 'react'

import TabletCard from './TabletCard'

import {DirectorsContext} from './../../Contexts/DirectorsContext'

export default function DirectorsSharon() {

    let {
        sharonList
    } = useContext(DirectorsContext)

    return sharonList.map(lot => {
        return (
            <TabletCard 
                key={lot.id}
                location={'Sharon'}
                lotNumber={lot.lotNumber}
                price={lot.price}
                section={lot.section}
                spaces={lot.spaces}
            />
        )
    })
}