import React, {useContext} from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import {GlobalContext} from './../../Contexts/GlobalContext'

export default function LoginDesktop() {
    
    let {
        handleChange,
        email,
        password,
        error,
        setError,
        loggedIn
    } = useContext(GlobalContext)

    function handleSignIn() {
        if (email && password) {
            firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                loggedIn()
            })
            .catch((err) => {
                setError(err.message)
          })
        } else if (email) {
            setError('Please enter your password')
        } else if (password) {
            setError('Please enter your email')
        } else {
            setError('Please enter login credentials')
        }
    }
    const loginStyle = {
        background: 'rgba(255,255,255,0.8)',
        marginBottom: '15px',
        width: '300px',
        color: 'black'
    }
    const buttonStyle = {
        width: '200px'
    }

    let header
    error
        ? header = (
            <div className='d-login-error-message'>
                {error}
            </div>
        )
        : header = 'Admin Login'   

    return (
        <div className='d-login-container'>
            <div className='d-login-error-container'>
                {header}
            </div>
            <form 
            className='d-login-form-container'
            onSubmit={(e) => {
                e.preventDefault()
                handleSignIn()
            }}>
            <TextField
                style={loginStyle}
                variant='outlined'
                // color='secondary'
                name='email'
                className="desktop-login"
                placeholder="Email"
                onChange={(e) => { handleChange(e)}}
            />
            <TextField
                style={loginStyle}
                variant="outlined" 
                // color='secondary'
                name='password'
                className="desktop-login"
                placeholder="Password"
                type='password'
                onChange={(e) => { handleChange(e)}}
            />
            <Button
            type='submit'
            style={buttonStyle}
            variant='contained'
            color='primary'
                onClick={() => {
                    handleSignIn()
                }}
            >Admin Login</Button>
            </form>
        </div>
    )
}