import React from 'react'
import { isMobileOnly, isTablet, isIPad13 } from 'react-device-detect'

import { DirectorsContextProvider } from './../Contexts/DirectorsContext'

import DirectorsMobile from './Mobile/DirectorsMobile'
import DirectorsTablet from './Tablet/DirectorsTablet'

import UpdatedDirectorsDesktop from './UpdatedDirectorsDesktop'

export default function Directors() {

    if (isMobileOnly) {
        return ( <DirectorsContextProvider><DirectorsMobile /></DirectorsContextProvider> )
    } else if (isTablet || isIPad13) {
        return ( <DirectorsContextProvider><DirectorsTablet /></DirectorsContextProvider> )
    } else {
        return ( <DirectorsContextProvider><UpdatedDirectorsDesktop /></DirectorsContextProvider> )
    }
}