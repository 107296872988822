import React, {useContext} from 'react'

import {InventoryContext} from '../../../../Contexts/InventoryContext'

export default function MobileDetailsComments() {
    let {
        detailsComments,
        location
    } = useContext(InventoryContext)

    let commentsContent
    if (detailsComments && location !== 'Shara') {
        commentsContent = (
            <div>
                <div className="t-details-comments-header">
                    Additional Comments from The Lot Broker:
                </div>
                <div className="t-details-comment">
                    {detailsComments}
                </div>
            </div>
        )
    } else if (detailsComments && location === 'Shara')  {
        commentsContent = (
            <div>
                <div className="t-details-shara-comments-header">
                    Additional Comments from The Lot Broker:
                </div>
                <div className="t-details-shara-comment">
                    {detailsComments}
                </div>
            </div>
        )
    } else {
        commentsContent = ''
    }
    return (
        <div className='t-details-comments-container'>
            {commentsContent}
        </div>
    )
}