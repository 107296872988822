import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function DetailsHeader() {

    let {
        closeIndividualLot,
        detailsSold,
        changingLotSold,
        savingEdits,
        editing
    } = useContext(MobileAdminContext)


    const buttonStyle = {
        padding: '0',
        textTransform: 'none',
        marginRight: '15px',
        minHeight: '0'
    }
    const soldStyle = {
        fontSize: '12px',
        color: 'green',
        marginRight: '3px'
    }
    let soldCheck
    if (detailsSold) {
        soldCheck = (
            <div className='t-ac-sold-check'>
                <CheckIcon style={soldStyle}/>
                Sold
            </div>
        )
    } else {
        soldCheck = ( '' )
    }

    let disabledCheck
    if (changingLotSold || savingEdits || editing) {
        disabledCheck = true
    } else {
        disabledCheck = false
    }
    
    return (
        <div className='t-ac-individual-header'>
            <div className='t-ac-individual-header-brand'>
                Lot Details
                {soldCheck}
            </div>
            <Button
                disabled={disabledCheck}
                variant='contained'
                color='default'
                style={buttonStyle}
                onClick={() => closeIndividualLot()}
            >
                <div className='t-ac-individual-close-button'>
                    Close
                </div>
            </Button>
        </div>
    )
}