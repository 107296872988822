import React, {useContext} from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import MobileDetailsHeader from './MobileDetailsHeader'
import MobileDetailsPicture from './MobileDetailsPicture'
import MobileDetailsInfo from './MobileDetailsInfo'
import MobileDetailsComments from './MobileDetailsComments'
import MobileDetailsContactButton from './MobileDetailsContact'
import MobileContact from './MobileContact'

import WhiteLogo from './../../../../../StyleNew/logoWhite.png'

import {InventoryContext} from './../../../../Contexts/InventoryContext'

export default function MobileDetails() {

    let {
        mobileLoaded,
        mobileShowDetails,
        mobileLoading
    } = useContext(InventoryContext)

    const detailsStyle = {
        marginTop: '8vh',
        zIndex: '100',
        backgroundColor: 'rgb(150, 208, 231)',
        height: '87vh'
    }
    const loadingDetailsStyle = {
        marginTop: '8vh',
        zIndex: '200',
        backgroundColor: 'rgb(150, 208, 231)',
        height: '87vh'
    }
    const spinnerStyle = {
        color: 'white',
        position: 'relative'

    }
    
    let detailsContent
    if (mobileLoaded) {
        detailsContent = (
            <div className='m-details-container'>
                <MobileDetailsHeader />
                <MobileDetailsPicture />
                <MobileDetailsInfo />
                <MobileDetailsComments />
                <MobileDetailsContactButton />
                <MobileContact />
            </div>
        )
    } else {
        detailsContent = ( '' )
    }
    return (
        <Backdrop
            transitionDuration={400}
            open={mobileShowDetails}
            style={detailsStyle}>
            <Backdrop
                transitionDuration={450}
                open={mobileLoading}
                style={loadingDetailsStyle}>
                <CircularProgress style={spinnerStyle} size={140}/>
                <img src={WhiteLogo} alt='logo' className='m-inventory-logo'/>
            </Backdrop>

            {detailsContent}

        </Backdrop>
    )
}