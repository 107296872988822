import React, { createContext } from 'react'

import {isMobileOnly, isTablet, isIPad13} from 'react-device-detect'

import firebase from '@firebase/app'
import 'firebase/firestore'

export const DirectorsContext = createContext()

export class DirectorsContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sharonList: [],
            interfaithList: [],
            gardenList: [],
            veteransList: [],
            addToSharonList: (lot) => {
                this.setState({
                    sharonList: [...this.state.sharonList, lot]
                })
            },
            addToInterfaithList: (lot) => {
                this.setState({
                    interfaithList: [...this.state.interfaithList, lot]
                })
            },
            addToGardenList: (lot) => {
                this.setState({
                    gardenList: [...this.state.gardenList, lot]
                })
            },
            addToVeteransList: (lot) => {
                this.setState({
                    veteransList: [...this.state.veteransList, lot]
                })
            },
            showLots: false,
            loadingLots: true,
            loadedLots: () => {
                this.setState({
                    loadingLots: false,
                    showLots: true
                })
            },
            knollwoodView: false,
            toggleKnollwoodView: () => {
                this.setState({
                    knollwoodView: !this.state.knollwoodView
                })
            },
            initiating: true,
            initiated: () => {
                this.setState({
                    initiating: false
                })
            },
            mobileList: 'Sharon',
            setMobileList: (location) => {
                this.setState({
                    mobileList: location
                })
            }
        }

    }
    componentDidMount() {
        if (isMobileOnly || isTablet || isIPad13) {
            firebase.firestore().collection('Sharon').orderBy('priority').orderBy('section').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let newlot = {
                        lotNumber: doc.data().lotNumber,
                        price: doc.data().price,
                        section: doc.data().section,
                        spaces: doc.data().spaces,
                        id: doc.id
                    }
                    this.setState({
                        sharonList: [...this.state.sharonList, newlot]
                    })
                })
            })
            .then(() => {
                firebase.firestore().collection('Interfaith').orderBy('priority').orderBy('section').orderBy('lotNumber')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let newlot = {
                            lotNumber: doc.data().lotNumber,
                            price: doc.data().price,
                            section: doc.data().section,
                            spaces: doc.data().spaces,
                            id: doc.id
                        }
                        this.setState({
                            interfaithList: [...this.state.interfaithList, newlot]
                        })
                    })
                })
            })
            .then(() => {
                firebase.firestore().collection('Knollwood').where('garden', '==', true).orderBy('lotName').orderBy('lotNumber')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let newlot = {
                            lotNumber: doc.data().lotNumber,
                            price: doc.data().price,
                            spaces: doc.data().spaces,
                            lotName: doc.data().lotName,
                            id: doc.id
                        }
                        this.setState({
                            gardenList: [...this.state.gardenList, newlot]
                        })
                    })
                })
            })
            .then(() => {
                firebase.firestore().collection('Knollwood').where('garden', '==', false).orderBy('lotName').orderBy('lotNumber')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let newlot = {
                            lotNumber: doc.data().lotNumber,
                            price: doc.data().price,
                            spaces: doc.data().spaces,
                            lotName: doc.data().lotName,
                            id: doc.id
                        }
                        this.setState({
                            veteransList: [...this.state.veteransList, newlot]
                        })
                    })
                })
            })
            .then(() => {
                setTimeout(() => {
                    this.setState({
                        loadingLots: false
                    })
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
        }
    }
    render() {
        return (
            <DirectorsContext.Provider value={this.state}>
                {this.props.children}
            </DirectorsContext.Provider>
        )
    }

}