import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import LinearProgress from '@material-ui/core/LinearProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import SuccessfullyDeletedCard from './SuccessfullyDeletedCard'
import { AdminContext } from '../../../../../Contexts/AdminContext'

export default function LotDeleteButton() {

    let {
        imageUrl,
        showDeletionSuccess,
        deleteSuccess,
        lotId,
        originalPath,
        // cancelNewLotAdd,
        // closeWarningAndCard,
        attemptCardDelete,
        // deleteCard,
        setComments,
        // cardSuccessfullyDeleted,
        deletingCard,
        updatingCard,
        deleteWarning,
        toggleDeleteWarning,
        location,
        section,
        lotName,
        lotNumber,
        otherLocation,
        editingCard,
        lotIsSold,
        // cardDeleted

        reloadLotList
        // clearVisibleLotList,
        // filterSection,
        // filterName,
        // filterLocation,
        // addLotToVisibleList

    } = useContext(AdminContext)

    let deleteLotDetails, deleteButtonDisabledCheck, deletingFeedback, disabledButtonCheck, successfulDeleteContent

    if (location === 'Sharon' || location === 'Interfaith') {
        deleteLotDetails = ( 
            <div className='delete-sharon-details-d'>
                <div className='delete-sharon-details-d-item'>{location}</div>
                <div className='delete-sharon-details-d-item'>{section}</div>
                <div>-</div>
                <div className='delete-sharon-details-d-item'>{lotNumber}</div>
            </div>
            )
    } else if (location === 'Knollwood') {
        deleteLotDetails = (
            <div className='delete-sharon-details-d'>
                <div className='delete-sharon-details-d-item'>{location}</div>
                <div className='delete-sharon-details-d-item'>{lotName}</div>
                <div>-</div>
                <div className='delete-sharon-details-d-item'>{lotNumber}</div>
            </div>
        )
    } else if (location === 'Estate' || location === 'Other') {
        deleteLotDetails = (
            <div className='delete-sharon-details-d'>
                <div className='delete-sharon-details-d-item'>{location}</div>
                <div>-</div>
                <div className='delete-sharon-details-d-item'>{otherLocation}</div>

            </div>
        )
    }
    if (updatingCard || editingCard) {
        deleteButtonDisabledCheck = false
    } else {
        deleteButtonDisabledCheck = true
    }

    const style = {
        background: 'rgb(173, 0, 0)',
        colorPrimary: 'purple',
        barColorPrimary: 'yellow',
        height: '100%',
        width: '100%'
      }
    deletingCard
        ? disabledButtonCheck = false
        : disabledButtonCheck = true
    deletingCard
        ? deletingFeedback = ( <LinearProgress style={style} /> )
        : deletingFeedback = ( <div></div>)

    function celebrateDeletion() {
        showDeletionSuccess()
        setComments(null)
        // cancelNewLotAdd()
    }

    function myAttemptCardDelete() {
        attemptCardDelete()

        // put in sold/unsold log here after creating it
        if (!lotIsSold) {
            firebase.firestore().collection(location).doc(lotId)
            .delete()
            .then(() => {
                if (imageUrl) {
                    firebase.storage().ref(originalPath)
                    .delete()
                }
            })
            .then(() => {
                reloadLotList()
            })
            // .then(() => {
            //     if (filterLocation && filterName === null && filterSection === null) {
            //         clearVisibleLotList()
            //         if (filterLocation === 'Sharon' || filterLocation === 'Interfaith') {
            //             firebase.firestore().collection(filterLocation).orderBy('priority').orderBy('section').orderBy('lotNumber')
            //             .get()
            //             .then((snapshot) => {
            //                 snapshot.forEach(lot => {
            //                     let lotDetails = {
            //                         lotNumber: lot.data().lotNumber,
            //                         location: lot.data().location,
            //                         section: lot.data().section,
            //                         lotName: lot.data().lotName,
            //                         otherLocation: lot.data().otherLocation,
            //                         price: lot.data().price,
            //                         lotId: lot.id,
            //                         spaces: lot.data().spaces
            //                     }
            //                     addLotToVisibleList(lotDetails)
            //                 })
            //             })
            //         } else if (filterLocation === 'Knollwood') {
            //             firebase.firestore().collection(filterLocation).orderBy('lotName').orderBy('lotNumber')
            //             .get()
            //             .then((snapshot) => {
            //                 snapshot.forEach(lot => {
            //                     let lotDetails = {
            //                         lotNumber: lot.data().lotNumber,
            //                         location: lot.data().location,
            //                         section: lot.data().section,
            //                         lotName: lot.data().lotName,
            //                         otherLocation: lot.data().otherLocation,
            //                         price: lot.data().price,
            //                         lotId: lot.id,
            //                         spaces: lot.data().spaces
            //                     }
            //                     addLotToVisibleList(lotDetails)
            //                 })
            //             })
            //         } else {
            //             firebase.firestore().collection(filterLocation)
            //             .get()
            //             .then((snapshot) => {
            //                 snapshot.forEach(lot => {
            //                     let lotDetails = {
            //                         lotNumber: lot.data().lotNumber,
            //                         location: lot.data().location,
            //                         section: lot.data().section,
            //                         lotName: lot.data().lotName,
            //                         otherLocation: lot.data().otherLocation,
            //                         price: lot.data().price,
            //                         lotId: lot.id,
            //                         spaces: lot.data().spaces
            //                     }
            //                     addLotToVisibleList(lotDetails)
            //                 })
            //             })
            //     }
            //     } else if (filterLocation && filterName === null && filterSection) {
            //         clearVisibleLotList()
            //         firebase.firestore().collection(filterLocation).where('section', '==', filterSection)
            //         .get()
            //         .then(snapshot => {
            //             snapshot.forEach(lot => {
            //                 let lotDetails = {
            //                     lotNumber: lot.data().lotNumber,
            //                     location: lot.data().location,
            //                     section: lot.data().section,
            //                     lotName: lot.data().lotName,
            //                     otherLocation: lot.data().otherLocation,
            //                     price: lot.data().price,
            //                     lotId: lot.id,
            //                     spaces: lot.data().spaces
            //                 }
            //                 addLotToVisibleList(lotDetails)
            //             })
            //         })
            //     } else if (filterLocation && filterName && filterSection === null ) {
            //         clearVisibleLotList()
            //         firebase.firestore().collection('Knollwood').where('lotName', '==', filterName)
            //         .get()
            //         .then(snapshot => {
            //             snapshot.forEach(lot => {
            //                 // add lot details to been seen and needed for
            //                 let lotDetails = {
            //                     lotNumber: lot.data().lotNumber,
            //                     location: lot.data().location,
            //                     section: lot.data().section,
            //                     lotName: lot.data().lotName,
            //                     otherLocation: lot.data().otherLocation,
            //                     price: lot.data().price,
            //                     lotId: lot.id,
            //                     spaces: lot.data().spaces
            //                 }
            //                 addLotToVisibleList(lotDetails)
            //             })
            //         })
            //     }
            // })
            .then(() => {
                setTimeout(() => { celebrateDeletion() }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (lotIsSold) {
            firebase.firestore().collection('sold').doc(lotId)
            .delete()
            .then(() => {
                if (imageUrl) {
                    firebase.storage().ref(originalPath)
                    .delete()
                }
                })
            .then(() => {
                reloadLotList()
                setTimeout(() => { celebrateDeletion() }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        }

    }

    deleteSuccess
        ? successfulDeleteContent = ( <SuccessfullyDeletedCard /> )
        : successfulDeleteContent = ''
    return (
        <div>
            <Button
                disableElevation={true}
                disabled={(!deleteButtonDisabledCheck)}
                color='primary'
                variant='contained'
                onClick={() => {
                    toggleDeleteWarning()
                }}
                >

                    <div className='admin-context-d-delete-button'>
                        DELETE LOT
                    </div>
            </Button>
                {successfulDeleteContent}
                <Backdrop 
                style={{ zIndex: 2000000, color: '#fff'}}
                open={deleteWarning}>
                    <div className='d-delete-lot-warning-container'>
                        <div className='d-delete-lot-warning-padding'>
                            <div className='d-delete-lot-warning-top-row'>
                                Are you sure you wish to delete?
                            </div>
                            <div className='d-delete-lot-warning-middle-row'>
                                {deleteLotDetails}
                            </div>
                            <div className='d-delete-lot-warning-bottom-row'>
                                <div className='d-delete-lot-warning-bottom-item'>
                                    <Button
                                        variant='contained'
                                        disabled={(!disabledButtonCheck)}
                                        onClick={() => {
                                            myAttemptCardDelete()
                                        }}
                                        >
                                        <div className='card-delete-actual-button-confirm'>
                                            Delete
                                        </div>
                                    </Button>
                                </div>
                                <div className='d-delete-lot-warning-bottom-item'>
                                    <Button
                                        disabled={(!disabledButtonCheck)}
                                        onClick={() => {
                                            toggleDeleteWarning()
                                        }}>
                                        Cancel
                                    </Button>
                                </div>
                            </div> 
                            <div className='d-delete-lot-warning-deleting-row'>
                                    {deletingFeedback}
                            </div>
                        </div>
                    </div>
                </Backdrop>
        </div>
    )
}