import React, {useContext} from 'react'

import AdminConsole from './components/AdminConsole/AdminConsole'
import LoginMobile from './components/Login/LoginMobile'

import {MobileAdminContextProvider} from './../../Contexts/MobileAdminContext'
import {GlobalContext} from './../../Contexts/GlobalContext'

export default function AdminMobile() {
    let {
        admin
    } = useContext(GlobalContext)

    if (admin) {
        return (<MobileAdminContextProvider><AdminConsole /></MobileAdminContextProvider>)
    } else {
        return <LoginMobile />
    }
}