import React from 'react'

import NewsCard from './NewsCard'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default class NewsDesktop extends React.Component {
    constructor() {
        super()

        this.state = {
            newsListings: [],
            loaded: false
        }
    }

    componentDidMount() {
        firebase.firestore().collection('News').orderBy('date', "asc")
        .get()
        .then(snapshot => {
            snapshot.forEach(news => {
                let newsItem = {
                    title: news.data().title,
                    content: news.data().content,
                    date: news.data().date,
                    id: news.id,
                }
                this.setState({
                    newsListings: [...this.state.newsListings, newsItem]
                })
            })
        })
        .then(() => {
            this.setState({
                loaded: true
            })
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    render() {
        let {
            loaded,
            newsListings
        } = this.state

        let newsContent

        if (loaded) {
            newsContent = newsListings.map(news => {
                return (
                    <NewsCard 
                        key={news.id}
                        title={news.title}
                        content={news.content}
                        date={news.date}
                        />
                )
            })
        } else {
            newsContent = ( '' )
        }
        return (
            <div className='d-about-news-container'>
                <div className='d-about-news-header'>
                    Recent News
                </div>
                <div className='d-news-content'>
                    {newsContent}
                </div>
            </div>
        )
    }
}