import React, {useContext} from 'react'

import {InventoryContext} from '../../../../Contexts/InventoryContext'

export default function MobileDetailsPicture() {
    let {
        detailsImageUrl
    } = useContext(InventoryContext)

    let noImageMessage
    if (!detailsImageUrl) {
        noImageMessage = 'No Image on File'
    } else {
        noImageMessage = ''
    }
    return (
        <div 
            className='t-details-picture-container'
            style={{ 
                backgroundImage: `url(${detailsImageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
                {noImageMessage}
        </div>
    )
}