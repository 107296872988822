import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { AdminContext } from './../../../../Contexts/AdminContext'
export default function AdminListCard(props) {

    let {
        openedIndividualLot,
        setImageUrl,
        setLastEdit,
        setLocation,
        setOtherLocation,
        setCreatedAt,
        setPrice,
        setLotNumber,
        setSpaces,
        setSection,
        setComments,
        setLotName,
        setGarden,
        setPriority,
        setLotId,
        setOriginalPath,
        setLotSold,
        openIndividualLot
    } = useContext(AdminContext)

    let {
        lotNumber,
        location,
        lotId,
        lotName,
        price,
        section,
        otherLocation,
        spaces,
        soldCheck
    } = props

    let cardContent

    const styles = {
        height: '100%',
        width: '100%',
        padding: '0'
    }

    function handleLoadLotDetails() {

        openIndividualLot()
        if (soldCheck) {
            firebase.firestore().collection('sold').doc(lotId)
            .get()
            .then(doc => {
                setOtherLocation(doc.data().otherLocation)
                setLocation(doc.data().location)
                setLotNumber(doc.data().lotNumber)
                setSection(doc.data().section)
                setCreatedAt(doc.data().createdAt)
                setLastEdit(doc.data().lastEdit)
                setPrice(doc.data().price)
                setSpaces(doc.data().spaces)
                setComments(doc.data().comments)
                setLotId(doc.id)
                setImageUrl(doc.data().imageUrl)
                setOriginalPath(doc.data().originalPath)
                setLotSold()
            })
            .then(() => {
                setTimeout(() => {
                    openedIndividualLot()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else {
            if (location === 'Sharon' || location === 'Interfaith') {
                firebase.firestore().collection(location).doc(lotId)
                .get()
                .then(doc => {
                    setLocation(doc.data().location)
                    setCreatedAt(doc.data().createdAt)
                    setLastEdit(doc.data().lastEdit)
                    setPrice(doc.data().price)
                    setLotNumber(doc.data().lotNumber)
                    setSpaces(doc.data().spaces)
                    setSection(doc.data().section)
                    setComments(doc.data().comments)
                    setPriority(doc.data().priority)
                    setLotId(doc.id)
                    setImageUrl(doc.data().imageUrl)
                    setOriginalPath(doc.data().originalPath)
            
                })
                .then(() => {
                    setTimeout(() => {
                        openedIndividualLot()
                    }, 1000)
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (location === 'Knollwood') {
                firebase.firestore().collection('Knollwood').doc(lotId)
                .get()
                .then(doc => {
                    setGarden(doc.data().garden)
                    setLocation(doc.data().location)
                    setCreatedAt(doc.data().createdAt)
                    setLastEdit(doc.data().lastEdit)
                    setPrice(doc.data().price)
                    setLotNumber(doc.data().lotNumber)
                    setLotName(doc.data().lotName)
                    setSpaces(doc.data().spaces)
                    setComments(doc.data().comments)
                    setLotId(doc.id)
                    setImageUrl(doc.data().imageUrl)
                    setOriginalPath(doc.data().originalPath)
                })
                .then(() => {
                    setTimeout(() => {
                        openedIndividualLot()
                    }, 1000)
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (location === 'Estate' || location === 'Other') {
                firebase.firestore().collection(location).doc(lotId)
                .get()
                .then(doc => {
                    setOtherLocation(doc.data().otherLocation)
                    setLocation(doc.data().location)
                    setCreatedAt(doc.data().createdAt)
                    setLastEdit(doc.data().lastEdit)
                    setPrice(doc.data().price)
                    setSpaces(doc.data().spaces)
                    setComments(doc.data().comments)
                    setLotId(doc.id)
                    setImageUrl(doc.data().imageUrl)
                    setOriginalPath(doc.data().originalPath)
                })
                .then(() => {
                    setTimeout(() => {
                        openedIndividualLot()
                    }, 1000)
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (location === 'Shara') {
                firebase.firestore().collection('Shara').doc(lotId)
                .get()
                .then(doc => {
                    setOtherLocation(doc.data().otherLocation)
                    setLocation(doc.data().location)
                    setCreatedAt(doc.data().createdAt)
                    setLastEdit(doc.data().lastEdit)
                    setPrice(doc.data().price)
                    setSpaces(doc.data().spaces)
                    setComments(doc.data().comments)
                    setLotId(doc.id)
                    setImageUrl(doc.data().imageUrl)
                    setOriginalPath(doc.data().originalPath)
                })
                .then(() => {
                    setTimeout(() => {
                        openedIndividualLot()
                    }, 1000)
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        }
    }

    if (soldCheck) {
        cardContent = (
            <div className='container-context-sharon-card'>
                <div className="container-context-item container-context-font">
                    {location}
                </div>
                <div className="container-context-item container-context-font">
                    {section}
                </div>
                <div className="container-context-item container-context-font">
                    {lotNumber}
                </div>
                <div className="container-context-item container-context-font">
                    {spaces}
                </div>
                <div className="container-context-item container-context-font">
                    ${price}
                </div>
            </div>
        )
    } else {
        if (location === 'Sharon' || location === 'Interfaith') {
            cardContent = (
                <div className='container-context-sharon-card'>
                    <div className="container-context-item container-context-font">
                        {section}
                    </div>
                    <div className="container-context-item container-context-font">
                        {lotNumber}
                    </div>
                    <div className="container-context-item container-context-font">
                        {spaces}
                    </div>
                    <div className="container-context-item container-context-font">
                        ${price}
                    </div>
                </div>
            )
        } else if (location === 'Knollwood') {
            cardContent = (
                <div className='container-context-knollwood-card'>
                    <div className="container-context-item2 container-context-font">
                        {lotName}
                    </div>
                    <div className="container-context-item container-context-font">
                        {lotNumber}
                    </div>
                    <div className="container-context-item container-context-font">
                        {spaces}
                    </div>
                    <div className="container-context-item container-context-font">
                        ${price}
                    </div>
                </div>
            )
        } else if (location === 'Estate' || location === 'Other') {
            cardContent = (
                <div className='container-context-estate-card'>
                    <div className="container-context-item2 container-context-font">
                    {otherLocation}
                    </div>
                    <div className="container-context-item container-context-font">
                        {spaces}
                    </div>
                    <div className="container-context-item container-context-font">
                        ${price}
                    </div>
                </div>
            )
        } else if (location === 'Shara') {
            cardContent = (
                <div className='container-context-estate-card'>
                    <div className="container-context-item container-context-font">
                        {spaces}
                    </div>
                    <div className="container-context-item container-context-font">
                        ${price}
                    </div>
                </div>
            )
        }
    }
    
    return (
        <div className='container-d-lotcard'>
            <Button
            color='primary'
            onClick={() => {
                handleLoadLotDetails()
            }}
             style={styles}>
                 {cardContent}
        </Button>
            
        </div>

    )
}