import React from 'react'

import firebase from 'firebase/app'
import 'firebase/firestore'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

export default class ContactDesktop extends React.Component {
    constructor() {
        super()

        this.state = {
            testimonialMessage: '',
            testimonialName: '',
            testimonialDate: '2020-03-12',
            writingTestimonial: false,
            submittedTestimonial: false
        }
    }
    toggleTestimonial() {
        this.setState({
            writingTestimonial: !this.state.writingTestimonial
        })
    }
    handleSubmitTestimonial() {
        let { testimonialDate, testimonialMessage, testimonialName } = this.state
        firebase.firestore().collection('TestimonialsPending')
        .add({
            message: testimonialMessage,
            name: testimonialName,
            dateOfSale: testimonialDate
        })
        .then(() => {
            this.setState({
                submittedTestimonial: true,
                writingTestimonial: false
            })
        })
        .catch(err => {
            console.error(err.code)
        })
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    render() {
        let { writingTestimonial, submittedTestimonial } = this.state
        let testimonialContent 
    
        let testimonialHeader
        writingTestimonial
            ? testimonialHeader = 'Cancel'
            : testimonialHeader = 'Leave a Testimonial!'

        writingTestimonial
            ? testimonialContent = (
                <div className='contact-d-testimonial-form'>
                    <div className='contact-d-testimonials-top'>
                        <TextField
                            name='testimonialName'
                            onChange={this.handleChange}
                            label="Your Name"
                            color='secondary'
                            variant="outlined" />
                        <TextField
                            color='secondary'
                            name='testimonialDate'
                            onChange={this.handleChange}
                            label="Date Of Sale"
                            type="date"
                            defaultValue="2020-01-01"
                            InputLabelProps={{
                            shrink: true,
                            }}/>
                    </div>
                    <TextField
                        name='testimonialMessage'
                        onChange={this.handleChange}
                        className='contact-d-testimonial-message'
                        placeholder="The Lot Broker was great!"
                        multiline
                        rows="4"
                        color='secondary'
                        variant="outlined"/>
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            this.handleSubmitTestimonial()
                        }}>
                        <div className="testimonial-d-button-edit">
                            Submit
                        </div>
                    </Button>
                </div>
            )
            : testimonialContent = (
                ''
            )
            let submitCheck
            submittedTestimonial
                ? submitCheck = ( 'Thank you for submitting a testimonial!')
                : submitCheck = ( 
                <div>
                    <div className="testimonial-d-button">
                        <div className="testimonial-d-button-question">
                            Enjoyed your experience with The Lot Broker? 
                        </div>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                this.toggleTestimonial()
                            }}>
                        <div className="testimonial-d-button-edit">
                            {testimonialHeader}
                        </div>
                        </Button>
                    </div>
                    {testimonialContent} 
                </div>
            )

            
        return (
            <div className='contact-d-container'>
                <div className='contact-details-container'>
                    <div className="contact-details-phone contact-details-section">
                        <div className="contact-details-label">
                            Phone Number:
                        </div>
                        <div className="contact-details-item">
                            (617) 640-3143
                        </div>
                    </div>
                    <div className="contact-details-email contact-details-section">
                        <div className="contact-details-label">
                            Email:
                        </div>
                        <a href="mailto:GLevine@TheLotBroker.com" className='d-link-fix'>
                            <div className="contact-details-item">
                                GLevine@TheLotBroker.com
                            </div>
                        </a>
                    </div>
                    <div className="contact-details-hours contact-details-section">
                        <div className="contact-details-label">
                            Hours of Operation:
                        </div>
                        <div className="contact-details-item">
                            All Day Every Day
                        </div>
                    </div>
                </div>
                <div className="testimonial-d-container">        
                    <div className="contact-d-submit-message">
                        {submitCheck}
                    </div>
                </div>
            </div>
        )
    }
}