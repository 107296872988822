import React from 'react'

import GalleryCard from './GalleryCard'

import firebase from 'firebase/app'
import 'firebase/firestore'

import CircularProgress from '@material-ui/core/CircularProgress'

export default class ViewGallery extends React.Component {
    constructor() {
        super()

        this.state = {
            gallery: [],
            addToGallery: (card) => {
                this.setState({
                    gallery: [...this.state.gallery, card]
                })
            },
            loaded: false,
            finishLoading: () => {
                this.setState({
                    loaded: true
                })
            }
        }
    }

    componentDidMount() {
        firebase.firestore().collection('Gallery').orderBy('order')
        .get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                let newCard = {
                    body: doc.data().body,
                    header: doc.data().header,
                    imageId: doc.data().imageId,
                    imageUrl: doc.data().imageUrl,
                    id: doc.id
                }
                this.state.addToGallery(newCard)
            })
        })
        .then(() => {
            setTimeout(() => {
                this.state.finishLoading()
            }, 1200)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    render() {
        let {
            gallery,
            loaded
        } = this.state

        if (loaded) {
            return (
                <div className='m-ac-gallery-container'>
                    {gallery.map(card => {
                        return (
                            <GalleryCard
                                key={card.id}
                                id={card.id}
                                header={card.header}
                                body={card.body}
                                imageUrl={card.imageUrl}
                                imageId={card.imageId}
                                />
                        )
                    })}
                    <div className="m-scroll-fix"></div>
                    <div className="m-scroll-fix"></div>
                </div>
            )
        } else {
            return (
                <div className='m-ac-gallery-container'>
                    <CircularProgress size={140} style={{ marginTop: '50px'}}/>
                </div>
            )
        }
    }
}