import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'

import SendMessage from './SendMessage'

import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function FooterContent() {
    
    let {
        showingMessage,
        showMessage
    } = useContext(InventoryContext)

    let messageContent

    showingMessage
        ? messageContent = (
            <SendMessage />
        )
        : messageContent = ( '' )

    return (
        <div className='d-i-b-footer-content'>
            <div className="d-i-b-footer-info">
                Contact The Lot Broker by phone at (617)640-3143 or email at GLevine@TheLotBroker.com
            </div>
            <div className="d-i-b-footer-or">
                OR
            </div>
            <Button
                color='primary'
                variant='contained'
                onClick={() => {
                    showMessage()
                }}
            >
                Message The Lot Broker Directly
            </Button>
            {messageContent}
        </div>
    )
}