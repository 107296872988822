import React, {useContext} from 'react'


import Button from '@material-ui/core/Button'
import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function DetailsHeader() {

    let {
        hideDetails
    } = useContext(InventoryContext)

    return (
        <div className='d-i-b-header'>
            <div className='d-i-b-header-item'>
                Lot Details
            </div>
            <div className='d-i-b-header-item'>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                        hideDetails()
                    }}>
                    Close
                </Button>
            </div>
        </div>
    )
}