import React, { useContext } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function DeletePictureWarning() {

    let {
        showingDeleteLotWarning,
        closeDeleteLotWarning,
        deletingLot,
        initializeDelete,
        finalizeDelete,
        detailsLocation,
        detailsId,
        detailsImageUrl,
        detailsOriginalPath,
        detailsSold
        // detailsOriginalPath,
        // deleteImage,
        // deletedImage,
        // detailsSold,
        // detailsLocation,
        // detailsId,
        // deletingImage
        // detailsLocation,
        // detailsSection,
        // detailsNumber,
        // details
    }= useContext(MobileAdminContext)

    const warningStyle = {
        marginTop: '9vh',
        zIndex: '50000',
        backgroundColor: 'rgba(0,0,0,0)',
        width: '96vw',
        marginLeft: '2vw',
        height: '50vw'
    }
    const closeButtonStyle = {
        padding: '0',
        minHeight: '0',
        marginRight: '5px'
    }
    const deleteButtonStyle = {
        padding: '0',
        minHeight: '0',
        // backgroundColor: 'red',
        textTransform: 'none'
    }
    const cancelButtonStyle = {
        padding: '0',
        minHeight: '0',
        textTransform: 'none'
    }
    const feedbackStyle = {
        width: '100%',
        height: '100%'
    }
    let deletingContent

    if (deletingLot) {
        deletingContent = ( <LinearProgress style={feedbackStyle} color="secondary" />)
    } else {
        deletingContent = ( '' )

    }
    function handleDeleteLot() {
        initializeDelete()
        if (detailsSold) {
            if (detailsImageUrl) {
                firebase.firestore().collection('sold').doc(detailsId)
                .delete()
                .then(() => {
                    firebase.storage().ref(detailsOriginalPath)
                    .delete()
                    .then(() => {
                        setTimeout(() => {
                            finalizeDelete()
                        }, 1500)
                    })
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else {
                firebase.firestore().collection('sold').doc(detailsId)
                .delete()
                .then(() => {
                    setTimeout(() => {
                        finalizeDelete()
                    }, 1500)
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        } else {
            if (detailsImageUrl) {
                firebase.firestore().collection(detailsLocation).doc(detailsId)
                .delete()
                .then(() => {
                    firebase.storage().ref(detailsOriginalPath)
                    .delete()
                    .then(() => {
                        setTimeout(() => {
                            finalizeDelete()
                        }, 1500)
                    })
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else {
                firebase.firestore().collection(detailsLocation).doc(detailsId)
                .delete()
                .then(() => {
                    setTimeout(() => {
                        finalizeDelete()
                    }, 1500)
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        }
        
    }
    return (
        <Backdrop
                transitionDuration={300}
                style={warningStyle}
                open={showingDeleteLotWarning}
            >
                <div className='t-ac-delete-picture-warning'>
                    <div className="t-ac-delete-picture-header">
                        <div className="t-ac-delete-picture-brand">
                            Delete Lot
                        </div>
                        <Button 
                            disabled={deletingLot}
                            style={closeButtonStyle}
                            variant='contained'
                            color='default'
                            onClick={() => closeDeleteLotWarning()}
                        >
                            <div className="t-ac-delete-picture-close-button">
                                Close
                            </div>
                        </Button>
                    </div>
                    <div className="t-ac-delete-picture-content">
                        Are you sure you wish to delete this lot?
                    </div>
                    <div className="t-ac-delete-picture-submit-row">
                        <Button
                            disabled={deletingLot}
                            style={deleteButtonStyle}
                            variant='contained'
                            onClick={() => handleDeleteLot()}
                        >
                            <div className="t-ac-delete-picture-delete-button">
                                Yes - Delete
                            </div>
                        </Button>
                        <Button
                            disabled={deletingLot}
                            style={cancelButtonStyle}
                            variant='contained'
                            color='default'
                            onClick={() => closeDeleteLotWarning()}
                        >
                            <div className="t-ac-delete-picture-cancel-button">
                                Cancel
                            </div>
                        </Button>
                    </div>
                    <div className="t-ac-delete-picture-feedback-row">
                        {deletingContent}
                    </div>
                </div>
            </Backdrop>
    )
}