import React, {createContext} from 'react'

import firebase from 'firebase/app'
import 'firebase/auth'

export const GlobalContext = createContext()

export class GlobalContextProvider extends React.Component {
    constructor(props) {
        super(props) 

        this.state = {
            admin: false,
            loggedIn: () => {
                this.setState({
                    admin: true,
                    loading: false
                })
            },
            loading: false,
            startLoading: () => {
                this.setState({
                    loading: true
                })
            },
            loggedOut: () => {
                this.setState({
                    admin: false
                })
            },
            email: null,
            password: null,
            error: '',
            handleChange: (event) => {
                this.setState({
                    [event.target.name]: event.target.value
                })
            },
            setError: (err) => {
                this.setState({
                    error: err
                })
            },
            catchError: (err) => {
                this.setState({
                    error: err,
                    loading: false
                })
            },
            feedback: false,
            showFeedback: () => {
                this.setState({
                    feedback: true
                })
            },
            testimonialName: '',
            nameError: false,
            setNameError: () => {
                this.setState({
                    nameError: true
                })
            },
            dateError: false,
            testimonialDate: '',
            setDateError: () => {
                this.setState({
                    dateError: true
                })
            },
            testimonialMessage: '',
            messageError: false,
            setMessageError: () => {
                this.setState({
                    messageError: true
                })
            },
            clearErrors: () => {
                this.setState({
                    nameError: false,
                    dateError: false,
                    messageError: false,
                    error: ''
                })
            },
            writingTestimonial: false,
            testimonialCheck: false,
            toggleTestimonial: () => {
                this.setState({
                    testimonialName: '',
                    testimonialDate: '',
                    testimonialMessage: '',
                    writingTestimonial: !this.state.writingTestimonial,
                    nameError: false,
                    dateError: false,
                    messageError: false,
                    error: ''
                })
            },
            wroteTestimonial: () => {
                this.setState({
                    testimonialName: '',
                    testimonialDate: '',
                    testimonialMessage: '',
                    writingTestimonial: false,
                    testimonialCheck: true,
                    feedback: false,
                    error: '',
                    nameError: false,
                    dateError: false,
                    messageError: false
                })
            }

        }
    }
    componentDidMount() {

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    admin: true
                })
            }
        })

        // setTimeout(() => {
        //     if (true) {
        //         this.setState({
        //             admin: true
        //         })
        //     }
        // }, 2000)

    }
    render() {
        return (
            <GlobalContext.Provider value={this.state}>
                {this.props.children}
            </GlobalContext.Provider>
        )
    }

}