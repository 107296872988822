import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function KnollwoodFilter() {

    let {
        filterName,
        addToLotList,
        finishLoadingLots,
        setFilterName,
        loading,
        setNameAnchor,
        anchor,
        clearLotList,
        nameMenu,
        clearAnchors
    } = useContext(MobileAdminContext)

    let nameHeader
    filterName && filterName !== 'Remembrance'
        ? nameHeader = (
            <div className='m-ac-header-knollwood-filter'>
                {filterName}
            </div>
        )
        : filterName === 'Remembrance'
            ? nameHeader = (
                <div className='m-ac-header-knollwood-filter'>
                    Remem.
                </div>
            )
            : nameHeader = (
                <div className='m-ac-header-knollwood-filter'>
                    Lot Name
                </div>
            )

    const menuStyle= {
        zIndex: '4000',
        padding: '0',
        disablePadding: true,
    }
    const menuItemStyle = {
        textAlign: 'right',
        padding: '0',
        minWidth: '30vw',
        minHeight: '0'
    }

    function handleFilterByName(name) {
        setFilterName(name)
        clearLotList()

        firebase.firestore().collection('Knollwood').where('lotName', '==', name).orderBy('lotNumber')
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let newLot = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addToLotList(newLot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    finishLoadingLots()
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
    }

    return (
        <div>
            <Button 
                disabled={loading}
                aria-controls="location-menu" 
                aria-haspopup="true"
                onClick={event => setNameAnchor(event.currentTarget) }>
                {nameHeader}
            </Button>
            <Menu
                style={menuStyle}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="location-menu"
                anchorEl={anchor}
                keepMounted
                open={nameMenu}
                MenuListProps={{ disablePadding: true}}
                onClose={() => clearAnchors() }>
                <div className='m-inventory-knollwood-category'>Garden:</div>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Cross') }>
                    <div className='m-ac-filter-knollwood-item'>Cross</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Faith') }>
                    <div className='m-ac-filter-knollwood-item'>Faith</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Gethsemane') }>
                    <div className='m-ac-filter-knollwood-item'>Gethsemane</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Last Supper') }>
                    <div className='m-ac-filter-knollwood-item'>Last Supper</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Meditation') }>
                    <div className='m-ac-filter-knollwood-item'>Meditation</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Peace') }>
                    <div className='m-ac-filter-knollwood-item'>Peace</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Remembrance') }>
                    <div className='m-ac-filter-knollwood-item'>Remembrance</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Saviour') }>
                    <div className='m-ac-filter-knollwood-item'>Saviour</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Sunset') }>
                    <div className='m-ac-filter-knollwood-item'>Sunset</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Word') }>
                    <div className='m-ac-filter-knollwood-item'>Word</div>
                </MenuItem>
                <div className='m-inventory-knollwood-category'>Veterans:</div>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Honor') }>
                    <div className='m-ac-filter-knollwood-item'>Honor</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Memorial') }>
                    <div className='m-ac-filter-knollwood-item'>Memorial</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Valor') }>
                    <div className='m-ac-filter-knollwood-item'>Valor</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Victory') }>
                    <div className='m-ac-filter-knollwood-item'>Victory</div>
                </MenuItem>
            </Menu>
        </div>
    )
}