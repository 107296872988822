import React, {useContext} from 'react'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

import SharonTablet from './SharonTablet'
import SharaTablet from './SharaTablet'
import KnollwoodTablet from './KnollwoodTablet'

import {GalleryContext} from '../../Contexts/GalleryContext'

export default function GalleryTablet() {

    let {
        currentGallery,
        setGallery
    } = useContext(GalleryContext)

    const groupStyle = {
        width: '96vw',
        marginTop: '10px',
        marginBottom: '15px',
        height: '85%'
    }
    const buttonStyle = {
        padding: '0',
        width: '32vw',
        backgroundColor: 'rgba(41, 87, 105, 0.849)',
        height: '100%'
    }
    let galleryTabContent
    if (currentGallery === 'Sharon') {
        galleryTabContent = ( <SharonTablet /> )
    } else if (currentGallery === 'Knollwood') {
        galleryTabContent = ( <KnollwoodTablet /> )
    } else if (currentGallery === 'Shara'){
        galleryTabContent = ( <SharaTablet /> )
    }
    return (
        <div className='t-gallery-container'>
            <div className="t-gallery-options">
                <ButtonGroup 
                    style={groupStyle}
                    variant='contained'
                    aria-label='contained primary button group'>
                    <Button
                        variant='contained'
                        style={buttonStyle}
                        onClick={() => { setGallery('Sharon') }}>
                            <div className='t-gallery-options-item'>
                                Sharon
                            </div>
                    </Button>
                    <Button
                        variant='contained'
                        style={buttonStyle}
                        onClick={() => { setGallery('Knollwood') }}>
                            <div className='t-gallery-options-item'>
                                Knollwood
                            </div>
                        </Button>
                    <Button
                        variant='contained'
                        style={buttonStyle}
                        onClick={() => { setGallery('Shara') }}>
                            <div className='t-gallery-options-item'>
                                Shara Tfilo
                            </div>
                        </Button>
                </ButtonGroup>
            </div>
            <div className='t-gallery-header'>
                {currentGallery} Gallery
            </div>
            <div className="t-gallery-content">
                {galleryTabContent}
            </div>

        </div>
    )
}