import React from 'react'

import { isMobileOnly, isTablet, isIPad13 } from 'react-device-detect'

import GalleryDesktop from './GalleryDesktop'
import GalleryMobile from './Mobile/GalleryMobile'
import GalleryTablet from './Tablet/GalleryTablet'

import {GalleryContextProvider} from './../Contexts/GalleryContext'

export default function Gallery() {
    if (isMobileOnly) {
        return ( <GalleryContextProvider><GalleryMobile /></GalleryContextProvider>)
    } else if (isTablet || isIPad13) {
        return ( <GalleryContextProvider><GalleryTablet /></GalleryContextProvider>)
    } else {
        return ( <GalleryContextProvider><GalleryDesktop /></GalleryContextProvider>)
    }
}