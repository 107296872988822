import React, {useContext} from 'react'

import AdminConsole from './components/AdminConsole/AdminConsole'
import LoginTablet from './components/Login/LoginTablet'

import {MobileAdminContextProvider} from './../../Contexts/MobileAdminContext'
import {GlobalContext} from './../../Contexts/GlobalContext'

export default function AdminTablet() {
    let {
        admin
    } = useContext(GlobalContext)

    if (admin) {
        return (<MobileAdminContextProvider><AdminConsole /></MobileAdminContextProvider>)
    } else {
        return <LoginTablet />
    }
}