import React, { useContext } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import DoneOutlineTwoToneIcon from '@material-ui/icons/DoneOutlineTwoTone';
import { AdminContext } from '../../../../../Contexts/AdminContext'

export default function SuccessfullyDeletedCard() {

    let {
        deleteSuccess,
        cardSuccessfullyDeleted
    } = useContext(AdminContext)

    setTimeout(() => {

        cardSuccessfullyDeleted()
    }, 2000)

    return (
        <Backdrop 
            style={{ zIndex: 2000000, color: '#fff'}}
            open={deleteSuccess}>
                  <div className='card-delete-success-backdrop-container'>
                    <div className='card-delete-success-backdrop-header'>
                        Lot successfully Deleted
                    </div>
                    <div className='card-delete-success-backdrop-check'>
                        <DoneOutlineTwoToneIcon color='secondary' style={{ fontSize: '8vh' }}/>
                    </div>
                </div>  
        </Backdrop>
    )
}