import React from 'react'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default class NewsCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            content: props.content,
            title: props.title,
            date: props.date,

            id: props.id,

            hidden: false,
            editing: false,
            updating: false,

            tempContent: null,
            tempTitle: null,

            showingWarning: false,
            deleting: false
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleEdit() {
        if (this.state.editing) {
            this.setState({
                editing: false,
                title: this.state.tempTitle,
                content: this.state.tempContent
            })
            setTimeout(() => {
                this.setState({
                    tempContent: null,
                    tempTitle: null
                })
            }, 100)
        } else {
            this.setState({
                editing: true,
                tempContent: this.state.content,
                tempTitle: this.state.title
            })

        }
    }

    handleDelete() {
        this.setState({
            deleting: true
        })

        firebase.firestore().collection('News').doc(this.state.id)
        .delete()
        .then(() => {
            setTimeout(() => {
                this.setState({
                    showingWarning: false,
                    deleting: false,
                    hidden: true

                })
            }, 2000)
        })
    }

    handleUpdate() {
        this.setState({
            updating: true
        })

        let today = new Date()
        let day = today.getDate().toString()
        let month = (today.getMonth() + 1).toString()
        let year = today.getFullYear().toString()
        let todaysDate = month.concat('-',day,'-',year)

        firebase.firestore().collection('News').doc(this.state.id)
        .update({
            content: this.state.content,
            date: todaysDate,
            title: this.state.title
        })
        .then(() => {
            setTimeout(() => {
                this.setState({
                    updating: false,
                    editing: false
                })
            }, 2000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    render() {
        let {
            content,
            title,
            date,
            hidden,
            updating,
            editing,
            showingWarning,
            deleting
        } = this.state

        const textfieldStyle = {
            width: '95%',
            marginBottom: '15px'
        }
        const buttonStyle = {
            padding: '0',
            minHeight: '0',
            minWidth: '0',
            textTransform: 'none'
        }
        const submitButtonStyle = {
            padding: '0',
            minHeight: '0',
            // height: '10vh',
            width: '95%',
            textTransform: 'none',
            marginBottom: '15px'
        }

        const testStyle = {
            background: 'rgb(86, 228, 86)',
            height: '33px',
            width: '100vw',
            zIndex: '98347509837405374935794879f'

        }
        const backdropStyle = {
            marginTop: '10vh',
            width: '96vw',
            height: '80vw',
            zIndex: '2384723492837498298',
            marginLeft: '2vw'
        }
        const closeButtonStyle = {
            textTransform: 'none',
            marginRight: '10px'
        }
        const deleteFeedbackStyle = {
            color: 'white'
        }
        let deleteFeedback
        if (deleting) {
            deleteFeedback = <CircularProgress size={22} style={deleteFeedbackStyle}/>
        } else {
            deleteFeedback = 'Yes - Delete'
        }

        const deleteWarning = (
            <Backdrop
                style={backdropStyle}
                transitionDuration={300}
                open={showingWarning}
            >
                <div className='m-ac-news-delete-backdrop-container'>
                    <div className="m-ac-news-delete-backdrop-header">
                        <div className="m-ac-news-delete-backdrop-brand">
                            Delete News
                        </div>
                        <Button
                            disabled={deleting}
                            variant='contained'
                            color='default'
                            style={closeButtonStyle}
                            onClick={() => this.setState({ showingWarning: false })}>
                            Close
                        </Button>
                    </div>
                    <div className="m-ac-news-delete-backdrop-body">
                        Are you sure you wish to delete this news?
                        <br/>
                        <div className='m-ac-news-delete-title'>
                            {title}
                        </div>
                    </div>
                    <div className="m-ac-news-delete-backdrop-options">
                        <Button
                            disabled={deleting}
                            style={buttonStyle}
                            onClick={() => this.handleDelete() }>
                                <div className='m-ac-news-delete-button'>
                                    {deleteFeedback}
                                </div>
                        </Button>
                        <Button
                            variant='contained'
                            color='default'
                            disabled={deleting}
                            style={buttonStyle}
                            onClick={() => this.setState({ showingWarning: false})}>
                                <div className='m-ac-news-cancel-button'>
                                    Cancel
                                </div>
                        </Button>
                    </div>
                </div>
            </Backdrop>
        )
        let disabledCheck, editCheck, submitCheck, savingcheck
        if (updating) {
            savingcheck = ( 

            <LinearProgress 
                style={testStyle}
                
                />
            )
        } else {
            savingcheck = 'Save Changes'
        }

        if (!editing || updating) {
            disabledCheck = true
        } else {
            disabledCheck = false
        }
        if (editing) {
            editCheck = 'Cancel'
            submitCheck = (
                <Button
                    style={submitButtonStyle}
                    onClick={() => this.handleUpdate() }
                >
                    <div className='m-ac-news-save-button'>
                        {savingcheck}
                    </div>
                </Button>
            )
        } else {
            editCheck = 'Edit'
            submitCheck = ( '' )
        }
        if (hidden) {
            return (
                <div>

                </div>
            )
        } else {
            return (
                <div className='m-ac-news-card-container'>
                    {submitCheck}
                    <TextField
                        label='Title'
                        variant='outlined'
                        color='secondary'
                        style={textfieldStyle}
                        value={title}
                        name='title'
                        onChange={e => this.handleChange(e)}
                        disabled={disabledCheck}
                        />
                    <TextField
                        label='News'
                        variant='outlined'
                        color='secondary'
                        style={textfieldStyle}
                        value={content}
                        name='content'
                        multiline
                        onChange={e => this.handleChange(e)}
                        disabled={disabledCheck}
                        />
                    <div className='m-ac-news-card-date'> 
                        {date}
                    </div>
                    <div className="m-ac-news-card-options">
                        <Button
                            disabled={updating}
                            variant='contained'
                            color='default'
                            style={buttonStyle}
                            disableElevation
                            onClick={() => this.handleEdit()}>
                            <div className='m-ac-news-card-option'>
                                {editCheck}
                            </div>
                        </Button>
                        <Button
                            disabled={(editing || updating)}
                            style={buttonStyle}
                            disableElevation
                            onClick={() => this.setState({ showingWarning: true})}>
                            <div className='m-ac-news-card-option m-ac-news-card-delete'>
                                Delete
                            </div>
                        </Button>
                    </div>
                    {deleteWarning}
                </div>
            )
        }
    }



}