import React, {useContext} from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import LinearProgress from '@material-ui/core/LinearProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {AdminContext} from './../../../../Contexts/AdminContext'

export default function DeleteConfirmation() {

    let {
        hideDeleteAll,
        showingDeleteConfirmation,
        deletingMessages,
        deleteMessages,
        deletedMessages,
        messagesDeleted
    } = useContext(AdminContext)

    const style = {
        color: 'white',
        fontSize: '5vh'
    }
    const style2 = {
        color: 'black',
        backgroundColor: 'red'
        
    }
    const style3 = {
        color: 'yellow',
        backgroundColor: 'red',
        height: '100%',
        width: '100%'
    }
    let deleteFeedbackContent, deleteButtonsRow, deleteMessageContent
    
    deletingMessages
        ? deleteFeedbackContent = ( <LinearProgress style={style3}/>)
        : deleteFeedbackContent = ( '' )
    
    messagesDeleted
        ? deleteButtonsRow = (
            <div className='d-a-messages-delete-all-buttons'>

            </div>
        )
        : deleteButtonsRow = (
            <div className='d-a-messages-delete-all-buttons'>
                <Button
                    disabled={deletingMessages}
                    style={style2}
                    variant='contained'
                    onClick={() => { handleDeleteAll()}}>
                        Yes, Delete All
                </Button>
                <Button
                    disabled={deletingMessages}
                    variant='contained'
                    onClick={() => { hideDeleteAll() }}>
                    Cancel
                </Button>
            </div>
        )
    
    messagesDeleted
        ? deleteMessageContent = ( 'All Messages Deleted Successfully')
        : deleteMessageContent = ( 'Are you sure you wish to delete all messages?')

    function handleDeleteAll() {
        deleteMessages()

        firebase.firestore().collection('Messages')
        .get()
        .then(snapshot => {
            snapshot.forEach(message => {
                firebase.firestore().collection('Messages').doc(message.id)
                .delete()
            })
        })
        .then(() => {
            setTimeout(() => {
                deletedMessages()
            }, 2000)
            setTimeout(() => {
                hideDeleteAll()
            }, 8000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    return (
        <Backdrop
        style={{ zIndex: 2000000, color: '#fff'}}
            open={showingDeleteConfirmation}>
        <div className='d-a-messages-delete-all-container'>
            <div className='d-a-messages-delete-all-header'>
                <div className='d-a-messages-delete-all-header-item1'>
                    Delete All Messages
                </div>
                <div className='d-a-messages-delete-all-header-item2'>
                    <Button
                        disabled={deletingMessages}
                        onClick={() => {
                            hideDeleteAll()
                        }}
                    >
                        <CancelIcon style={style}/>
                    </Button>
                </div>

            </div>
            <div className='d-a-messages-delete-all-question'>
                {deleteMessageContent}
            </div>
            {deleteButtonsRow}
            <div className='d-a-messages-delete-all-feedback'>
                {deleteFeedbackContent}
            </div>

        </div>
        </Backdrop>
    )
}
