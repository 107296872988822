import React, {useContext} from 'react'

import MobileCard from './MobileCard'

import {DirectorsContext} from './../../Contexts/DirectorsContext'

export default function DirectorsGarden() {

    let {
        gardenList
    } = useContext(DirectorsContext)

    return gardenList.map(lot => {
        return (
            <MobileCard 
                key={lot.id}
                location={'Garden'}
                lotNumber={lot.lotNumber}
                price={lot.price}
                lotName={lot.lotName}
                spaces={lot.spaces}
            />
        )
    })
}