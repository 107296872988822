import React from 'react'

import FilterBar from './FilterBar'
import Lots from './Lots'
export default function ViewLots() {

    return (
        <div className='m-ac-view-lots-container'>
            <FilterBar />
            <Lots />
        </div>
    )
}