import React from 'react'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { Redirect } from 'react-router'

export default class LoginMobile extends React.Component {
    constructor(){
        super()

        this.state = {
            email: '',
            password: '',
            error: '',
            redirect: false
        }
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleSignIn = () => {
        firebase
            .auth()
            .signInWithEmailAndPassword(this.state.email, this.state.password)
            .then( () => {
                this.setState({redirect: true})
            })
            .catch((err) => {
                this.setState({
                    error: err.message
                })
                console.error(err.message)
          })
    }
    autoSignIn() {
        firebase
            .auth()
            .signInWithEmailAndPassword('auto@test.com', 'qwerty')
            .then(() => {
                this.setState({redirect: true})
            })
            .catch(function(error) {
                console.error(error.message)
          })
    }

    render () {
        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: '/',
                admin: true
            }}/>;
        }
        return (
            <div className='admin-auth-container-mobile'>
                <form className='admin-auth-form-mobile'>
                    {this.state.error}
                    <TextField
                        name='email'
                        className="sign-in-mobile"
                        label="Email"
                        variant="outlined"
                        onChange={this.handleChange}
                    />
                    <TextField
                        name='password'
                        className="sign-in-mobile"
                        label="Password"
                        type='password'
                        variant="outlined"
                        onChange={this.handleChange}
                    />
                    <Button
                        onClick={() => {
                            this.handleSignIn()
                        }}
                    >Admin Login</Button>
                </form>

                <Button
                        onClick={() => {
                            this.autoSignIn()
                        }}
                    >auto sign in</Button>
            </div>
        )
    }
}