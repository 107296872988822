import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import DirectorsKnollwood from './DirectorsKnollwood'
import DirectorsSharon from './DirectorsSharon'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { DirectorsContext } from './../Contexts/DirectorsContext'

export default function UpdatedDirectorsDesktop() {
    let {
        initiating,
        initiated,

        knollwoodView,
        toggleKnollwoodView,
        loadingLots,
        loadedLots,
        showLots,

        addToSharonList,
        addToInterfaithList,
        addToGardenList,
        addToVeteransList
    } = useContext(DirectorsContext)

    let directorsLotContent, switchLocation, locationView, loadingFeedback

    if (knollwoodView) {
        switchLocation = ( 'View Sharon Lots' )
        locationView = ( 'Knollwood Lots')
    } else {
        switchLocation = ( 'View Knollwood Lots' )
        locationView = ( 'Sharon Lots')
    }
    showLots
        ? knollwoodView
            ? directorsLotContent = ( <DirectorsKnollwood />)
            : directorsLotContent = ( <DirectorsSharon />)
        : directorsLotContent = ( '' )
        
    loadingLots
        ? loadingFeedback = ( <LinearProgress style={{ width: '100%', height: '100%'}} color="secondary"/> )
        : loadingFeedback = ( '' )

    if (initiating) {
        initiated()

        firebase.firestore().collection('Sharon').orderBy('priority').orderBy('section').orderBy('lotNumber')
        .get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                let newlot = {
                    lotNumber: doc.data().lotNumber,
                    price: doc.data().price,
                    section: doc.data().section,
                    spaces: doc.data().spaces,
                    id: doc.id
                }
                addToSharonList(newlot)
            })
        })
        .then(() => {
            firebase.firestore().collection('Interfaith').orderBy('priority').orderBy('section').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let newlot = {
                        lotNumber: doc.data().lotNumber,
                        price: doc.data().price,
                        section: doc.data().section,
                        spaces: doc.data().spaces,
                        id: doc.id
                    }
                    addToInterfaithList(newlot)
                })
            })
        })
        .then(() => {
            firebase.firestore().collection('Knollwood').where('garden', '==', true).orderBy('lotName').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let newlot = {
                        lotNumber: doc.data().lotNumber,
                        price: doc.data().price,
                        spaces: doc.data().spaces,
                        lotName: doc.data().lotName,
                        urn: doc.data().urn,
                        id: doc.id
                    }
                    addToGardenList(newlot)
                })
            })
        })
        .then(() => {
            firebase.firestore().collection('Knollwood').where('garden', '==', false).orderBy('lotName').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let newlot = {
                        lotNumber: doc.data().lotNumber,
                        price: doc.data().price,
                        spaces: doc.data().spaces,
                        lotName: doc.data().lotName,
                        id: doc.id,
                        urn: doc.data().urn
                    }
                    addToVeteransList(newlot)
                })
            })
        })
        .then(() => {
            setTimeout(() => {
                loadedLots()
            }, 700)
        })
        .catch(err => {
            console.error(err.code)
        })
    }
        



    return (
        <div className='u-d-directors-container'>
            <div className='u-d-directors-header'>
                Funeral Directors Quick Access
            </div>
            <div className='u-d-directors-location-row'>
                <div>
                    {locationView}
                </div>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        toggleKnollwoodView()
                    }}>
                    {switchLocation}
                </Button>
            </div>
            <div className='u-d-directors-loading-feedback'>
                {loadingFeedback}
            </div>
            {directorsLotContent}
        </div>
    )
}