import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function KnollwoodNames() {

    let {
        detailsName,
        editing,
        setDetailsName,
        setDetailsNameAnchor,
        detailsNameList,
        anchor,
        clearAnchors,
        savingEdits
    } = useContext(MobileAdminContext)

    let nameHeader
    if (detailsName && detailsName !== 'WestChapel') {
        nameHeader = detailsName
    } else if (detailsName && detailsName === 'WestChapel'){
        nameHeader = 'West Chapel'
    } else {
        nameHeader = 'Lot Name'
    }

    const interfaithButtonStyle = {
        padding: '0',
        minHeight: '0',
        width: '100%',
        textTransform: 'none',
        marginBottom: '13px'
    }
    const menuItemStyle = {
        padding: '0',
        textAlign: 'center',
        minHeight: '0'
    }
    let disabledCheck
    if (!editing || savingEdits) {
        disabledCheck = true
    } else {
        disabledCheck = false
    }

    return (
        <div className='t-ac-interfaith-button'>
            <Button 
                style={interfaithButtonStyle}
                aria-controls="location-menu"
                aria-haspopup="true"
                disabled={disabledCheck}
                onClick={event => {
                    setDetailsNameAnchor(event.currentTarget)
                }}>
                    <div className='t-ac-interfaith-button-style'>
                        {nameHeader}
                    </div>
            </Button>
            <Menu
                MenuListProps={{ disablePadding: true}}
                style={{ zIndex: 1000030}}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="location-menu"
                anchorEl={anchor}
                keepMounted
                open={detailsNameList}
                onClose={() => {
                    clearAnchors()
                }}>
                <div className='t-ac-knollwood-category'>Garden of:</div>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Cross')}}>
                    <div className='t-ac-knollwood-menu-items'>Cross</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Faith')}}>
                    <div className='t-ac-knollwood-menu-items'>Faith</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Gethsemane')}}>
                    <div className='t-ac-knollwood-menu-items'>Gethsemane</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Last Supper')}}>
                    <div className='t-ac-knollwood-menu-items'>Last Supper</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Meditation')}}>
                    <div className='t-ac-knollwood-menu-items'>Meditation</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Peace')}}>
                    <div className='t-ac-knollwood-menu-items'>Peace</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Remembrance')}}>
                    <div className='t-ac-knollwood-menu-items'>Remembrance</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Saviour')}}>
                    <div className='t-ac-knollwood-menu-items'>Saviour</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Sunset')}}>
                    <div className='t-ac-knollwood-menu-items'>Sunset</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Word')}}>
                    <div className='t-ac-knollwood-menu-items'>Word</div>
                </MenuItem>
                <div className='t-ac-knollwood-category'>Veterans:</div>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Honor')}}>
                    <div className='t-ac-knollwood-menu-items'>Honor</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Memorial')}}>
                    <div className='t-ac-knollwood-menu-items'>Memorial</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Valor')}}>
                    <div className='t-ac-knollwood-menu-items'>Valor</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('Victory')}}>
                    <div className='t-ac-knollwood-menu-items'>Victory</div>
                </MenuItem>
                <div className='t-ac-knollwood-category'>Urn:</div>
                <MenuItem style={menuItemStyle} onClick={() => { setDetailsName('WestChapel')}}>
                    <div className='t-ac-knollwood-menu-items'>West Chapel</div>
                </MenuItem>
            </Menu>
        </div>
    )
}