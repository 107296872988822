import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {InventoryContext} from './../../../../Contexts/InventoryContext'

export default function MobileDetailsHeader() {

    let {
        mobileCloseDetails
    } = useContext(InventoryContext)

    const arrowStyle = {
        height: '25px',
        marginRight: '4px'
    }
    return (
        <div className="m-details-header">
            <div className="m-details-header-item">
                View Details
            </div>
            <div className="m-inventory-header-close">
                <Button
                    variant='contained'
                    color='default'
                    style={{textTransform: 'none', padding: '5px'}}
                    onClick={() => mobileCloseDetails() }>
                    <ArrowBackIcon style={arrowStyle}/>
                    Back to Inventory
                </Button>
            </div>
        </div>
    )
}