import React, {useContext} from 'react'

import {GlobalContext} from './../../Contexts/GlobalContext'

import Login from './../Login/Login'
import LoggedIn from './LoggedIn/LoggedIn'

export default function AdminDesktop() {

    let {
        admin
    } = useContext(GlobalContext)

    let adminLoginContent

    admin
    ? adminLoginContent = ( <LoggedIn /> )
    : adminLoginContent = ( <Login /> )

    return (
        <div className='d-u-admin-container'>
            {adminLoginContent}
        </div>
    )
}