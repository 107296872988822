import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function LocationSelector() {

    let {
        detailsLocation,
        setDetailsLocation,
        editing,
        setDetailsLocationAnchor,
        detailsLocationMenu,
        clearAnchors,
        anchor,
        savingEdits
    } = useContext(MobileAdminContext)

    const interfaithButtonStyle = {
        padding: '0',
        minHeight: '0',
        width: '100%',
        textTransform: 'none',
        marginBottom: '13px'
    }
    let disabledCheck
    if (!editing || savingEdits) {
        disabledCheck = true
    } else {
        disabledCheck = false
    }
    return (
        <div className='m-ac-interfaith-button'>
            <Button
                style={interfaithButtonStyle}
                disabled={disabledCheck}
                aria-controls="location-menu" 
                aria-haspopup="true" 
                onClick={event => {
                    setDetailsLocationAnchor(event.currentTarget)
                }}>
                    <div className='m-ac-interfaith-button-style'>
                        {detailsLocation}
                    </div>

            </Button>
            <Menu
                style={{ zIndex: 1000030}}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="location-menu"
                anchorEl={anchor}
                keepMounted
                open={detailsLocationMenu}
                onClose={() => {
                    clearAnchors()
                }}>
                <MenuItem onClick={() => {
                    setDetailsLocation('Sharon')
                }}>Sharon</MenuItem>
                <MenuItem onClick={() => {
                    setDetailsLocation('Interfaith')
                }}>Interfaith</MenuItem>
                <MenuItem onClick={() => {
                    setDetailsLocation('Knollwood')
                }}>Knollwood</MenuItem>
                <MenuItem onClick={() => {
                    setDetailsLocation('Shara')
                }}>Shara</MenuItem>
                <MenuItem onClick={() => {
                    setDetailsLocation('Estate')
                }}>Estate</MenuItem>
                <MenuItem onClick={() => {
                    setDetailsLocation('Other')
                }}>Other</MenuItem>
            </Menu>
        </div>
    )
}