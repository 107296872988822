import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'

import {InventoryContext} from './../../../../Contexts/InventoryContext'

export default function MobileDetailsContactButton() {

    let {
        showContact
    } = useContext(InventoryContext)

    const buttonStyle = {
        backgroundColor: 'rgb(150, 208, 231)',
        textTransform: 'none',
        marginTop: '30px'
    }
    return (
        <Button
            style={buttonStyle}
            onClick={() => showContact()}>
            Contact The Lot Broker
        </Button>
    )
}