import React, {useContext} from 'react'

import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function DetailsDetails() {

    let {
        detailsSection,
        detailsNumber,
        detailsName,
        detailsPrice,
        detailsOtherLocation,
        detailsSpaces,
        detailsGarden,
        location
    } = useContext(InventoryContext)

    let detailsDetailsContent, gardenInfoCheck, interfaithCheck

    if (location === 'Knollwood') {
        if (detailsGarden) {
            gardenInfoCheck = 'Garden of:'
        } else {
            gardenInfoCheck = 'Veterans:'
        }
    } else {
        gardenInfoCheck = ''
    }

    if (location === 'Interfaith') {
        interfaithCheck = (
            <div className='d-i-b-interfaith'>
                <div>
                    Sharon
                </div>
                <div>
                    Interfaith
                </div>
            </div>
        )
    } else if (location === 'Sharon') {
        interfaithCheck = location
    }

    let beershebaCheck
    if (location === 'Interfaith') {
        if (detailsSection === '1') {
            beershebaCheck = (
                <div className="d-i-b-info2">
                    Beersheba
                </div>
            )
        } else if (detailsSection === '2') {
            beershebaCheck = (
                <div className="d-i-b-info2">
                    Beersheba II
                </div>
            )
        } else if (detailsSection === '3') {
            beershebaCheck = (
                <div className="d-i-b-info2">
                    Beersheba III
                </div>
            )
        } 
    } else {
        beershebaCheck = detailsSection
    }


    if (location === 'Sharon' || location === 'Interfaith') {
        detailsDetailsContent = (
            <div className='d-i-b-details-container'>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Location
                    </div>
                    <div className="d-i-b-info">
                        {interfaithCheck}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Section
                    </div>
                    <div className="d-i-b-info">
                        {beershebaCheck}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Lot Number
                    </div>
                    <div className="d-i-b-info">
                        {detailsNumber}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Spaces Available
                    </div>
                    <div className="d-i-b-info">
                        {detailsSpaces}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Price
                    </div>
                    <div className="d-i-b-info">
                        ${detailsPrice}ea.
                    </div>
                </div>
            </div>
        )
    } else if (location === 'Knollwood') {

        let urnCheckLabel, urnCheckName
        if (detailsName === 'WestChapel') {
            urnCheckLabel = 'Urn'
            urnCheckName = 'West Chapel'
            gardenInfoCheck = ''
            detailsName = ''
        } else {
            urnCheckLabel = ''
            urnCheckName = ''
        }

        detailsDetailsContent = (
            <div className='d-i-b-details-container'>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Location
                    </div>
                    <div className="d-i-b-info">
                        {location}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        {gardenInfoCheck}{urnCheckLabel}
                    </div>
                    <div className="d-i-b-info">
                        {detailsName}{urnCheckName}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Lot Number
                    </div>
                    <div className="d-i-b-info">
                        {detailsNumber}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Spaces Available
                    </div>
                    <div className="d-i-b-info">
                        {detailsSpaces}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Price
                    </div>
                    <div className="d-i-b-info">
                        ${detailsPrice}ea.
                    </div>
                </div>
            </div>
        )
    } else if (location === 'Estate' || location === 'Other') {
        detailsDetailsContent = (
            <div className='d-i-b-details-container'>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Location
                    </div>
                    <div className="d-i-b-info3">
                        {detailsOtherLocation}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Spaces Available
                    </div>
                    <div className="d-i-b-info">
                        {detailsSpaces}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Price
                    </div>
                    <div className="d-i-b-info">
                        ${detailsPrice}
                    </div>
                </div>
            </div>
        )
    } else if (location === 'Shara') {
        detailsDetailsContent = (
            <div className='d-i-b-details-container'>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Location
                    </div>
                    <div className="d-i-b-info">
                        Shara Tfilo
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Spaces Available
                    </div>
                    <div className="d-i-b-info">
                        {detailsSpaces}
                    </div>
                </div>
                <div className="d-i-b-item">
                    <div className="d-i-b-label">
                        Price
                    </div>
                    <div className="d-i-b-info">
                        ${detailsPrice}ea.
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='d-i-b-details'>
            {detailsDetailsContent}
        </div>
    )
}