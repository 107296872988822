import React, { useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import { AdminContext } from './../../../../../Contexts/AdminContext'

export default function LotCardLotNumber() {
    let { 
        comments,
        editingCard,
        handleChange
    } = useContext(AdminContext)

    let availabilityCheck, commentsValue
    editingCard
        ? availabilityCheck = true
        : availabilityCheck = false

    comments
    ? commentsValue = comments
    : commentsValue = ( '' )
    return (
        <TextField 
            multiline
            fullWidth
            rowsMax={3}
            disabled={(!availabilityCheck)}
            color='secondary'
            name='comments'
            type='text'
            value={commentsValue}
            label='Comments'
            variant='outlined'
            onChange={event => {
                handleChange(event)
            }}/>
    )

}