import React, { useContext } from 'react'

import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone'
import Button from '@material-ui/core/Button'

import { AdminContext } from './../../../Contexts/AdminContext'

export default function AdminAddLotButton() {

    let {
        toggleAddingLot,
        lotIsNotSold
    } = useContext(AdminContext)

    return (
        // <div className="new-admin-d-lot-header">
        //     <div className="new-admin-d-lot-brand">
        //         Lot List
        //     </div>
            <div className="new-admin-d-add-lot">
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                        toggleAddingLot()
                        lotIsNotSold()
                    }}>
                    <AddBoxTwoToneIcon color='primary' />
                    <div className='new-admin-d-add-lot-logo'>
                        New Lot
                    </div>
                </Button>
        </div>
    // </div>    
    )
}