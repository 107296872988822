import React, { useContext } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function SoldButton() {

    let {
        detailsSold,
        changeLotSold,
        changingLotSold,
        finishChangingLotSold,
        editing,
        savingEdits,

        detailsOriginalPath,
        detailsImageUrl,
        detailsImageNumber,
        detailsLocation,
        detailsId,
        detailsSection,
        detailsName,
        detailsNumber,
        detailsSpaces,
        detailsPrice,
        detailsComments,
        detailsPriority,
        detailsOtherLocation,
        setDetailsId,
        setDetailsSold,

        callTimeout,
        setFinishedCall

    } = useContext(MobileAdminContext)

    let buttonMessage
    if (changingLotSold) {
        buttonMessage = ( <CircularProgress size={25} />)
    } else {
        if (detailsSold) {
            buttonMessage = 'Mark Lot as Available'
        } else {
            buttonMessage = 'Mark Lot as Sold'
        }
    }
        

    const soldButtonStyle = {
        padding: '0',
        minHeight: '0',
        textTransform: 'none',
        width: '100%',
        marginBottom: '13px',
        backgroundColor: 'green',
        marginTop: '13px'
    }

    function handleSoldLot() {
        changeLotSold()

        callTimeout()

        let today = new Date()
        let day = today.getDate().toString()
        let month = (today.getMonth() + 1).toString()
        let year = today.getFullYear().toString()
        let todaysDate = month.concat('-',day,'-',year)

        let fixedPath, fixedUrl, fixedNumber, urnCheck
        if (detailsOriginalPath) {
            fixedPath = detailsOriginalPath
        } else {
            fixedPath = ''
        }
        if (detailsImageUrl) {
            fixedUrl = detailsImageUrl
        } else {
            fixedUrl = ''
        }
        if (detailsImageNumber) {
            fixedNumber = detailsImageNumber
        } else {
            fixedNumber = ''
        }

        if (detailsName === 'WestChapel'){
            urnCheck = true
        } else {
            urnCheck = false
        }



        if (detailsSold) {
            let gardenCheck

            const gardenList = [
                'Cross',
                'Faith',
                'Gethsemane',
                'Last Supper',
                'Meditation',
                'Saviour',
                'Sunset',
                'Word',
                'Peace',
                'Remembrance'
            ]
            if (detailsLocation === 'Knollwood' && gardenList.includes(detailsName)) {
                gardenCheck = true
            } else {
                gardenCheck = null
            }
            firebase.firestore().collection('sold').doc(detailsId)
            .delete()
            .then(() => {
                firebase.firestore().collection(detailsLocation)
                .add({
                    location: detailsLocation,
                    section: detailsSection,
                    lotName: detailsName,
                    lotNumber: detailsNumber,
                    spaces: detailsSpaces,
                    price: detailsPrice,
                    comments: detailsComments,
                    lastEdit: todaysDate,
                    priority: detailsPriority,
                    otherLocation: detailsOtherLocation,
                    imageUrl: fixedUrl,
                    imageNumber: fixedNumber,
                    originalPath: fixedPath,
                    urn: urnCheck,
                    garden: gardenCheck
                })
                .then(docRef => {
                    setDetailsId(docRef.id)
                })
                .catch(err => {
                    console.error(err.code)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setDetailsSold(false)
                    finishChangingLotSold()
                    setFinishedCall()
                }, 1500)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else {
            firebase.firestore().collection(detailsLocation).doc(detailsId)
            .delete()
            .then(() => {
                firebase.firestore().collection('sold')
                .add({
                    location: detailsLocation,
                    section: detailsSection,
                    lotName: detailsName,
                    lotNumber: detailsNumber,
                    spaces: detailsSpaces,
                    price: detailsPrice,
                    comments: detailsComments,
                    lastEdit: todaysDate,
                    priority: detailsPriority,
                    otherLocation: detailsOtherLocation,
                    imageUrl: fixedUrl,
                    imageNumber: fixedNumber,
                    originalPath: fixedPath,
                    urn: urnCheck
                })
                .then(docRef => {
                    setDetailsId(docRef.id)
                })
                .catch(err => {
                    console.error(err.code)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setDetailsSold(true)
                    finishChangingLotSold()
                    setFinishedCall()
                }, 1500)
            })
            .catch(err => {
                console.error(err.code)
            })
        }
    }
    let disabledCheck
    if (editing || savingEdits || changingLotSold) {
        disabledCheck = true
    } else {
        disabledCheck = false
    }
    return (
        <div className='m-ac-details-delete-lot-container'>
            <Button
                disabled={disabledCheck}
                style={soldButtonStyle}
                onClick={() => handleSoldLot()}>
                <div className='m-ac-sold-button-style'>
                    {buttonMessage}
                </div>
            </Button>
        </div>
    )
}