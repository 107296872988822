import React from 'react'

import { isMobileOnly, isTablet, isIPad13 } from 'react-device-detect'

import { InventoryContextProvider } from './../Contexts/InventoryContext'

import InventoryMobile from './Mobile/InventoryMobile'
import InventoryDesktop from './Desktop/InventoryDesktop'
import InventoryTablet from './Tablet/InventoryTablet'

export default function Inventory() {

    if (isMobileOnly) {
        return (<InventoryContextProvider><InventoryMobile /></InventoryContextProvider> )
    } else if (isTablet || isIPad13) {
        return (<InventoryContextProvider><InventoryTablet /></InventoryContextProvider> )
    } else {
        return (<InventoryContextProvider><InventoryDesktop /></InventoryContextProvider> )
    }
}