import React, {useContext} from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

import WhiteLogo from './../../../../../StyleNew/logoWhite.png'

import AdminHome from './AdminHome/AdminHome'
import AddLot from './AddLot/AddLot'
import ViewGallery from './ViewGallery/ViewGallery'
import ViewNews from './ViewNews/ViewNews'
import ViewLots from './ViewLots/ViewLots'
import ViewTestimonials from './ViewTestimonials/ViewTestimonials'
import ViewMessages from './ViewMessages/ViewMessages'
import ViewLotDetails from './ViewLotDetails/ViewLotDetails'

import AdminConsoleHeader from './AdminConsoleHeader'

import {MobileAdminContext} from './../../../../Contexts/MobileAdminContext'

export default function AdminConsole() {

    let {
        adminView,
        loading
    } = useContext(MobileAdminContext)

    let mobileAdminContent

    if (adminView === null) {
        mobileAdminContent = ( <AdminHome />)
    } else if (adminView === 'Add New Lot') {
        mobileAdminContent = ( <AddLot /> )
    } else if (adminView === 'Lots') {
        mobileAdminContent = ( <ViewLots /> )
    } else if (adminView === 'Gallery') {
        mobileAdminContent = ( <ViewGallery /> )
    } else if (adminView === 'News') {
        mobileAdminContent = ( <ViewNews /> )
    } else if (adminView === 'Testimonials') {
        mobileAdminContent = ( <ViewTestimonials /> )
    } else if (adminView === 'Messages') {
        mobileAdminContent = ( <ViewMessages /> )
    }
    
    // else if (adminView === 'Lot Details') {
    //     mobileAdminContent = ( <ViewLotDetails /> )
    // }

    const loadingStyle = {
        marginTop: '8vh',
        zIndex: '100',
        backgroundColor: 'rgb(150, 208, 231)',
        height: '87vh'
    }
    const spinnerStyle = {
        color: 'white',
        position: 'relative'
    }

    return (
        <div className='m-admin-container'>
            <div className="m-ac-container">
                <AdminConsoleHeader />
                {mobileAdminContent}
                <ViewLotDetails />
                <Backdrop
                    transitionDuration={400}
                    style={loadingStyle}
                    open={loading}>
                    <CircularProgress style={spinnerStyle} size={140}/>
                    <img src={WhiteLogo} alt='logo' className='m-inventory-logo'/>
                </Backdrop>
            </div>
        </div>
    )
}