import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'

import { AdminContext } from './../../../Contexts/AdminContext'

export default function AdminCancelLotAddButton() {

    let {
        cancelNewLotAdd
    } = useContext(AdminContext)

    return (
        <div className="new-admin-d-add-lot-backdrop-header">
            <div className="new-admin-d-add-lot-backdrop-header-item">
                Add New Lot
            </div>
            <div className="new-admin-d-add-lot-backdrop-header-item">
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        cancelNewLotAdd()
                    }}>
                    Cancel
                </Button>
            </div>
        </div>
    )
}