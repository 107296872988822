import React, {
    useContext
    } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {InventoryContext} from '../../../../Contexts/InventoryContext'

export default function KnollwoodFilter() {

    let {
        addToVisibleLots,
        disableMenu,
        lotName,
        setLotName,
        clearAnchors,
        setNameAnchor,
        anchor,
        nameMenu,
        filteringByName,
        filteredSuccess
    } = useContext(InventoryContext)

    let nameHeader
    lotName
        ? nameHeader = (
            <div className='t-inventory-knollwood-filter'>
                {lotName}
            </div>
        )
        : nameHeader = (
            <div className='t-inventory-knollwood-filter'>
                Lot Name
            </div>
        )

    function handleFilterByName(name) {
        setLotName(name)
        filteringByName()

        firebase.firestore().collection('Knollwood').where('lotName', '==', name)
        .get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                let lot = {
                    location: doc.data().location,
                    lotName: doc.data().lotName,
                    price: doc.data().price,
                    spaces: doc.data().spaces,
                    lotNumber: doc.data().lotNumber,
                    id: doc.id
                }
                addToVisibleLots(lot)
            })
        })
        .then(() => {
            setTimeout(() => {
                filteredSuccess()
            }, 1000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }
    const menuStyle= {
        zIndex: '1000',
        padding: '0',
        disablePadding: true,
    }
    const menuItemStyle = {
        textAlign: 'right',
        padding: '0',
        minWidth: '35vw'
    }
    return (
        <div>
            <Button disabled={disableMenu} aria-controls="location-menu" aria-haspopup="true"
                onClick={event => {
                    setNameAnchor(event.currentTarget)
                }}>
                {nameHeader}
            </Button>
            <Menu
                style={menuStyle}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="location-menu"
                anchorEl={anchor}
                keepMounted
                open={nameMenu}
                MenuListProps={{ disablePadding: true}}
                onClose={() => {
                    clearAnchors()
                }}>
                <div className='t-inventory-knollwood-category'>Garden:</div>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Cross')}>
                    <div className='t-inventory-knollwood-item'>Cross</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Faith')}>
                    <div className='t-inventory-knollwood-item'>Faith</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Gethsemane')}>
                    <div className='t-inventory-knollwood-item'>Gethsemane</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Last Supper')}>
                    <div className='t-inventory-knollwood-item'>Last Supper</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Meditation')}>
                    <div className='t-inventory-knollwood-item'>Meditation</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Peace')}>
                    <div className='t-inventory-knollwood-item'>Peace</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Remembrance')}>
                    <div className='t-inventory-knollwood-item'>Remembrance</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Saviour')}>
                    <div className='t-inventory-knollwood-item'>Saviour</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Sunset')}>
                    <div className='t-inventory-knollwood-item'>Sunset</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Word')}>
                    <div className='t-inventory-knollwood-item'>Word</div>
                </MenuItem>
                <div className='t-inventory-knollwood-category'>Veterans:</div>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Honor')}>
                    <div className='t-inventory-knollwood-item'>Honor</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Memorial')}>
                    <div className='t-inventory-knollwood-item'>Memorial</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Valor')}>
                    <div className='t-inventory-knollwood-item'>Valor</div>
                </MenuItem>
                <MenuItem style={menuItemStyle} onClick={() => handleFilterByName('Victory')}>
                    <div className='t-inventory-knollwood-item'>Victory</div>
                </MenuItem>
            </Menu>
        </div>
    )
}