import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {InventoryContext} from '../../../Contexts/InventoryContext'

export default function ChooseLocationMobile() {

    let {
        loading,
        setInitialLocation,
        addToVisibleLots,
        setLocationChosen
    } = useContext(InventoryContext)

    const tabletChooseStyle = {
        minHeight: '10vh',
        minWidth: '15vh',
        margin: '5px',
        backgroundColor: 'rgba(150, 208, 231, 0.849)',
        marginTop: '7px',
        borderRadius: '20px',
        textTransform: 'none',
        padding: '0'
    }
    function handleGetInventory(location) {
        setInitialLocation(location)

        if (location === 'Sharon' || location === 'Interfaith') {
            firebase.firestore().collection(location).orderBy('priority').orderBy('section').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        section: doc.data().section,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        lotNumber: doc.data().lotNumber,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 100)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Knollwood') {
            firebase.firestore().collection(location).orderBy('lotName').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        lotName: doc.data().lotName,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        lotNumber: doc.data().lotNumber,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Estate' || location === 'Other') {
            firebase.firestore().collection(location)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        otherLocation: doc.data().otherLocation,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'sold') {
            firebase.firestore().collection('sold').where('location', '==', 'Sharon').orderBy('priority').orderBy('section')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        otherLocation: doc.data().otherLocation,
                        section: doc.data().section,
                        lotName: doc.data().lotName,
                        lotNumber: doc.data().lotNumber,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                firebase.firestore().collection('sold').where('location', '==', 'Interfaith').orderBy('priority').orderBy('section')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let lot = {
                            location: doc.data().location,
                            otherLocation: doc.data().otherLocation,
                            section: doc.data().section,
                            lotName: doc.data().lotName,
                            lotNumber: doc.data().lotNumber,
                            spaces: doc.data().spaces,
                            price: doc.data().price,
                            id: doc.id,
                        }
                        addToVisibleLots(lot)
                    })
                })
            })
            .then(() => {
                firebase.firestore().collection('sold').where('location', '==', 'Knollwood')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let lot = {
                            location: doc.data().location,
                            otherLocation: doc.data().otherLocation,
                            section: doc.data().section,
                            lotName: doc.data().lotName,
                            lotNumber: doc.data().lotNumber,
                            spaces: doc.data().spaces,
                            price: doc.data().price,
                            id: doc.id,
                        }
                        addToVisibleLots(lot)
                    })
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Shara') {
            firebase.firestore().collection('Shara')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setLocationChosen()
                }, 400)
            })
            .catch(err => {
                console.error(err.code)
            })
        }
    }
    return (
        <div className='t-inventory-container'>
            <div className="t-inventory-choose-header">
                Choose a Location to Browse Current Inventory
            </div>

            <div className="t-inventory-choose-top">
                <Button 
                    disabled={loading}
                    style={tabletChooseStyle}
                    onClick={() => handleGetInventory('Sharon') }>
                    <div className='t-inventory-choose-button'>
                        Sharon
                    </div>
                </Button>
                <Button 
                    disabled={loading}
                    style={tabletChooseStyle}
                    onClick={() => handleGetInventory('Interfaith') }>
                    <div className='t-inventory-choose-button'>
                        Sharon
                        <br/>
                        Interfaith
                    </div>
                </Button>
                <Button 
                    disabled={loading}
                    style={tabletChooseStyle}
                    onClick={() => handleGetInventory('Knollwood') }>
                    <div className='t-inventory-choose-button'>
                        Knollwood
                    </div>
                </Button>
                <Button 
                    disabled={loading}
                    style={tabletChooseStyle}
                    onClick={() => handleGetInventory('Shara') }>
                    <div className='t-inventory-choose-button'>
                        Shara
                        <br/>
                        Tfilo
                    </div>
                </Button>
            </div>
            <div className="t-inventory-choose-bottom">
                <Button 
                    disabled={loading}
                    style={tabletChooseStyle}
                    onClick={() => handleGetInventory('Estate') }>
                    <div className='t-inventory-choose-button'>
                        Estates
                    </div>
                </Button>
                <Button 
                    disabled={loading}
                    style={tabletChooseStyle}
                    onClick={() => handleGetInventory('Other') }>
                    <div className='t-inventory-choose-button'>
                        Other
                    </div>
                </Button>
                <Button 
                    disabled={loading}
                    style={tabletChooseStyle}
                    onClick={() => handleGetInventory('sold') }>
                    <div className='t-inventory-choose-button'>
                        Sold
                    </div>
                </Button>
            </div>
        </div>
    )
}