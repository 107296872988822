import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default function AddNews() {

    let {
        submittingNews,
        addingNews,
        cancelAddingNews,
        handleChange,
        newsTitle,
        newsContent,
        finishSubmittingNews,
        submitNews,
        addToNews,
        clearNews,
        finishLoadingNews
    } = useContext(MobileAdminContext)

    function handleSubmitNews() {
        submitNews()

        let today = new Date()
        let day = today.getDate().toString()
        let month = (today.getMonth() + 1).toString()
        let year = today.getFullYear().toString()
        let todaysDate = month.concat('-',day,'-',year)

        firebase.firestore().collection('News')
        .add({
            content: newsContent,
            title: newsTitle,
            date: todaysDate,
        })
        .then(() => {
            clearNews()
            firebase.firestore().collection('News').orderBy('date', 'desc')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let news = {
                        date: doc.data().date,
                        content: doc.data().content,
                        title: doc.data().title,
                        id: doc.id
                    }
                    addToNews(news)
                })
            })
        })
        .then(() => {
            finishLoadingNews()
            setTimeout(() => {
                finishSubmittingNews()
            }, 400)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    const backdropStyle = {
        marginTop: '10vh',
        width: '96vw',
        height: '80vw',
        zIndex: '2384723492837498298',
        marginLeft: '2vw'
    }
    const closeButtonStyle = {
        marginRight: '10px',
        textTransform: 'none'
    }
    const titleStyle = {
        marginTop: '15px',
    } 
    const bodyStyle = {
        marginTop: '15px'

    }
    const submitButtonStyle = {
        height: '100%',
        width: '100%',
        padding: '0'
    }
    let submitCheck, titleCheck, contentCheck
    if (submittingNews) {
        submitCheck = <CircularProgress size={25} style={{ color: 'black', padding: '0', }}/>
    } else {
        submitCheck = 'Submit News'
    }
    if (newsTitle) {
        titleCheck = newsTitle
    } else {
        titleCheck = ''
    }
    if (newsContent) {
        contentCheck = newsContent
    } else {
        contentCheck = ''
    }
    return (
        <Backdrop
            style={backdropStyle}
            transitionDuration={300}
            open={addingNews}>
            <div className='m-ac-news-backdrop-container'>
                <div className='m-ac-news-backdrop-header'>
                    <div className="m-ac-news-backdrop-brand">
                        Add News
                    </div>
                    <Button
                        disabled={submittingNews}
                        style={closeButtonStyle}
                        variant='contained'
                        color='default'
                        onClick={() => cancelAddingNews()}>
                        Close
                    </Button>
                </div>
                <div className="m-ac-news-backdrop-body">
                    <TextField
                        disabled={submittingNews}
                        label='Title'
                        name='newsTitle'
                        fullWidth
                        value={titleCheck}
                        variant='outlined'
                        color='secondary'
                        style={titleStyle}
                        onChange={(e) => handleChange(e)}/>
                    <TextField
                        disabled={submittingNews}
                        label='News'
                        name='newsContent'
                        fullWidth
                        value={contentCheck}
                        variant='outlined'
                        color='secondary'
                        style={bodyStyle}
                        rows={4}
                        multiline
                        onChange={(e) => handleChange(e)}/>  
                </div>
                <div className="m-ac-news-backdrop-submit">
                    <Button
                        disabled={submittingNews}
                        style={submitButtonStyle}
                        onClick={() => handleSubmitNews()}>
                        <div className="m-ac-news-submit-style">
                            {submitCheck}
                        </div>
                    </Button>
                </div>
            </div>
        </Backdrop>
    )
}