import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function DateInfo() {

    let {
        detailsLastEdit,
        detailsCreatedAt
    } = useContext(MobileAdminContext)

    if (detailsLastEdit) {
        return (
            <div className='t-ac-details-date-container'>
                <div className='t-ac-details-date-header'>
                    Last Edited on:
                </div>
                <div className='t-ac-details-date'>
                    {detailsLastEdit}
                </div>
            </div>
        )
    } else if (detailsCreatedAt) {
        return (
            <div className='t-ac-details-date-container'>
                <div className='t-ac-details-date-header'>
                    Lot created on:
                </div>
                <div className='t-ac-details-date'>
                    {detailsCreatedAt}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                
            </div>
        )
    }
}