import React, {useContext} from 'react'

import firebase from 'firebase/app'
import 'firebase/firestore'

import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import ScheduleIcon from '@material-ui/icons/Schedule'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import LinearProgress from '@material-ui/core/LinearProgress'

import {GlobalContext} from './../Contexts/GlobalContext'

export default function ContactMobile() {

    let {
        testimonialCheck,
        handleChange,
        writingTestimonial,
        toggleTestimonial,
        testimonialName,
        testimonialDate,
        testimonialMessage,
        wroteTestimonial,
        feedback,
        showFeedback,
        error,
        setError,
        nameError,
        dateError,
        messageError,
        clearErrors,
        setDateError,
        setNameError,
        setMessageError
    } = useContext(GlobalContext)

    const contactStyle = {
        fontSize: '60px',
        color: 'black'
    }
    const exitStyle = {
        color: 'rgba(41, 87, 105, 0.849)',
        fontSize: '30px',
        padding: '10px'
    }
    const textfieldStyle = {

    }
    const testButtonStyle = {
        backgroundColor: 'rgba(150, 208, 231, 0.849)', 
        // height: '60px', 
        // width: '62vw' ,
        padding: '0',
        borderRadius: '10px'
    }
    let today = new Date()
        // let day = today.getDate().toString()
        // let month = (today.getMonth() + 1).toString()
        let year = today.getFullYear().toString()
        

        let adjustedMonth = today.getMonth() + 1
        let finalMonth
        if (adjustedMonth < 10) {
            let thisthing = '0'
            finalMonth = thisthing.concat(adjustedMonth)
        } else {
            finalMonth = (today.getMonth() + 1).toString()
        }
        let presetDate = year.concat('-',finalMonth,'-','01').toString()

    function handleSubmitTestimonial() {

        
        if (testimonialName && testimonialDate && testimonialMessage) {
            showFeedback()
            clearErrors()
            firebase.firestore().collection('TestimonialsPending')
            .add({
                message: testimonialMessage,
                name: testimonialName,
                dateOfSale: testimonialDate
            })
            .then(() => {
                setTimeout(() => {
                    wroteTestimonial()
                }, 1100)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if ( !testimonialName && !testimonialDate && !testimonialMessage ) {
            clearErrors()
            setDateError()
            setNameError()
            setMessageError()
            setError('Please fill out Name, Date of Sale and Testimonial Message')
        } else if ( !testimonialName && !testimonialDate ) {
            clearErrors()
            setDateError()
            setNameError()
            setError('Please fill out Name and Date of Sale')
        } else if ( !testimonialName && !testimonialMessage ) {
            clearErrors()
            setNameError()
            setMessageError()
            setError('Please fill out Name and Testimonial Message')
        } else if ( !testimonialDate && !testimonialMessage ) {
            clearErrors()
            setDateError()
            setMessageError()
            setError('Please fill out Date of Sale and Testimonial Message')
        } else if ( !testimonialName ) {
            clearErrors()
            setNameError()
            setError('Please fill out Name')
        } else if ( !testimonialDate ) {
            clearErrors()
            setDateError()
            setError('Please fill out Date of Sale')
        } else if ( !testimonialMessage ) {
            clearErrors()
            setMessageError()
            setError('Please fill out Testimonial Message')
        }
    }
    let contactFeedback, testimonialContent
    if (feedback) {
        contactFeedback = <LinearProgress 
        color="secondary"
        style={{
            height: '100%',
            width: '100%'
        }} />
    } else {
        contactFeedback = error
    }
    if (testimonialCheck) {
        testimonialContent = (
            <div className="m-contact-testimonial-button">
                <div className="m-contact-testimonial-success">
                    Thank you for writing a testimonial!
                </div>
            </div>
        )
    } else {
        testimonialContent = (
            <div className="m-contact-testimonial-button">
                <div className="m-contact-testimonial-header">
                    Enjoyed your experience with 
                    <br/>
                    The Lot Broker?
                </div>
                <Button 
                    variant='contained'
                    style={testButtonStyle}
                    onClick={() => { toggleTestimonial() }}>
                    <div className='m-toggle-testimonial-button'>
                        Write a testimonial!
                    </div>
                </Button>
            </div>
        )
    }
    return (
        <div className='m-contact-container'>
            <div className="m-contact-header">
                Contact The Lot Broker
            </div>
            <a href="mailto:GLevine@TheLotBroker.com" style={{textDecoration: 'none'}}>
                <div className="m-contact-item">
                    <div className="m-contact-item-icon">
                        <EmailIcon style={contactStyle} />
                    </div>
                    <div className="m-contact-item-info">
                        GLevine@TheLotBroker.com
                    </div>
                </div>
            </a>
            <a href="tel:6176403143" style={{textDecoration: 'none'}}>
                <div className="m-contact-item">
                    <div className="m-contact-item-icon">
                        <PhoneIcon style={contactStyle} />
                    </div>
                    <div className="m-contact-item-info">
                        (617) 640-3143
                    </div>
                </div>
            </a>
            <div className="m-contact-item">
                <div className="m-contact-item-icon">
                    <ScheduleIcon style={contactStyle} />
                </div>
                <div className="m-contact-item-schedule">
                    Hours of Operation:
                    <br/>
                    All Day Every Day
                </div>
            </div>
            {testimonialContent}
            <Backdrop 
                open={writingTestimonial}
                style={{
                    zIndex: 100, 
                    height: '100vh', 
                    width: '100vw', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start'
                }} 
                transitionDuration={500}>
                <div className='m-contact-testimonial-backdrop'>
                    <div className="m-contact-backdrop-header">
                        <div className="m-contact-backdrop-header-item">
                            Write a Testimonial
                        </div>
                        <div className="m-contact-backdrop-header-item2">
                        <Button 
                        disabled={feedback}
                        style={{padding: '0'}}
                        onClick={() => {
                            toggleTestimonial()
                        }}
                    >
                        <CancelIcon style={exitStyle}/>
                    </Button>
                        </div>
                    </div>
                    <div className="m-contact-backdrop-top">
                        <div className="m-contact-backdrop-item">
                            <TextField
                                value={testimonialName}
                                error={nameError}
                                disabled={feedback}
                                style={textfieldStyle}
                                fullWidth
                                name='testimonialName'
                                onChange={(e) => { handleChange(e) }}
                                label="Your Name"
                                color='secondary'
                                />
                        </div>
                        <div className="m-contact-backdrop-date">
                            <TextField
                                // value={testimonialDate}
                                error={dateError}
                                disabled={feedback}
                                color='secondary'
                                fullWidth
                                name='testimonialDate'
                                onChange={(e) => { handleChange(e) }}
                                label="Date Of Sale"
                                type="date"
                                defaultValue={presetDate}
                                inputProps={{ style: {textAlign: 'center'} }}
                                InputLabelProps={{
                                shrink: true,
                                }}/>
                        </div>
                    </div>
                    <div className="m-contact-backdrop-body">
                        <TextField
                            value={testimonialMessage}
                            error={messageError}
                            disabled={feedback}
                            style={{
                                height: '95%',
                                width: '90%'
                            }}
                            name='testimonialMessage'
                            onChange={(e) => handleChange(e)}
                            placeholder="The Lot Broker was great!"
                            multiline
                            rows={5}
                            color='secondary'
                            variant='outlined'
                            />
                    </div>
                    <div className="m-contact-backdrop-submit">
                        <Button
                            
                            disabled={feedback}
                            color='secondary'
                            variant='contained'
                            style={{marginRight: '40px'}}
                            onClick={() => handleSubmitTestimonial() }>
                            Submit
                        </Button>
                        <Button
                            disabled={feedback}
                            color='default'
                            variant='contained'
                            onClick={() => toggleTestimonial() }>
                            Cancel
                        </Button>
                    </div>
                    <div className="m-contact-backdrop-feedback">
                        {contactFeedback}
                    </div>
                </div>
            </Backdrop>
        </div>
    )
}