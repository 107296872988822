import React from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import firebase from '@firebase/app'
import 'firebase/firestore'


export default class PublishedCard extends React.Component {
    constructor(props) {
        super(props)
            
        this.state = {
            editing: false,
            message: props.message, 
            name: props.name, 
            dateOfSale: props.dateOfSale,
            id: props.id,
            published: false,
            initialName: props.name,
            initialDate: props.dateOfSale,
            initialMessage: props.message,
            backdrop: false,
            deleted: false,
            updated: false
        }
    }

    handleUnPublish() {
        firebase.firestore().collection('TestimonialsPending')
        .add({
            message: this.state.message,
            name: this.state.name,
            dateOfSale: this.state.dateOfSale
        })
        .then(() =>{
            firebase.firestore().collection('TestimonialsCleared').doc(this.state.id)
            .delete()
        })
        .then(() => {
            this.setState({
                published: true
            })
            this.toggleEdit(true)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    toggleEdit(published) {
        if (published) {
            this.setState({
                editing: false
            })
        } else {
            if (this.state.editing) {
                this.setState({
                    editing: false,
                    name: this.state.initialName,
                    dateOfSale: this.state.initialDate,
                    message: this.state.initialMessage
                })
            } else {
                this.setState({
                    editing: true
                })
            }
        }
    }

    handleUpdate() {
        firebase.firestore().collection('TestimonialsCleared').doc(this.state.id)
        .set({
            name: this.state.name,
            dateOfSale: this.state.dateOfSale,
            message: this.state.message
        })
        .then(() => {
            this.setState({
                updated: true,
                editing: false
            })
        })
    }

    toggleDelete() {
        this.setState({
            backdrop: !this.state.backdrop
        })
    }

    handleDelete() {
        firebase.firestore().collection('TestimonialsPending').doc(this.state.id)
        .delete()
        .then(() => {
            this.setState({
                deleted: true,
                backdrop: false

            })
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {

        let { message, name, dateOfSale, published, editing, backdrop, deleted, updated } = this.state

        let editMessage, nameEdit, dateEdit, messageEdit, publishedCardContent, editCardSave

        editing
            ? editMessage = ( 'Cancel' )
            : updated 
                ? editMessage = ( 'Updated' )
                : editMessage = ( 'Edit' )
    
        editing
            ? nameEdit = (
                <TextField
                    name='name'
                    onChange={this.handleChange}
                    defaultValue={name}
                />
            )
            : nameEdit = ( name )
        
        editing
            ? dateEdit = (
                <TextField
                    name='dateOfSale'
                    onChange={this.handleChange}
                    type="date"
                    defaultValue={dateOfSale}
                    />
            )
            : dateEdit = ( dateOfSale )

        editing
            ? messageEdit = (
                <TextField
                    variant='outlined'
                    fullWidth
                    multiline
                    name='message'
                    onChange={this.handleChange}
                    defaultValue={message}
                />
            )
            : messageEdit = ( message )
        
        editing
            ? editCardSave = (
                <div className='pending-d-card-save'>
                    <Button
                        onClick={() => {
                            this.handleUpdate()
                        }}
                        color='secondary'
                        variant='contained'
                    >
                        Update
                    </Button>
                </div>
            )
            : editCardSave = ( '' )

        deleted
            ? publishedCardContent = (
                <div className='admin-d-testimonials-successful-action'>
                    Deleted!
                </div>

                )
            : published
                ? publishedCardContent = (
                    <div className='admin-d-testimonials-successful-action'>
                        Unpublished!
                    </div>
                    )
                : publishedCardContent = (
                    <React.Fragment>
                    <Backdrop 
                    style={{ zIndex: 100000, color: '#fff'}}
                    open={backdrop} 
                    >
                    <div className='admin-d-testimonials-backdrop-container'>
                        <div className="admin-d-testimonials-backdrop-question">
                            Are you sure you wish to delete testimonial written by {name}?
                        </div>
                        <div className="admin-d-testimonials-backdrop-options">
                            <div className="admin-d-testimonials-backdrop-item">
                                <Button
                                    variant='contained'
                                    // color='danger'
                                    onClick={() => {
                                        this.handleDelete()
                                    }}
                                >Delete</Button>
                            </div>
                            <div className="admin-d-testimonials-backdrop-item">
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => {
                                        this.toggleDelete()
                                    }}
                                >Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Backdrop>
                <div className="pending-d-card-details">
                    <div className="pending-d-card-header">
                        <div className="pending-d-card-header-name">
                            {nameEdit}
                        </div>
                        <div className="pending-d-card-header-date">
                            {dateEdit}
                        </div>
                    </div>
                    <div className="pending-d-card-body">
                        <div className="pending-d-card-message">
                            {messageEdit}
                        </div>
                    </div>
                </div>
                <div className="pending-d-card-options">
                    <div className="pending-d-card-publish">
                        <Button
                            onClick={() => {
                                this.handleUnPublish()
                            }}
                            variant='contained'
                            color='secondary'
                        >
                            UnPublish
                        </Button>
                    </div>
                    <div className="pending-d-card-edit">
                        <Button
                            onClick={() => {
                                this.toggleEdit(false)
                            }}
                            variant='contained'
                            color='secondary'
                        >
                            {editMessage}
                        </Button>
                    </div>
                    {editCardSave}
                    <div className="pending-d-card-delete">
                        <Button
                            onClick={() => {
                                this.toggleDelete()
                            }}
                            variant='contained'
                            color='secondary'>
                            Delete
                        </Button>
                    </div>
                </div>
                </React.Fragment>
                )
            
        return ( 
            <div className='pending-d-card-container'>
                {publishedCardContent}
            </div>
        )
    }
}