import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'


import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function NewsHeader() {

    let {
        addNews
    } = useContext(MobileAdminContext)

    const addButtonStyle = {
        padding: '0',
        minHeight: '0',
        minWidth: '0',
        // marginRight: '10px'
    }
    const addIconStyle = {
        fontSize: '40px',
        padding: '5px',
        height: '7vh',
        marginRight: '10px',
        marginLeft: '100px',
        color: 'white'
    }

    function handleAddNews() {
        addNews()
    }

    return ( 
        <div className='m-ac-news-header'>
            <div className="m-ac-news-brand">
                News
            </div>
            <Button
                style={addButtonStyle}
                onClick={() => handleAddNews()}>
                <AddIcon style={addIconStyle}/>
            </Button>
        </div>
    )
}