import React from 'react'

import firebase from '@firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import GalleryCard from './GalleryCard'

// import HomeTestimonials from './Testimonials/HomeTestimonials'

export default class HomeDesktopGuest extends React.Component {
    constructor () {
        super()

        this.state = {
            lotGallery: [],
            loaded: false
        }
    }
    componentDidMount() {
        const db = firebase.firestore().collection('Gallery')
        db
        .orderBy('order')
        .get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                let galleryItem = {
                    header: doc.data().header,
                    body: doc.data().body,
                    imageId: doc.data().imageId,
                    imageUrl: doc.data().imageUrl,
                    galleryId: doc.id
                }
                this.setState({
                    lotGallery: [...this.state.lotGallery, galleryItem]
                })
            })
        })
        .then(() => {
            this.setState({loaded: true})
        })
        .catch(err => {
            console.error(err)
        })
    }

    render () {
        let { lotGallery, loaded } = this.state

        let galleryContent
        const settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 3500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 6000
        }

        loaded
            ? galleryContent = (
                <div className='home-d-slider-container'>
                    <Slider {...settings}>
                        <GalleryCard 
                            imageId={lotGallery[0].imageId}
                            key={lotGallery[0].imageId}
                            header={lotGallery[0].header}
                            body={lotGallery[0].body}
                            imageUrl={lotGallery[0].imageUrl}
                        />
                    <GalleryCard 
                            imageId={lotGallery[1].imageId}
                            key={lotGallery[1].imageId}
                            header={lotGallery[1].header}
                            body={lotGallery[1].body}
                            imageUrl={lotGallery[1].imageUrl}
                        />
                    <GalleryCard 
                            imageId={lotGallery[2].imageId}
                            key={lotGallery[2].imageId}
                            header={lotGallery[2].header}
                            body={lotGallery[2].body}
                            imageUrl={lotGallery[2].imageUrl}
                        />
                    <GalleryCard 
                            imageId={lotGallery[3].imageId}
                            key={lotGallery[3].imageId}
                            header={lotGallery[3].header}
                            body={lotGallery[3].body}
                            imageUrl={lotGallery[3].imageUrl}
                        />
                    <GalleryCard 
                            imageId={lotGallery[4].imageId}
                            key={lotGallery[4].imageId}
                            header={lotGallery[4].header}
                            body={lotGallery[4].body}
                            imageUrl={lotGallery[4].imageUrl}
                        />
                </Slider>
                </div>
                
            )
            : galleryContent = ( <div className='home-d-loading-gallery'></div> )




        return (
            <div className='home-d-guest-container'>
                <div className='home-d-guest-header'>
                    <div className='home-d-guest-header-name'>
                        The Lot Broker
                    </div>
                    <div className='home-d-guest-header-contact'>
                        <div className='home-d-guest-header-contact-item'>
                            Gary Levine
                        </div>
                        <div className='home-d-guest-header-contact-item'>
                            (617)640-3143
                        </div>
                        <a href="mailto:GLevine@TheLotBroker.com" className='d-link-fix'>
                            <div className="home-d-guest-header-contact-item">
                                GLevine@TheLotBroker.com
                            </div>
                        </a>
                    </div>
                    <div className='home-d-guest-header-statement'>
                        Bringing Buyers and Sellers of Interment Spaces Together
                    </div>
                    
                </div>
                <div className='home-d-gallery-center'>
                {galleryContent}   
                </div>
                <div className='home-d-guest-sub-gallery'>
                    <div className='home-d-guest-sub-header'>
                        <div className='home-d-guest-sub-header-info'>
                            Lots available now at Sharon and Knollwood Memorial Parks
                                <br/>
                            Up to 50% savings off current park prices
                        </div>
                    </div>
                    <div className='home-d-guest-sub-header2'>
                        *The Lot Broker is an independent broker and is not affiliated with any cemetery
                    </div>
                    <div className='home-d-guest-sub-header-body'>
                    The Lot Broker has been actively adding more lots weekly. 
                    We now have lots available in Sharon Memorial Park in over 50+ 
                    sections and counting. Lots are available in 1,2,3,4,6,8 and 12 spaces 
                    depending on the lot. Spaces start at only $2000, and most are 
                    priced at 50% or less than regular park prices.
                    </div>
                    <div className='home-d-guest-sub-header-body'>
                    We have 12+ sections available in Knollwood, including all (4) veteran sections.
                    <br />
                    Spaces start at $1050.00 each
                    </div>
                    <div className='home-d-guest-sub-header-body'>
                    Please check out our other section listed under current Inventory for additional cemetery offerings!
                    </div>
                </div> 
            </div>
        )
    }
}