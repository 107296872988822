import React from 'react'

export default function MobileCard(props) {
    let {
        lotNumber,
        section,
        spaces,
        location,
        price,
        lotName
    } = props

    let interfaithFix

    if (location === 'Sharon') {
        if (section === '4-ORCHARD') {
            interfaithFix = (
                <div>
                    4
                    <br/>
                    <div className='m-directors-section-card-fix'>
                        Orchard
                    </div>
                </div>
            )
        } else if (section === '26A-OVAL') {
            interfaithFix = (
                <div>
                    26A
                    <br/>
                    <div className='m-directors-section-card-fix'>
                        Oval
                    </div>
                </div>
            )
        } else {
            interfaithFix = section
        }
    } else if (location === 'Interfaith') {
        if (section === '1') {
            interfaithFix = (
                <div className='m-directors-beersheba-fix'>
                    <div className="m-directors-beersheba-header">
                        Beersheba
                    </div>
                    <div className="m-directors-beersheba-number">
                        I
                    </div>
                </div>
            )
        } else if (section === '2') {
            interfaithFix = (
                <div className='m-directors-beersheba-fix'>
                    <div className="m-directors-beersheba-header">
                        Beersheba
                    </div>
                    <div className="m-directors-beersheba-number">
                        II
                    </div>
                </div>
            )
        } else if (section === '3') {
            interfaithFix = (
                <div className='m-directors-beersheba-fix'>
                    <div className="m-directors-beersheba-header">
                        Beersheba
                    </div>
                    <div className="m-directors-beersheba-number">
                        III
                    </div>
                </div>
            )
        }
    }
    if (location === 'Sharon' || location === 'Interfaith') {
        return (
            <div className='m-directors-card-container-updated'>
                <div className="m-directors-card-section-updated">
                    <div className="m-directors-card-section-header">
                        Section
                    </div>
                    <div className="m-directors-card-section-content">
                        {interfaithFix}
                    </div>
                </div>
                <div className="m-directors-card-middle">
                    <div className="m-directors-card-middle-item">
                        <div className="m-directors-card-middle-label">
                            Lot Number:
                        </div>
                        <div className="m-directors-card-middle-content">
                            {lotNumber}
                        </div>
                    </div>
                    <div className="m-directors-card-middle-item">
                        <div className="m-directors-card-middle-label">
                            Spaces:
                        </div>
                        <div className="m-directors-card-middle-content">
                            {spaces}
                        </div>
                    </div>
                </div>
                <div className="m-directors-card-right">
                    <div className="m-directors-card-right-price-label">
                        Price:
                    </div>
                    <div className="m-directors-card-right-price">
                        ${price}
                        <br/>
                        <div className='m-directors-card-right-price-label'>
                            each
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if ((location === 'Garden' || location === 'Veterans') && lotName !== 'WestChapel') {
        let rememberCheck
        if (lotName === 'Remembrance') {
            rememberCheck = 'Remem.'
        } else {
            rememberCheck = lotName
        }
        return (
            <div className='m-directors-card-container-updated'>
                <div className="m-directors-card-section-knollwood">
                    <div className="m-directors-card-section-header">
                        Lot Name
                    </div>
                    <div className="m-directors-card-name-content">
                        {rememberCheck}
                    </div>
                </div>
                <div className="m-directors-card-middle-knollwood">
                    <div className="m-directors-card-middle-item">
                        <div className="m-directors-card-middle-label">
                            Lot Number:
                        </div>
                        <div className="m-directors-card-middle-content">
                            {lotNumber}
                        </div>
                    </div>
                    <div className="m-directors-card-middle-item">
                        <div className="m-directors-card-middle-label">
                            Spaces:
                        </div>
                        <div className="m-directors-card-middle-content">
                            {spaces}
                        </div>
                    </div>
                </div>
                <div className="m-directors-card-right">
                    <div className="m-directors-card-right-price-label">
                        Price:
                    </div>
                    <div className="m-directors-card-right-price">
                        ${price}
                        <br/>
                        <div className='m-directors-card-right-price-label'>
                            each
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return ( '' )
    }
}