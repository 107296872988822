import React, {useContext} from 'react'

import Card from './Card'
import TabletDetails from '../Details/TabletDetails'

import {InventoryContext} from '../../../../Contexts/InventoryContext'

export default function InventoryCards() {
    let {
        showLotList,
        visibleLots,
        loading
    } = useContext(InventoryContext)

    if (showLotList && loading === false) {
        return (
            <div className="t-inventory-scroll-container">
                {visibleLots.map(lot => {
                    return (
                        <Card
                            key={lot.id}
                            id={lot.id}
                            lotLocation={lot.location}
                            price={lot.price}
                            lotNumber={lot.lotNumber}
                            spaces={lot.spaces}
                            lotName={lot.lotName}
                            section={lot.section}
                            otherLocation={lot.otherLocation}
                        />
                    )
                })}
                <div>
                    <TabletDetails />
                </div>
            </div>
        )
    } else {
        return (
            <div className="t-inventory-scroll-container">
                
            </div>
        )
    }
}