import React from 'react'

import firebase from '@firebase/app'
import 'firebase/firestore'

import PublishedCard from './PublishedCard'

export default class Pending extends React.Component {
    constructor() {
        super()

        this.state = {
            publishedTestimonials: [],
            loaded: false
        }
    }
    componentDidMount() {
        firebase.firestore().collection('TestimonialsCleared').get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                let newPublishedTestimonial = {
                    name: doc.data().name,
                    dateOfSale: doc.data().dateOfSale,
                    message: doc.data().message,
                    id: doc.id
                }
                this.setState({
                    publishedTestimonials: [...this.state.publishedTestimonials, newPublishedTestimonial]
                })
            })
        })
        .then(() => {
            this.setState({
                loaded: true
            })
        })
        .catch(err => {
            console.error(err.code)
        })
    }
    render() {
        let { publishedTestimonials, loaded } = this.state

        let testimonialsContent

        loaded
            ? publishedTestimonials.length
                ? testimonialsContent = ( 
                    publishedTestimonials.map(testimonial => {
                        return (
                            <PublishedCard
                                name={testimonial.name}
                                message={testimonial.message}
                                dateOfSale={testimonial.dateOfSale}
                                key={testimonial.id}
                                id={testimonial.id}
                            />
                        )
                    })
                )
                : testimonialsContent = ( 'No new testimonials' )
            : testimonialsContent = ( 'Loading' )

        return (
            <div className='pending-d-container'>
                {testimonialsContent}
            </div>
        )
    }
}