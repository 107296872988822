import React from 'react'

export default function NewsCard(props) {
    let {
        date,
        content,
        title
    } = props

    return (
        <div className='d-about-news-card-container'>
            <div className='d-news-card-title'>
                {title}
            </div>
            <div className='d-news-card-date'>
                {date}
            </div>
            <div className='d-news-card-content'>
                {content}
            </div>
        </div>
    )
}