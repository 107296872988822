import React, {useContext} from 'react'

import SharonFilter from './Filters/SharonFilter'
import KnollwoodFilter from './Filters/KnollwoodFilter'

import {InventoryContext} from '../../../Contexts/InventoryContext'

export default function SecondFilter() {
    let {
        location
    } = useContext(InventoryContext)

    if (location === 'Sharon' || location === 'Interfaith') {
        return <SharonFilter />
    } else if (location === 'Knollwood') {
        return <KnollwoodFilter />
    } else {
        return ( <div></div>)
    }
}