import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import PhotoSizeSelectActualTwoToneIcon from '@material-ui/icons/PhotoSizeSelectActualTwoTone'
import FormatQuoteTwoToneIcon from '@material-ui/icons/FormatQuoteTwoTone'
import FormatListBulletedTwoToneIcon from '@material-ui/icons/FormatListBulletedTwoTone'
import FeedbackTwoToneIcon from '@material-ui/icons/FeedbackTwoTone'
import ChatTwoToneIcon from '@material-ui/icons/ChatTwoTone'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { AdminContext } from './../../../Contexts/AdminContext'

export default function AdminOptions() {

    let { 
        iconSize,

        toggleViewAdminLots,
        toggleViewAdminGallery,
        toggleViewAdminTestimonials,
        toggleViewAdminMessages,
        toggleViewAdminNews,
        viewAdminMessages,
        addMessage,
        addToNewsList,
        clearNewsList,

        messagesLoadedSuccessfully

    } = useContext(AdminContext)

    function handleGetMessages(check) {
        if (!check) {
            toggleViewAdminMessages()

            firebase.firestore().collection('Messages').get()
            .then(snapshot => {
                snapshot.forEach(msg => {
                    let message = {
                        body: msg.data().body,
                        date: msg.data().date,
                        id: msg.id
                    }
                    addMessage(message)
                })
            })
            .then(() => {
                messagesLoadedSuccessfully()
            })
            .catch(err => {
                console.error(err.code)
            })
        } else {
            toggleViewAdminMessages()
        }
    }
    function handleGetNews() {
        clearNewsList()
        toggleViewAdminNews()

        firebase.firestore().collection('News')
        .get()
        .then(snapshot => {
            snapshot.forEach(news => {
                let newNews = {
                    title: news.data().title,
                    content: news.data().content,
                    date: news.data().date,
                    id: news.id
                }
                addToNewsList(newNews)
            })
        })
        .catch(err => {
            console.error(err.code)
        })

    }

    return (
        <div className='admin-d-context-items-container'>
                <div className='admin-d-context-home-item'>
                    <Button 
                        onClick={() => {
                            toggleViewAdminLots()
                        }}
                    >
                        <div className='admin-d-context-button-container'>
                            <div className='admin-d-context-option-item'>
                                Lots
                            </div>
                            <div className='admin-d-context-button-icon'>
                                <FormatListBulletedTwoToneIcon color='secondary' style={{ fontSize: iconSize }}/>
                            </div>
                        </div>
                    </Button>
                </div>
                <div className='admin-d-context-home-item'>
                    <Button 
                        onClick={() => {
                            handleGetMessages(viewAdminMessages)
                        }}
                    >
                        <div className='admin-d-context-button-container'>
                            <div className='admin-d-context-option-item'>
                                Messages
                            </div>
                            <div className='admin-d-context-button-icon'>
                                <ChatTwoToneIcon color='secondary' style={{ fontSize: iconSize }}/>
                            </div>
                        </div>
                    </Button>
                </div>
                
                <div className='admin-d-context-home-item'>
                    <Button 
                        onClick={() => {
                            toggleViewAdminTestimonials()
                        }}
                    >
                        <div className='admin-d-context-button-container'>
                            <div className='admin-d-context-option-item'>
                                Testimonials
                            </div>
                            <div className='admin-d-context-button-icon'>
                                <FormatQuoteTwoToneIcon color='secondary' style={{ fontSize: iconSize }}/>
                            </div>
                        </div>
                    </Button>
                </div>
                <div className='admin-d-context-home-item'>
                    <Button 
                        onClick={() => {
                            handleGetNews()
                        }}
                    >
                        <div className='admin-d-context-button-container'>
                            <div className='admin-d-context-option-item'>
                                News
                            </div>
                            <div className='admin-d-context-button-icon'>
                                <FeedbackTwoToneIcon color='secondary' style={{ fontSize: iconSize }}/>
                            </div>
                        </div>
                    </Button>
                </div>
                <div className='admin-d-context-home-item'>
                    <Button 
                        onClick={() => {
                            toggleViewAdminGallery()
                        }}
                    >
                        <div className='admin-d-context-button-container'>
                            <div className='admin-d-context-option-item'>
                                Gallery
                            </div>
                            <div className='admin-d-context-button-icon'>
                                <PhotoSizeSelectActualTwoToneIcon color='secondary' style={{ fontSize: iconSize }}/>
                            </div>
                        </div>
                    </Button>
                </div>
            </div>
    )
}