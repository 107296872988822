import React from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default class MessageCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            message: props.message,
            date: props.date,
            deleted: false,
            deleting: false
        }
    }


    handleDelete() {
        this.setState({
            deleting: true
        })
        firebase.firestore().collection('Messages').doc(this.props.id)
        .delete()
        .then(() => {
            setTimeout(() => {
                this.setState({
                    deleting: false,
                    deleted: true
                })
            }, 1500)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    render() {

        let { message, date, deleted, deleting } = this.state

        let deleteButtonFeedback

        const deleteButtonStyle = {
            padding: '0',
            minHeight: '0',
            textTransform: 'none',
            backgroundColor: 'red'
        }

        if (deleting) {
            deleteButtonFeedback = ( <CircularProgress size={15} style={{color: 'white'}}/>)
        } else {
            deleteButtonFeedback = 'Delete'
        }

        if (deleted) {
            return (
                <div>

                </div>
            )
        } else {
            return (
                <div className='m-ac-message-card-container'>
                    <div className="m-ac-message-card-body">
                        {message}
                    </div>
                    <div className="m-ac-message-card-bottom">
                        <div className="m-ac-message-card-date">
                            {date}
                        </div>
                        <div className="m-ac-message-card-delete">
                            <Button
                                style={deleteButtonStyle}
                                onClick={() => this.handleDelete()}
                            >
                                <div className='m-ac-delete-single-message-button'>
                                    {deleteButtonFeedback}
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    }



}