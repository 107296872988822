import React from 'react'

import { Link } from 'react-router-dom'

export default function DesktopFooter () {

    return (
        <div className='d-footer-container'>
            <div className='d-footer-item'>
                © 2020 The Lot Broker. All rights reserved.
            </div>
            <div className='d-footer-item'>
                <Link to='/admin' style={{ textDecoration: 'none' }}>
                    <div className='d-footer-link'>
                        Admin
                    </div>
                </Link>
            </div>
            <div className='d-footer-item'>
                Web application developed by <a className='d-footer-link' href='https://matthew-taylor.dev/' target='_blank' rel='noopener noreferrer'>Matt Taylor</a>
            </div>
        </div>
    )
}