import React from 'react'
import { isMobileOnly, isTablet, isIPad13 } from 'react-device-detect'

import DesktopFooter from './DesktopFooter'
import MobileFooter from './MobileFooter'
import TabletFooter from './TabletFooter'

export default function Footer () {
    if (isMobileOnly) {
        return <MobileFooter />
    } else if (isTablet || isIPad13) {
        return <TabletFooter />
    } else {
        return <DesktopFooter />
    }
}