import React, { useContext } from 'react'

import LinearProgress from '@material-ui/core/LinearProgress'

import { AdminContext } from './../../../../Contexts/AdminContext'

export default function AdminLoadingLotsProgress() {
    let {
        loadingLocation
    } = useContext(AdminContext)

    if (loadingLocation) {
        return (
            <div className='admin-loading-lots-progress-container'>
                <LinearProgress style={{ width: '100%', height: '100%'}} color="secondary" />
            </div>
        )
    } else {
        return (
            <div className='admin-loading-lots-progress-container'>
            </div>
        )
    }
}