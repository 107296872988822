import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import {MobileAdminContext} from './../../../../../Contexts/MobileAdminContext'

export default function Location() {

    let {
        location,
        setLocation,
        addingLot,
        locationMenu,
        anchor,
        clearAnchors,
        setLocationAnchor
    } = useContext(MobileAdminContext)

    let locationHeader
    if (location) {
        locationHeader = location
    } else {
        locationHeader = 'Location'
    }

    const locationStyle = {
        width: '70vw',
        marginTop: '5px',
        fontSize: '3rem',
        textTransform: 'none'
    }
    const locationMenuItemStyle = {
        padding: '0',
        margin: '0',
        minHeight: '2px',
        minWidth: '40vw',
        fontSize: '3rem'
    }
    return (
        <div className='t-ac-add-lot-location'>
            <Button
                style={locationStyle}
                disabled={addingLot}
                aria-controls="location-menu" 
                aria-haspopup="true" 
                onClick={event => {
                    setLocationAnchor(event.currentTarget)
                }}>
                    {locationHeader}
                </Button>
                <Menu
                    style={{ zIndex: 1000030}}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    id="location-menu"
                    anchorEl={anchor}
                    keepMounted
                    MenuListProps={{ disablePadding: true }}
                    open={locationMenu}
                    onClose={() => {
                        clearAnchors()
                    }}>
                    <MenuItem 
                        style={locationMenuItemStyle}
                        onClick={() => {
                        setLocation('Sharon')
                    }}>
                        <div className='t-ac-location-item'> 
                            Sharon
                        </div>
                    </MenuItem>
                    <MenuItem 
                        style={locationMenuItemStyle}
                        onClick={() => {
                        setLocation('Interfaith')
                    }}>
                        <div className='t-ac-location-item'>
                            Interfaith
                        </div>
                    </MenuItem>
                    <MenuItem 
                        style={locationMenuItemStyle}
                        onClick={() => {
                        setLocation('Knollwood')
                    }}>
                        <div className='t-ac-location-item'>
                            Knollwood
                        </div>
                    </MenuItem>
                    <MenuItem 
                        style={locationMenuItemStyle}
                        onClick={() => {
                        setLocation('Estate')
                    }}>
                        <div className='t-ac-location-item'>
                            Estate
                        </div>
                    </MenuItem>
                    <MenuItem 
                        style={locationMenuItemStyle}
                        onClick={() => {
                        setLocation('Other')
                    }}>
                        <div className='t-ac-location-item'>
                            Other
                        </div>
                    </MenuItem>
                </Menu>
        </div>
    )
}