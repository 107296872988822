import React, {useContext} from 'react'

import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function DetailsGallery() {

    let {
        detailsImageUrl,
        detailsName
    } = useContext(InventoryContext)

    let imageContent
    detailsImageUrl !== undefined && detailsImageUrl !== ''
        ? imageContent = (
            <div 
                className='d-i-b-gallery-pic' 
                style={{ 
                    backgroundImage: `url(${detailsImageUrl})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>

            </div>
        )
        : imageContent = ( 'No Image Preview' )

    // come back to add estate gallery options here

    if (detailsName === 'WestChapel') {
        return (
            <div 
                style={{ 
                    backgroundImage: `url(${detailsImageUrl})`,
                    backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat'
                    width: '25vw',
                    height: '40%',

                    // maxWidth: '25vw',
                    // maxHeight: '40%',
                    // width: 'auto',
                    // height: 'auto',

                    margin: 'auto',
                    // paddingTop: '5px',
                    // paddingBottom: '5px'
                }}>

            </div>
        )
    } else {
        return (
            <div className='d-i-b-gallery'>
                {imageContent}
            </div>
        )
    }
}