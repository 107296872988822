import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function InventoryCard(props) {
    let {
        location,
        showDetails,
        loadedDetails,

        setDetailsSection,
        setDetailsNumber,
        setDetailsName,
        setDetailsPrice,
        setDetailsComments,
        setDetailsOtherLocation,
        setDetailsSpaces,
        setDetailsImageUrl,
        setDetailsGarden

    } = useContext(InventoryContext)

    let {
        section,
        lotName,
        price,
        otherLocation,
        lotNumber,
        spaces,
        lotLocation,
        id,
        // showingDetails,
    } = props

    let sectionCheck, nameCheck, locationCheck

    lotName
        ? nameCheck = lotName
        : nameCheck = ''

    section
        ? sectionCheck = section
        : sectionCheck = ''  
    
    lotLocation === 'Other' || lotLocation === 'Estate'
        ? locationCheck = otherLocation
        : lotLocation === 'Sharon' || lotLocation === 'Interfaith' || lotLocation === 'Knollwood' || lotLocation === 'Shara'
            ? locationCheck = lotLocation
            : locationCheck = 'error'

    function loadCardDetails(id) {
        showDetails(id)
        firebase.firestore().collection(location).doc(id)
        .get()
        .then(doc => {
            setDetailsSection(doc.data().section)
            setDetailsNumber(doc.data().lotNumber)
            setDetailsName(doc.data().lotName)
            setDetailsPrice(doc.data().price)
            setDetailsComments(doc.data().comments)
            setDetailsOtherLocation(doc.data().otherLocation)
            setDetailsSpaces(doc.data().spaces)
            setDetailsImageUrl(doc.data().imageUrl)
            setDetailsGarden(doc.data().garden)
        })
        .then(() => {
            setTimeout(() => {
                loadedDetails()
            }, 100)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    const cardStyle = {
        padding: '3px',
        marginBottom: '4px'
    }
    if (location === 'Sharon') {
        return (
            <Button
                style={cardStyle}
                fullWidth
                color='primary'
                onClick={() => {
                    loadCardDetails(id)
                }}>
                <div className='d-inventory-card-container'>
                    <div className="d-inventory-card-item">
                        {section}
                    </div>
                    <div className="d-inventory-card-item">
                        {lotNumber}
                    </div>
                    <div className="d-inventory-card-item">
                        {spaces}
                    </div>
                    <div className="d-inventory-card-item">
                        ${price} each
                    </div>
                </div>
            </Button>
        )
    } else if (location === 'Interfaith') {
        let beershebaCheck
        if (section === '1') {
            beershebaCheck = 'Beersheba'
        } else if (section === '2') {
            beershebaCheck = 'Beersheba II'
        } else if (section === '3') {
            beershebaCheck = 'Beersheba III'
        }
        return (
            <Button
                style={cardStyle}
                fullWidth
                color='primary'
                onClick={() => {
                    loadCardDetails(id)
                }}>
                <div className='d-inventory-card-container'>
                    <div className="d-inventory-card-item">
                        {beershebaCheck}
                    </div>
                    <div className="d-inventory-card-item">
                        {lotNumber}
                    </div>
                    <div className="d-inventory-card-item">
                        {spaces}
                    </div>
                    <div className="d-inventory-card-item">
                        ${price} each
                    </div>
                </div>
            </Button>
        )
    } else if (location === 'Knollwood') {
        return (
            <Button
                style={cardStyle}
                fullWidth
                color='primary'
                onClick={() => {
                    loadCardDetails(id)
                }}>
                <div className='d-inventory-card-container'>
                    <div className="d-inventory-card-item">
                        {lotName}
                    </div>
                    <div className="d-inventory-card-item">
                        {lotNumber}
                    </div>
                    <div className="d-inventory-card-item">
                        {spaces}
                    </div>
                    <div className="d-inventory-card-item">
                        ${price} each
                    </div>
                </div>
            </Button>
        )
    } else if (location === 'Estate' || location === 'Other') {
        return (
            <Button
                style={cardStyle}
                fullWidth
                color='primary'
                onClick={() => {
                    loadCardDetails(id)
                }}>
                <div className='d-inventory-card-container'>
                    <div className="d-inventory-card-item">
                        {otherLocation}
                    </div>
                    <div className="d-inventory-card-item">
                        {spaces}
                    </div>
                    <div className="d-inventory-card-item">
                        ${price}
                    </div>
                </div>
            </Button>
        )
    } else if (location === 'sold') {

        if (locationCheck === 'Interfaith') {
        if (section === '1') {
            sectionCheck = 'Beersheba'
        } else if (section === '2') {
            sectionCheck = 'Beersheba II'
        } else if (section === '3') {
            sectionCheck = 'Beersheba III'
        }
        }

        return (
            <Button
                style={cardStyle}
                disabled
                fullWidth
                color='primary'
            >
                <div className='d-inventory-card-container'>
                    <div className="d-inventory-card-sold-item">
                        {locationCheck}
                    </div>
                    <div className="d-inventory-card-sold-item">
                        {sectionCheck}{nameCheck}
                    </div>
                    <div className="d-inventory-card-sold-item">
                        {lotNumber}
                    </div>
                    <div className="d-inventory-card-sold-item">
                        {spaces}
                    </div>
                    {/* <div className="d-inventory-card-sold-item">
                        ${price} each
                    </div> */}
                </div>
            </Button>
        )
    } else if (location === 'Shara') {
        return (
            <Button
                fullWidth
                color='primary'
                onClick={() => {
                    loadCardDetails(id)
                }}>
                <div className='d-inventory-card-container'>
                    <div className="d-inventory-card-item">
                        {spaces}
                    </div>
                    <div className="d-inventory-card-item">
                        ${price} each
                    </div>
                </div>
            </Button>
        )
    }
}