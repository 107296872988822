import React, {useContext} from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import DoneOutlineTwoToneIcon from '@material-ui/icons/DoneOutlineTwoTone'

import MessageHeader from './Message/MessageHeader'
import MessageBody from './Message/MessageBody'
import MessageFooter from './Message/MessageFooter'

import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function SendMessage() {

    let {
        showingMessage,
        messageSent
    } = useContext(InventoryContext)

    let sentCheck

    messageSent
        ? sentCheck = ( 
            <div className='d-i-b-message-container2'>
                <MessageHeader />
                <div className='d-i-b-message-success'>
                    <div>
                        Message sent successfully, The Lot Broker will reach out at his earliest convenience.
                    </div>
                    <DoneOutlineTwoToneIcon color='secondary' style={{ fontSize: '6vh' }}/>
                </div>
            </div>
         )
        : sentCheck = (
            <div className='d-i-b-message-container'>
                <MessageHeader />
                <MessageBody />
                <MessageFooter />
            </div>
        )
    return (
        <Backdrop 
            style={{ zIndex: 2000000}}
            open={showingMessage}>
                {sentCheck}
        </Backdrop>
    )
}