import React from 'react'

import NewsHeader from './NewsHeader'
import AddNews from './AddNews'
import AllNews from './AllNews'
export default function ViewNews() {

    return (
        <div className='m-ac-news-container'>
            <NewsHeader />
            <AddNews />
            <AllNews />
        </div>
    )
}