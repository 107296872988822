import React, {useContext} from 'react'

import {InventoryContext} from './../../../../Contexts/InventoryContext'

export default function MobileDetailsComments() {
    let {
        detailsComments
    } = useContext(InventoryContext)

    let commentsContent
    if (detailsComments) {
        commentsContent = (
            <div>
                <div className="m-details-comments-header">
                    Additional Comments from The Lot Broker:
                </div>
                <div className="m-details-comment">
                    {detailsComments}
                </div>
            </div>
        )
    } else {
        commentsContent = ''
    }
    return (
        <div className='m-details-comments-container'>
            {commentsContent}
        </div>
    )
}