import React, {useContext} from 'react'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

import NewSharon from './Galleries/NewSharon'
import NewKnollwood from './Galleries/NewKnollwood'
import NewShara from './Galleries/NewShara'
// import Sharon from './Galleries/Sharon'
// import Shara from './Galleries/Shara'
// import Knollwood from './Galleries/Knollwood'

import {GalleryContext} from './../Contexts/GalleryContext'

export default function GalleryDesktop(){

    let {
        // test,
        currentGallery,
        setGallery
    } = useContext(GalleryContext)

        let galleryTabContent, galleryTabHeader
        if (currentGallery === 'Sharon') {
            galleryTabContent = ( <NewSharon /> )
            galleryTabHeader = 'Sharon'
        } else if (currentGallery === 'Knollwood') {
            galleryTabContent = ( <NewKnollwood /> )
            galleryTabHeader = 'Knollwood'
        } else if (currentGallery === 'Shara'){
            galleryTabContent = ( <NewShara /> )
            galleryTabHeader = 'Shara'
        }

        return (
            <div className='gallery-d-tab-container'>
                <ButtonGroup variant='contained' color='default' aria-label='contained primary button group' style={{paddingTop: '10px'}}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => { setGallery('Sharon') }}>Sharon</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => { setGallery('Knollwood') }}>Knollwood</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => { setGallery('Shara') }}>Shara Tfilo</Button>
                </ButtonGroup>
                    <div className='d-gallery-tab-header'>
                    {galleryTabHeader} Gallery
                    </div>
                        {galleryTabContent}
                    <div className='d-gallery-sub-header'>
                        Scroll to view more images
                    </div>
            </div>
        )
}