import React from 'react'
import { isMobileOnly, isTablet, isIPad13 } from 'react-device-detect'

import AboutDesktop from './AboutDesktop'
import AboutMobile from './Mobile/AboutMobile'
import AboutTablet from './Tablet/AboutTablet'

import {AboutContextProvider} from './../Contexts/AboutContext'

export default function About () {
    if (isMobileOnly) {
        return ( <AboutContextProvider><AboutMobile /></AboutContextProvider> )
    } else if (isTablet || isIPad13) {
        return ( <AboutContextProvider><AboutTablet /></AboutContextProvider>)
    } else {
        return ( <AboutDesktop /> )
    }
}