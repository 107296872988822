import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

import MessageCard from './MessageCard'

export default function AllMessages() {

    let {
        messagesLoaded,
        messages
    } = useContext(MobileAdminContext)

    if (messagesLoaded && messages.length > 0) {
        return (
            <div className='t-ac-all-messages-container'>
                {messages.map(message => {
                    return (
                        <MessageCard 
                            key={message.id}
                            message={message.message}
                            date={message.date}
                            id={message.id}
                        />
                    )
                })}
                <div className="m-scroll-fix"></div>
            </div>
        )
    } else {
        return (
            <div className='t-ac-all-messages-container'>
                <div className='t-ac-no-messages-notification'>
                    No new messages
                </div>
            </div>
        )
    }
    
}