import React, { useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import { AdminContext } from './../../../../../Contexts/AdminContext'

export default function LotCardSpaces() {
    let { 
        spaces,
        editingCard,
        handleChange
    } = useContext(AdminContext)

    let availabilityCheck
    editingCard
        ? availabilityCheck = true
        : availabilityCheck = false
    return (
        <TextField
                disabled={(!availabilityCheck)}
                color='secondary'
                name='spaces'
                type='text'
                value={spaces}
                label='Spaces'
                variant='outlined'
                onChange={event => {
                    handleChange(event)
                }}/>
    )

}