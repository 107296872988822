import React, {useContext} from 'react'

import firebase from 'firebase/app'
import 'firebase/firestore'

import TextField from '@material-ui/core/TextField'

import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function SharonFiler() {

    let {
        location,
        handleChange,
        filterSection,
        disableMenu,
        filteringBySection,
        filteredSuccess,
        addToVisibleLots
    } = useContext(InventoryContext)

    let textFieldFix

    filterSection
        ? textFieldFix = filterSection
        : textFieldFix = ''

    function handleFilterBySection() {
        filteringBySection()

        let cappedSection = filterSection.toUpperCase()

        firebase.firestore().collection(location).where('section', '==', cappedSection)
        .get()
        .then(snapshot => {
            snapshot.forEach(lot => {
                let lotDetails = {
                    location: lot.data().location,
                    lotNumber: lot.data().lotNumber,
                    section: lot.data().section,
                    price: lot.data().price,
                    spaces: lot.data().spaces,
                    id: lot.id
                }
                addToVisibleLots(lotDetails)
            })
        })
        .then(() => {
            setTimeout(() => {
                filteredSuccess()
            }, 1000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }


    return (
        <TextField 
            disabled={disableMenu}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                    handleFilterBySection()
                ev.preventDefault();
                }
            }}
            color='secondary'
            name='filterSection'
            type="text"
            size='medium'
            placeholder='Search Section'
            style={{width: '60%', textAlign: 'center'}}
            inputProps={{ style: {textAlign: 'center'} }}
            value={textFieldFix}
            onChange={event => {
                handleChange(event)
            }}
        />
    )
}