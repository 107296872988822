import React, { useContext } from 'react'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import {MobileAdminContext} from './../../../../../Contexts/MobileAdminContext'

export default function Details() {

    let {
        location,
        lotName,
        handleChange,
        addingLot,
        section
    } = useContext(MobileAdminContext)

    const textfieldStyle = {
        marginTop: '15px'
    }
    if (location === 'Sharon') {
        return (
            <div className='m-ac-details'>
                <TextField
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Section'
                    type='text'
                    name='section'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
                <TextField
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Number'
                    type='text'
                    name='lotNumber'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
                <TextField
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Spaces'
                    type='text'
                    name='spaces'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
                <TextField
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                              each
                            </InputAdornment>
                          ),
                      }}
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Price'
                    type='number'
                    name='price'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
            </div>
        )
    } else if (location === 'Interfaith' && section) {
        return (
            <div className='m-ac-details'>
                <TextField
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Number'
                    type='text'
                    name='lotNumber'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
                <TextField
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Spaces'
                    type='text'
                    name='spaces'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
                <TextField
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                              each
                            </InputAdornment>
                          ),
                      }}
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Price'
                    type='number'
                    name='price'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
            </div>
        )
    } else if (location === 'Knollwood' && lotName) {
        return (
            <div className='m-ac-details'>
                <TextField
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Number'
                    type='text'
                    name='lotNumber'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
                <TextField
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Spaces'
                    type='text'
                    name='spaces'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
                <TextField
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                              each
                            </InputAdornment>
                          ),
                      }}
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Price'
                    type='number'
                    name='price'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
            </div>
        )
    } else if (location === 'Estate' || location === 'Other') {
        return (
            <div className='m-ac-details'>
                <TextField
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    multiline
                    rowsMax={4}
                    label='Location'
                    type='text'
                    name='otherLocation'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
                <TextField
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Spaces'
                    type='text'
                    name='spaces'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
                <TextField
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        )
                      }}
                    color='secondary'
                    disabled={addingLot}
                    style={textfieldStyle}
                    label='Price'
                    type='number'
                    name='price'
                    variant='outlined'
                    fullWidth
                    onChange={event => {
                        handleChange(event)
                    }}/>
            </div>
        )
    } else {
        return (
            <div>

            </div>
        )
    }
}