import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default function TestimonialsHeader() {

    let {
        testimonalsView,
        setTestimonialsView,
        loadTestimonials,
        loadedTestimonials,
        loadingTestimonials,
        addToTestimonials
    } = useContext(MobileAdminContext)

    let headerMessage, buttonMessage
    if (testimonalsView === 'Pending') {
        headerMessage = (
            <div>
                Pending
                <br/>
                Testimonials
            </div>
            )
        buttonMessage = (
            <div className='t-ac-testimonial-button'>
                View
                <br/>
                Published
            </div>
            )
    } else if (testimonalsView === 'Published') {
        headerMessage = (
            <div>
                Published
                <br/>
                Testimonials
            </div>
            )
        buttonMessage = (
            <div className='t-ac-testimonial-button'>
                View
                <br/>
                Pending
            </div>
            )
    }
    const testimonialButtonStyle = {
        padding: '0',
        minHeight: '0',
        textTransform: 'none',
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '1.2rem'
    }
    function handleToggleTestimonials() {
        if (testimonalsView === 'Published') {
            loadTestimonials()

            firebase.firestore().collection('TestimonialsPending')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let testimonial = {
                        body: doc.data().message,
                        date: doc.data().dateOfSale,
                        name: doc.data().name,
                        id: doc.id,
                        published: false
                    }
                    addToTestimonials(testimonial)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setTestimonialsView('Pending')
                    loadedTestimonials()
                }, 300)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (testimonalsView === 'Pending') {
            loadTestimonials()

            firebase.firestore().collection('TestimonialsCleared')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let testimonial = {
                        body: doc.data().message,
                        date: doc.data().dateOfSale,
                        name: doc.data().name,
                        id: doc.id,
                        published: true
                    }
                    addToTestimonials(testimonial)
                })
            })
            .then(() => {
                setTimeout(() => {
                    setTestimonialsView('Published')
                    loadedTestimonials()
                }, 300)
            })
            .catch(err => {
                console.error(err.code)
            })
        }
    }
    return (
        <div className='t-ac-testimonials-header-container'>
            <div className='t-ac-testimonials-header-brand'>
                {headerMessage}
            </div>
            <Button
                disabled={loadingTestimonials}
                variant='contained'
                style={testimonialButtonStyle}
                onClick={() => handleToggleTestimonials() }>
                {buttonMessage}
            </Button>
        </div>
    )
}