import React from 'react'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function BuyersFaqDesktop () {
    return (
        <div className='about-d-buyers-container'>
            <div className='about-d-buyers-header'>
                Frequently Asked Questions for Lot Buyers
            </div>
            <div className='about-d-buyers-sellers-sub-header'>
                The Lot Broker is an independent business. We are not affiliated in 
                any way with Sharon or Knollwood Memorial Parks.
            </div>
            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                color='primary'
                >
                    <div className='about-d-panel-quesiton'>
                    Can I see the spaces I want to buy beforehand in person?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className='about-d-panel-answer'>
                        Yes. The Lot Broker can meet with you at Sharon or Knollwood Memorial Park by
                        appointment (6) days a week in Sharon, and (7) days a week in Knollwood, and take
                        you around as many times as needed. You can also call anytime with questions. We
                        are reachable 24/7. We also have pictures available of all the spaces in our inventory.    
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                color='primary'
                >
                    <div className='about-d-panel-quesiton'>
                        What do you charge for the Lots you have for sale?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className='about-d-panel-answer'>
                    The spaces we broker for sale Typically sell for up to 50% 
                    off Sharon and Knollwood Memorial Park current prices. For 
                    example, if a space in Sharon is worth $5,100.00, we would 
                    sell it for $2,550.00. If a space in Knollwood is worth 
                    $2800.00, we would sell it for $1400.00. There are some 
                    premium locations that would sell for more than 50% off.    
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                color='secondary'
                >
                    <div className='about-d-panel-quesiton'>
                        Does the buyer pay the Lot Broker for his services?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='about-d-panel-answer'>
                    No. Only the seller pays The Lot Broker for the service.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                color='secondary'
                >
                    <div className='about-d-panel-quesiton'>
                        Are there any other Fee’s Required along with the purchase?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='about-d-panel-answer'>
                    Yes. There is a $250.00 transfer fee paid by the buyer. This 
                    is the administration fee charged by Sharon and Knollwood 
                    Memorial Parks.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                color='secondary'
                >
                    <div className='about-d-panel-quesiton'>
                        How long does the process take?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='about-d-panel-answer'>
                    Typically, 2 weeks. You will receive your deed in 1-2 
                    weeks after Sharon or Knollwood Memorial Park receives 
                    all of the required paperwork from the seller.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                color='secondary'
                >
                    <div className='about-d-panel-quesiton'>
                        If I buy spaces, when is the payment due?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='about-d-panel-answer'>
                    The payment is due after you decide on a particular location. We 
                    can hold the spaces for a short time. After that, we will give 
                    you the right of first refusal if we receive another offer after 
                    a period of time if payment is not made.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                color='secondary'
                >
                    <div className='about-d-panel-quesiton'>
                        What forms of payment do you accept?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='about-d-panel-answer'>
                    We accept Cash, (Checks payable to The Lot Broker) and 
                    all major credit cards through PayPal (Fee's will apply).
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                color='secondary'
                >
                    <div className='about-d-panel-quesiton'>
                        If I change my mind, can I cancel without penalty?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='about-d-panel-answer'>
                    Yes. Both the Buyer and the seller can cancel up to the point 
                    when the cemetery clears the sale to go thru.
                    Once Sharon and Knollwood Memorial Park receives the required 
                    paperwork, and the $250.00 Transfer fee has been submitted, the 
                    sale is final. The seller does not receive payment until then. 
                    Any future expenses (opening/closing of space, liner, funeral, 
                    and marker are due at time of that service)
                    <br />
                    <br />
                    Note: If paid by credit card, the service fee will not be refunded in the case of cancellation.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                color='secondary'
                >
                    <div className='about-d-panel-quesiton'>
                        If I buy spaces, and decide in the future we no longer need them, can I sell them?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='about-d-panel-answer'>
                    Yes, The Lot Broker would be more than happy to assist you in that.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                >
                    <div className='about-d-panel-quesiton'>
                        Does the Lot Broker Own the spaces you sell?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='about-d-panel-answer'>
                    We do not in most cases buy the lots we advertise. 
                    We broker them between willing buyers and sellers.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                color='secondary'
                >
                    <div className='about-d-panel-quesiton'>
                        What if I have an immediate need for a space?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='about-d-panel-answer'>
                    We have a process in place where Sharon and Knollwood 
                    Memorial Park will allow a burial to take place before 
                    the transfer is complete. Payment for 'IN' spaces must 
                    be paid for using Cash, Bank Check or credit card 
                    (refer to "What forms of payment do you accept?" above)
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}