import React, { useContext } from 'react'

import { AdminContext } from './../../../../../Contexts/AdminContext'

import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'
import LotDeleteButton from './LotDeleteButton'
export default function LotCardFooter() {

    let { 
        toggleEditCard,
        editingCard,
        updatingCard,
        saveCardEdits,
        updatedCard,
        imageNumber,
        // setLotId,
        setPrice,
        setLotNumber,
        setSpaces,
        setSection,
        setComments,
        // setLotCreated,
        setOtherLocation,
        setLocation,
        // setCreatedAt,
        setLotName,
        // setGarden,
        // setPriority,
        // setSold,

        originalPrice,
        originalComments,
        originalOtherLocation,
        originalSpaces,
        originalLotName,
        originalSection,
        originalLotNumber,

        setOriginalPrice,
        setOriginalComments,
        setOriginalOtherLocation,
        setOriginalSpaces,
        setOriginalLotName,
        setOriginalSection,
        setOriginalLocation,
        setOriginalLotNumber,

        lotId,
        location,
        lotName,
        section,
        spaces,
        price,
        otherLocation,
        lotNumber,
        comments,
        originalLocation,
        lotIsSold,
        imageUrl,
        originalPath
    } = useContext(AdminContext)
    
    let editButtons, knollwoodDisabledButtonCheck, checkForUpdating

    if (location === 'Knollwood') {
        if ( !lotName ) {
            knollwoodDisabledButtonCheck = false
        } else if ( lotName) {
            knollwoodDisabledButtonCheck = true
        }
    } else {
        knollwoodDisabledButtonCheck = true
    }

    let today = new Date()
    let day = today.getDate().toString()
    let month = (today.getMonth() + 1).toString()
    let year = today.getFullYear().toString()
    let todaysDate = month.concat('-',day,'-',year)

    function mySaveCardEdits() {
        saveCardEdits()
        let fixedPath, fixedUrl
            if (originalPath) {
                fixedPath = originalPath
            } else {
                fixedPath = ''
            }
            if (imageUrl) {
                fixedUrl = imageUrl
            } else {
                fixedUrl = ''
            }
        if (!lotIsSold) {
            if (location === originalLocation) {
                if (location === 'Sharon' || location === 'Interfaith') {
                    let correctedSection = section.toUpperCase()
                    let correctedLotNumber = lotNumber.toUpperCase()
                    let priority = section.replace(/\D/g, "")

                    firebase.firestore().collection(location).doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageUrl: fixedUrl,
                        location: location,
                        spaces: spaces,
                        price: price,
                        lotNumber: correctedLotNumber,
                        comments: comments,
                        priority: Number(priority),
                        section: correctedSection,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } else if (location === 'Knollwood') {
                    let correctedLotNumber = lotNumber.toUpperCase()
                    const gardenList = [
                        'Cross',
                        'Faith',
                        'Gethsemane',
                        'Last Supper',
                        'Meditation',
                        'Saviour',
                        'Sunset',
                        'Word'
                    ]
                    let gardenCheck 
                        if (gardenList.includes(lotName)) {
                                gardenCheck = true
                        } else {
                                gardenCheck = false
                        }
                    firebase.firestore().collection('Knollwood').doc(lotId)
                    .set({ 
                        originalPath: fixedPath,
                        imageUrl: fixedUrl,
                        garden: gardenCheck,
                        location: location,
                        spaces: spaces,
                        price: price,
                        lotNumber: correctedLotNumber,
                        comments: comments,
                        lotName: lotName,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } else if (location === 'Estate' || location === 'Other') {
                    let fixedNumber
                    if (imageNumber) {
                        fixedNumber = imageNumber
                    } else {
                        fixedNumber = ''
                    }
                    firebase.firestore().collection(location).doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageNumber: fixedNumber,
                        imageUrl: fixedUrl,
                        location: location,
                        otherLocation: otherLocation,
                        spaces: spaces,
                        price: price,
                        comments: comments,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                        
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } else if (location === 'Shara') {
                    let fixedNumber
                    if (imageNumber) {
                        fixedNumber = imageNumber
                    } else {
                        fixedNumber = ''
                    }
                    firebase.firestore().collection(location).doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageNumber: fixedNumber,
                        imageUrl: fixedUrl,
                        location: location,
                        spaces: spaces,
                        price: price,
                        comments: comments,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                        
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                }
            } else if (location !== originalLocation) {
                if (location === 'Sharon' || location === 'Interfaith') {
                    let correctedSection = section.toUpperCase()
                    let correctedLotNumber = lotNumber.toUpperCase()
                    let priority = section.replace(/\D/g, "")
                    firebase.firestore().collection(location).doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageUrl: fixedUrl,
                        location: location,
                        spaces: spaces,
                        price: price,
                        lotNumber: correctedLotNumber,
                        comments: comments,
                        priority: Number(priority),
                        section: correctedSection,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        firebase.firestore().collection(originalLocation).doc(lotId)
                        .delete()
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } else if (location === 'Knollwood') {
                    let correctedLotNumber = lotNumber.toUpperCase()
                    const gardenList = [
                        'Cross',
                        'Faith',
                        'Gethsemane',
                        'Last Supper',
                        'Meditation',
                        'Saviour',
                        'Sunset',
                        'Word'
                    ]
                    let gardenCheck 
                        if (gardenList.includes(lotName)) {
                                gardenCheck = true
                        } else {
                                gardenCheck = false
                        }
                    firebase.firestore().collection('Knollwood').doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageUrl: fixedUrl,
                        garden: gardenCheck,
                        location: location,
                        spaces: spaces,
                        price: price,
                        lotNumber: correctedLotNumber,
                        comments: comments,
                        lotName: lotName,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        firebase.firestore().collection(originalLocation).doc(lotId)
                        .delete()
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } else if (location === 'Estate' || location === 'Other') {
                    let fixedNumber
                    if (imageNumber) {
                        fixedNumber = imageNumber
                    } else {
                        fixedNumber = ''
                    }
                    firebase.firestore().collection(location).doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageNumber: fixedNumber,
                        imageUrl: fixedUrl,
                        location: location,
                        otherLocation: otherLocation,
                        spaces: spaces,
                        price: price,
                        comments: comments,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        firebase.firestore().collection(originalLocation).doc(lotId)
                        .delete()
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                }
            }
        } else if (lotIsSold) {
            if (location === originalLocation) {
                if (location === 'Sharon' || location === 'Interfaith') {
                    let correctedSection = section.toUpperCase()
                    let correctedLotNumber = lotNumber.toUpperCase()
                    let priority = section.replace(/\D/g, "")
                    firebase.firestore().collection('sold').doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageUrl: fixedUrl,
                        location: location,
                        spaces: spaces,
                        price: price,
                        lotNumber: correctedLotNumber,
                        comments: comments,
                        priority: Number(priority),
                        section: correctedSection,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } else if (location === 'Knollwood') {
                    let correctedLotNumber = lotNumber.toUpperCase()
                    const gardenList = [
                        'Cross',
                        'Faith',
                        'Gethsemane',
                        'Last Supper',
                        'Meditation',
                        'Saviour',
                        'Sunset',
                        'Word'
                    ]
                    let gardenCheck 
                        if (gardenList.includes(lotName)) {
                                gardenCheck = true
                        } else {
                                gardenCheck = false
                        }
                    firebase.firestore().collection('sold').doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageUrl: fixedUrl,
                        garden: gardenCheck,
                        location: location,
                        spaces: spaces,
                        price: price,
                        lotNumber: correctedLotNumber,
                        comments: comments,
                        lotName: lotName,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } else if (location === 'Estate' || location === 'Other') {
                    let fixedNumber
                    if (imageNumber) {
                        fixedNumber = imageNumber
                    } else {
                        fixedNumber = ''
                    }
                    firebase.firestore().collection('sold').doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageNumber: fixedNumber,
                        imageUrl: fixedUrl,
                        location: location,
                        otherLocation: otherLocation,
                        spaces: spaces,
                        price: price,
                        comments: comments,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                        
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                }
            } else if (location !== originalLocation) {
                if (location === 'Sharon' || location === 'Interfaith') {
                    let correctedSection = section.toUpperCase()
                    let correctedLotNumber = lotNumber.toUpperCase()
                    let priority = section.replace(/\D/g, "")
                    firebase.firestore().collection('sold').doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageUrl: fixedUrl,
                        location: location,
                        spaces: spaces,
                        price: price,
                        lotNumber: correctedLotNumber,
                        comments: comments,
                        priority: Number(priority),
                        section: correctedSection,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        firebase.firestore().collection(originalLocation).doc(lotId)
                        .delete()
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } else if (location === 'Knollwood') {
                    let correctedLotNumber = lotNumber.toUpperCase()
                    const gardenList = [
                        'Cross',
                        'Faith',
                        'Gethsemane',
                        'Last Supper',
                        'Meditation',
                        'Saviour',
                        'Sunset',
                        'Word'
                    ]
                    let gardenCheck 
                        if (gardenList.includes(lotName)) {
                                gardenCheck = true
                        } else {
                                gardenCheck = false
                        }
                    firebase.firestore().collection('sold').doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageUrl: fixedUrl,
                        garden: gardenCheck,
                        location: location,
                        spaces: spaces,
                        price: price,
                        lotNumber: correctedLotNumber,
                        comments: comments,
                        lotName: lotName,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        firebase.firestore().collection(originalLocation).doc(lotId)
                        .delete()
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                } else if (location === 'Estate' || location === 'Other') {
                    let fixedNumber
                    if (imageNumber) {
                        fixedNumber = imageNumber
                    } else {
                        fixedNumber = ''
                    }
                    firebase.firestore().collection('sold').doc(lotId)
                    .set({
                        originalPath: fixedPath,
                        imageNumber: fixedNumber,
                        imageUrl: fixedUrl,
                        location: location,
                        otherLocation: otherLocation,
                        spaces: spaces,
                        price: price,
                        comments: comments,
                        lastEdit: todaysDate
                    })
                    .then(() => {
                        firebase.firestore().collection(originalLocation).doc(lotId)
                        .delete()
                    })
                    .then(() => {
                        setTimeout(() => { updatedCard() }, 1200)
                    })
                    .catch(err => {
                        console.error(err.code)
                    })
                }
            }
        }
        
    }

    function setOGinfo() {
        if (location === 'Sharon' || location === 'Interfaith') {
            setOriginalLocation(location)
            setOriginalPrice(price)
            setOriginalComments(comments)
            setOriginalSpaces(spaces)
            setOriginalSection(section)
            setOriginalLotNumber(lotNumber)

        } else if (location === 'Knollwood') {
            setOriginalLocation(location)
            setOriginalPrice(price)
            setOriginalComments(comments)
            setOriginalSpaces(spaces)
            setOriginalLotNumber(lotNumber)
            setOriginalLotName(lotName)
        } else if (location === 'Estate' || location === 'Other') {
            setOriginalLocation(location)
            setOriginalPrice(price)
            setOriginalComments(comments)
            setOriginalSpaces(spaces)
            setOriginalOtherLocation(otherLocation)
        }  else if (location === 'Shara') {
            setOriginalLocation(location)
            setOriginalPrice(price)
            setOriginalComments(comments)
            setOriginalSpaces(spaces)
        }
    }
    function revertForm() {
        setPrice(originalPrice)
        setLotNumber(originalLotNumber)
        setSpaces(originalSpaces)
        setSection(originalSection)
        setComments(originalComments)
        setOtherLocation(originalOtherLocation)
        setLocation(originalLocation)
        setLotName(originalLotName)
    }

    updatingCard
        ? checkForUpdating = ( '' )
        : checkForUpdating = ( 
            <div className='admin-final-d-context-button-delete'>
                <LotDeleteButton />
            </div>
        ) 
    updatingCard
        ? editButtons = ( 
                <div className='waskywilly'>
                    <LinearProgress style={{ width: '100%', height: '50%'}} color="secondary" />
                </div>
         )
        : editingCard
            ? editButtons = (
                <div className="admin-final-d-lot-card-footer-item1">
                    <Button
                        variant='contained'
                        onClick={() => {
                            revertForm()
                            toggleEditCard()
                        }}>
                        Cancel
                    </Button>
                    <Button
                        disabled={(!knollwoodDisabledButtonCheck)}
                        color='secondary'
                        variant='contained'
                        onClick={() => {
                            mySaveCardEdits()
                        }}>
                        Save
                    </Button>
                </div>
            )
            : editButtons = (
                <div className="admin-final-d-lot-card-footer-item1">
                    <Button
                        disabled={lotIsSold}
                        variant='contained'
                        onClick={() => {
                            toggleEditCard()
                            setOGinfo()

                        }}>
                        Edit Details
                    </Button>
                </div>
            )
    return (
        <div className='admin-final-d-lot-card-footer-container'>
            {editButtons}
            {checkForUpdating}
        </div>
    )
}