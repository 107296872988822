import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function SaveButton() {

    let {
        setDetailsId,
        editing,
        savingEdits,
        startSavingEdits,
        finishSavingEdits,
        temporaryLocation,
        detailsSold,
        detailsId,
        detailsLocation,
        detailsNumber,
        detailsName,
        detailsPrice,
        detailsComments,
        detailsOtherLocation,
        detailsGarden,
        detailsSpaces,
        detailsSection,
        setDetailsLastEdit,
        detailsImageUrl,
        detailsImageNumber,
        detailsOriginalPath
    } = useContext(MobileAdminContext)

    const saveButtonStyle = {
        padding: '0',
        minHeight: '0',
        marginBottom: '18px',
        width: '100%'
    }
    let saveButtonMessage
    if (savingEdits) {
        saveButtonMessage = ( <CircularProgress size={25} style={{color: 'black'}}/>)
    } else {
        saveButtonMessage = 'Save Changes'
    }
    function handleSaveEdits() {
        startSavingEdits()

        let today = new Date()
        let day = today.getDate().toString()
        let month = (today.getMonth() + 1).toString()
        let year = today.getFullYear().toString()
        let todaysDate = month.concat('-',day,'-',year)



        let nameCheck, otherLocationCheck, gardenCheck, priorityCheck, correctedSection, correctedLotNumber
        if (detailsName === undefined) {
            nameCheck = null
        } else {
            nameCheck = detailsName 
        }
        
        if (detailsOtherLocation === undefined) {
            otherLocationCheck = null
        } else {
            otherLocationCheck = detailsOtherLocation 
        }

        if (detailsGarden === undefined) {
            gardenCheck = null
        } else {
            gardenCheck = true 
        }
        if (detailsSection && detailsName !== 'WestChapel') {
            priorityCheck = detailsSection.replace(/\D/g, "")
            correctedSection = detailsSection.toUpperCase()
        } else  if (detailsName === 'WestChapel') {
            correctedSection = null
            priorityCheck = null
        } else {
            correctedSection = null
            priorityCheck = null
        }
        if (detailsNumber) {
            correctedLotNumber = detailsNumber.toUpperCase()
        } else {
            correctedLotNumber = null
        }

        if (detailsSold) {
            firebase.firestore().collection('sold').doc(detailsId)
            .update({
                location: detailsLocation,
                section: correctedSection,
                lotNumber: correctedLotNumber,
                lotName: nameCheck,
                price: detailsPrice,
                priority: priorityCheck,
                comments: detailsComments,
                lastEdit: todaysDate,
                otherLocation: otherLocationCheck,
                garden: gardenCheck,
                spaces: detailsSpaces
            })
            .then(() => {
                setTimeout(() => {
                    setDetailsLastEdit(todaysDate)
                    finishSavingEdits()
                }, 1200) 
            })
            .catch(err => {
                console.error(err.code)
            })
        } else {
            if (detailsLocation === temporaryLocation) {
                // update all the fields in the form
                firebase.firestore().collection(detailsLocation).doc(detailsId)
                .update({
                    section: correctedSection,
                    lotNumber: correctedLotNumber,
                    lotName: nameCheck,
                    price: detailsPrice,
                    priority: priorityCheck,
                    comments: detailsComments,
                    lastEdit: todaysDate,
                    otherLocation: otherLocationCheck,
                    garden: gardenCheck,
                    spaces: detailsSpaces
                })
                .then(() => {
                    setTimeout(() => {
                        setDetailsLastEdit(todaysDate)
                        finishSavingEdits()
                    }, 1200)
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else {
                firebase.firestore().collection(temporaryLocation).doc(detailsId)
                .delete()
                .then(() => {
                    firebase.firestore().collection(detailsLocation)
                    .add({
                        location: detailsLocation,
                        section: correctedSection,
                        lotNumber: correctedLotNumber,
                        lotName: nameCheck,
                        price: detailsPrice,
                        priority: priorityCheck,
                        comments: detailsComments,
                        lastEdit: todaysDate,
                        otherLocation: otherLocationCheck,
                        garden: gardenCheck,
                        spaces: detailsSpaces,
                        imageUrl: detailsImageUrl,
                        imageNumber: detailsImageNumber,
                        originalPath: detailsOriginalPath
                    })
                    .then(docRef => {
                        setDetailsId(docRef.id)
                        setTimeout(() => {
                            setDetailsLastEdit(todaysDate)
                            finishSavingEdits()
                        }, 1200)
                    })
                    .catch(err =>{
                        console.error(err.code)
                    })
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        }
        
        // firebase.firestore().collection('sold').doc(detailsId)
        // .update({

        // })
        
        
        // if (detailsLocation === temporaryLocation) {
        //     firebase.firestore().collection(detailsLocation).doc(detailsId)
        //     .update({
                
        //     })
        // } else if (detailsLocation !== temporaryLocation) {

        // }


        // if not in sold
        // check if details location = temp location
        // if not, delete old location and create new item (all values)
        // if so, update item with .update (only section/name/number/price/spaces/otherLocation/comments)
   
        // if in sold
        // just update all fields INCLUDING location in ('sold')

    }
    if (editing) {
        return (
            <Button
                disabled={savingEdits}
                style={saveButtonStyle}
                onClick={() => handleSaveEdits() }
            >
                <div className='t-ac-save-changes-button'>
                    {saveButtonMessage}
                </div>
            </Button>
        )
    } else {
        return (
            <div>
    
            </div>
        )
    }
}