import React, {Component} from 'react'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone'
import Backdrop from '@material-ui/core/Backdrop'
import CancelIcon from '@material-ui/icons/Cancel'
import LinearProgress from '@material-ui/core/LinearProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default class NewsCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.id,
            title: this.props.title,
            content: this.props.content,
            date: this.props.date,

            originalTitle: null,
            originalContent: null,
            editing: false,
            warning: false,
            updated: false,
            updating: false,
            deletingNews: false,
            deletedNews: false
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleEdit(editing) {
        if (!editing) {
            this.setState({
                editing: true,
                originalTitle: this.state.title,
                originalContent: this.state.content
            })

        } else {
            this.setState({
                editing: false,
                title: this.state.originalTitle,
                content: this.state.originalContent
            })
        }
    }
    handleUpdate() {
        this.setState({
            updating: true
        })
        firebase.firestore().collection('News').doc(this.state.id)
        .set({
            title: this.state.title,
            content: this.state.content,
            date: this.state.date
        })
        .then(() => {
            setTimeout(() => {
                this.setState({
                    updating: false,
                    updated: true,
                    editing: false
                })
            }, 1200)
        })
        .catch(err => {
            console.error(err.code)
        })
    }
    showDeleteWarning() {
        this.setState({
            warning: true
        })
    }
    handleDelete() {
        this.setState({
            deletingNews: true
        })

        firebase.firestore().collection('News').doc(this.state.id)
        .delete()
        .then(() => {
            setTimeout(() => {
                this.setState({
                    deletingNews: false,
                    deletedNews: true
                })
            }, 1300)
        })
        .then(() => {
            setTimeout(() => {
                this.setState({
                    warning: false
                })
            }, 6000)
        })
        .catch(err => {
            console.error(err.code)
        })

    }
    render() {
        let {
            title,
            content,
            date,
            editing,
            warning,
            updating,
            deletingNews,
            deletedNews
        } = this.state

        let style = {
            fontSize: '5vh'
        }
        let style2 = {
            backgroundColor: 'red'
        }
        let style3 = {
            backgroundColor: 'red',
            height: '100%',
            width: '100%'
        }
        
        let editingOptions, deleteOptions, deleteNewsWarning, buttonFeedback, deletingFeedback, deletedMessage, buttonFix

        deletingNews
            ? deletingFeedback = ( <LinearProgress style={style3}/>) 
            : deletingFeedback = ( '')

        updating
            ? buttonFeedback = ( <CircularProgress color='primary'/>)
            : buttonFeedback = ( 'Save Changes')

        deletedNews
            ? deletedMessage = ( 
                <div className='d-a-delete-news-body'>
                    Successfully Deleted News
                </div>
                )
            : deletedMessage = ( 
                <div className='d-a-delete-news-body'>
                            <div className='d-a-delete-news-question'>
                                Are you sure you wish to delete this news?
                            </div>
                            <div className='d-a-delete-news-news'>
                                "{this.state.title}"
                            </div>
                        </div>
            )

        deletedNews
            ? buttonFix = ( 
                <div className='d-a-delete-news-buttons'>

                </div>
            )
            : buttonFix = (
                <div className='d-a-delete-news-buttons'>
                            <Button
                                style={style2}
                                onClick={() => {
                                    this.handleDelete()
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    this.setState({
                                        warning: false
                                    })}}
                            >
                                Cancel
                            </Button>
                        </div>
            )
        editing
            ? editingOptions = (
                <div className='d-a-new-buttons-change'>
                    <div>
                        <Button disabled={updating} color='secondary' variant='contained' className='d-a-news-save-button' onClick={() => {
                            this.handleUpdate()
                        }}>
                            {buttonFeedback}
                        </Button>
                    </div>
                    <div>
                        <Button onClick={() => { this.handleEdit(editing) }} variant='contained' className='d-a-news-cancel-button'>
                            Cancel
                        </Button>
                    </div>
                </div>
            )
            : editingOptions = (
                <Button onClick={() => { this.handleEdit(editing) }} >
                    <EditTwoToneIcon color='secondary' style={style}/>
                </Button>
            )
        editing
            ? deleteOptions = ( '' )
            : deleteOptions = (
                <div className='d-a-news-del-button'>
                    <Button onClick={() => { this.showDeleteWarning()}}>
                            <DeleteTwoToneIcon color='secondary' style={style}/>
                        </Button>

                </div>
            )



        warning
            ? deleteNewsWarning = ( 
                <Backdrop open={warning} style={{ zIndex: 50000}}>
                    <div className='d-a-delete-news-container'>
                        <div className='d-a-delete-news-header'>
                            <div className='d-a-delete-news-header-item'>
                                Delete News
                            </div>
                            <div>
                                <Button
                                    disabled={deletingNews}
                                    onClick={() => {
                                        this.setState({
                                            warning: false
                                        })}}>
                            <CancelIcon style={style}/>
                        </Button>
                            </div>
                        </div>
                        {deletedMessage}
                        {buttonFix}
                        <div className='d-a-delete-news-feedback'>
                                {deletingFeedback}
                        </div>
                    </div>
                </Backdrop>
            )
            : deleteNewsWarning = ( '')
        
        if (deletedNews) {
            return (
                <div className='d-a-news-card-container'>
                    <div className='d-a-news-card-successful-delete'>
                        Deleted
                    </div>
                </div>
            )
        } else {
            return (
                <div className='d-a-news-card-container'>
                    {deleteNewsWarning}
                    <div className='d-a-news-card-details'>
                        <div className='d-a-news-card-title'>
                        <TextField 
                            disabled={!editing}
                            name='title'
                            value={title}
                            label="News Title"
                            variant="outlined"
                            color='secondary'
                            fullWidth
                            onChange={(e) => {
                                this.handleChange(e)
                            }}
                        />
                        </div>
                        <div className='d-a-news-card-date'>
                            {date}
                        </div>
                        <div className='d-a-news-card-content'>
                        <TextField 
                            disabled={!editing}
                            name='content'
                            value={content}
                            label="News Content"
                            variant="outlined"
                            color='secondary'
                            fullWidth
                            multiline
                            rowsMax={4}
                            onChange={(e) => {
                                this.handleChange(e)
                            }}
                        />
                        </div>
                    </div>
                    <div className='d-a-news-card-options'>
                        {editingOptions}
                        {deleteOptions}
                    </div>
                </div>
            )
        }
    }
}