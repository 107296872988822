import React from 'react'

export default function LocationCard(props) {

    let {
        section,
        lotNumber,
        spaces,
        price,
        lotName,
        interfaith
    } = props

    let sectionCheck

    if (interfaith) {
        if (section === '1') {
            sectionCheck = 'Beersheba'
        } else if (section === '2') {
            sectionCheck = 'Beersheba II'
        } else if (section === '3') {
            sectionCheck = 'Beersheba III'
        } 
    } else {
        sectionCheck = section
    }
    if (lotName === 'WestChapel') {
        return ( '' )
    } else {
        return (
            <div className='u-d-d-card'>
                <div className="u-d-d-card-item">
                    {sectionCheck}{lotName}
                </div>
                <div className="u-d-d-card-item">
                    {lotNumber}
                </div>
                <div className="u-d-d-card-item">
                    {spaces}
                </div>
                <div className="u-d-d-card-item">
                    ${price}ea.
                </div>
            </div>
        )
    }
}