import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default class TestimonialCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            published: props.published,
            body: props.body,
            date: props.date,
            name: props.name,
            id: props.id,

            hidden: false,

            disableButtons: false,
            deleting: false,
            updating: false,
            editing: false,

            tempBody: null,
            tempDate: null,
            tempName: null,

            publishing: false
        }
    }

    handleDelete() {
        this.setState({
            deleting: true,
            disableButtons: true
        })

        let collection
        if (this.state.published) {
            collection = 'TestimonialsCleared'
        } else {
            collection = 'TestimonialsPending'
        }

        firebase.firestore().collection(collection).doc(this.state.id)
        .delete()
        .then(() => {
            setTimeout(() => {
                this.setState({
                    deleting: false,
                    hidden: true,
                    disableButtons: false
                })
            }, 2000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    toggleEdit() {
        if (this.state.editing) {
            this.setState({
                editing: false,
                name: this.state.tempName,
                date: this.state.tempDate,
                body: this.state.tempBody
            })
            setTimeout(() => {
                this.setState({
                    tempName: null,
                    tempBody: null,
                    tempDate: null
                })
            }, 100)
        } else {
            this.setState({
                editing: true,
                tempName: this.state.name,
                tempBody: this.state.body,
                tempDate: this.state.date
            })
        }
    }

    handleSaveChanges() {
        this.setState({
            updating: true
        })

        let collection
        if (this.state.published) {
            collection = 'TestimonialsCleared'
        } else {
            collection = 'TestimonialsPending'
        }

        firebase.firestore().collection(collection).doc(this.state.id)
        .update({
            message: this.state.body,
            name: this.state.name,
            dateOfSale: this.state.date
        })
        .then(() => {
            setTimeout(() => {
                this.setState({
                    editing: false,
                    updating: false
                })
            }, 1500)
        })
        .catch(err => {
            console.error(err.code)
        })

    }

    handlePublish() {
        this.setState({
            publishing: true
        })

        let newCollection, oldCollection
        if (this.state.published) {
            oldCollection = 'TestimonialsCleared'
            newCollection = 'TestimonialsPending'
        } else {
            oldCollection = 'TestimonialsPending'
            newCollection = 'TestimonialsCleared'
        }

        firebase.firestore().collection(newCollection)
        .add({
            message: this.state.body,
            dateOfSale: this.state.date,
            name: this.state.name
        })
        .then(() => {
            firebase.firestore().collection(oldCollection).doc(this.state.id)
            .delete()
            .then(() => {
                setTimeout(() => {
                    this.setState({
                        publishing: false,
                        hidden: true
                    })
                }, 2000)
            })
            .catch(err => {
                console.error(err.code)
            })
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    render() {
        let { 
            body, 
            date, 
            name, 
            hidden, 
            published, 
            deleting, 
            disableButtons, 
            editing,
            updating,
            publishing
        } = this.state

        let publishedCheck, deletingCheck, editCheck, submitCheck, disabledCheck, savingCheck, disableTextFields, disableEdit

        const testimonialButtonStyle = {
            padding: '0',
            minHeight: '0',
            width: '25vw',
            textTransform: 'none'
        }
        const textfieldStyle = {

        }
        const submitButtonStyle = {
            padding: '0',
            minHeight: '0',
            width: '90%',
            textTransform: 'none',
            marginBottom: '15px'
        }

        if (disableButtons || updating || publishing) {
            disableEdit = true
        } else {
            disableEdit = false
        }
        if (!editing || updating) {
            disableTextFields = true
        } else {
            disableTextFields = false
        }
        if (editing || disableButtons || publishing) {
            disabledCheck = true
        } else {
            disabledCheck = false
        }
        if (updating) {
            savingCheck = <CircularProgress size={20} style={{ color: 'black' }}/>
        } else {
            savingCheck = 'Save Changes'
        }
        if (publishing) {
            publishedCheck = <CircularProgress size={20} style={{ color: 'white' }}/>
        } else {
            if (published) {
                publishedCheck = 'Unpublish'
            } else {
                publishedCheck = 'Publish'
            }
        }
        if (deleting) {
            deletingCheck = <CircularProgress size={20} style={{ color: 'white' }}/>
        } else {
            deletingCheck = 'Delete'
        }
        if (editing) {
            editCheck = 'Cancel'
            submitCheck = (
                <Button
                    disabled={updating}
                    style={submitButtonStyle}
                    variant='contained'
                    onClick={() => this.handleSaveChanges() }
                >
                    <div className='m-ac-testimonials-save-buton'>
                        {savingCheck}
                    </div>
                </Button>
            )
        } else {
            editCheck = 'Edit'
        }

        if (hidden) {
            return (
                <div>

                </div>
            )
        } else {
            return (
                <div className='m-ac-testimonial-card-container'>
                    {submitCheck}
                    <div className="m-ac-testimonial-card-body">
                        <TextField
                            label='Testimonial'
                            disabled={disableTextFields}
                            color='secondary'
                            style={textfieldStyle}
                            value={body}
                            name='body'
                            multiline
                            fullWidth
                            variant='outlined'
                            onChange={(e) => this.handleChange(e)}
                            />
                    </div>
                    <div className="m-ac-testimonial-card-info">
                        <div className="m-ac-testimonial-card-name">
                            <TextField
                                label='Name'
                                disabled={disableTextFields}
                                color='secondary'
                                style={textfieldStyle}
                                value={name}
                                name='name'
                                fullWidth
                                variant='outlined'
                                onChange={(e) => this.handleChange(e)}
                                />
                        </div>
                        <div className="m-ac-testimonial-card-date">
                        <TextField
                            label='Date of Sale'
                            disabled={disableTextFields}
                            name='date'
                            type="date"
                            value={date}
                            fullWidth
                            color='secondary'
                            variant='outlined'
                            onChange={(e) => this.handleChange(e)}
                            />
                        </div>
                    </div>
                    <div className="m-ac-testimonial-card-options">
                        <Button
                            disabled={disabledCheck}
                            variant='contained'
                            style={testimonialButtonStyle}
                            onClick={() => this.handlePublish()}>
                            <div className='m-ac-testimonial-card-button'>
                                {publishedCheck}
                            </div>
                        </Button>
                        <Button
                            disabled={disableEdit}
                            variant='contained'
                            style={testimonialButtonStyle}
                            onClick={() => this.toggleEdit()} >
                            <div className='m-ac-testimonial-card-button'>
                                {editCheck}
                            </div>
                        </Button>
                        <Button
                            disabled={disabledCheck}
                            variant='contained'
                            style={testimonialButtonStyle}
                            onClick={() => this.handleDelete() }
                        >
                            <div className='m-ac-testimonial-card-button'>
                                {deletingCheck}
                            </div>
                        </Button>
                    </div>
                </div>
            )
        }
    }
}