import React, { useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import { AdminContext } from './../../../../../Contexts/AdminContext'

export default function LotCardSection() {
    let { 
        section,
        editingCard,
        location,
        handleChange
    } = useContext(AdminContext)
    
    let sectionValue 
    if (section) {
        sectionValue = section
    } else {
        sectionValue = ''
    }
    let availabilityCheck
    if ((location === 'Sharon' || location === 'Interfaith') && editingCard) {
        availabilityCheck = true
    } else {
        availabilityCheck = false
    }
    return (
        <TextField 
            disabled={(!availabilityCheck)}
            color='secondary'
            name='section'
            type='text'
            value={sectionValue}
            label='Section'
            variant='outlined'
            onChange={event => {
                handleChange(event)
            }}/>
    )

}