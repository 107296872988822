import React from 'react'

import GardenList from './LocationLists/GardenList'
import VeteransList from './LocationLists/VeteransList'

export default function DirectorsKnollwood() {

    return (
        <div className='u-d-directors-location-container'>
            <GardenList />
            <VeteransList />
        </div>
    )
}