import React, {useContext} from 'react'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

import {AboutContext} from './../../Contexts/AboutContext'

import Buyers from './Buyers'
import Sellers from './Sellers'
import News from './News'
import AboutContent from './AboutContent'
import Legal from './Legal'

export default function AboutTablet() {
    let {
        showAbout,
        showNews,
        showFaq,
        toggleAbout,
        toggleNews,
        toggleFaq,
        toggleLegal,
        showLegal
    } = useContext(AboutContext)

    let content, contentHeader
    if (showAbout) {
        content = <AboutContent />
        contentHeader = 'About The Lot Broker'
    } else if (showNews) {
        content = <News />
        contentHeader = 'Latest News from The Lot Broker'
    } else if (showFaq === 'buyers') {
        content = <Buyers />
        contentHeader = 'Buyers Frequently Asked Questions'
    } else if (showFaq === 'sellers') {
        content = <Sellers />
        contentHeader = 'Sellers Frequently Asked Questions'
    } else if (showLegal) {
        content = <Legal />
        contentHeader = 'Legal'
    }

    const groupStyle = {
        width: '96vw',
        marginTop: '10px',
        marginBottom: '15px',
        height: '85%'
    }
    const buttonStyle = {
        padding: '0',
        width: '20%',
        backgroundColor: 'rgba(41, 87, 105, 0.849)',
        height: '100%'
    }
    return (
        <div className='t-about-container'>
            <div className="t-about-options">
                <ButtonGroup 
                    style={groupStyle}
                    variant='contained' 
                    color='default' 
                    aria-label='contained primary button group'>
                    <Button
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { toggleAbout() }}>
                            <div className='t-about-options-item'>
                                About 
                            </div>
                    </Button>
                    <Button
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { toggleNews() }}>
                            <div className='t-about-options-item'>
                                News  
                            </div>
                    </Button>
                    <Button
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { toggleFaq('buyers') }}>
                            <div className='t-about-options-item'>
                                Buyer 
                                FAQ
                            </div>
                    </Button>
                    <Button
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { toggleFaq('sellers') }}>
                            <div className='t-about-options-item'>
                               Seller 
                               FAQ 
                            </div>
                    </Button>
                    <Button
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { toggleLegal() }}>
                            <div className='t-about-options-item'>
                               Legal
                            </div>
                    </Button>
                </ButtonGroup>
            </div>
            <div className="t-about-indicator">
                {contentHeader}
            </div>
            <div className='t-about-content-container'>
                {content}
            </div>
        </div>
    )
}