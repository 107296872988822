import React, { createContext } from 'react'

export const AdminContext = createContext()

export class AdminContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            iconSize: '3.3rem',

                // handle
            viewAdminLots: true,
            toggleViewAdminLots: () => {
                this.setState({
                    viewAdminLots: true,
                    viewAdminTestimonials: false,
                    viewAdminGallery: false,
                    viewAdminMessages: false,
                    viewAdminNews: false
                })
            },
            viewAdminGallery: false,
            toggleViewAdminGallery: () => {
                this.setState({
                    viewAdminGallery: true,
                    viewAdminLots: false,
                    viewAdminTestimonials: false,
                    viewAdminMessages: false,
                    viewAdminNews: false
                })
            },

            viewAdminTestimonials: false,
            toggleViewAdminTestimonials: () => {
                this.setState({
                    viewAdminTestimonials: true,
                    viewAdminLots: false,
                    viewAdminGallery: false,
                    viewAdminMessages: false,
                    viewAdminNews: false
                })
            },
            adminMessages: [],
            clearAdminMessages: () => {
                this.setState({
                    adminMessages: []
                })
            },
            viewAdminMessages: false,
            toggleViewAdminMessages: () => {
                this.setState({
                    viewAdminMessages: true,
                    viewAdminTestimonials: false,
                    viewAdminLots: false,
                    viewAdminGallery: false,
                    viewAdminNews: false
                })
            },
            viewAdminNews: false,
            toggleViewAdminNews: () => {
                this.setState({
                    viewAdminNews: true,
                    viewAdminMessages: false,
                    viewAdminTestimonials: false,
                    viewAdminLots: false,
                    viewAdminGallery: false,
                })
            },
            addMessage: (message) => {
                this.setState({
                    adminMessages: [...this.state.adminMessages, message]
                })
            },
            messagesLoaded: false,
            messagesLoadedSuccessfully: () => {
                this.setState({
                    messagesLoaded: true
                })
            },
            location: null,
            setLocation: (location) => {
                this.setState({
                    location,
                    locationMenu: false,
                    showAddComment: false
                })
            },
            otherLocation: null,
            setOtherLocation: (otherLocation) => {
                this.setState({ otherLocation })
            },
            createdAt: null,
            setCreatedAt: (createdAt) => {
                this.setState({ createdAt})
            },
            price: null,
            setPrice: (price) => {
                this.setState({ price })
            },
            lotNumber: null,
            setLotNumber: (lotNumber) => {
                this.setState({ lotNumber })
            },
            spaces: null,
            setSpaces: (spaces) => {
                this.setState({ spaces })
            },
            section: null,
            setSection: (section) => {
                this.setState({ section })
            },
            comments: null,
            setComments: (comments) => {
                this.setState({ comments })
            },
            lotCreated: false,
            setLotCreated: (lotCreated) => {
                this.setState({ lotCreated: true, savingNewLot: false, loading: false })
            },
            lotName: null,
            setLotName: (lotName) => {
                this.setState({ lotName, nameMenu: false })
            },
            garden: null,
            setGarden: (garden) => {
                this.setState({ garden })
            },
            priority: null,
            setPriority: (priority) => {
                this.setState({ priority })
            },
            switchLotId: () => {
                this.setState({
                    lotId: this.state.newLotId
                })
            },
            clearLotId: () => {
                this.setState({
                    newLotId: null
                })
            },
            newLotId: null,
            setNewLotId: (newLotId) => {
                this.setState({
                    newLotId
                })
            },
            lotId: null,
            setLotId: (lotId) => {
                this.setState({ lotId})
            },

            handleChange: (event) => {
                this.setState({
                    [event.target.name]: event.target.value
                })
            },


            addingLot: false,
            toggleAddingLot: () => {
                this.setState({
                    addingLot: !this.state.addingLot
                })
            },

            cancelNewLotAdd: () => {
                this.setState({
                    addingLot: false,
                    location: null,
                    lotName: null,
                    section: null,
                    spaces: null,
                    price: null,
                    lotNumber: null,
                    showAddComment: false
                })
            },
            clearNewLotAdd: () => {
                this.setState({
                    comments: null,
                    location: null,
                    lotName: null,
                    section: null,
                    spaces: null,
                    price: null,
                    garden: null,
                    priority: null,
                    lotNumber: null,
                    showAddComment: false,
                    sold: null,
                    imageUrl: null,
                    imageLinkNumber: null
                })
            },

            locationMenu: false,
            nameMenu: false,
            anchor: null,
            setLocationAnchor: (anchor) => {
                this.setState({
                    anchor,
                    locationMenu: true
                })
            },
            setNameAnchor: (anchor) => {
                this.setState({
                    anchor,
                    nameMenu: true
                })
            },
            clearAnchors: () => {
                this.setState({
                    anchor: null,
                    nameMenu: false,
                    locationMenu: false,
                    filterLocationMenu: false,
                    filterNameMenu: null
                })
            },

            showAddComment: false,
            toggleAddComment: () => {
                this.setState({
                    showAddComment: !this.state.showAddComment
                })
            },

            savingNewLot: false,
            saveNewLot: () => {
                this.setState({
                    savingNewLot: true,
                    loading: true
                })
            },
            loading: false,
            toggleLoading: () => {
                this.setState({
                    loading: !this.state.loading
                })
            },
            viewingIndvidualLot: false,
            toggleViewIndividualLot: () => {
                this.setState({
                    loading: false,
                    viewingIndvidualLot: !this.state.viewingIndvidualLot
                })
            },

            closeLotCard: () => {
                this.setState({
                    updatingCard: false,
                    lotCreated: false,
                    viewAdminLots: true,
                    addingLot: false,
                    viewingIndividualLot: false
                })
            },
            updatingCard: false,
            editingCard: false,
            toggleEditCard: () => {
                this.setState({
                    updatingCard: false,
                    editingCard: !this.state.editingCard
                })
            },
            saveCardEdits: () => {
                this.setState({
                    updatingCard: true,
                    editingCard: false
                })
            },
            resetCardEdits: () => {

            },
            updatedCard: () => {
                this.setState({
                    updatingCard: false
                })
            },
            originalLocation: null,
            setOriginalLocation: (originalLocation) => {
                this.setState({ 
                    originalLocation
                })
            },
            originalLotName: null,
            setOriginalLotName: (originalLotName) => {
                this.setState({ 
                    originalLotName
                })
            },
            originalPrice: null,
            setOriginalPrice: (originalPrice) => {
                this.setState({ 
                    originalPrice
                })
            },
            originalSection: null,
            setOriginalSection: (originalSection) => {
                this.setState({ 
                    originalSection
                })
            },
            originalSpaces: null,
            setOriginalSpaces: (originalSpaces) => {
                this.setState({ 
                    originalSpaces
                })
            },
            originalComments: null,
            setOriginalComments: (originalComments) => {
                this.setState({ 
                    originalComments
                })
            },
            originalLotNumber: null,
            setOriginalLotNumber: (originalLotNumber) => {
                this.setState({
                    originalLotNumber
                })
            },
            originalOtherLocation: null,
            setOriginalOtherLocation: (originalOtherLocation) => {
                this.setState({ 
                    originalOtherLocation
                })
            },
            deleteWarning: false,
            toggleDeleteWarning: () => {
                this.setState({
                    deleteWarning: !this.state.deleteWarning
                })
            },
            deletingCard: false,
            cardDeleted: false,
            attemptCardDelete: () => {
                this.setState({
                    deletingCard: true
                })
            },
            cardSuccessfullyDeleted: () => {
                this.setState({
                    viewingIndividualLot: false,
                    deleteSuccess: false,
                    savingNewLot: false,
                    updatingCard: false,
                    deletingCard: false,
                    deletedCard: true,
                    deleteWarning: false,
                    viewingIndvidualLot: false,
                    lotCreated: false,
                    addingLot: false,
                    location: null,
                    lotName: null,
                    otherLocation: null,
                    price: null,
                    lotId: null,
                    loading: false,
                    lotNumber: false,
                    section: null,
                    spaces: null,
                    priority: null,
                    garden: null,
                    sold: null,
                    imageUrl: null,
                    imageNumber: null
                })
            },
            imageLinkNumber: null,
            setImageLinkNumber: (imageLinkNumber) => {
                this.setState({
                    imageLinkNumber
                })
            },
            imageNumber: null,
            setImageNumber: (imageNumber) => {
                this.setState({
                    imageNumber
                })
            },
            showDeletionSuccess: () => {
                this.setState({
                    deleteWarning: false,
                    deletingCard: false,
                    deleteSuccess: true
                })
            },
            uploadingLotImage: false,
            uploadingImage: () => {
                this.setState({
                    updatingCard: true,
                    editingCard: false,
                    uploadingLotImage: true
                })
            },
            uploadImageSuccess: () => {
                this.setState({
                    updatingCard: false,
                    uploadingLotImage: false
                })
            },
            imageUrl: null,
            setImageUrl: (imageUrl) => {
                this.setState({
                    imageUrl
                })
            }, 
            currentlyDeleting: false,
            deletingImage: () => {
                this.setState({
                    currentlyDeleting: true,
                    updatingCard: true,
                    editingCard: false
                })
            },
            successfullyDeletedImage: () => {
                this.setState({
                    currentlyDeleting: false,
                    updatingCard: false,
                    imageUrl: null
                })
            },
            currentlySelling: false,
            attemptToSell: () => {
                this.setState({
                    currentlySelling: true,
                    updatingCard: true,
                    editingCard: false
                })
            },
            lotIsSold: false,
            toggleSold: () => {
                this.setState({
                    lotIsSold: !this.state.lotIsSold,
                    currentlySelling: false,
                    updatingCard: false
                })
            },
            setLotSold: () => {
                this.setState({
                    lotIsSold: true
                })
            },
            lotIsNotSold: () => {
                this.setState({
                    lotIsSold: false
                })
            },
            lastEdit: null,
            setLastEdit: (lastEdit) => {
                this.setState({
                    lastEdit
                })
            },
            originalPath: null,
            setOriginalPath: (originalPath) => {
                this.setState({
                    originalPath
                })
            },
            filterNameMenu: false,
            setFilterNameAnchor: (anchor) => {
                this.setState({
                    anchor,
                    filterNameMenu: true
                })
            },
            filterLocationMenu: false,
            setFilterLocationAnchor: (anchor) => {
                this.setState({
                    anchor,
                    filterLocationMenu: true
                })
            },
            filterLocation: null,
            setFilterLocation: (filterLocation) => {
                this.setState({
                    filterLocation,
                    filterLocationMenu: false,
                    filterSection: false,
                    filterName: false
                })
            },
            filterName: false,
            setFilterName: (filterName) => {
                this.setState({
                    filterName,
                    filterNameMenu: false

                })
            },
            clearNameFilter: () => {
                this.setState({
                    filterName: null
                })
            },
            loadingLocation: false,
            startLoadingLocation: () => {
                this.setState({
                    adminVisibleLotList: [],
                    loadingLocation: true,
                    fullyLoadedLots: false
                })
            },
            stopLoadingLocation: () => {
                this.setState({
                    loadingLocation: false,
                    fullyLoadedLots: true
                })
            },
            filterSection: false,
            setFilterSection: (filterSection) => {
                this.setState({
                    filterSection
                })
            },
            clearFilters: () => {
                this.setState({
                    filterLocation: 'Sharon',
                    filterName: null,
                    filterSection: null
                })
            },
            adminVisibleLotList: [],
            addLotToVisibleList: (lot) => {
                this.setState({
                    adminVisibleLotList: [...this.state.adminVisibleLotList, lot]
                })
            },
            clearVisibleLotList: () => {
                this.setState({
                    adminVisibleLotList: []
                })
            },
            fullyLoadedLots: false,
            viewingIndividualLot: false,
            openingIndividualLot: false,
            openIndividualLot: () => {
                this.setState({
                    openingIndividualLot: true
                })
            },
            openedIndividualLot: () => {
                this.setState({
                    openingIndividualLot: false,
                    viewingIndividualLot: true
                })
            },
            showingDeleteConfirmation: false,
            showDeleteAll: () => {
                this.setState({
                    showingDeleteConfirmation: true
                })
            },
            hideDeleteAll: () => {
                this.setState({
                    showingDeleteConfirmation: false,
                    deletingMessages: false,
                    messagesDeleted: false
                })
            },
            deletingMessages: false,
            messagesDeleted: false,
            deleteMessages: () => {
                this.setState({
                    deletingMessages: true
                })
            },
            deletedMessages: () => {
                this.setState({
                    deletingMessages: false,
                    messagesDeleted: true,
                    adminMessages: []
                })
            },
            showingAddNews: false,
            showAddNews: () => {
                this.setState({
                    showingAddNews: true
                })
            },
            hideAddNews: () => {
                this.setState({
                    showingAddNews: false,
                    newsTitle: null,
                    newsContent: null,
                    newsDate: null,
                    addingNews: false,
                    successfullyAddedNews: false
                })
            },
            newsTitle: null,
            newsContent: null,
            newsDate: null,

            addingNews: false,
            successfullyAddedNews: false,
            addNews: () => {
                this.setState({
                    addingNews: true
                })
            },
            addedNews: () => {
                this.setState({
                    addingNews: false,
                    successfullyAddedNews: true
                })
            },
            newsList: [],
            addToNewsList: (news) => {
                this.setState({
                    newsList: [...this.state.newsList, news]
                })
            },
            clearNewsList: () => {
                this.setState({
                    newsList: []
                })
            },
            reloading: false,
            reloadLotList: () => {
                this.setState({
                    reloading: true
                })
            },
            endReload: () => {
                this.setState({
                    reloading: false
                })
            }
// details
        }
    }
    render() {
        return (
            <AdminContext.Provider value={this.state}>
                {this.props.children}
            </AdminContext.Provider>
        )
    }

}