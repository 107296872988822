import React, { useContext } from 'react'

import TextField from '@material-ui/core/Textfield'
import Button from '@material-ui/core/Button'

import { AdminContext } from './../../../../Contexts/AdminContext'

export default function CommentsSection() {
let {
    showAddComment,
    handleChange,
    toggleAddComment
    } = useContext(AdminContext)

    if (showAddComment) {
        return (
            <TextField
                fullWidth={true}
                color='secondary'
                name='comments'
                type="text"
                rowsMax={3}
                multiline
                label="Comments"
                variant="outlined" 
                onChange={event => {
                               handleChange(event)
                           }}/>
        )
    } else {
        return (
            <Button
                color='primary'
                onClick={() => {
                    toggleAddComment()
                }}>
                    <div className='new-admin-add-comment-button'>
                        Add comment
                    </div>
            </Button>
        )
    }
}