import React, { useContext } from 'react'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {AboutContext} from './../../Contexts/AboutContext'

export default function Sellers() {

    let {
        toggleFaq
    } = useContext(AboutContext)


    const summaryStyle = {
        backgroundColor: 'rgba(150, 208, 231, 0.849)'
    }
    const toggleButtonStyle = {
        textTransform: 'none',
        marginBottom: '10px'
    }
    return (
        <div className='m-about-faq-container'>
            <Button
                variant='contained'
                color='default'
                style={toggleButtonStyle}
                onClick={() => toggleFaq('buyers')}>
                Show Buyers FAQ
            </Button>
            <div className="m-about-faq-disclaimer">
            * Please note that we are not affiliated with Sharon and Knollwood Memorial Parks
            </div>
            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                style={summaryStyle}
                >
                    <div className='m-about-panel-quesiton'>
                        How do you advertise our spaces for sale?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className='m-about-panel-answer'>
                        We use an aggressive form of both print advertising, Temple 
                        and senior center newsletters, and the www.ThelotBroker.com 
                        web site. We also receive referrals from the many contacts 
                        (Family, Friends, Clients and Funeral Directors) we have made 
                        over the past 60 years servicing the local community. We are 
                        working every day to expand our reach and make new contacts.    
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                style={summaryStyle}
                >
                    <div className='m-about-panel-quesiton'>
                        Is there paperwork Involved in selling our lots?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='m-about-panel-answer'>
                    Yes. Sharon and Knollwood Memorial Parks require the original deed 
                    and notarized signatures from all owners of record. This varies on 
                    a case by case basis.
                    <br/>
                    We will let you know what the cemetery will require from you beforehand, 
                    and from whom. Sharon or Knollwood Memorial park will contact you directly 
                    when I am able to sell your spaces.
                    <br/>
                    For more information please refer to the Massachusetts General Law 
                    Section 114 Chapter 29.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                style={summaryStyle}
                >
                    <div className='m-about-panel-quesiton'>
                        How long does it take to sell my spaces?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='m-about-panel-answer'>
                    We cannot say. Like real estate, it's based on need and Location 
                    (when family are located nearby).
                    <br/>
                    My wish would be to sell them as quickly as possible, but we cannot commit to when.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                style={summaryStyle}
                >
                    <div className='m-about-panel-quesiton'>
                        What happens when you receive an offer?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='m-about-panel-answer'>
                    We will contact you directly and let you know. Once agreed upon, 
                    we will give all the required information to Sharon or Knollwood 
                    Memorial park, and they will contact you for the paperwork/signatures.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                style={summaryStyle}
                >
                    <div className='m-about-panel-quesiton'>
                        How much do you charge for your services?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='m-about-panel-answer'>
                    Our commission rate is 20% of the total selling price.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                style={summaryStyle}
                >
                    <div className='m-about-panel-quesiton'>
                        If I sell my spaces on my own, do I owe you anything?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='m-about-panel-answer'>
                    You will owe us nothing. We only get paid when we sell your spaces.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                style={summaryStyle}
                >
                    <div className='m-about-panel-quesiton'>
                        Is there a transfer fee that Sharon Memorial Park Charges? 
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='m-about-panel-answer'>
                    Not for the seller. The $250.00 transfer fee is always paid by the Buyer.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                style={summaryStyle}
                >
                    <div className='m-about-panel-quesiton'>
                        Once I sign your contract, can I cancel at any time? 
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='m-about-panel-answer'>
                    Yes. Our contract is not binding. Both seller and buyer can 
                    cancel at any time without penalty up until the time Sharon 
                    or Knollwood Memorial Park receives all the required paperwork 
                    from you.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                style={summaryStyle}
                >
                    <div className='m-about-panel-quesiton'>
                        When do we receive our money from the sale?
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className='m-about-panel-answer'>
                    Once Sharon or Knollwood Memorial Park receives all the 
                    required paperwork, and ok's the transfer, you will receive 
                    a check directly from The Lot Broker.
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <div className="m-scroll-fix">

            </div>
            <div className="m-scroll-fix">

            </div>
        </div>
    )
}