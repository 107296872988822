import React, { useContext } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import { AdminContext } from './../../../../Contexts/AdminContext'

import CircularProgress from '@material-ui/core/CircularProgress'


export default function AddingNewLot() {
    let {
        savingNewLot,
        openingIndividualLot
        } = useContext(AdminContext)
    
    let backdropStatus
    savingNewLot || openingIndividualLot
        ? backdropStatus = true
        : backdropStatus = false

    return (
        <Backdrop 
            style={{ zIndex: 1000000, color: '#fff'}}
            open={backdropStatus}>
                <div className='admin-d-context-success-container'>
                    <CircularProgress color='primary' size={170}/>
                </div>
        </Backdrop>
    )
}