import React from 'react'
import { isMobileOnly, isTablet, isIPad13 } from 'react-device-detect'

import ContactDesktop from './ContactDesktop'
import ContactMobile from './ContactMobile'
import ContactTablet from './ContactTablet'

export default function Contact() {

    if (isMobileOnly) {
        return <ContactMobile /> 
    } else if (isTablet || isIPad13) {
        return <ContactTablet />
    } else {
        return (
            <div className='contact-container'>
                <ContactDesktop /> 
            </div>
        )
    }
}