import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {InventoryContext} from '../../../../Contexts/InventoryContext'

export default function MobileDetailsHeader() {

    let {
        mobileCloseDetails
    } = useContext(InventoryContext)

    const arrowStyle = {
        // height: '65px',
        fontSize:'42px',
        marginRight: '7px'
    }
    return (
        <div className="t-details-header">
            <div className="t-details-header-item">
                View Details
            </div>
            <div className="t-inventory-header-close">
                <Button
                    variant='contained'
                    color='default'
                    style={{textTransform: 'none', padding: '5px', fontSize: '1.3rem'}}
                    onClick={() => mobileCloseDetails() }>
                    <ArrowBackIcon style={arrowStyle}/>
                    Back to Inventory
                </Button>
            </div>
        </div>
    )
}