import React from 'react'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

import GLpic from './../images/GL.png'

import BuyersFaqDesktop from './BuyersFaqDesktop'
import SellersFaqDesktop from './SellersFaqDesktop'
import NewsDesktop from './News/Desktop/NewsDesktop'
import Legal from './Legal'

export default class AboutDesktop extends React.Component {
    constructor() {
        super()

        this.state = {
            about: true,
            buyerFaq: false,
            news: false,
            legal: false
        }
    }
    render () {

        const buttonStyle = {
            textTransform: 'none'
        }

        let { about, buyerFaq, news, legal } = this.state
        let aboutContent
        if (legal) {
            aboutContent = (
                <Legal />
            )
        } else if (about) {
            aboutContent = ( 
                <div className='about-d-standard-container'>
                    <div className="d-about-header">
                        About The Lot Broker
                    </div>
                    <div className="d-about-info-container">
                        <div className="d-about-info-top">
                            The Lot Broker was started back in the mid-1990s by Bernie Delman, a former family 
                            service counselor at Sharon Memorial Park. His vision was to create a service where 
                            cemetery lot owners had an avenue to sell their unwanted and un-used lots and give 
                            buyers a chance to purchase lots at a reduced price (up to half of what the cemetery 
                            was charging). Bernie operated the business successfully until retiring to Florida in 2004.
                        </div>

                        <div className="d-about-info-middle">
                            <div className="d-about-info-middle-item">
                                Joe Bank, another former counselor, took over the business and ran it for many years, 
                                carrying on the legacy that Bernie had started. Joe retired in October 2016 and asked 
                                me to take over. My plan is to continue in the legacy that was set before me.
                                I have been servicing the community for over 25 years. I previously worked for Sharon 
                                Memorial Park for 11 years and have been working for a local Funeral home for the last 
                                16 years. I also assisted Joe with The Lot Broker since he started. My aim is to 
                                continue providing the same great service that Bernie and Joe offered in the past.
                            </div>
                            <div className="d-about-info-middle-item2">
                                <img src={GLpic} alt='logo' className='d-about-gl-pic'/>
                            </div>
                        </div>
                        <div className="d-about-info-bottom">
                            We Advertise in many local publications, including Temple and senior center newsletters, and 
                            utilize digital media to help people looking for Cemetery spaces at Sharon and Knollwood 
                            Memorial Parks (up to 50% off Park prices).
                            My years of experience and understanding in the field will be beneficial in helping the 
                            families I serve, which is my top priority.
                        </div>
                        <div className="d-about-info-signature">
                            - Gary Levine
                        </div>
                        <div className="d-about-info-sub-signature">
                            * Please note that we are not affiliated with Sharon and Knollwood Memorial Parks
                        </div>
                    </div>
                </div>
            )
        } else if (!about && !news) {
            buyerFaq
                ? aboutContent = ( 
                    <div className='about-d-buyer-container'>
                        <BuyersFaqDesktop />
                    </div>
                 )
                : aboutContent = ( 
                    <div className='about-d-seller-container'>
                        <SellersFaqDesktop />
                    </div>
                 )
        } else if (news) {
            aboutContent = (
                <NewsDesktop />
            )
        } else if (legal) {
            aboutContent = (
                <Legal />
            )
        }
        return (
            <div className='about-desktop-container'>
                <ButtonGroup variant='contained' color='default' aria-label='contained primary button group' className='about-desktop-button-group'>
                    <Button
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            this.setState({
                                about: true,
                                buyerFaq: false,
                                news: false,
                                legal: false
                            })
                        }}>About</Button>
                        <Button
                            style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            this.setState({
                                about: false,
                                buyerFaq: false,
                                news: true,
                                legal: false
                            })
                        }}>News</Button>
                    <Button
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            this.setState({
                                about: false,
                                buyerFaq: true,
                                news: false,
                                legal: false
                            })
                        }}
                    >Buyer FAQ</Button>
                    <Button
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            this.setState({
                                about: false,
                                buyerFaq: false,
                                news: false,
                                legal: false
                            })
                        }}>Seller FAQ</Button>
                        <Button
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            this.setState({
                                about: false,
                                buyerFaq: false,
                                news: false,
                                legal: true
                            })
                        }}>Legal</Button>
                </ButtonGroup>
                {aboutContent}
            </div>
        )
    }
}