import React, {useContext} from 'react'

// import Button from '@material-ui/core/Button'

import NewsCard from './NewsCard'

import {AdminContext} from './../../../../Contexts/AdminContext'

export default function NewsListings() {

    let {
        newsList
    } = useContext(AdminContext)

    let newsListingContent

    newsList.length > 0
        ? newsListingContent = newsList.map(news => {
            return (
                <NewsCard 
                    key={news.id}
                    id={news.id}
                    title={news.title}
                    content={news.content}
                    date={news.date}
                />
            )
        })
        : newsListingContent = ( 'Loading news...' )

    return (
        <div className='d-a-news-listings-container'>
            {newsListingContent}
        </div>
    )
}