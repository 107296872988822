import React, { useContext } from 'react'

import { AdminContext } from './../../../../Contexts/AdminContext'
import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default function SubmitNewLotButton() {

    let {
        lotName,
        location,
        lotNumber,
        price,
        section,
        spaces,
        otherLocation,
        saveNewLot,
        setLotId,
        setPrice,
        setLotNumber,
        setSpaces,
        setSection,
        setComments,
        setLotCreated,
        setOtherLocation,
        setLocation,
        setCreatedAt,
        setLotName,
        setGarden,
        setPriority,
        // setSold,
        // loading,
        comments,

        // savingNewLot,
        } = useContext(AdminContext)
            let today = new Date()
            let day = today.getDate().toString()
            let month = (today.getMonth() + 1).toString()
            let year = today.getFullYear().toString()
            let todaysDate = month.concat('-',day,'-',year)
    
        // if (loading) {  
            


    function mySaveNewLot() {
        saveNewLot()
        if (location === 'Sharon' || location === 'Interfaith') {
            let correctedSection = section.toUpperCase()
            let correctedLotNumber = lotNumber.toUpperCase()
            let priority = section.replace(/\D/g, "")
            firebase.firestore().collection(location)
            .add({
                location: location,
                spaces: spaces,
                price: price,
                lotNumber: correctedLotNumber,
                comments: comments,
                priority: Number(priority),
                section: correctedSection,
                createdAt: todaysDate,
            })
            .then((docRef) => {
                firebase.firestore().collection(location).doc(docRef.id)
                .get()
                .then(doc => {
                    setPrice(doc.data().price)
                    setLotNumber(doc.data().lotNumber)
                    setSpaces(doc.data().spaces)
                    setSection(doc.data().section)
                    setComments(doc.data().comments)
                    setLocation(doc.data().location)
                    setCreatedAt(doc.data().createdAt)
                    setPriority(doc.data().priority)
                    // setSold(false)
                    setLotId(doc.id)
                }) 
            })
            .then(() => {
                setTimeout(() => {
                    setLotCreated()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Knollwood') {
            let correctedLotNumber = lotNumber.toUpperCase()
            const gardenList = [
                'Cross',
                'Faith',
                'Gethsemane',
                'Last Supper',
                'Meditation',
                'Saviour',
                'Sunset',
                'Word',
                'Peace',
                'Remembrance'
            ]
            let gardenCheck, urnCheck
            if (gardenList.includes(lotName)) {
                    gardenCheck = true
            } else {
                    gardenCheck = false
            }
            if (lotName === 'WestChapel') {
                urnCheck = true
            } else {
                urnCheck = false
            }
            firebase.firestore().collection('Knollwood')
            .add({
                urn: urnCheck,
                garden: gardenCheck,
                location: location,
                spaces: spaces,
                price: price,
                lotNumber: correctedLotNumber,
                comments: comments,
                lotName: lotName,
                createdAt: todaysDate,
            })
            .then((docRef) => {
                firebase.firestore().collection(location).doc(docRef.id)
                .get()
                .then(doc => {
                    setPrice(doc.data().price)
                    setGarden(doc.data().garden)
                    setLotNumber(doc.data().lotNumber)
                    setLotName(doc.data().lotName)
                    setSpaces(doc.data().spaces)
                    setComments(doc.data().comments)
                    setLocation(doc.data().location)
                    setCreatedAt(doc.data().createdAt)
                    // setSold(false)
                    setLotId(doc.id)
                }) 
            })
            .then(() => {
                setTimeout(() => {
                    setLotCreated()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Estate' || location === 'Other') {
            firebase.firestore().collection(location)
            .add({
                location: location,
                otherLocation: otherLocation,
                spaces: spaces,
                price: price,
                comments: comments,
                createdAt: todaysDate,
            })
            .then((docRef) => {
                firebase.firestore().collection(location).doc(docRef.id)
                .get()
                .then(doc => {
                    setLotId(doc.id)
                    setLocation(doc.data().location)
                    setOtherLocation(doc.data().otherLocation)
                    setPrice(doc.data().price)
                    setSpaces(doc.data().spaces)
                    setComments(doc.data().comments)
                    setCreatedAt(doc.data().createdAt)
                }) 
            })
            .then(() => {
                setTimeout(() => {
                    setLotCreated()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        }
    }

    if (lotNumber && price && section && spaces && location) {
        return (
            <Button
                color='primary'
                variant='contained'
                onClick={() => {
                    mySaveNewLot()
                }}
            >
                Add Lot
            </Button>
        )
    } else if (location === 'Knollwood' && price && lotName && spaces && lotNumber) {
        return (
            <Button
                color='primary'
                variant='contained'
                onClick={() => {
                    mySaveNewLot()
                }}
            >
                Add Lot
            </Button>
        )
    } else if ((location === 'Other' || location === 'Estate') && price && spaces && otherLocation) {
        return (
            <Button
                color='primary'
                variant='contained'
                onClick={() => {
                    mySaveNewLot()
                }}
            >
                Add Lot
            </Button>
        )
    } else {
        return (
            <Button 
            color='primary'
            variant='contained'
            disabled
            >Add Lot</Button>
        )
    }
}