import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import {MobileAdminContext} from './../../../../../Contexts/MobileAdminContext'

export default function Beersheba() {
    let {
        location,
        section,
        setInterfaithAnchor,
        interfaithMenu,
        anchor,
        clearAnchors,
        setInterfaithSection
    } = useContext(MobileAdminContext)

    let nameHeader
    if (section) {
        if (section === '1') {
            nameHeader = 'Beersheba I'
        } else if (section === '2') {
            nameHeader = 'Beersheba II'
        } else if (section === '3') {
            nameHeader = 'Beersheba III'
        }
    } else {
        nameHeader = 'Section'
    }

    const nameMenuItemStyle = {
        padding: '0',
        margin: '0',
        minHeight: '2px',
        minWidth: '40vw',
        fontSize: '3rem'
    }
    const nameButtonStyle = {
        width: '70vw',
        marginTop: '5px',
        fontSize: '3rem',
        textTransform: 'none'
    }


    if (location === 'Interfaith') {
        return (
            <div>
                <Button 
                    style={nameButtonStyle}
                    aria-controls="location-menu" aria-haspopup="true"
                    onClick={event => {
                        setInterfaithAnchor(event.currentTarget)
                    }}>
                    {nameHeader}
                </Button>
                <Menu
                    MenuListProps={{ disablePadding: true }}
                    style={{ zIndex: 1000030}}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    id="location-menu"
                    anchorEl={anchor}
                    keepMounted
                    open={interfaithMenu}
                    onClose={() => {
                        clearAnchors()
                    }}>
                    <MenuItem 
                        style={nameMenuItemStyle}
                        onClick={() => {
                        setInterfaithSection('1')
                    }}>
                        <div className='m-ac-name-item'>
                            Beersheba I
                        </div>
                    </MenuItem>
                    <MenuItem 
                        style={nameMenuItemStyle}
                        onClick={() => {
                        setInterfaithSection('2')
                    }}>
                        <div className='m-ac-name-item'>
                            Beersheba II
                        </div>
                    </MenuItem>
                    <MenuItem 
                        style={nameMenuItemStyle}
                        onClick={() => {
                        setInterfaithSection('3')
                    }}>
                        <div className='m-ac-name-item'>
                            Beersheba III
                        </div>
                    </MenuItem>
                </Menu>
            </div>
        )
    } else {
        return (
            <div>
            </div>
        )
    }
    
}