import React, {useContext} from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import TabletDetailsHeader from './TabletDetailsHeader'
import TabletDetailsPicture from './TabletDetailsPicture'
import TabletDetailsInfo from './TabletDetailsInfo'
import TabletDetailsComments from './TabletDetailsComments'
import TabletDetailsContactButton from './TabletDetailsContact'
import MobileContact from './MobileContact'

import WhiteLogo from './../../../../../StyleNew/logoWhite.png'

import {InventoryContext} from '../../../../Contexts/InventoryContext'

export default function TabletDetails() {

    let {
        mobileLoaded,
        mobileShowDetails,
        mobileLoading
    } = useContext(InventoryContext)

    const detailsStyle = {
        marginTop: '7vh',
        zIndex: '100',
        backgroundColor: 'rgb(150, 208, 231)',
        height: '90vh'
    }
    const loadingDetailsStyle = {
        marginTop: '7vh',
        zIndex: '200',
        backgroundColor: 'rgb(150, 208, 231)',
        height: '90avh'
    }
    const spinnerStyle = {
        color: 'white',
        position: 'relative'

    }
    
    let detailsContent
    if (mobileLoaded) {
        detailsContent = (
            <div className='t-details-container'>
                <TabletDetailsHeader />
                <TabletDetailsPicture />
                <TabletDetailsInfo />
                <TabletDetailsComments />
                <TabletDetailsContactButton />
                <MobileContact />
            </div>
        )
    } else {
        detailsContent = ( '' )
    }
    return (
        <Backdrop
            transitionDuration={400}
            open={mobileShowDetails}
            style={detailsStyle}>
            <Backdrop
                transitionDuration={450}
                open={mobileLoading}
                style={loadingDetailsStyle}>
                <CircularProgress style={spinnerStyle} size={140}/>
                <img src={WhiteLogo} alt='logo' className='m-inventory-logo'/>
            </Backdrop>

            {detailsContent}

        </Backdrop>
    )
}