import React, { 
    // useContext 
} from 'react'

import LotCardLocationMenu from './LotCardLocationMenu'
import LotCardAdditionalMenu from './LotCardAdditionalMenu'
import LotCardSection from './LotCardSection'
import LotCardSpaces from './LotCardSpaces'
import LotCardLotNumber from './LotCardLotNumber'
import LotCardPrice from './LotCardPrice'
import LotCardComments from './LotCardComments'
import LotCardLastUpdated from './LotCardLastUpdated'

export default function LotCardForm() {

    return(
        <div className='admin-final-d-lot-card-form-container'>
            <div className="admin-final-d-lot-card-form-row1">
                <div className="admin-final-d-lot-card-form-row-item6">
                    <LotCardLocationMenu />
                </div>
                <div className="admin-final-d-lot-card-form-row-item5">
                    <LotCardAdditionalMenu />
                </div>
            </div>
            <div className="admin-final-d-lot-card-form-row">
                <div className="admin-final-d-lot-card-form-row-item">
                    <LotCardSection />
                </div>
                <div className="admin-final-d-lot-card-form-row-item">
                    <LotCardSpaces />
                </div>
            </div>
            <div className="admin-final-d-lot-card-form-row">
                <div className="admin-final-d-lot-card-form-row-item">
                    <LotCardLotNumber />
                </div>
                <div className="admin-final-d-lot-card-form-row-item">
                    <LotCardPrice />
                </div>
            </div>
            <div className="admin-final-d-lot-card-form-comments-row">
                <div className="admin-final-d-lot-card-form-comments-item">
                    <LotCardComments />
                </div>
            </div>
            <div className="admin-final-d-lot-card-form-date-row">
                <div className="admin-final-d-lot-card-form-row-date">
                    <LotCardLastUpdated />
                </div>
            </div>
        </div>
    )
}