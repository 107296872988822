import React, {useContext} from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

import DirectorsSharon from './DirectorsSharon'
import DirectorsInterfaith from './DirectorsInterfaith'
import DirectorsGarden from './DirectorsGarden'
import DirectorsVeterans from './DirectorsVeterans'

import {DirectorsContext} from './../../Contexts/DirectorsContext'

export default function DirectorsTablet() {
    let {
        loadingLots,
        mobileList,
        setMobileList
    } = useContext(DirectorsContext)

    const loadingStyle = {
        color: 'white'
    }
    const groupStyle = {
        width: '96vw',
        marginTop: '10px',
        marginBottom: '15px',
        height: '85%'
    }
    const buttonStyle = {
        padding: '0',
        width: '25%',
        backgroundColor: 'rgba(41, 87, 105, 0.849)',
        height: '100%'
    }
    let locationHeader, directorsHeader, loadingFeedback

    if (mobileList === 'Sharon') {
        locationHeader = 'Sharon Inventory List'
        directorsHeader = (
            <div className="t-directors-location-header">
                <div className="t-directors-header-item">
                    Section
                </div>
                <div className="t-directors-header-number">
                    Number
                </div>
                <div className="t-directors-header-spaces">
                    Spaces
                </div>
                <div className="t-directors-header-price">
                    Price
                </div>
            </div>
        )
    } else if (mobileList === 'Interfaith') {
        locationHeader = 'Sharon Interfaith Inventory List'
        directorsHeader = (
            <div className="t-directors-location-header">
                <div className="t-directors-header-item">
                    Section
                </div>
                <div className="t-directors-header-number">
                    Number
                </div>
                <div className="t-directors-header-spaces">
                    Spaces
                </div>
                <div className="t-directors-header-price">
                    Price
                </div>
            </div>
        )
    } else if (mobileList === 'Garden') {
        locationHeader = 'Knollwood Garden Inventory List'
        directorsHeader = (
            <div className="t-directors-location-header">
                <div className="t-directors-header-name">
                    Name
                </div>
                <div className="t-directors-header-number">
                    Number
                </div>
                <div className="t-directors-header-spaces">
                    Spaces
                </div>
                <div className="t-directors-header-price">
                    Price
                </div>
            </div>
        )
    } else if (mobileList === 'Veterans') { 
        locationHeader = 'Knollwood Veterans Inventory List'
        directorsHeader = (
            <div className="t-directors-location-header">
                <div className="t-directors-header-item">
                    Name
                </div>
                <div className="t-directors-header-number">
                    Number
                </div>
                <div className="t-directors-header-spaces">
                    Spaces
                </div>
                <div className="t-directors-header-price">
                    Price
                </div>
            </div>
        )
    }

    if (loadingLots) {
        loadingFeedback = ( 
            <div className='t-directors-loading-center'>
                <CircularProgress style={loadingStyle} size={170}/> 
            </div>
        )
    } else {
        if (mobileList === 'Sharon') {
            loadingFeedback = <DirectorsSharon />
        } else if (mobileList === 'Interfaith') {
            loadingFeedback = <DirectorsInterfaith />
        } else if (mobileList === 'Garden') {
            loadingFeedback = <DirectorsGarden />
        } else if (mobileList === 'Veterans') { 
            loadingFeedback = <DirectorsVeterans />
        }
    }
    return (
        <div className='t-directors-container'>
            <div className="t-directors-options">
            <ButtonGroup 
                    style={groupStyle}
                    variant='contained' 
                    color='default' 
                    aria-label='contained primary button group'>
                    <Button
                        disabled={loadingLots}
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { setMobileList('Sharon') }}>
                            <div className='t-directors-options-item'>
                                Sharon
                            </div>
                    </Button>
                    <Button
                        disabled={loadingLots}
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { setMobileList('Interfaith') }}>
                            <div className='t-directors-options-item'>
                                Interfaith 
                            </div>
                    </Button>
                    <Button
                        disabled={loadingLots}
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { setMobileList('Garden') }}>
                            <div className='t-directors-options-item'>
                                Garden
                            </div>
                    </Button>
                    <Button
                        disabled={loadingLots}
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { setMobileList('Veterans') }}>
                            <div className='t-directors-options-item'>
                               Veterans
                            </div>
                    </Button>
                </ButtonGroup>
            </div>
            <div className='t-directors-header'>
                Funeral Directors
            </div>
            <div className="t-directors-location">
                {locationHeader}
            </div>
            {directorsHeader}
            <div className="t-directors-content">
                {loadingFeedback}
            </div>
            <div className="t-directors-bottom">
                *The Lot Broker is not affiliated with Sharon or Knollwood Memorial Parks
            </div>
        </div>
    )
}