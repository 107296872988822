import React, {
    // useContext
    } from 'react'

import ChangeLocation from './ChangeLocation'
import SecondFilter from './SecondFilter'
import InventoryCards from './Cards/InventoryCards'

// import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function MobileInventory() {
    // let {

    // } = useContext(InventoryContext)

    return (
        <div className='t-inventory-container'>
            <div className='t-inventory-location-header'>
                <ChangeLocation />
                <SecondFilter />
            </div>

            <InventoryCards />
        </div>
    )
}