import React, { useContext } from 'react'

import SharonFilter from './SharonFilter'
import InterfaithFilter from './InterfaithFilter'
import KnollwoodFilter from './KnollwoodFilter'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function SecondFilter() {

    let {
        filterLocation
    } = useContext(MobileAdminContext)

    if (filterLocation === 'Sharon') {
        return <SharonFilter />
    } else if (filterLocation === 'Interfaith') {
        return <InterfaithFilter />
    } else if (filterLocation === 'Knollwood') {
        return <KnollwoodFilter />
    } else {
        return (<div></div>)
    }
}