import React, { useContext } from 'react'

import TextField from '@material-ui/core/TextField'
// import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'

import SaveButton from './SaveButton'
import EditButtons from './EditButtons'
import InterfaithSections from './InterfaithSections'
import LocationSelector from './LocationSelector'
import KnollwoodNames from './KnollwoodNames'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function DetailsContent() {

    let {
        detailsLocation,
        detailsNumber,
        detailsSpaces,
        detailsPrice,
        detailsComments,
        detailsSection,
        detailsOtherLocation,
        // detailsSold,
        handleChange,
        editing,
        savingEdits
        // toggleEdit
    } = useContext(MobileAdminContext)

    const textfieldStyle = {
        marginBottom: '13px'
    }
    let commentsCheck, sectionCheck, numberCheck, spacesCheck, otherLocationCheck
    if (detailsComments) {
        commentsCheck = detailsComments
    } else {
        commentsCheck = ''
    }
    if (detailsSection) {
        sectionCheck = detailsSection
    } else {
        sectionCheck = ''
    }
    if (detailsNumber) {
        numberCheck = detailsNumber
    } else {
        numberCheck = ''
    }
    if (detailsSpaces) {
        spacesCheck = detailsSpaces
    } else {
        spacesCheck = ''
    }
    if (detailsOtherLocation) {
        otherLocationCheck = detailsOtherLocation
    } else {
        otherLocationCheck = ''
    }
    let disabledCheck
    if (savingEdits || !editing) {
        disabledCheck = true
    } else {
        disabledCheck = false
    }
    if (detailsLocation) {
        if (detailsLocation === 'Sharon') {
            return (
                <div className='m-ac-details-content-container'>
                    <SaveButton />
                    <LocationSelector />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsSection'
                        color='secondary'
                        type='text'
                        value={sectionCheck}
                        label='Section'
                        variant='outlined'
                        onChange={event => handleChange(event) }
                    />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        value={numberCheck}
                        name='detailsNumber'
                        color='secondary'
                        type='text'
                        label='Lot Number'
                        variant='outlined'
                        onChange={event => handleChange(event) }
                    />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsSpaces'
                        value={spacesCheck}
                        color='secondary'
                        type='text'
                        label='Spaces'
                        variant='outlined'
                        onChange={event => handleChange(event) }
                    />
                    <TextField 
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                  each
                                </InputAdornment>
                              ),
                          }}
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsPrice'
                        value={detailsPrice}
                        color='secondary'
                        type='number'
                        label='Price'
                        variant='outlined'
                        onChange={event => handleChange(event) }
                    />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsComments'
                        color='secondary'
                        value={commentsCheck}
                        multiline
                        rowsMax={4}
                        type='text'
                        label='Comments'
                        variant='outlined'
                        onChange={event => handleChange(event) }
                    />
                    <EditButtons />
                </div>
            )
        } else if (detailsLocation === 'Interfaith') {
            return (
                <div className='m-ac-details-content-container'>
                    <SaveButton />
                    <LocationSelector />
                    <InterfaithSections />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        value={numberCheck}
                        name='detailsNumber'
                        color='secondary'
                        type='text'
                        label='Lot Number'
                        variant='outlined'
                        onChange={event => handleChange(event) }
                    />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsSpaces'
                        value={spacesCheck}
                        color='secondary'
                        type='text'
                        label='Spaces'
                        variant='outlined'
                        onChange={event => handleChange(event) }
                    />
                    <TextField 
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                  each
                                </InputAdornment>
                              ),
                          }}
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsPrice'
                        value={detailsPrice}
                        color='secondary'
                        type='number'
                        label='Price'
                        variant='outlined'
                        onChange={event => handleChange(event) }
                    />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsComments'
                        color='secondary'
                        value={commentsCheck}
                        multiline
                        rowsMax={4}
                        type='text'
                        label='Comments'
                        variant='outlined'
                        onChange={event => handleChange(event) }
                    />
                    <EditButtons />
                </div>
            )
        } else if (detailsLocation === 'Knollwood') {
            return (
                <div className='m-ac-details-content-container'>
                    <SaveButton />
                    <LocationSelector />
                    <KnollwoodNames />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsNumber'
                        color='secondary'
                        type='text'
                        label='Lot Number'
                        variant='outlined'
                        value={numberCheck}
                        onChange={event => handleChange(event) }/>
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsSpaces'
                        color='secondary'
                        type='text'
                        label='Spaces'
                        variant='outlined'
                        value={spacesCheck}
                        onChange={event => handleChange(event) }/>
                    <TextField 
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                  each
                                </InputAdornment>
                              ),
                          }}
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsPrice'
                        value={detailsPrice}
                        color='secondary'
                        type='number'
                        label='Price'
                        variant='outlined'
                        onChange={event => handleChange(event) }/>
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsComments'
                        color='secondary'
                        value={commentsCheck}
                        multiline
                        rowsMax={4}
                        type='text'
                        label='Comments'
                        variant='outlined'
                        onChange={event => handleChange(event) }/>
                   <EditButtons />
                </div>
            )
        } else if (detailsLocation === 'Shara') {
            return (
                <div className='m-ac-details-content-container'>
                    <SaveButton />
                    <LocationSelector />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsSpaces'
                        color='secondary'
                        type='text'
                        label='Spaces'
                        variant='outlined'
                        value={spacesCheck}
                        onChange={event => handleChange(event) }/>
                    <TextField 
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                  each
                                </InputAdornment>
                              ),
                          }}
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsPrice'
                        value={detailsPrice}
                        color='secondary'
                        type='number'
                        label='Price'
                        variant='outlined'
                        onChange={event => handleChange(event) }/>
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsComments'
                        color='secondary'
                        value={commentsCheck}
                        multiline
                        rowsMax={4}
                        type='text'
                        label='Comments'
                        variant='outlined'
                        onChange={event => handleChange(event) }/>
                    <EditButtons />
                </div>
            )
        } else if (detailsLocation === 'Other' || detailsLocation === 'Estate') {
            return (
                <div className='m-ac-details-content-container'>
                    <SaveButton />
                    <LocationSelector />
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsOtherLocation'
                        value={otherLocationCheck}
                        color='secondary'
                        multiline
                        rowsMax={2}
                        type='text'
                        label='Location'
                        variant='outlined'
                        onChange={event => handleChange(event) }/>
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsSpaces'
                        color='secondary'
                        type='text'
                        label='Spaces'
                        variant='outlined'
                        value={spacesCheck}
                        onChange={event => handleChange(event) }/>
                    <TextField 
                        InputProps={{ startAdornment: (<InputAdornment position="start">$</InputAdornment>) }}
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsPrice'
                        value={detailsPrice}
                        color='secondary'
                        type='number'
                        label='Price'
                        variant='outlined'
                        onChange={event => handleChange(event) }/>
                    <TextField 
                        disabled={disabledCheck}
                        fullWidth
                        style={textfieldStyle}
                        name='detailsComments'
                        color='secondary'
                        value={commentsCheck}
                        multiline
                        rowsMax={4}
                        type='text'
                        label='Comments'
                        variant='outlined'
                        onChange={event => handleChange(event) }/>
                    <EditButtons />
                </div>
            )
        } 
    } else {
        return (
            <div className='m-ac-details-content-container'>

            </div>
        )
    }
}