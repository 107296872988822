import React from 'react'

import Button from '@material-ui/core/Button'
import AddAPhotoTwoToneIcon from '@material-ui/icons/AddAPhotoTwoTone'
import TextField from '@material-ui/core/TextField'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

export default class GalleryCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            header: props.header,
            body: props.body,
            id: props.id,
            imageUrl: props.imageUrl,
            imageId: props.imageId,

            editing: false,
            updating: false,

            uploading: false,

            tempHeader: null,
            tempBody: null,
            setTempValues: () => {
                this.setState({
                    editing: true,
                    tempHeader: this.state.header,
                    tempBody: this.state.body
                })
            },
            revertValues: () => {
                this.setState({
                    editing: false,
                    header: this.state.tempHeader,
                    body: this.state.tempBody
                })
                setTimeout(() => {
                    this.setState({
                        tempBody: null,
                        tempHeader: null
                    })
                }, 150)
            }
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleEdit() {
        if (this.state.editing) {
            this.state.revertValues()
        } else {
            this.state.setTempValues()
        }
    }

    handleSaveChanges() {
        this.setState({
            updating: true
        })

        firebase.firestore().collection('Gallery').doc(this.state.id)
        .update({
            body: this.state.body,
            header: this.state.header
        })
        .then(() => {
            setTimeout(() => {
                this.setState({
                    updating: false,
                    editing: false
                })
            }, 2000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    handleUpload(e) {
        this.setState({
            uploading: true
        })

        let newImageName = Math.floor(Math.random() * 100000000)
        const image = e.target.files[0]

        firebase.storage().ref(`/gallery/${newImageName}`)
        .put(image)
        .then(() => {
            firebase.storage().ref(`/gallery/${newImageName}`)
            .getDownloadURL()
            .then(url => {
                firebase.firestore().collection('Gallery').doc(this.state.id)
                .update({
                    imageUrl: url,
                    imageId: newImageName
                })
                .then(() => {
                    setTimeout(() => {
                        this.setState({
                            uploading: false,
                            imageUrl: url,
                            imageId: newImageName
                        })
                    })
                })
            })
        })
        .catch(err => {
            console.error(err.code)
        })
    }
    render() {

        let {
            header,
            body,
            // id,
            // imageId,
            imageUrl,
            uploading,
            updating,
            editing
        } = this.state

        const uploadButtonStyle = {
            padding: '0',
            minHeight: '0',
            minWidth: '0'
        }
        const uploadIconStyle = {
            color: 'white',
            fontSize: '50px',
            padding: '10px',
            // marginRight: ''
        }
        const textFieldStyle = {
            width: '70vw',
            marginTop: '13px'
        }
        const editButtonStyle = {
            minWidth: '30vw',
            marginTop: '13px',
            marginBottom: '8px',
            textTransform: 'none',
            fontSize: '1.5rem'
        }
        const saveButtonStyle = {
            padding: '0',
            minHeight: '0',
            minWidth: '0',
            textTransform: 'none',
            marginTop: '13px'
        }
        const feedbackStyle = {
            height: '50px',
            width: '70vw',
            background: 'rgb(86, 228, 86)'
        }
        const uploadingStyle = {
            color: 'white',
            padding: '10px'
        }

        let editCheck, saveButton, saveFeedback, uploadFeedback
        if (updating) {
            saveFeedback = (
                <LinearProgress style={feedbackStyle}/>
            )
        } else {
            saveFeedback = (
                <div className='t-ac-gallery-save-container'>
                    Save Changes
                </div>
            )
        }
        if (editing) {
            editCheck = 'Cancel'
            saveButton = (
                <Button
                    disabled={(updating)}
                    style={saveButtonStyle}
                    onClick={() => this.handleSaveChanges() }
                >
                    {saveFeedback}
                </Button>
            )
        } else {
            editCheck = 'Edit'
            saveButton = ( '')
        }
        if (uploading) {
            uploadFeedback = (
                <CircularProgress style={uploadingStyle} size={50}/>
            )
        } else {
            uploadFeedback = (
                <AddAPhotoTwoToneIcon style={uploadIconStyle}/>
            )
        }
        return (
            <div className='t-ac-gallery-card-container'>
                <div
                    className='t-ac-gallery-card-image'
                    style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                    <Button
                        disabled={(editing || uploading)}
                        style={uploadButtonStyle}
                        component="label"
                        onChange={(e) => this.handleUpload(e) }>
                        {uploadFeedback}
                        <input
                            type="file"
                            style={{ display: "none" }}
                        />
                    </Button>

                </div>
                {saveButton}
                <TextField
                    disabled={(!editing || updating || uploading)}
                    variant='outlined'
                    color='secondary'
                    label='Header'
                    name='header'
                    style={textFieldStyle}
                    value={header}
                    onChange={e => this.handleChange(e)}
                    />
                <TextField
                    disabled={(!editing || updating || uploading)}
                    variant='outlined'
                    color='secondary'
                    label='Body'
                    name='body'
                    style={textFieldStyle}
                    value={body}
                    multiline
                    rows={1}
                    rowsMax={5}
                    onChange={e => this.handleChange(e)}
                    />
                    <Button
                        disabled={(updating || uploading)}
                        variant='contained'
                        color='default'
                        style={editButtonStyle}
                        onClick={() => this.handleEdit() }>
                        {editCheck}
                    </Button>
            </div>
        )
    }
}