import React, {useContext} from 'react'

import AdminLotFilterBar from './AdminLotFilterBar'
import AdminLoadingLotsProgress from './AdminLoadingLotsProgress'
import AdminLotContainer from './AdminLotContainer'
import AdminDetailsHeader from './AdminDetailsHeader'
import { AdminContext } from './../../../../Contexts/AdminContext'

export default function AdminLotList() {

    let {
        fullyLoadedLots
    } = useContext(AdminContext)

    let listContent, loadedHeaderContent
    fullyLoadedLots
        ? listContent = ( <AdminLotContainer /> )
        : listContent = ( <div></div>) 
    fullyLoadedLots
        ? loadedHeaderContent = ( <AdminDetailsHeader /> )
        : loadedHeaderContent = ( '' )
    return (
        <div className='new-admin-d-lot-content-container'>
            <AdminLotFilterBar />
            <AdminLoadingLotsProgress />
            {loadedHeaderContent}
            {listContent}
        </div>
    )
}
