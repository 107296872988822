import React from 'react'

export default function Legal() {


    return (
        <div className='m-about-legal-container'>
            <div className="m-about-legal-header m-about-legal-title">
                Mass General Law
            </div>
            <div className="m-about-legal-header">
                Part I
            </div>
            <div className="m-about-legal-content">
                Administration of the Government
            </div>
            <div className="m-about-legal-header">
                Title XVI
            </div>
            <div className="m-about-legal-content">
                Public Health
            </div>
            <div className="m-about-legal-header">
                Chapter 114
            </div>
            <div className="m-about-legal-content">
                Cemeteries and Burials
            </div>
            <div className="m-about-legal-header">
                Section 29
            </div>
            <div className="m-about-legal-content m-about-legal-main-content">
                Indivisible character of lots: vesting limitations 
                and conditions: right of interment: designation at 
                representation of lot: record
            </div>
            <div className="m-about-legal-main-content">
                Lots in cemeteries incorporated under section one, 
                tombs in public cemeteries in cities and lots and 
                tombs in public cemeteries in towns, shall be held 
                indivisible, and upon the decease of a proprietor of 
                such lot the title thereto shall vest in the heirs 
                at law or devisees of the deceased subject to the 
                following limitations and conditions: If the deceased 
                leaves a spouse and children, they shall have the 
                possession, care and control of said lot or tomb 
                in common during the life of such surviving spouse. 
                If the deceased leaves a spouse and no children, 
                said spouse shall have possession, care and control 
                of such lots or tombs during life. If the deceased 
                leaves children and no spouse, they shall have in 
                common the possession, care and control of such 
                lots or tombs during their joint lives and the 
                survivor of them during his life. The persons in 
                possession, care and control of such lots or tombs 
                may erect a monument and make other permanent 
                Improvements thereon. The surviving spouse shall 
                have a right of permanent interment for his own body 
                in such lot or tomb, but it may be removed therefrom 
                to some other family lot or tomb with the consent of 
                the heirs. If two or more persons are entitled to the 
                possession, care and control of such lot or tomb, they 
                shall designate in writing to the clerk of the 
                corporation, or if it is a tomb or lot in a public 
                cemetery, to the board of cemetery commissioners, if any,
                or to the town clerk, which of their number shall 
                represent the lot and in default of such designation, 
                the board of trustees or directors of the corporation 
                the board of cemetery commissioners, if any, or the 
                board of health if such lots or tombs are in public 
                cemeteries in towns, shall enter of record which of 
                said persons shall represent the lot during such default. 
                The surviving spouse may release his right in such lot, 
                but no conveyance or devise by any other person shall 
                deprive him of such right.
            </div>
            <div className="m-scroll-fix"></div>
            <div className="m-scroll-fix"></div>
        </div>
    )
}