import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'

import DeleteLotWarning from './DeleteLotWarning'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function DeleteLotButton() {

    let {
        showDeleteLotWarning,
        savingEdits,
        changingLotSold,
        editing
    } = useContext(MobileAdminContext)

    const deleteButtonStyle = {
        minHeight: '0',
        padding: '0',
        width: '100%',
        textTransform: 'none',
        backgroundColor: 'red',
        marginTop: '13px',
        marginBottom: '12px'
    }

    let disabledCheck
    if (changingLotSold || savingEdits || editing) {
        disabledCheck = true
    } else {
        disabledCheck = false
    }
    return (
        <div className='m-ac-details-delete-lot-container'>
            <DeleteLotWarning />
            <Button
                disabled={disabledCheck}
                style={deleteButtonStyle}
                onClick={() => showDeleteLotWarning() }
            >
                <div className='m-ac-delete-lot-style'>
                    Delete Lot
                </div>
            </Button>
        </div>
    )
}