import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

import Button from '@material-ui/core/Button'

export default function DeleteAllButton() {

    let {
        showDeleteMessagesWarning,
        messages
    } = useContext(MobileAdminContext)

    const deleteAllstyle = {
        padding: '0',
        height: '7vh',
        width: '90vw',
        textTransform: 'none',
        marginTop: '1vh',
        marginBottom: '1vh'
    }
    if (messages.length > 0) {
        return(
            <Button
                style={deleteAllstyle}
                onClick={() => showDeleteMessagesWarning()}>
                <div className='t-ac-delete-messages-button'>
                    Delete All Messages
                </div>
            </Button>
        )
    } else {
        return (
            <div>
                
            </div>
        )
    }
}