import React, {useContext} from 'react'

import WhiteLogo from './../../../StyleNew/logoWhite.png'

import CircularProgress from '@material-ui/core/CircularProgress'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

import DirectorsSharon from './DirectorsSharon'
import DirectorsInterfaith from './DirectorsInterfaith'
import DirectorsGarden from './DirectorsGarden'
import DirectorsVeterans from './DirectorsVeterans'

import {DirectorsContext} from './../../Contexts/DirectorsContext'

export default function DirectorsMobile() {
    let {
        loadingLots,
        mobileList,
        setMobileList
    } = useContext(DirectorsContext)

    const loadingStyle = {
        color: 'white'
    }
    const groupStyle = {
        width: '98vw',
        marginTop: '3px',
        marginBottom: '5px',
        height: '90%'
    }
    const buttonStyle = {
        padding: '0',
        width: '25%',
        backgroundColor: 'rgba(41, 87, 105, 0.849)',
        height: '100%'
    }
    let directorsHeader, loadingFeedback

    if (mobileList === 'Sharon') {
        directorsHeader = (
            <div className="m-directors-location-header">
                Sharon Inventory List
            </div>
        )
        // directorsHeader = (
        //     <div className="m-directors-location-header">
        //         <div className="m-directors-header-section">
        //             Section
        //         </div>
        //         <div className="m-directors-header-number">
        //             #
        //         </div>
        //         <div className="m-directors-header-number">
        //             Spaces
        //         </div>
        //         <div className="m-directors-header-price">
        //             Price
        //         </div>
        //     </div>
        // )
    } else if (mobileList === 'Interfaith') {
        directorsHeader = (
            <div className="m-directors-location-header">
                Sharon Interfaith Inventory List
            </div>
        )
        // directorsHeader = 'Sharon Interfaith Inventory List'
        // directorsHeader = (
        //     <div className="m-directors-location-header">
        //         <div className="m-directors-header-item">
        //             Section
        //         </div>
        //         <div className="m-directors-header-item">
        //             Number
        //         </div>
        //         <div className="m-directors-header-item">
        //             Spaces
        //         </div>
        //         <div className="m-directors-header-item">
        //             Price
        //         </div>
        //     </div>
        // )
    } else if (mobileList === 'Garden') {
        directorsHeader = (
            <div className="m-directors-location-header">
                Knollwood Garden Inventory List
            </div>
        )
        // directorsHeader = 'Knollwood Garden Inventory List'
        // directorsHeader = (
        //     <div className="m-directors-location-header">
        //         <div className="m-directors-header-item">
        //             Name
        //         </div>
        //         <div className="m-directors-header-item">
        //             Number
        //         </div>
        //         <div className="m-directors-header-item">
        //             Spaces
        //         </div>
        //         <div className="m-directors-header-item">
        //             Price
        //         </div>
        //     </div>
        // )
    } else if (mobileList === 'Veterans') { 
        directorsHeader = (
            <div className="m-directors-location-header">
                Knollwood Veterans Inventory List
            </div>
        )
        // directorsHeader = 'Knollwood Veterans Inventory List'
        // directorsHeader = (
        //     <div className="m-directors-location-header">
        //         <div className="m-directors-header-item">
        //             Name
        //         </div>
        //         <div className="m-directors-header-item">
        //             Number
        //         </div>
        //         <div className="m-directors-header-item">
        //             Spaces
        //         </div>
        //         <div className="m-directors-header-item">
        //             Price
        //         </div>
        //     </div>
        // )
    }

    if (loadingLots) {
        loadingFeedback = ( 
            <div className='m-directors-loading-center'>
                <CircularProgress style={loadingStyle} size={150}/> 
                <img src={WhiteLogo} alt='logo' className='m-inventory-logo'/>
            </div>
        )
    } else {
        if (mobileList === 'Sharon') {
            loadingFeedback = <DirectorsSharon />
        } else if (mobileList === 'Interfaith') {
            loadingFeedback = <DirectorsInterfaith />
        } else if (mobileList === 'Garden') {
            loadingFeedback = <DirectorsGarden />
        } else if (mobileList === 'Veterans') { 
            loadingFeedback = <DirectorsVeterans />
        }
    }
    return (
        <div className='m-directors-container'>
            <div className="m-directors-options">
            <ButtonGroup 
                    style={groupStyle}
                    variant='contained' 
                    color='default' 
                    aria-label='contained primary button group'>
                    <Button
                        disabled={loadingLots}
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { setMobileList('Sharon') }}>
                            <div className='m-directors-options-item'>
                                Sharon
                            </div>
                    </Button>
                    <Button
                        disabled={loadingLots}
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { setMobileList('Interfaith') }}>
                            <div className='m-directors-options-item'>
                                Interfaith 
                            </div>
                    </Button>
                    <Button
                        disabled={loadingLots}
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { setMobileList('Garden') }}>
                            <div className='m-directors-options-item'>
                                Garden
                            </div>
                    </Button>
                    <Button
                        disabled={loadingLots}
                        style={buttonStyle}
                        variant='contained'
                        color='primary'
                        onClick={() => { setMobileList('Veterans') }}>
                            <div className='m-directors-options-item'>
                               Veterans
                            </div>
                    </Button>
                </ButtonGroup>
            </div>
            <div className='m-directors-header'>
                Funeral Directors
            </div>
            {directorsHeader}
            <div className="m-directors-content">
                {loadingFeedback}
            </div>
            <div className="m-scroll-fix2">
                *The Lot Broker is not affiliated with Sharon or Knollwood Memorial Parks
            </div>
        </div>
    )
}