import React, { useContext } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import { AdminContext } from './../../../../Contexts/AdminContext'

import LotCardHeader from './LotCard/LotCardHeader'
import LotCardBody from './LotCard/LotCardBody'
import LotCardFooter from './LotCard/LotCardFooter'

// import firebase from 'firebase/app'
// import 'firebase/firestore'

export default function IndividualLotCard() {
    let { lotCreated, viewingIndividualLot } = useContext(AdminContext)

    return (
        <Backdrop 
            style={{ zIndex: 1000000, color: '#fff'}}
            open={(lotCreated || viewingIndividualLot)}>
                <div className='admin-final-d-lot-card-container'>
                    <LotCardHeader />
                    <LotCardBody />
                    <LotCardFooter />
                </div>
        </Backdrop>
    )
}