import React from 'react'

import SharonList from './LocationLists/SharonList'
import InterfaithList from './LocationLists/InterfaithList'

export default function DirectorsSharon() {

    return (
        <div className='u-d-directors-location-container'>
            <SharonList />
            <InterfaithList />
        </div>
    )
}