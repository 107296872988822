import React, { useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import { AdminContext } from './../../../../../Contexts/AdminContext'
// import InputAdornment from '@material-ui/core/InputAdornment'

export default function LotCardLotNumber() {
    let {
        price,
        editingCard,
        handleChange
    } = useContext(AdminContext)

    let availabilityCheck
    editingCard
        ? availabilityCheck = true
        : availabilityCheck = false
    return (
        <TextField 
            // InputProps={{
            //     startAdornment: <InputAdornment position="start">$</InputAdornment>
            // }}
            // style={{width: '89%', height: 50}}
            // fullWidth
            disabled={(!availabilityCheck)}
            color='secondary'
            name='price'
            type='number'
            value={price}
            label='Price'
            variant='outlined'
            onChange={event => {
                handleChange(event)
            }}/>
    )

}