import React from 'react'

import AdminMobile from './AdminMobile/AdminMobile'
import AdminTablet from './AdminTablet/AdminTablet'
import AdminDesktop from './AdminDesktop/AdminDesktop'

import {
    isMobileOnly,
    isTablet,
    isIPad13
} from 'react-device-detect'

export default function Admin() {
    if (isMobileOnly) {
        return ( <AdminMobile /> )
    } else if (isTablet || isIPad13 ) {
        return ( <AdminTablet /> )
    } else {
        return ( <AdminDesktop />)
    }
}