import React from 'react'
import { isMobileOnly, isTablet, isIPad13 } from 'react-device-detect'

import HomeDesktop from './Desktop/HomeDesktop'
import HomeMobile from './Mobile/HomeMobile'
import HomeTablet from './Tablet/HomeTablet'


export default function Home() {
    if (isMobileOnly) {
        return ( <HomeMobile /> )
    } else if (isTablet || isIPad13) {
        return ( <HomeTablet />)
    } else {
        return ( <HomeDesktop /> )
    }
}