import React, {useContext} from 'react'
import {InventoryContext} from './../../../Contexts/InventoryContext'

import SharonFilter from './SharonFilter'
import KnollwoodFilter from './KnollwoodFilter'

export default function FilterMenu() {
    let {
        location
    } = useContext(InventoryContext)

    let filterContent

    location === 'Sharon' || location === 'Interfaith'
        ? filterContent = ( <SharonFilter />)
        : location === 'Knollwood'
            ? filterContent = ( <KnollwoodFilter />)
            : filterContent = ( '' )
    return (
        <div className='d-inventory-second-filter-container'>
            {filterContent}
        </div>
    )
}