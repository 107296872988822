import React from 'react'

export default function NewsCard(props) {
    let {
        title,
        content,
        date
    } = props

    return (
        <div className='t-news-card-container'>
            <div className="t-news-card-date">
                {date}
            </div>
            <div className="t-news-card-title">
                {title}
            </div>
            <div className="t-news-card-content">
                {content}
            </div>
        </div>
    )
}