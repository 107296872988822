import React from 'react'

export default class GalleryCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            url: this.props.imageUrl,
            header: this.props.header,
            body: this.props.body
        }
    }

    render() {
        const { header, body, url } = this.state
        return (
            <div className="home-d-gallery-container" 
            style={{backgroundImage: `url(${url})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            }}
            
            >
                <div className="d-gallery-content">
                    <div className="d-gallery-header-container">
                        <div className="d-gallery-header">
                            {header}
                        </div>
                        <div className='d-gallery-gradient-top'>
                            
                        </div>
                    </div>
                    <div className='d-gallery-gradient-container'>
                        <div className='d-gallery-gradient-bottom'>

                        </div>
                        <div className='d-gallery-caption-container'>
                            <div className="d-gallery-caption">
                                {body}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}