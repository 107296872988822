import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
// import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone';
import { AdminContext } from '../../../../../Contexts/AdminContext'

import LotCardSoldButton from './LotCardSoldButton'
export default function LotCardHeader() {
    let {
        closeLotCard,
        clearNewLotAdd,
        updatingCard,
        editingCard,
        currentlySelling,
        lotIsNotSold
        } = useContext(AdminContext)

    let closeButtonCheck
    updatingCard || editingCard || currentlySelling
        ? closeButtonCheck = true
        : closeButtonCheck = false

    return (
        <div className="admin-final-d-lot-card-header-contaner">
            <div className="admin-final-d-lot-card-header-item">
                View Lot Details
            </div>
                <LotCardSoldButton />
                
            <div className="admin-final-d-lot-card-header-item2">
                <Button
                    disabled={closeButtonCheck}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        closeLotCard()
                        clearNewLotAdd()
                        lotIsNotSold()
                    }}>
                    Close
                </Button>
            </div>
        </div>
    )
}
