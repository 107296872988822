import React, {useContext} from 'react'

import TabletCard from './TabletCard'

import {DirectorsContext} from './../../Contexts/DirectorsContext'

export default function DirectorsInterfaith() {

    let {
        interfaithList
    } = useContext(DirectorsContext)

    return interfaithList.map(lot => {
        return (
            <TabletCard 
                key={lot.id}
                location={'Interfaith'}
                lotNumber={lot.lotNumber}
                price={lot.price}
                section={lot.section}
                spaces={lot.spaces}
            />
        )
    })
}