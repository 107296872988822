import React, { useContext } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

// import ViewLotDetails from './../ViewLotDetails/ViewLotDetails'
import LotListHeader from './LotListHeader'
import WhiteLogo from './../../../../../../StyleNew/logoWhite.png'
import LotCard from './LotCard'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function Lots() {

    let {
        filterLocation,
        loadingLotList,
        loadedLots,
        lotList
    }= useContext(MobileAdminContext)

    const spinnerStyle = {
        color: 'white',
        position: 'relative'
    }

    if (filterLocation && loadedLots) {
        return (
            <div className='m-ac-lot-list-container'>
                <LotListHeader />
                <div className="m-ac-lot-list-scroll-container">
                    {lotList.map(lot => {
                        return (
                            <LotCard 
                                key={lot.lotId}
                                section={lot.section}
                                lotName={lot.lotName}
                                lotNumber={lot.lotNumber}
                                location={lot.location}
                                otherLocation={lot.otherLocation}
                                id={lot.lotId}
                            />
                        )
                    })}
                    <div className="m-scroll-fix">

                    </div>
                </div>
            </div>
        )
    } else if (loadingLotList) {
        return (
            <div className='m-ac-lot-list-loading'>
                <CircularProgress style={spinnerStyle} size={140}/>
                <img src={WhiteLogo} alt='logo' className='m-inventory-logo'/>
            </div>
        )
    } else {
        return (
            <div className='m-ac-lot-list-choose'>
                Choose a location to get started
            </div>
        )
    }
}