import React, {createContext} from 'react'

import firebase from 'firebase/app'
import 'firebase/firestore'

export const MobileAdminContext = createContext()

export class MobileAdminContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            adminView: null,
            setAdminView: (adminView) => {
                this.state.exitDetails()
                this.setState({
                    adminView,
                    adminMenu: false,
                    
                })
            },
            anchor: null,
            adminMenu: false,
            setAdminAnchor: (anchor) => {
                this.setState({ 
                    anchor, 
                    adminMenu: true
                })
            },
            clearAnchors: () => {
                this.setState({
                    anchor: null,
                    interfaithMenu: false,
                    adminMenu: false,
                    locationMenu: false,
                    nameMenu: false,
                    filterMenu: false,
                    detailsNameList: false,
                    detailsLocationMenu: false
                })
            },

            // Add new lot

            addingLot: false,
            location: null,
            setLocation: (location) => {
                this.setState({
                    location,
                    locationMenu: false,
                    lotName: null,
                    section: null,
                    lotNumber: null,
                    spaces: null,
                    price: null,
                    otherLocation: null,
                    showingAddComment: false
                })
            },
            locationMenu: false,
            setLocationAnchor: (anchor) => {
                this.setState({ 
                    anchor, 
                    locationMenu: true
                })
            },
            lotName: null,
            nameMenu: false,
            setLotName: (lotName) => {
                this.setState({
                    lotName,
                    nameMenu: false
                })
            },
            setNameAnchor: (anchor) => {
                this.setState({
                    anchor,
                    nameMenu: true
                })
            },
            handleChange: (event) => {
                this.setState({
                    [event.target.name]: event.target.value
                })
            },
            comments: null,
            section: null,
            lotNumber: null,
            spaces: null,
            price: null,
            otherLocation: null,
            showingAddComment: false,
            showAddComment: () => {
                this.setState({
                    showingAddComment: true
                })
            },
            interfaithMenu: false,
            setInterfaithAnchor: (anchor) => {
                this.setState({
                    anchor,
                    interfaithMenu: true
                })
            }, 
            setInterfaithSection: (interfaithSection) => {
                this.setState({
                    section: interfaithSection,
                    interfaithMenu: false
                })
            },
            clearNewLot: () => {
                this.setState({
                    lotName: null,
                    location: null,
                    section: null,
                    lotNumber: null,
                    spaces: null,
                    price: null,
                    otherLocation: null,
                    showingAddComment: false,
                    comments: null
                })
            },
            loading: false,
            startAddingLot: () => {
                this.setState({
                    loading: true,
                    addingLot: true
                })
            },
            successfullyAddedLot: () => {
                this.setState({
                    loading: false,
                    adminView: 'Lots',
                    addingLot: false,
                    viewingIndividualLot: true,
                    location: null
                })
            },

            // Lot list
            filterLocation: null,
            setFilterLocation: (filterLocation) => {
                this.setState({
                    filterLocation,
                    filterMenu: false,
                    loadingLotList: true,
                    loadedLots: false,
                    filterName: null,
                    filterSection: null
                })
            },
            filterMenu: false,
            setFilterAnchor: (anchor) => {
                this.setState({
                    anchor,
                    filterMenu: true
                })
            },
            loadingLotList: false,
            loadedLots: false,
            finishLoadingLots: () => {
                this.setState({
                    loadingLotList: false,
                    loadedLots: true
                })
            },
            lotList: [],
            addToLotList: (lot) => {
                this.setState({
                    lotList: [...this.state.lotList, lot]
                })
            },
            clearLotList: () => {
                this.setState({
                    lotList: []
                })
            },
            filterName: null,
            setFilterName: (filterName) => {
                this.setState({
                    filterName,
                    nameMenu: false,
                    loadingLotList: true,
                    loadedLots: false
                })
            },
            filterSection: null,
            setFilterSection: (filterSection) => {
                this.setState({
                    filterSection,
                    loadingLotList: true,
                    loadedLots: false
                })
            },
            startFilterSection: () => {
                this.setState({
                    loadingLotList: true,
                    loadedLots: false
                })
            },

            // individual lot
            viewingIndividualLot: false,
            loadingLot: false,
            loadingIndividualLot: () => {
                this.setState({
                    loadingLot: true,
                    viewingIndividualLot: true
                })
            },
            loadedIndividualLot: () => {
                this.setState({
                    loadingLot: false
                })
            },
            closeIndividualLot: () => {
                this.setState({
                    viewingIndividualLot: false
                })
            },
            detailsLocation: null,
            setDetailsLocation: (detailsLocation) => {
                this.setState({
                    detailsLocation,
                    locationMenu: false,
                    detailsLocationMenu: false
                })
            },
            detailsSection: null,
            setDetailsSection: (detailsSection) => {
                this.setState({
                    detailsSection,
                    interfaithMenu: false
                })
            },
            detailsNumber: null,
            setDetailsNumber: (detailsNumber) => {
                this.setState({
                    detailsNumber
                })
            },
            detailsName: null,
            setDetailsName: (detailsName) => {
                this.setState({
                    detailsName,
                    detailsNameList: false
                })
            },
            detailsSpaces: null,
            setDetailsSpaces: (detailsSpaces) => {
                this.setState({
                    detailsSpaces
                })
            },
            detailsPrice: null,
            setDetailsPrice: (detailsPrice) => {
                this.setState({
                    detailsPrice
                })
            },
            detailsOtherLocation: null,
            setDetailsOtherLocation: (detailsOtherLocation) => {
                this.setState({
                    detailsOtherLocation
                })
            },
            detailsComments: null,
            setDetailsComments: (detailsComments) => {
                this.setState({
                    detailsComments
                })
            },
            detailsGarden: null,
            setDetailsGarden: (detailsGarden) => {
                this.setState({
                    detailsGarden
                })
            },
            detailsId: null,
            setDetailsId: (detailsId) => {
                this.setState({
                    detailsId
                })
            },
            // setDoc
            detailsSold: false,
            setDetailsSold: (detailsSold) => {
                this.setState({
                    detailsSold
                })
            },
            detailsPriority: null,
            setDetailsPriority: (detailsPriority) => {
                this.setState({
                    detailsPriority
                })
            },
            detailsImageUrl: null,
            setDetailsImageUrl: (detailsImageUrl) => {
                this.setState({
                    detailsImageUrl
                })
            },
            detailsImageNumber: null,
            setDetailsImageNumber: (detailsImageNumber) => {
                this.setState({
                    detailsImageNumber
                })
            },
            detailsLastEdit: null,
            setDetailsLastEdit: (detailsLastEdit) => {
                this.setState({
                    detailsLastEdit
                })
            },
            detailsCreatedAt: null,
            setDetailsCreatedAt: (detailsCreatedAt) => {
                this.setState({
                    detailsCreatedAt
                })
            },
            detailsOriginalPath: null,
            setDetailsOriginalPath: (detailsOriginalPath) => {
                this.setState({
                    detailsOriginalPath
                })
            },
            exitDetails: () => {
                this.setState({
                    editing: false,
                    viewingIndividualLot: false,
                    detailsLastEdit: null,
                    detailsLocation: null,
                    detailsGarden: null,
                    detailsImageUrl: null,
                    detailsImageNumber: null,
                    detailsOriginalPath: null,
                    detailsSection: null,
                    detailsNumber: null,
                    detailsName: null,
                    detailsSpaces: null,
                    detailsPrice: null,
                    detailsOtherLocation: null,
                    detailsComments: null,
                    detailsId: null,
                    detailsSold: false
                })
            },
            showingDeletePictureWarning: false,
            showDeletePictureWarning: () => {
                this.setState({
                    showingDeletePictureWarning: true
                })
            },
            closeDeletePictureWarning: () => {
                this.setState({
                    showingDeletePictureWarning: false
                })
            },
            uploadingImage: false,
            uploadImage: () => {
                this.setState({
                    uploadingImage: true
                })
            },
            uploadedImage: () => {
                this.setState({
                    uploadingImage: false
                })
            },
            deletingImage: false,
            deleteImage: () => {
                this.setState({
                    deletingImage: true
                })
            },
            deletedImage: () => {
                this.setState({
                    deletingImage: false,
                    detailsImageUrl: null,
                    detailsImageNumber: null,
                    detailsOriginalPath: null,
                    showingDeletePictureWarning: false
                })
            },
            editing: false,
            startEditing: () => {
                this.setState({
                    editing: true,
                    // save original values here
                })
            }, 
            cancelEditing: () => {
                this.setState({
                    editing: false
                })
            },

            saveEdits: () => {
                this.setState({

                    // saves changes to original values
                    // clear temporary values
                })
            },

            temporaryLocation: null,
            setTemporaryLocation: (temporaryLocation) => {
                this.setState({
                    temporaryLocation
                })
            },
            temporarySection: null,
            setTemporarySection: (temporarySection) => {
                this.setState({
                    temporarySection
                })
            },
            temporaryNumber: null,
            setTemporaryNumber: (temporaryNumber) => {
                this.setState({
                    temporaryNumber
                })
            },
            temporaryName: null,
            setTemporaryName: (temporaryName) => {
                this.setState({
                    temporaryName
                })
            },
            temporarySpaces: null,
            setTemporarySpaces: (temporarySpaces) => {
                this.setState({
                    temporarySpaces
                })
            },
            temporaryPrice: null,
            setTemporaryPrice: (temporaryPrice) => {
                this.setState({
                    temporaryPrice
                })
            },
            temporaryOtherLocation: null,
            setTemporaryOtherLocation: (temporaryOtherLocation) => {
                this.setState({
                    temporaryOtherLocation
                })
            },
            temporaryComments: null,
            setTemporaryComments: (temporaryComments) => {
                this.setState({
                    temporaryComments
                })
            },
            revertValues: () => {
                this.setState({
                    detailsLocation: this.state.temporaryLocation,
                    detailsSection: this.state.temporarySection,
                    detailsName: this.state.temporaryName,
                    detailsNumber: this.state.temporaryNumber,
                    detailsSpaces: this.state.temporarySpaces,
                    detailsPrice: this.state.temporaryPrice,
                    detailsComments: this.state.temporaryComments,
                    detailsOtherLocation: this.state.temporaryOtherLocation,
                    lastEdit: null
                })
            },
            clearTemporaryValues: () => {
                this.setState({
                    temporaryLocation: null,
                    temporarySection: null,
                    temporaryNumber: null,
                    temporaryName: null,
                    temporarySpaces: null,
                    temporaryPrice: null,
                    temporaryComments: null,
                    temporaryOtherLocation: null
                })
            },
            detailsNameList: false,
            setDetailsNameAnchor: (anchor) => {
                this.setState({
                    anchor,
                    detailsNameList: true
                })
            },
            showingDeleteLotWarning: false,
            showDeleteLotWarning: () => {
                this.setState({
                    showingDeleteLotWarning: true
                })
            },
            closeDeleteLotWarning: () => {
                this.setState({
                    showingDeleteLotWarning: false
                })
            },
            deletingLot: false,
            initializeDelete: () => {
                this.setState({
                    deletingLot: true
                })
            },
            finalizeDelete: () => {
                this.state.exitDetails()
                this.setState({
                    deletingLot: false,
                    showingDeleteLotWarning: false,
                    lotList: [],
                    loadedLots: false,
                    filterLocation: null,
                })
            },
            detailsLocationMenu: false,
            setDetailsLocationAnchor: (anchor) => {
                this.setState({
                    anchor,
                    detailsLocationMenu: true
                })
            },
            changingLotSold: false,
            changeLotSold: () => {

                this.setState({
                    changingLotSold: true,
                    editing: false
                })
            },
            finishChangingLotSold: () => {
                this.setState({
                    changingLotSold: false
                })
            },
            toggleSold: () => {
                this.setState({
                    detailsSold: !this.state.detailsSold
                })
            },
            // lastEdit
            // update

            savingEdits: false,
            startSavingEdits: () => {
                this.setState({
                    savingEdits: true
                })
            },
            finishSavingEdits: () => {
                this.state.clearTemporaryValues()
                this.setState({
                    savingEdits: false,
                    editing: false
                })
            },
            // lastEdit

            // messages
            messages: [],
            addToMessages: (message) => {
                this.setState({
                    messages: [...this.state.messages, message]
                })
            },
            clearMessages: () => {
                this.setState({
                    messages: []
                })
            },
            messagesLoaded: false,
            finishLoadingMessages: () => {
                this.setState({
                    messagesLoaded: true
                })
            },
            showingDeleteMessagesWarning: false,
            showDeleteMessagesWarning: () => {
                this.setState({
                    showingDeleteMessagesWarning: true
                })
            },
            hideDeleteMessagesWarning: () => {
                this.setState({
                    showingDeleteMessagesWarning: false
                })
            },
            deletingMessages: false,
            deleteMessages: () => {
                this.setState({
                    deletingMessages: true
                })
            },
            deletedMessages: () => {
                this.setState({
                    deletingMessages: false,
                    messages: [],
                    numberOfMessages: 0,
                    showingDeleteMessagesWarning: false
                })
            },
            numberOfMessages: 0,
            increaseNumberOfMessages: () => {
                this.setState({
                    numberOfMessages: this.state.numberOfMessages + 1
                })
            },

            // Testimonials

            testimonials: [],
            addToTestimonials: (testimonial) => {
                this.setState({
                    testimonials: [...this.state.testimonials, testimonial]
                })
            },
            clearTestimonials: () => {
                this.setState({
                    testimonials: []
                })
            },
            numberOfTestimonials: 0,
            increaseNumberOfTestimonials: () => {
                this.setState({
                    numberOfTestimonials: this.state.numberOfTestimonials + 1
                })
            },
            testimonialsLoaded: false,
            finishLoadingTestimonials: () => {
                this.setState({
                    testimonialsLoaded: true
                })
            },
            testimonalsView: 'Pending',
            setTestimonialsView: (testimonalsView) => {
                this.setState({
                    testimonalsView
                })
            },
            loadingTestimonials: false,
            loadTestimonials: () => {
                this.setState({
                    testimonials: [],
                    loadingTestimonials: true
                })
            },
            loadedTestimonials: () => {
                this.setState({
                    loadingTestimonials: false
                })
            },
            finalPublishCheck: false,
            publishCheckTrue: () => {
                this.setState({
                    finalPublishCheck: true
                })
            },
            publishCheckFalse: () => {
                this.setState({
                    finalPublishCheck: false
                })
            },

            // News

            newsLoaded: false,
            finishLoadingNews: () => {
                this.setState({
                    newsLoaded: true
                })
            },
            newsArticles: [],
            addToNews: (news) => {
                this.setState({
                    newsArticles: [...this.state.newsArticles, news]
                })
            }, 
            clearNews: () => {
                this.setState({
                    newsArticles: [],
                    newsLoaded: false
                })
            },
            addingNews: false,
            addNews: () => {
                this.setState({
                    addingNews: true
                })
            },
            cancelAddingNews: () => {
                this.setState({
                    addingNews: false,
                    newsTitle: null,
                    newsContent: null
                })
            },
            newsTitle: null,
            newsContent: null,
            submittingNews: false,
            submitNews: () => {
                this.setState({
                    submittingNews: true
                })
            },
            finishSubmittingNews: () => {
                this.setState({
                    submittingNews: false,
                    addingNews: false,
                    newsTitle: null,
                    newsContent: null
                })
            },

            checkContext: () => {
                console.log('context:', this.state)
            },

            finishedCall: null,
            setFinishedCall: () => {
                this.setState({ 
                    finishedCall: true
                })
                setTimeout(() => {
                    this.setState({
                        finishedCall: null
                    })
                }, 10000)
            },
            callTimeout: () => {
                setTimeout(() => {
                    if (this.state.finishedCall) {
                        this.setState({
                            finishedCall: null
                        })
                    } else {
                        // if the call is not finished
                        // change variables back to not disabled
                        // and names to whats appropriate

                        this.setState({
                            changingLotSold: false
                        })
                    }

                }, 10000)
            }


        }
    }

    // context

    componentDidMount() {
        firebase.firestore().collection('Messages')
        .get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                let message = {
                    message: doc.data().body,
                    date: doc.data().date,
                    id: doc.id
                }
                this.state.increaseNumberOfMessages()
                this.state.addToMessages(message)
            })
        })
        .then(() => {
            firebase.firestore().collection('TestimonialsPending')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let testimonial = {
                        body: doc.data().message,
                        date: doc.data().dateOfSale,
                        name: doc.data().name,
                        id: doc.id,
                        published: false
                    }
                    this.state.increaseNumberOfTestimonials()
                    this.state.addToTestimonials(testimonial)
                })
            })
            .then(() => {
                this.state.finishLoadingMessages()
                this.state.finishLoadingTestimonials()
            })
            .then(() => {
                firebase.firestore().collection('News').orderBy('date', 'desc')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let news = {
                            date: doc.data().date,
                            content: doc.data().content,
                            title: doc.data().title,
                            id: doc.id
                        }
                        this.state.addToNews(news)
                    })
                })
                .then(() => {
                    this.state.finishLoadingNews()
                })
            })
            .catch(err => {
                console.error(err.code)
            })
        })
        .catch(err => {
            console.error(err.code)
        })
    }
    render() {
        return (
            <MobileAdminContext.Provider value={this.state}>
                {this.props.children}
            </MobileAdminContext.Provider>
        )
    }

}