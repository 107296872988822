import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {InventoryContext} from './../../../../Contexts/InventoryContext'

export default function Card(props) {

    let {
        location,
        mobileLoadDetails,
        mobileLoadedDetails,

        setDetailsSection,
        setDetailsNumber,
        setDetailsName,
        setDetailsPrice,
        setDetailsComments,
        setDetailsOtherLocation,
        setDetailsSpaces,
        setDetailsImageUrl,
        setDetailsGarden
    } = useContext(InventoryContext)

    let {
        section,
        lotName,
        lotNumber,
        spaces,
        price,
        otherLocation,
        lotLocation,
        id
    } = props

    const cardStyle = {
        width: '95vw',
        backgroundColor: 'rgba(150, 208, 231, 0.849)',
        padding: '0',
        borderRadius: '10px',
        marginTop: '5px',
        flexShrink: '0',
        textTransform: 'none'
    }

    function handleMobileDetails() {
        mobileLoadDetails()

        firebase.firestore().collection(location).doc(id)
        .get()
        .then(doc => {
            setDetailsSection(doc.data().section)
            setDetailsNumber(doc.data().lotNumber)
            setDetailsName(doc.data().lotName)
            setDetailsPrice(doc.data().price)
            setDetailsComments(doc.data().comments)
            setDetailsOtherLocation(doc.data().otherLocation)
            setDetailsSpaces(doc.data().spaces)
            setDetailsImageUrl(doc.data().imageUrl)
            setDetailsGarden(doc.data().garden)
        })
        .then(() => {
            setTimeout(() => {
                mobileLoadedDetails()
            }, 400)
        })
        .catch(err => {
            console.error(err.code)
        })
    }


    let interfaithFix

    if (location === 'Sharon') {
        if (section === '4-ORCHARD') {
            interfaithFix = (
                <div>
                    4
                    <br/>
                    <div className='m-inventory-section-card-fix'>
                        Orchard
                    </div>
                </div>
            )
        } else if (section === '26A-OVAL') {
            interfaithFix = (
                <div>
                    26A
                    <br/>
                    <div className='m-inventory-section-card-fix'>
                        Oval
                    </div>
                </div>
            )
        } else {
            interfaithFix = section
        }
    } else if (location === 'Interfaith') {
        if (section === '1') {
            interfaithFix = (
                <div className='m-inventory-beersheba-fix'>
                    <div className="m-inventory-beersheba-header">
                        Beersheba 
                        I
                    </div>
                </div>
            )
        } else if (section === '2') {
            interfaithFix = (
                <div className='m-inventory-beersheba-fix'>
                    <div className="m-inventory-beersheba-header">
                        Beersheba 
                        II
                    </div>
                </div>
            )
        } else if (section === '3') {
            interfaithFix = (
                <div className='m-inventory-beersheba-fix'>
                    <div className="m-inventory-beersheba-header">
                        Beersheba 
                        III
                    </div>
                </div>
            )
        }
    }


    if (location === 'Sharon' || location === 'Interfaith') {
        return (
            <Button
                style={cardStyle}
                onClick={() => handleMobileDetails()}>
                <div className='m-inventory-card-container'>
                    <div className="m-inventory-card-top">
                        <div className="m-inventory-card-top-section">
                            Section
                        </div>
                        <div className="m-inventory-card-top-number">
                            Number
                        </div>
                        <div className="m-inventory-card-top-spaces">
                            Spaces
                        </div>
                    </div>
                    <div className="m-inventory-card-bottom">
                        <div className="m-inventory-card-bottom-section">
                            {interfaithFix}
                        </div>
                        <div className="m-inventory-card-bottom-number">
                            {lotNumber}
                        </div>
                        <div className="m-inventory-card-bottom-spaces">
                            {spaces}
                        </div>
                    </div>
                </div>
            </Button>
            
        )
    } else if (location === 'Knollwood') {
        let lotnamefix
        if (lotName === 'WestChapel') {
            lotnamefix = 'West Chapel'
        } else {
            lotnamefix = lotName
        }
        return (
            <Button
                style={cardStyle}
                onClick={() => handleMobileDetails()}>
            <div className='m-inventory-card-container'>
                <div className="m-inventory-card-top">
                    <div className="m-inventory-card-top-name">
                        Name
                    </div>
                    <div className="m-inventory-card-top-number">
                        Number
                    </div>
                    <div className="m-inventory-card-top-spaces">
                        Spaces
                    </div>
                </div>
                <div className="m-inventory-card-bottom">
                    <div className="m-inventory-card-bottom-name">
                        {lotnamefix}
                    </div>
                    <div className="m-inventory-card-bottom-number">
                        {lotNumber}
                    </div>
                    <div className="m-inventory-card-bottom-spaces">
                        {spaces}
                    </div>
                </div>
            </div>
            </Button>
        )
    } else if (location === 'Estate' || location === 'Other') {
        let priceCheck
        if (price) {
            priceCheck = `$${price}`
        } else {
            priceCheck = '-'
        }
        return (
            <Button
                style={cardStyle}
                onClick={() => handleMobileDetails()}>
                <div className='m-inventory-card-container'>
                    <div className="m-inventory-estate-top">
                        <div className="m-inventory-estate-top-location">
                            Location
                        </div>
                        <div className="m-inventory-estate-top-price">
                            Price
                        </div>
                    </div>
                    <div className="m-inventory-estate-bottom">
                        <div className="m-inventory-estate-bottom-location">
                            {otherLocation}
                        </div>
                        <div className="m-inventory-estate-bottom-price">
                            {priceCheck}
                        </div>
                    </div>
                </div>
            </Button>
        )
    } else if (location === 'sold') {
        let sectionCheck, nameCheck, locationCheck

    lotName
        ? nameCheck = lotName
        : nameCheck = ''

    section
        ? sectionCheck = section
        : sectionCheck = ''  
    
    lotLocation === 'Other' || lotLocation === 'Estate'
        ? locationCheck = otherLocation
        : lotLocation === 'Sharon' || lotLocation === 'Interfaith' || lotLocation === 'Knollwood' || lotLocation === 'Shara'
            ? locationCheck = lotLocation
            : locationCheck = 'error'
        if (locationCheck === 'Interfaith') {
            if (section === '1') {
                sectionCheck = 'Beersheba'
            } else if (section === '2') {
                sectionCheck = 'Beersheba II'
            } else if (section === '3') {
                sectionCheck = 'Beersheba III'
            }
            }
        return (
            <Button
                disabled={true}
                style={cardStyle}
                >
                <div className='m-inventory-card-container'>
                    <div className="m-inventory-sold-top">
                        <div className="m-inventory-sold-top-location">
                            Location
                        </div>
                        <div className="m-inventory-sold-top-section">
                            Section
                        </div>
                        <div className="m-inventory-sold-top-number">
                            Number
                        </div>
                        <div className="m-inventory-sold-top-spaces">
                            Spaces
                        </div>
                    </div>
                    <div className="m-inventory-sold-bottom">
                        <div className="m-inventory-sold-bottom-location">
                            {locationCheck}
                        </div>
                        <div className="m-inventory-sold-bottom-section">
                            {sectionCheck}{nameCheck}
                        </div>
                        <div className="m-inventory-sold-bottom-number">
                            {lotNumber}
                        </div>
                        <div className="m-inventory-sold-bottom-spaces">
                            {spaces}
                        </div>
                    </div>
                </div>
            </Button>
        )
    } else if (location === 'Shara') {
        return (
            <Button
                style={cardStyle}
                onClick={() => handleMobileDetails()}>
                <div className='m-inventory-card-container'>
                    <div className="m-inventory-shara-top">
                        <div className="m-inventory-shara-item">
                            Spaces
                        </div>
                        <div className="m-inventory-shara-item">
                            Price
                        </div>
                    </div>
                    <div className="m-inventory-shara-bottom">
                        <div className="m-inventory-shara-item">
                            {spaces}
                        </div>
                        <div className="m-inventory-shara-item">
                            ${price} each
                        </div>
                    </div>
                    
                </div>
            </Button>
        )
    }
}