import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default function DeleteAllWarning() {

    let {
        showingDeleteMessagesWarning,
        deletingMessages,
        hideDeleteMessagesWarning,
        deleteMessages,
        deletedMessages
    } = useContext(MobileAdminContext)

    const backdropStyle = {
        marginTop: '9vh',
        zIndex: '50000',
        backgroundColor: 'rgba(0,0,0,0)',
        width: '96vw',
        marginLeft: '2vw',
        height: '50vw'
    }

    const closeButtonStyle = {
        padding: '0',
        minHeight: '0',
        marginRight: '5px'
    }
    const deleteButtonStyle = {
        padding: '0',
        minHeight: '0',
        // backgroundColor: 'red',
        textTransform: 'none'
    }
    const cancelButtonStyle = {
        padding: '0',
        minHeight: '0',
        textTransform: 'none'
    }
    const feedbackStyle = {
        width: '100%',
        height: '100%'
    }

    let deletingContent
    if (deletingMessages) {
        deletingContent = ( <LinearProgress style={feedbackStyle} color="secondary" />)
    } else {
        deletingContent = ( '' )

    }

    function deleteAllMessages() {
        deleteMessages()

        firebase.firestore().collection('Messages')
        .get()
        .then(snapshot => {
            snapshot.forEach(message => {
                firebase.firestore().collection('Messages').doc(message.id)
                .delete()
            })
        })
        .then(() => {
            setTimeout(() => {
                deletedMessages()
            }, 2000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }
    return (
        <Backdrop
            style={backdropStyle}
            transitionDuration={300}
            open={showingDeleteMessagesWarning}
        >
            <div className='t-ac-delete-all-messages-warning'>
            <div className="t-ac-delete-picture-header">
                        <div className="t-ac-delete-picture-brand">
                            Delete Lot Picture
                        </div>
                        <Button 
                            disabled={deletingMessages}
                            style={closeButtonStyle}
                            variant='contained'
                            color='default'
                            onClick={() => hideDeleteMessagesWarning()}
                        >
                            <div className="t-ac-delete-picture-close-button">
                                Close
                            </div>
                        </Button>
                    </div>
                    <div className="t-ac-delete-picture-content">
                        Are you sure you wish to delete all messages?
                    </div>
                    <div className="t-ac-delete-picture-submit-row">
                        <Button
                            disabled={deletingMessages}
                            style={deleteButtonStyle}
                            variant='contained'
                            onClick={() => deleteAllMessages()}
                        >
                            <div className="t-ac-delete-picture-delete-button">
                                Yes - Delete
                            </div>
                        </Button>
                        <Button
                            disabled={deletingMessages}
                            style={cancelButtonStyle}
                            variant='contained'
                            color='default'
                            onClick={() => hideDeleteMessagesWarning()}
                        >
                            <div className="t-ac-delete-picture-cancel-button">
                                Cancel
                            </div>
                        </Button>
                    </div>
                    <div className="t-ac-delete-picture-feedback-row">
                        {deletingContent}
                    </div>
            </div>
        </Backdrop>
    )
}