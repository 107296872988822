import React, {useContext} from 'react'

import TextField from '@material-ui/core/TextField'

import {InventoryContext} from './../../../../Contexts/InventoryContext'

export default function MessageBody() {

    let {
        handleChange,
        location,
        detailsSection,
        detailsNumber,
        detailsName,
        detailsOtherLocation,
        sendingMessage
    } = useContext(InventoryContext)

    let defaultMessage


    let beershebaCheck
    if (location === 'Interfaith') {
        if (detailsSection === '1') {
            beershebaCheck = 'Beersheba'
        } else if (detailsSection === '2') {
            beershebaCheck = 'Beersheba II'
        } else if (detailsSection === '3') {
            beershebaCheck = 'Beersheba III'
        } 
    } else {
        beershebaCheck = detailsSection
    }

    if (location === 'Sharon' || location === 'Interfaith') {
        defaultMessage = `Hello, my name is (Name) and I am interested in lot ${location}-${beershebaCheck}-${detailsNumber}. You can contact me at (Phone Number) or (Email). Thank you`
    } else if (location === 'Knollwood') {
        defaultMessage = `Hello, my name is (Name) and I am interested in lot ${location}-${detailsName}-${detailsNumber}. You can contact me at (Phone Number) or (Email). Thank you`
    } else if (location === 'Estate' || location === 'Other') {
        defaultMessage = `Hello, my name is (Name) and I am interested in lot ${location}-${detailsOtherLocation}. You can contact me at (Phone Number) or (Email). Thank you`
    } else if (location === 'Shara') {
        defaultMessage = `Hello, my name is (Name) and I am interested in lot Shara. You can contact me at (Phone Number) or (Email). Thank you`

    }

    return (
        <div className='d-i-b-message-body'>
            <TextField
                disabled={sendingMessage}
                color='secondary'
                fullWidth
                multiline
                rows="3"
                rowsMax={3}
                name='message'
                defaultValue={defaultMessage}
                variant="outlined"
                onChange={(event) => {
                    handleChange(event)
                }}
            />
        </div>
    )
}