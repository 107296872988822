import React, { useContext } from 'react'

import TextField from '@material-ui/core/TextField'

import { AdminContext } from './../../../../Contexts/AdminContext'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default function AdminLotFilterSection() {

    let {
        addLotToVisibleList,
        stopLoadingLocation,
        setFilterSection,
        startLoadingLocation,
        loadingLocation,
        handleChange,
        filterSection,
        filterLocation
    } = useContext(AdminContext)

    let textFieldFix, sectionLocationCheck, disableSectionCheck
    filterSection
        ? textFieldFix = filterSection
        : textFieldFix = ''
    loadingLocation
        ? disableSectionCheck = true
        : filterLocation === 'Sharon' || filterLocation === 'Interfaith'
            ? disableSectionCheck = false
            : disableSectionCheck = true
    

    function handleFilterBySection() {
        startLoadingLocation()
        setFilterSection(filterSection)

        let cappedSection = filterSection.toUpperCase()

        firebase.firestore().collection(filterLocation).where('section', '==', cappedSection)
        .get()
        .then(snapshot => {
            snapshot.forEach(lot => {
                let lotDetails = {
                    lotNumber: lot.data().lotNumber,
                    location: lot.data().location,
                    section: lot.data().section,
                    lotName: lot.data().lotName,
                    otherLocation: lot.data().otherLocation,
                    price: lot.data().price,
                    lotId: lot.id,
                    spaces: lot.data().spaces
                }
                addLotToVisibleList(lotDetails)
            })
        })
        .then(() => {
            setTimeout(() => {
                stopLoadingLocation()
            }, 700)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    filterLocation === 'Sharon' || filterLocation === 'Interfaith'
        ? sectionLocationCheck = (
            <TextField
                disabled={disableSectionCheck}
                onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        handleFilterBySection()
                    ev.preventDefault();
                    }
                }}
                resize={{fontSize: '2rem'}}
                style={{width: '100%', textTransform: 'uppercase', fontSize: '2rem'}}
                SelectProps={{ autoWidth: true }}
                inputProps={{ style: {textAlign: 'center'} }}
                color='secondary'
                name='filterSection'
                className=""
                type="text"
                size='medium'
                placeholder='Section'
                value={textFieldFix}
                onChange={event => {
                    handleChange(event)
                }}/>
        )
        : filterLocation === 'Estate' || filterLocation === 'Other'
            ? sectionLocationCheck = ( '-' )
            : sectionLocationCheck = ( '' )

    return (
        <div className='context-lot-filter-section'>
           {sectionLocationCheck}
    </div>
    )
}