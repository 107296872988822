import React, { useContext } from 'react'

import InventoryPage from './components/InventoryPage'
import ChooseLocation from './components/ChooseLocation'
import DetailsCard from './DetailsCard/DetailsCard'

import { InventoryContext } from './../../Contexts/InventoryContext'

export default function InventoryDesktop() {

    let {
        locationChosen,
        showingDetails
    } = useContext(InventoryContext)

    let inventoryContent, detailsContent

    locationChosen
        ? inventoryContent = ( <InventoryPage />)
        : inventoryContent = ( <ChooseLocation />)

    showingDetails
        ? detailsContent = ( <DetailsCard /> )
        : detailsContent = ( '' ) 
    return (
        <div className='d-inventory-container'>
            {inventoryContent}
            {detailsContent}
        </div>
    )
}