import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone'

import {AdminContext} from './../../../../Contexts/AdminContext'

export default function AddNewsButton() {

    let {
        showAddNews
    } = useContext(AdminContext)

    return (
        <Button
            variant='contained'
            color='secondary'
            onClick={() => {
                showAddNews()
            }}>
            <AddBoxTwoToneIcon color='primary' />
            <div className='new-admin-d-add-lot-logo'>
                Add News
            </div>
        </Button>
    )
}