import React from 'react'
import { Link } from 'react-router-dom'

import Logo from './logo.png'

export default function NavDesktop () {

    return (
        <div className='d-nav-container'>
            <div className='d-nav-header'>
                <Link to='/' style={{ textDecoration: 'none' }} className='d-nav-brand-container'>
                    <img src={Logo} alt='logo' className='d-nav-logo'/>
                    <div className='d-nav-brand'>The Lot Broker</div>
                </Link>
            </div>
            <div className='d-nav-links'>
                <Link to='/about' style={{ textDecoration: 'none' }} className='d-nav-link'>
                    <div>About Us</div>
                </Link>
                <Link to='/gallery' style={{ textDecoration: 'none' }} className='d-nav-link'>
                    <div>Gallery</div>
                </Link>
                <Link to='/directors' style={{ textDecoration: 'none' }} className='d-nav-link-directors'>
                    <div>Funeral Directors</div> 
                </Link>
                <Link to='/inventory' style={{ textDecoration: 'none' }} className='d-nav-link'>
                    <div>Current Inventory</div>
                </Link>
                <Link to='/contact' style={{ textDecoration: 'none' }} className='d-nav-link-last'>
                    <div>Contact</div>
                </Link>
            </div>
        </div>
    )
}