import React, { 
    // useContext 
} from 'react'

import LocationFilter from './LocationFilter'
import SecondFilter from './SecondFilter'

// import {MobileAdminContext} from './../../../../../Contexts/MobileAdminContext'

export default function FilterBar() {

    // let {
    //     filterLocation
    // } = useContext(MobileAdminContext)

    return (
        <div className='m-ac-filter-bar-container'>
            <LocationFilter />
            <SecondFilter />
        </div>
    )
}