import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function KnollwoodFilter() {
    let {
        addToVisibleLots,
        disableMenu,
        lotName,
        setLotName,
        clearAnchors,
        setNameAnchor,
        anchor,
        nameMenu,
        filteringByName,
        filteredSuccess
    } = useContext(InventoryContext)

    let nameHeader

    lotName
        ? nameHeader = ( <div className='new-admin-d-location-choice'>{lotName}</div> )
        : nameHeader = ( <div className='new-admin-d-location-choice'>Lot Name...</div> )

    function handleFilterByName(name) {
        setLotName(name)
        filteringByName()

        firebase.firestore().collection('Knollwood').where('lotName', '==', name)
        .get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                let lot = {
                    location: doc.data().location,
                    lotName: doc.data().lotName,
                    price: doc.data().price,
                    spaces: doc.data().spaces,
                    lotNumber: doc.data().lotNumber,
                    id: doc.id
                }
                addToVisibleLots(lot)
            })
        })
        .then(() => {
            setTimeout(() => {
                filteredSuccess()
            }, 1000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    return (
        <div>
            <Button disabled={disableMenu} aria-controls="location-menu" aria-haspopup="true"
                onClick={event => {
                    setNameAnchor(event.currentTarget)
                }}>
                {nameHeader}
            </Button>
            <Menu
                style={{ zIndex: 1000030}}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="location-menu"
                anchorEl={anchor}
                keepMounted
                open={nameMenu}
                onClose={() => {
                    clearAnchors()
                }}>
                <div className='new-admin-d-knollwood-category'>Garden of:</div>
                <MenuItem onClick={() => handleFilterByName('Cross') }>Cross</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Faith') }>Faith</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Gethsemane') }>Gethsemane</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Last Supper') }>Last Supper</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Meditation') }>Meditation</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Peace') }>Peace</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Remembrance') }>Remembrance</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Saviour') }>Saviour</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Sunset') }>Sunset</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Word') }>Word</MenuItem>
                <div className='new-admin-d-knollwood-category'>Veterans:</div>
                <MenuItem onClick={() => handleFilterByName('Honor') }>Honor</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Memorial') }>Memorial</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Valor') }>Valor</MenuItem>
                <MenuItem onClick={() => handleFilterByName('Victory') }>Victory</MenuItem>
            </Menu>
        </div>
    )
}