import React from 'react'

import AdminAddLotButton from './AdminAddLotButton'

export default function AddLotHeader() {
    return(
        <div className="new-admin-d-lot-header">
            <div className="new-admin-d-lot-brand">
                Lot List
            </div>
            <AdminAddLotButton />
        </div>
    )
}