import React, { useContext } from 'react'

import LocationCard from './LocationCard'

import { DirectorsContext } from './../../Contexts/DirectorsContext'

export default function InterFaithList() {
    let {
        interfaithList
    } = useContext(DirectorsContext)

    let interfaithListContent = interfaithList.map(lot => {
        return (
            <LocationCard
                key={lot.id}
                section={lot.section}
                lotNumber={lot.lotNumber}
                price={lot.price}
                spaces={lot.spaces}
                interfaith={true}
            />
        )
    })



    return (
        <div className='u-d-directors-category'>
            <div className='u-d-d-header'>
                Interfaith
            </div>
            <div className='u-d-d-label'>
                <div className="u-d-d-label-item">
                    Section
                </div>
                <div className="u-d-d-label-item">
                    Number
                </div>
                <div className="u-d-d-label-item">
                    Spaces
                </div>
                <div className="u-d-d-label-item">
                    Price
                </div>
            </div>
            <div className='u-d-d-lotlist'>
                {interfaithListContent}
            </div>
        </div>
    )
}