import React from 'react'

import DeleteAllButton from './DeleteAllButton'
import AllMessages from './AllMessages'
import DeleteAllWarning from './DeleteAllWarning'

export default function ViewMessages() {
    return (
        <div className='m-ac-messages-container'>
            <DeleteAllButton />
            <AllMessages />
            <DeleteAllWarning />
        </div>
    )
}