import React from 'react'

import firebase from '@firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddAPhotoTwoToneIcon from '@material-ui/icons/AddAPhotoTwoTone';

export default class AdminGalleryCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            header: this.props.header,
            body: this.props.body,
            imageId: this.props.imageId,
            order: this.props.order,
            galleryId: this.props.galleryId,
            updatingInfo: false,
            updated: false,
            uploadingImage: false,
            imageUrl: this.props.imageUrl
        }
    }

    componentDidMount () {
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleImageUpload = (event) => {
        this.setState({
            uploadingImage: true
        })
        let newImageName = Math.floor(Math.random() * 100000000)
        const image = event.target.files[0]
        firebase
            .storage()
            .ref(`/gallery/${newImageName}`)
            .put(image)
            .then(() => {
                firebase
                    .storage()
                    .ref(`/gallery/${newImageName}`)
                    .getDownloadURL()
                    .then(url => {
                        let { header, body, order, galleryId } = this.state
                        firebase
                            .firestore()
                            .collection('Gallery')
                            .doc(galleryId)
                            .set({
                                imageUrl: url,
                                header: header,
                                body: body,
                                order: order,
                                imageId: newImageName
                            })
                        this.setState({
                            uploadingImage: false,
                            imageUrl: url 
                        })
                    })
            })
            .catch(err => {
                console.error(err)
            })
    }
    updateGallery() {
        this.setState({
            updatingInfo: true
        })
        let { header, body, imageId, order, galleryId, imageUrl } = this.state
        firebase
        .firestore()
        .collection('Gallery')
        .doc(galleryId)
        .set({
            imageUrl: imageUrl,
            header: header,
            body: body,
            order: order,
            imageId: imageId
        })
        .then(() => {
            this.setState({
                updatingInfo: false,
                updated: true
            })
        })
        .catch(err => {
            console.error(err)
        })
    }

    render() {
        const { header, body, updatingInfo, updated, uploadingImage } = this.state

        let updateMessage
        updated
            ? updateMessage = (
                'Successfully Updated'
            )
            : updateMessage = ( 'Update' )

        let updateStatus
        updatingInfo
            ? updateStatus = (
                <CircularProgress color='secondary'/>
            )
            : updateStatus = (
                <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                    this.updateGallery()
                }}
            >{updateMessage}</Button>
            )
            let imageUploadStatus
            uploadingImage
                ? imageUploadStatus = ( <CircularProgress color='secondary' size={90}/>)
                : imageUploadStatus = ( '' )
        return (
                <div className="admin-d-gallery-item">
                    <div className='admin-d-gallery-photo'
                        style={{backgroundImage: `url(${this.state.imageUrl})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}}>
                        <div className='admin-d-gallery-upload-icon'>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color='primary'
                                    onChange={this.handleImageUpload}
                                    >
                                    <AddAPhotoTwoToneIcon color='secondary'/>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                    />
                                </Button>
                        </div>
                        <div className='admin-d-gallery-upload-spinner'>
                            {imageUploadStatus}
                        </div>

                    </div>
                    <div className='admin-d-gallery-card-info'>
                    <div className='admin-d-gallery-input admin-d-gallery-input-header'>
                                    <TextField
                                        className='admin-d-gallery-input-header'
                                        color='secondary'
                                        name='header'
                                        defaultValue={header}
                                        type="text"
                                        label="Gallery header"
                                        variant="outlined" 
                                        onChange={this.handleChange}/>
                                </div>
                                <div className='admin-d-gallery-input admin-d-gallery-input-body'>
                                    <TextField 
                                        multiline={true}
                                        rows={4}
                                        className='admin-d-gallery-input-body'
                                        color='secondary'
                                        name='body'
                                        defaultValue={body}
                                        type="text"
                                        label="Gallery Body Text"
                                        variant="outlined"
                                        onChange={this.handleChange} />
                                </div>
                                <div>
                                {updateStatus}
                                </div>
                    </div>
                </div>
        )
    }
}