import React from 'react'

import TestimonialsHeader from './TestimonialsHeader'
import AllTestimonials from './AllTestimonials'

export default function ViewTestimonials() {

    return (
        <div className='m-ac-testimonials-container'>
            <TestimonialsHeader />
            <AllTestimonials />
        </div>
    )
}