import React, { useContext } from 'react'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone'
import AddAPhotoTwoToneIcon from '@material-ui/icons/AddAPhotoTwoTone'
import Button from '@material-ui/core/Button'

import DeletePictureWarning from './DeletePictureWarning'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function DetailsPicture() {

    let {
        detailsImageUrl,
        // showingDeletePictureWarning,
        showDeletePictureWarning,
        detailsSold,
        uploadImage,
        detailsLocation,
        detailsSection,
        detailsNumber,
        detailsId,
        detailsName,
        setDetailsImageUrl,
        setDetailsOriginalPath,
        uploadedImage,
        detailsOtherLocation,
        setDetailsImageNumber,

        editing,
        changingLotSold,
        savingEdits
        // closeDeletePictureWarning
    } = useContext(MobileAdminContext)


    function deletePictureWarning() {
        showDeletePictureWarning()
    }

    const buttonStyle = {
        padding: '0',
        minHeight: '0',
        background: 'rgba(255, 255, 255, 0)'
    }
    let iconOpacity
    if (detailsSold) {
        iconOpacity = 'rgba(255,255,255,0.15)'
    } else {
        iconOpacity = 'rgba(255,255,255,0.95)'
    }
    const iconStyle = {
        color: iconOpacity,
        fontSize: '50px'
    }
    const iconStyle2 = {
        color: iconOpacity,
        fontSize: '75px'
    }
    let deleteDisabledCheck
    if (detailsSold || editing || changingLotSold || savingEdits) {
        deleteDisabledCheck = true
    } else {
        deleteDisabledCheck = false
    }
    function handleImageUpload(event) {
        uploadImage()

        let image
        if (!detailsSold) {
            if (detailsLocation === 'Sharon' || detailsLocation === 'Interfaith') {
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${detailsLocation}/${detailsSection}-${detailsNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${detailsLocation}/${detailsSection}-${detailsNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setDetailsImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/${detailsLocation}/${detailsSection}-${detailsNumber}`
                        }
                        firebase.firestore().collection(detailsLocation).doc(detailsId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(detailsLocation).doc(detailsId)
                        .update(path)
                        .then(() => {
                            setDetailsOriginalPath(path.originalPath)
                        })
                        })
                    })
                })
                .then(() => {
                    uploadedImage()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (detailsLocation === 'Knollwood') {
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${detailsLocation}/${detailsName}-${detailsNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${detailsLocation}/${detailsName}-${detailsNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setDetailsImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/${detailsLocation}/${detailsName}-${detailsNumber}`
                        }
                        firebase.firestore().collection(detailsLocation).doc(detailsId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(detailsLocation).doc(detailsId)
                        .update(path)
                        .then(() => {
                            setDetailsOriginalPath(path.originalPath)
                        })
                        })
                    })
                })
                .then(() => {
                    uploadedImage()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (detailsLocation === 'Estate' || detailsLocation === 'Other') {
                let randomNumber = Math.floor(Math.random() * 1000)
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${detailsLocation}/${detailsOtherLocation}-${randomNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${detailsLocation}/${detailsOtherLocation}-${randomNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setDetailsImageUrl(url)
                        setDetailsImageNumber(randomNumber)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let imageNumber = {
                            imageNumber: randomNumber
                        }
                        let path = {
                            originalPath: `/Images/${detailsLocation}/${detailsOtherLocation}-${randomNumber}`
                        }
                        firebase.firestore().collection(detailsLocation).doc(detailsId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(detailsLocation).doc(detailsId)
                            .update(imageNumber)
                            .then(() => {
                                firebase.firestore().collection(detailsLocation).doc(detailsId)
                            .update(path)
                            .then(() => {
                                setDetailsOriginalPath(path.originalPath)
                            })
                            })
                        })
                    })
                })
                .then(() => {
                    uploadedImage()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (detailsLocation === 'Shara') {
                image = event.target.files[0]
                firebase.storage().ref(`/Images/Shara/SharaImage`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/Shara/SharaImage`)
                    .getDownloadURL()
                    .then(url => {
                        setDetailsImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/Shara/SharaImage`
                        }
                        firebase.firestore().collection(detailsLocation).doc(detailsId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(detailsLocation).doc(detailsId)
                        .update(path)
                        .then(() => {
                            setDetailsOriginalPath(path.originalPath)
                        })
                        })
                    })
                })
                .then(() => {
                    uploadedImage()
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        } else if (detailsSold) {
            if (detailsLocation === 'Sharon' || detailsLocation === 'Interfaith') {
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${detailsLocation}/${detailsSection}-${detailsNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${detailsLocation}/${detailsSection}-${detailsNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setDetailsImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/${detailsLocation}/${detailsSection}-${detailsNumber}`
                        }
                        firebase.firestore().collection('sold').doc(detailsId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(detailsLocation).doc(detailsId)
                            .update(path)
                            .then(() => {
                                setDetailsOriginalPath(path.originalPath)
                            })
                        })
                    })
                })
                .then(() => {
                    uploadedImage()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (detailsLocation === 'Knollwood') {
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${detailsLocation}/${detailsName}-${detailsNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${detailsLocation}/${detailsName}-${detailsNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setDetailsImageUrl(url)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let path = {
                            originalPath: `/Images/${detailsLocation}/${detailsSection}-${detailsNumber}`
                        }
                        firebase.firestore().collection('sold').doc(detailsId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection(detailsLocation).doc(detailsId)
                            .update(path)
                            .then(() => {
                                setDetailsOriginalPath(path.originalPath)
                            })
                        })
                    })
                })
                // handle putting image on background
                .then(() => {
                    uploadedImage()
                })
                .catch(err => {
                    console.error(err.code)
                })
            } else if (detailsLocation === 'Estate' || detailsLocation === 'Other') {
                let randomNumber = Math.floor(Math.random() * 1000)
                image = event.target.files[0]
                firebase.storage().ref(`/Images/${detailsLocation}/${detailsOtherLocation}-${randomNumber}`)
                .put(image)
                .then(() => {
                    firebase.storage().ref(`/Images/${detailsLocation}/${detailsOtherLocation}-${randomNumber}`)
                    .getDownloadURL()
                    .then(url => {
                        setDetailsImageUrl(url)
                        setDetailsImageNumber(randomNumber)
                        let lotUrl = {
                            imageUrl: url
                        }
                        let imageNumber = {
                            imageNumber: randomNumber
                        }
                        let path = {
                            originalPath: `/Images/${detailsLocation}/${detailsSection}-${detailsNumber}`
                        }
                        firebase.firestore().collection('sold').doc(detailsId)
                        .update(lotUrl)
                        .then(() => {
                            firebase.firestore().collection('sold').doc(detailsId)
                            .update(imageNumber)
                        })
                        .then(() => {
                            firebase.firestore().collection(detailsLocation).doc(detailsId)
                            .update(path)
                            .then(() => {
                                setDetailsOriginalPath(path.originalPath)
                            })
                        })
                    })
                })
                // handle putting image on background
                .then(() => {
                    uploadedImage()
                })
                .catch(err => {
                    console.error(err.code)
                })
            }
        }
    }

    let check

    if (detailsImageUrl) {
        check = (
            <div className='m-ac-details-yes-picture'>
                <div className="m-ac-details-yes-picture-header">
                    <div className="m-ac-details-yes-picture-reupload">
                    <Button
                        disableElevation={true}
                        style={buttonStyle}
                        disabled={deleteDisabledCheck}
                        variant="contained"
                        component="label"
                        color='primary'
                        onChange={handleImageUpload}
                        >
                        <AddAPhotoTwoToneIcon color='secondary' style={iconStyle}/>
                        <input type="file" style={{ display: "none" }} />
                    </Button>
                    </div>
                    <div className="m-ac-details-yes-picture-delete">
                        <IconButton 
                            disabled={deleteDisabledCheck}
                                onClick={() => {
                                    deletePictureWarning()
                                }} 
                                >
                            <HighlightOffTwoToneIcon style={iconStyle}/>
                        </IconButton>
                    </div>
                </div>
            </div>
        )
    } else {
        check = (
            <div className='m-ac-details-no-picture'>
                <Button
                    disableElevation={true}
                    style={buttonStyle}
                    disabled={deleteDisabledCheck}
                    variant="contained"
                    component="label"
                    color='primary'
                    onChange={handleImageUpload}
                    >
                    <AddAPhotoTwoToneIcon color='secondary' style={iconStyle2}/>
                    <input type="file" style={{ display: "none" }} />
                </Button>
            </div>
        )
    }
    return (
        <div 
            className='m-ac-details-picture-container'
            style={{ 
                backgroundImage: `url(${detailsImageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
            {check}
            <DeletePictureWarning />
        </div>
    )
}