import React, { useContext } from 'react'
import { AdminContext } from './../../../../../Contexts/AdminContext'

export default function LotCardLastUpdated() {
    let { 
        createdAt,
        lastEdit
    } = useContext(AdminContext)

    let thisTime

    lastEdit
        ? thisTime = ( <div>{lastEdit}</div> )
        : thisTime = ( <div>{createdAt}</div> )
    return (
    <div className='admin-context-created-at-container'>
        <div className='admin-context-created-at-item'>
            last updated:
        </div>
        <div className='admin-context-created-at-item'>
            {thisTime}
        </div>
    </div>
    )

}