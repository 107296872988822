import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import {MobileAdminContext} from './../../../../Contexts/MobileAdminContext'

export default function AdminConsoleHeader() {

    let {
        adminView,
        setAdminAnchor,
        anchor,
        adminMenu,
        clearAnchors,
        setAdminView
    } = useContext(MobileAdminContext)

    let disableMenu, menuContent
    if (adminView === null) {
        disableMenu = true
        menuContent = 'Admin Console'
    } else {
        disableMenu = false
        menuContent = adminView
    }
    const adminConsoleButton = {
        width: '100vw',
        minHeight: '10vh',
        padding: '0',
        textTransform: 'none',
        backgroundColor: 'rgba(0,0,0,0.5'
    }
    const menuStyle = {
        paddingTop: '0',
        // background: 'transparent'
    }
    const menuItemStyle = {
        padding: '0',
        margin: '0',
        minHeight: '2px',
        minWidth: '50vw',
        fontSize: '2.5rem',
        backgroundColor: '#142c34'
        // background: 'transparent'

    }
    return (
        <div className='t-ac-header-container'>
            <Button
                variant='contained'
                style={adminConsoleButton}
                disabled={disableMenu}
                aria-controls="admin-menu" 
                aria-haspopup="true" 
                onClick={event => {
                    setAdminAnchor(event.currentTarget)
                }}>
                    <div className='t-ac-header-button'>
                        {menuContent}
                    </div>
            </Button>
            <Menu
                style={menuStyle}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="view-admin-menu"
                anchorEl={anchor}
                keepMounted
                MenuListProps={{ disablePadding: true }}
                open={adminMenu}
                onClose={() => {
                    clearAnchors()
                }}>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => setAdminView('Add New Lot') }>
                        <div className='t-ac-header-item'>
                            Add New Lot
                        </div>
                </MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => setAdminView('Lots') }>
                        <div className='t-ac-header-item'>
                            Lots
                        </div>
                </MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => setAdminView('Messages') }>
                        <div className='t-ac-header-item'>
                            Messages
                        </div>
                </MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => setAdminView('Gallery') }>
                        <div className='t-ac-header-item'>
                            Gallery
                        </div>
                </MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => setAdminView('News') }>
                        <div className='t-ac-header-item'>
                            News
                        </div>
                </MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => setAdminView('Testimonials') }>
                        <div className='t-ac-header-item'>
                            Testimonials
                        </div>
                </MenuItem>
            </Menu>
        </div>
    )
}