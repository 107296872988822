import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { AdminContext } from './../../../../Contexts/AdminContext'

export default function KnollwoodLocationMenu() {
    let { 
        setNameAnchor,
        nameMenu,
        anchor,
        clearAnchors,
        lotName,
        setLotName
    } = useContext(AdminContext)

    let nameHeader

    lotName
        ? nameHeader = ( <div className='new-admin-d-location-choice'>{lotName}</div> )
        : nameHeader = ( <div className='new-admin-d-location-choice'>Choose...</div> )

    const knollMenuStyle = {
        zIndex: 1000030,
        paddingTop: 0,
        paddingBottom: 0
    }
    const itemStyle = {
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        marginLeft: '10px'
    }
    return (
        <div className="new-admin-d-add-lot-backdrop-name2">
                    <div className="new-admin-d-add-lot-backdrop-location-header">
                    Lot Name: 
                    </div>
        <div className="new-admin-d-add-lot-backdrop-location-menu">
            <Button aria-controls="location-menu" aria-haspopup="true"
                onClick={event => {
                    setNameAnchor(event.currentTarget)
                }}>
                {nameHeader}
            </Button>
            <Menu
                style={knollMenuStyle}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="location-menu"
                anchorEl={anchor}
                keepMounted
                open={nameMenu}
                onClose={() => {
                    clearAnchors()
                }}>
                <div className='new-admin-d-knollwood-category'>Garden of:</div>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Cross')
                }}>Cross</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Faith')
                }}>Faith</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Gethsemane')
                }}>Gethsemane</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Last Supper')
                }}>Last Supper</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Meditation')
                }}>Meditation</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Peace')
                }}>Peace</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Remembrance')
                }}>Remembrance</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Saviour')
                }}>Saviour</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Sunset')
                }}>Sunset</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Word')
                }}>Word</MenuItem>
                <div className='new-admin-d-knollwood-category'>Veterans:</div>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Honor')
                }}>Honor</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Memorial')
                }}>Memorial</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Valor')
                }}>Valor</MenuItem>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('Victory')
                }}>Victory</MenuItem>
                <div className='new-admin-d-knollwood-category'>Urn:</div>
                <MenuItem 
                    style={itemStyle}
                    onClick={() => {
                    setLotName('WestChapel')
                }}>West Chapel</MenuItem>
            </Menu>
        </div>
        </div>
    )
}