import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

import firebase from 'firebase/app'
import 'firebase/firestore'

import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'


export default function SharonFilter() {

    let {
        loading,
        filterSection,
        handleChange,
        startFilterSection,
        clearLotList,
        addToLotList,
        finishLoadingLots
    } = useContext(MobileAdminContext)

    let textFieldFix

    filterSection
    ? textFieldFix = filterSection
    : textFieldFix = ''

    const searchStyle = {
        fontSize: '35px',
        color: 'white'
    }

    function handleFilterBySection() {
        clearLotList()
        startFilterSection()
        
        let cappedSection = filterSection.toUpperCase()

        firebase.firestore().collection('Sharon').where('section', '==', cappedSection)
        .get()
        .then(snapshot => {
            snapshot.forEach(lot => {
                let lotDetails = {
                    location: lot.data().location,
                    lotNumber: lot.data().lotNumber,
                    section: lot.data().section,
                    price: lot.data().price,
                    spaces: lot.data().spaces,
                    lotId: lot.id
                }
                addToLotList(lotDetails)
            })
        })
        .then(() => {
            setTimeout(() => {
                finishLoadingLots()
            }, 700)
        })
        .catch(err => {
            console.error(err.code)
        })
    }
    return (
        <div className='t-ac-lot-list-sharon-filter'>
            <TextField 
            disabled={loading}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                    handleFilterBySection()
                ev.preventDefault();
                }
            }}
            name='filterSection'
            type="text"
            size='medium'
            placeholder='Section'
            style={{width: '20vw', textAlign: 'center'}}
            inputProps={{ style: {textAlign: 'center', color: 'white', fontSize: '2rem'} }}
            value={textFieldFix}
            onChange={event => {
                handleChange(event)
            }}
        />
        <SearchIcon
            style={searchStyle}
            onClick={() => handleFilterBySection()}
            />
        </div>
    )
}