import React, {useContext} from 'react'

import {GalleryContext} from './../../Contexts/GalleryContext'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import WhiteLogo from './../../../StyleNew/logoWhite.png'

import SharonMobile from './SharonMobile'
import KnollwoodMobile from './KnollwoodMobile'
import SharaMobile from './SharaMobile'

export default function GalleryMobile() {

    let {
        currentGallery,
        setGallery,
        loaded
    } = useContext(GalleryContext)

    const groupStyle = {
        width: '99vw',
        marginTop: '1px',
        marginBottom: '5px',
        height: '90%',

    }
    const buttonStyle = {
        padding: '0',
        width: '33vw',
        backgroundColor: 'rgba(41, 87, 105, 0.849)',
        height: '100%'
    }

    const backdropStyle = {
        marginTop: '8vh',
        zIndex: '100',
        backgroundColor: 'rgb(150, 208, 231)',
        height: '87vh'
    }
    const spinnerStyle = {
        color: 'white',
        position: 'relative'

    }

    let mobileGalleryContent
    if (currentGallery === 'Sharon') {
        mobileGalleryContent = <SharonMobile />
    } else if (currentGallery === 'Knollwood') {
        mobileGalleryContent = <KnollwoodMobile />
    } else if (currentGallery === 'Shara') {
        mobileGalleryContent = <SharaMobile />
    }

    let totalGalleryContent
    if (loaded) {
        totalGalleryContent = (
            <div className='m-gallery-container'>
            <div className="m-gallery-options">
                <ButtonGroup variant='contained' aria-label='contained primary button group' style={groupStyle}>
                    <Button 
                    style={buttonStyle}
                    onClick={() => {
                        setGallery('Sharon')
                    }}>
                        <div className='m-gallery-button-style'>
                            Sharon
                        </div>
                        </Button>
                    <Button 
                    style={buttonStyle}
                    onClick={() => {
                        setGallery('Knollwood')
                    }}>
                        <div className='m-gallery-button-style'>
                            Knollwood
                        </div>
                    </Button>
                    <Button 
                    style={buttonStyle}
                    onClick={() => {
                        setGallery('Shara')
                    }}>
                        <div className='m-gallery-button-style'>
                            Shara
                        </div>
                    </Button>
                </ButtonGroup>
            </div>
            <div className="m-gallery-header">
                    {currentGallery} Gallery
            </div>
            <div className='m-gallery-content'>
                {mobileGalleryContent}
                <div className="m-scroll-fix"></div>
            </div>
        </div>
        )
    } else {
        totalGalleryContent = ( '' )
    }

    return (
        <div className='m-gallery-height-fix'>
            <Backdrop
                open={!loaded}
                transitionDuration={500}
                style={backdropStyle}
            >
                <img src={WhiteLogo} alt='logo' className='m-inventory-logo'/>
                <CircularProgress style={spinnerStyle} size={140}/> 
            </Backdrop>
            {totalGalleryContent}
        </div>
    )
}