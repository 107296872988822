import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {MobileAdminContext} from './../../../../../Contexts/MobileAdminContext'

export default function Submit() {


    let {
        location,
        lotName,
        section,
        clearNewLot,
        startAddingLot,
        lotNumber,
        price,
        spaces,
        otherLocation,
        setDetailsId,
        successfullyAddedLot,
        comments,

        setDetailsLocation,
        setDetailsSection,
        setDetailsNumber,
        setDetailsSpaces,
        setDetailsPrice,
        setDetailsComments,
        setDetailsName,
        setDetailsOtherLocation,
        setDetailsPriority,
        setDetailsCreatedAt
    } = useContext(MobileAdminContext)

    let today = new Date()
    let day = today.getDate().toString()
    let month = (today.getMonth() + 1).toString()
    let year = today.getFullYear().toString()
    let todaysDate = month.concat('-',day,'-',year)

    function handleNewLotAdd() {
        startAddingLot()
        
        if (location === 'Sharon' || location === 'Interfaith') {
            let correctedSection = section.toUpperCase()
            let correctedLotNumber = lotNumber.toUpperCase()
            let priority = section.replace(/\D/g, "")
            setDetailsPriority(priority)
            setDetailsLocation(location)
            setDetailsSection(correctedSection)
            setDetailsNumber(correctedLotNumber)
            setDetailsSpaces(spaces)
            setDetailsPrice(price)
            
            if(comments) {
                setDetailsComments(comments)
            }

            firebase.firestore().collection(location)
            .add({
                location: location,
                spaces: spaces,
                price: price,
                lotNumber: correctedLotNumber,
                comments: comments,
                priority: Number(priority),
                section: correctedSection,
                createdAt: todaysDate,
            })
            .then((docRef) => {
                firebase.firestore().collection(location).doc(docRef.id)
                .get()
                .then(doc => {
                    setDetailsId(doc.id)
                    setDetailsCreatedAt(todaysDate)
                }) 
            })
            .then(() => {
                setTimeout(() => {
                    successfullyAddedLot()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Knollwood') {
            let correctedLotNumber = lotNumber.toUpperCase()
            const gardenList = [
                'Cross',
                'Faith',
                'Gethsemane',
                'Last Supper',
                'Meditation',
                'Saviour',
                'Sunset',
                'Word',
                'Peace',
                'Remembrance'
            ]
            let gardenCheck 
                if (gardenList.includes(lotName)) {
                        gardenCheck = true
                } else {
                        gardenCheck = false
                }

            setDetailsLocation(location)
            setDetailsName(lotName)
            setDetailsNumber(correctedLotNumber)
            setDetailsSpaces(spaces)
            setDetailsPrice(price)
            if(comments) {
                setDetailsComments(comments)
            }
            firebase.firestore().collection('Knollwood')
            .add({
                garden: gardenCheck,
                location: location,
                spaces: spaces,
                price: price,
                lotNumber: correctedLotNumber,
                comments: comments,
                lotName: lotName,
                createdAt: todaysDate,
            })
            .then((docRef) => {
                firebase.firestore().collection(location).doc(docRef.id)
                .get()
                .then(doc => {
                    setDetailsCreatedAt(todaysDate)
                    setDetailsId(doc.id)
                }) 
            })
            .then(() => {
                setTimeout(() => {
                    successfullyAddedLot()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Estate' || location === 'Other') {
            setDetailsLocation(location)
            setDetailsOtherLocation(otherLocation)
            setDetailsSpaces(spaces)
            setDetailsPrice(price)
            setDetailsComments(comments)
            firebase.firestore().collection(location)
            .add({
                location: location,
                otherLocation: otherLocation,
                spaces: spaces,
                price: price,
                comments: comments,
                createdAt: todaysDate,
            })
            .then((docRef) => {
                firebase.firestore().collection(location).doc(docRef.id)
                .get()
                .then(doc => {
                    setDetailsCreatedAt(todaysDate)
                    setDetailsId(doc.id)
                }) 
            })
            .then(() => {
                setTimeout(() => {
                    successfullyAddedLot()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        }
    }

    if (location === null || (location === 'Knollwood' && lotName === null) || (location === 'Interfaith' && section === null)) {
        return (
            <div>
    
            </div>
        )
    } else {
        return (
            <div className='m-ac-submit-container'>
                <Button
                    color='secondary'
                    variant='contained'
                    onClick={() => handleNewLotAdd() }
                >
                    Submit
                </Button>
                <Button
                    color='default'
                    variant='contained'
                    onClick={() => clearNewLot()}
                >
                    Clear
                </Button>
            </div>
        )
    }
}