import React from 'react'

export default function TabletCard(props) {
    let {
        lotNumber,
        section,
        spaces,
        location,
        price,
        lotName
    } = props

    let interfaithFix

    if (location === 'Sharon') {
        if (section === '4-ORCHARD') {
            interfaithFix = (
                <div>
                    4
                    <br/>
                    <div className='t-directors-section-card-fix'>
                        Orchard
                    </div>
                </div>
            )
        } else if (section === '26A-OVAL') {
            interfaithFix = (
                <div>
                    26A
                    <br/>
                    <div className='t-directors-section-card-fix'>
                        Oval
                    </div>
                </div>
            )
        } else {
            interfaithFix = section
        }
    } else if (location === 'Interfaith') {
        if (section === '1') {
            interfaithFix = (
                <div className='t-directors-beersheba-container'>
                    <div className="t-directors-beersheba-header">
                        Beersheba 
                        <br/>
                        I
                    </div>
                </div>
            )
        } else if (section === '2') {
            interfaithFix = (
                <div className='t-directors-beersheba-container'>
                    <div className="t-directors-beersheba-header">
                        Beersheba 
                        <br/>
                        II
                    </div>
                </div>
            )
        } else if (section === '3') {
            interfaithFix = (
                <div className='t-directors-beersheba-container'>
                    <div className="t-directors-beersheba-header">
                        Beersheba 
                        <br/>
                        III
                    </div>
                </div>
            )
        }
    }
    if (location === 'Sharon' || location === 'Interfaith') {
        return (
            <div className='t-directors-card-container'>
                <div className="t-directors-card-item">
                    {interfaithFix}
                </div>
                <div className="t-directors-card-number">
                    {lotNumber}
                </div>
                <div className="t-directors-card-spaces">
                    {spaces}
                </div>
                <div className="t-directors-card-price">
                    ${price} ea.
                </div>
            </div>
        )
    } else if (location === 'Garden' || location === 'Veterans') {
        return (
            <div className='t-directors-card-container'>
                <div className="t-directors-card-name">
                    {lotName}
                </div>
                <div className="t-directors-card-number">
                    {lotNumber}
                </div>
                <div className="t-directors-card-spaces">
                    {spaces}
                </div>
                <div className="t-directors-card-price">
                    ${price} ea.
                </div>
            </div>
        )
    }
}