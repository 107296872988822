import React, {createContext} from 'react'

import {isMobileOnly} from 'react-device-detect'

import firebase from '@firebase/app'
import 'firebase/firestore'

export const GalleryContext = createContext()

export class GalleryContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loaded: false,
            fullyLoaded: () => {
                this.setState({
                    loaded: true
                })
            },
            currentGallery: 'Sharon',
            setGallery: (gallery) => {
                this.setState({
                    currentGallery: gallery
                })
            },
            sharonGallery: [],
            addToSharonGallery: (image) => {
                this.setState({
                    sharonGallery: [...this.state.sharonGallery, image]
                })
            },
            knollwoodGallery: [],
            addToKnollwoodGallery: (image) => {
                this.setState({
                    knollwoodGallery: [...this.state.knollwoodGallery, image]
                })
            },
            sharaGallery: [],
            addToSharaGallery: (image) => {
                this.setState({
                    sharaGallery: [...this.state.sharaGallery, image]
                })
            },
        }
    }

    componentDidMount() {
        let {
            addToSharonGallery,
            addToKnollwoodGallery,
            addToSharaGallery,
            fullyLoaded
        } = this.state
        firebase.firestore().collection('GuestGallery').where('visible', '==', true).orderBy('order')
        .get()
        .then(snapshot => {
            snapshot.forEach(doc => {
                let url = { 
                    address: doc.data().url
                }
                addToSharonGallery(url)
            })
        })
        .then(() => {
            firebase.firestore().collection('GuestGallery2').where('visible', '==', true).orderBy('order')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let url = { 
                        address: doc.data().url
                    }
                    addToKnollwoodGallery(url)
                })
            })
        })
        .then(() => {
            firebase.firestore().collection('GuestGallery3').orderBy('order')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let url = { 
                        address: doc.data().url
                    }
                    addToSharaGallery(url)
                    
                })
            })
        })
        .then(() => {
            if (isMobileOnly) {
                setTimeout(() => {
                    fullyLoaded()
                }, 300)
            } else {
                fullyLoaded()
            }
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    render() {
        return (
            <GalleryContext.Provider value={this.state}>
                {this.props.children}
            </GalleryContext.Provider>
        )
    }
}