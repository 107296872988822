import React, {useContext} from 'react'

import WhiteLogo from './../../../StyleNew/logoWhite.png'

import MobileInventory from './Inventory/MobileInventory'
import ChooseLocationMobile from './ChooseLocation/ChooseLocationMobile'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import {InventoryContext} from './../../Contexts/InventoryContext'

export default function InventoryMobile() {

    let {
        locationChosen,
        loading
    } = useContext(InventoryContext)

    let inventoryContent
    if (locationChosen) {
        inventoryContent = <MobileInventory />
    } else if (locationChosen === false) {
        inventoryContent = <ChooseLocationMobile />
    }
    const loadingStyle = {
        marginTop: '8vh',
        zIndex: '100',
        backgroundColor: 'rgb(150, 208, 231)',
        height: '100vh'
    }
    const spinnerStyle = {
        color: 'white',
        position: 'relative'

    }
    return (
        <div>
            <Backdrop
                transitionDuration={400}
                style={loadingStyle}
                open={loading}>
                <CircularProgress style={spinnerStyle} size={140}/>
                <img src={WhiteLogo} alt='logo' className='m-inventory-logo'/>
            </Backdrop>
            {inventoryContent}
        </div>
    )
}