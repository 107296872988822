import React from 'react'

import GLpic from './../../images/GL.png'
export default function AboutContent() {

    return (
        <div className='m-about-standard-container'>
            <div className="m-about-info-section">
                The Lot Broker was started back in the mid-1990s by Bernie Delman, a former family 
                service counselor at Sharon Memorial Park. His vision was to create a service where 
                cemetery lot owners had an avenue to sell their unwanted and un-used lots and give 
                buyers a chance to purchase lots at a reduced price (up to half of what the cemetery 
                was charging). Bernie operated the business successfully until retiring to Florida in 2004.
            </div>

            <div className="m-about-info-section">
                Joe Bank, another former counselor, took over the business and ran it for many years, 
                carrying on the legacy that Bernie had started. Joe retired in October 2016 and asked 
                me to take over. My plan is to continue in the legacy that was set before me.
                I have been servicing the community for over 25 years. I previously worked for Sharon 
                Memorial Park for 11 years and have been working for a local Funeral home for the last 
                16 years. I also assisted Joe with The Lot Broker since he started. My aim is to 
                continue providing the same great service that Bernie and Joe offered in the past.
            </div>

            <div className="m-about-info-section">
                We Advertise in many local publications, including Temple and senior center newsletters, and 
                utilize digital media to help people looking for Cemetery spaces at Sharon and Knollwood 
                Memorial Parks (up to 50% off Park prices).
                My years of experience and understanding in the field will be beneficial in helping the 
                families I serve, which is my top priority.
            </div>
            <div className="m-about-bottom-row">
                <div className="m-about-bottom-row-left">
                    <img src={GLpic} alt='logo' className='m-about-gl-pic'/>
                </div>
                <div className="m-about-bottom-row-right">
                    <div className="m-about-info-signature">
                        - Gary Levine
                    </div>
                    <div className="m-about-info-sub-signature">
                        * Please note that we are not affiliated with Sharon and Knollwood Memorial Parks
                    </div>
                </div>
            </div>
            <div className="m-scroll-fix">
                
            </div>
        </div>
    )
}