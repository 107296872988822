import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {InventoryContext} from './../../../../Contexts/InventoryContext'

export default function MessageBody() {

    let {
        cancelMessage,
        sendMessage,
        messageSentSuccessfully,
        message,
        sendingMessage
    } = useContext(InventoryContext)

    let messageFooterContent

    let today = new Date()
    let day = today.getDate().toString()
    let month = (today.getMonth() + 1).toString()
    let year = today.getFullYear().toString()
    let todaysDate = month.concat('-',day,'-',year)


    function handleSubmitMessage() {
        sendMessage()

        firebase.firestore().collection('Messages')
        .add({
            body: message,
            date: todaysDate
        })
        .then(() => {
            setTimeout(() => {
                messageSentSuccessfully()
            }, 1300)
        })
        .then(() => {
            setTimeout(() => {
                cancelMessage()
            }, 10000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }

    sendingMessage
        ? messageFooterContent = (
            <LinearProgress style={{ width: '100%', height: '50%'}} color="secondary"/>
        ) 
        : messageFooterContent = (
                <div className='d-i-b-message-footer-container'>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                            handleSubmitMessage()
                        }}>
                            Submit
                        </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            cancelMessage()
                        }}>
                        Cancel
                    </Button>
                </div>
        )

    return (
        <div className='d-i-b-message-footer'>
            {messageFooterContent}
        </div>
    )
}