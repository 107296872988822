import React, {useContext} from 'react'
import FooterContent from './FooterContent'
import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function DetailsFooter() {

    let {
        detailsComments
    } = useContext(InventoryContext)

    let commentsContent

    detailsComments
        ? commentsContent = (
            <div className='d-i-b-comments-container'>
                <div className='d-i-b-comments-header'>
                    Additional Comments from The Lot Broker:
                </div>
                <div className='d-i-b-comments-info'>
                    "{detailsComments}"
                </div>
            </div>
        )
        : commentsContent = ( '' )
    return (
        <div className='d-i-b-footer'>
            <div>{commentsContent}</div>
            <FooterContent />
        </div>
    )
}