import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function LotCard(props) {

    let {
        section,
        lotName,
        lotNumber,
        otherLocation,
        location,
        id
    } = props

    let {
        filterLocation,
        loadingIndividualLot,
        loadedIndividualLot,

        setDetailsLocation,
        setDetailsSection,
        setDetailsNumber,
        setDetailsName,
        setDetailsSpaces,
        setDetailsPrice,
        setDetailsOtherLocation,
        setDetailsComments,
        setDetailsId,
        setDetailsSold,
        setDetailsImageUrl,
        setDetailsGarden,
        setDetailsLastEdit,
        setDetailsImageNumber,
        setDetailsOriginalPath,
        setDetailsPriority,
        setDetailsCreatedAt
    } = useContext(MobileAdminContext)

    const lotCardStyle = {
        width: '95%',
        padding: '0',
        textTransform: 'none',
        borderRadius: '15px',
    }
    function openIndividualLot(id) {
        loadingIndividualLot()

        if (filterLocation === 'sold') {
            firebase.firestore().collection('sold').doc(id).get()
            .then(doc => {
                setDetailsPriority(doc.data().priority)
                setDetailsLocation(doc.data().location)
                setDetailsSection(doc.data().section)
                setDetailsNumber(doc.data().lotNumber)
                setDetailsName(doc.data().lotName)
                setDetailsSpaces(doc.data().spaces)
                setDetailsPrice(doc.data().price)
                setDetailsOtherLocation(doc.data().otherLocation)
                setDetailsComments(doc.data().comments)
                setDetailsId(doc.id)
                setDetailsImageUrl(doc.data().imageUrl)
                setDetailsOriginalPath(doc.data().originalPath)
                setDetailsSold(true)
                setDetailsCreatedAt(doc.data().createdAt)
                setDetailsGarden(doc.data().garden)
                setDetailsLastEdit(doc.data().lastEdit)
                setDetailsImageNumber(doc.data().imageNumber)
            })
            .then(() => {
                setTimeout(() => {
                    loadedIndividualLot()
                }, 1500)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else {
            firebase.firestore().collection(filterLocation).doc(id).get()
            .then(doc => {
                setDetailsId(doc.id)
                setDetailsPriority(doc.data().priority)
                setDetailsLocation(doc.data().location)
                setDetailsSection(doc.data().section)
                setDetailsNumber(doc.data().lotNumber)
                setDetailsName(doc.data().lotName)
                setDetailsSpaces(doc.data().spaces)
                setDetailsPrice(doc.data().price)
                setDetailsOtherLocation(doc.data().otherLocation)
                setDetailsComments(doc.data().comments)
                setDetailsGarden(doc.data().garden)
                
                setDetailsImageUrl(doc.data().imageUrl)
                setDetailsImageNumber(doc.data().imageNumber)
                setDetailsOriginalPath(doc.data().originalPath)
                
                setDetailsSold(false)
                
                setDetailsCreatedAt(doc.data().createdAt)
                setDetailsLastEdit(doc.data().lastEdit)
            })
            .then(() => {
                setTimeout(() => {
                    loadedIndividualLot()
                }, 1500)
            })
            .catch(err => {
                console.error(err.code)
            })
        }

    }

    if (filterLocation !== 'sold') {
        if (location === 'Sharon') {
            let sectionFix
            if (section === '4-ORCHARD') {
                sectionFix = (
                    <div>
                        4
                        <br/>
                        <div className='t-ac-lot-list-orchard'>
                            Orchard
                        </div>
                    </div>
                )
            } else if (section === '26A-OVAL') {
                sectionFix = (
                    <div>
                        26A
                        <br/>
                        <div className='t-ac-lot-list-orchard'>
                            Oval
                        </div>
                    </div>
                )
            } else {
                sectionFix = section
            }
            return (
                <Button
                    style={lotCardStyle}
                    onClick={() => openIndividualLot(id)}>
                    <div className='t-ac-lotcard-container'>
                        <div className='t-ac-lotcard-sharon-section'>
                            {sectionFix}
                        </div>
                        <div className='t-ac-lotcard-number'>
                            {lotNumber}
                        </div>
                    </div>
                </Button>
            )
        } else if (location === 'Interfaith') {
    
            let beershebaCheck
            if (section === '1') {
                beershebaCheck = 'Beersheba I'
            } else if (section === '2') {
                beershebaCheck = 'Beersheba II'
            } else if (section === '3') {
                beershebaCheck = 'Beersheba III'
            }
            return (
                <Button
                    style={lotCardStyle}
                    onClick={() => openIndividualLot(id)}>
                    <div className='t-ac-lotcard-container'>
                        <div className='t-ac-lotcard-sharon-section'>
                            {beershebaCheck}
                        </div>
                        <div className='t-ac-lotcard-number'>
                            {lotNumber}
                        </div>
                    </div>
                </Button>
            )
        } else if (location === 'Knollwood') {
            let lotNameCheck
            if (lotName === 'WestChapel') {
                lotNameCheck = 'West Chapel'
            } else {
                lotNameCheck = lotName
            }
            return (
                <Button
                    style={lotCardStyle}
                    onClick={() => openIndividualLot(id)}>
                    <div className='t-ac-lotcard-container'>
                        <div className='t-ac-lotcard-sharon-section'>
                            {lotNameCheck}
                        </div>
                        <div className='t-ac-lotcard-number'>
                            {lotNumber}
                        </div>
                    </div>
                </Button>
            )
        } else if (location === 'Estate' || location === 'Other') {
            return (
                <Button
                    style={lotCardStyle}
                    onClick={() => openIndividualLot(id)}>
                    <div className='t-ac-lotcard-container'>
                        <div className='t-ac-lotcard-location'>
                            {otherLocation}
                        </div>
                    </div>
                </Button>
            )
        } else if (location === 'Shara') {
            return (
                <Button
                    style={lotCardStyle}
                    onClick={() => openIndividualLot(id)}>  
                <div className='t-ac-lotcard-container'>
                    <div className='t-ac-lotcard-shara'>
                        {location}
                    </div>
                </div>
                </Button>
            )
        }
    } else if (filterLocation === 'sold') {
        let allsection
        if (location === 'Sharon') {
            allsection = section
        } else if (location === 'Knollwood') {
            allsection = lotName
        } else if (location === 'Interfaith') {
            if (section === '1') {
                allsection = 'Beersheba I'
            } else if (section === '2') {
                allsection = 'Beersheba II'
            } else if (section === '3') {
                allsection = 'Beersheba III'
            }
        } else {
            allsection = ''
        }

        return (
            <Button
                style={lotCardStyle}
                onClick={() => openIndividualLot(id)}>
                <div className='t-ac-lotcard-container'>

                    <div className="t-ac-lot-list-sold-location">
                        {location}
                    </div>
                    <div className="t-ac-lot-list-sold-section">
                        {allsection}
                    </div>
                    <div className="t-ac-lot-list-sold-number">
                        {lotNumber}
                    </div>
                </div>
            </Button>
        )
    }
}