import React from 'react'

import LotCardForm from './LotCardForm'
import LotCardDivider from './LotCardDivider'
import LotCardPicture from './LotCardPicture'

// import { AdminContext } from './../../../../Contexts/AdminContext'

// import firebase from 'firebase/app'
// import 'firebase/firestore'

export default function LotCardBody() {


    // let {
    //     lotCreated
    //     } = useContext(AdminContext)

    return (
        <div className='admin-final-d-lot-card-body-container'>
           <LotCardForm />
           <LotCardDivider />
           <LotCardPicture />
        </div>
    )
}