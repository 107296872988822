import React from 'react'

import { Link } from 'react-router-dom'
// import Button from '@material-ui/core/Button'

import InfoIcon from '@material-ui/icons/Info'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import PersonIcon from '@material-ui/icons/Person'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import ContactMailIcon from '@material-ui/icons/ContactMail'

import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'

export default function HomeMobile() {

    const iconStyle = {
        fontSize: '40px'
    }
    const contactStyle = {
        fontSize: '25px',
        padding: '8px'
    }
    return (
        <div className='m-home-container'>
            <div className="m-home-scroll-container">
                <div className="m-home-brand">
                    The Lot Broker
                </div>
                <div className='m-home-contact-row'>
                    <div className="m-home-contact-name">
                        Gary Levine
                    </div>
                    <a href="tel:6176403143" className='m-link-fix'>
                        <div className="m-home-contact-row-item">
                            <PhoneIcon style={contactStyle} />
                        </div>
                    </a>
                    <a href="mailto:GLevine@TheLotBroker.com" className='m-link-fix'>
                        <div className="m-home-contact-row-item">
                            <EmailIcon style={contactStyle} />
                        </div>
                    </a>
                </div>
                <div className="m-home-statement">
                    Bringing Buyers and Sellers of Interment Spaces Together
                </div>
                
                <div className="m-home-info-top">
                    Lots available now at Sharon and
                    <br/>
                    Knollwood Memorial Parks
                    <br/>
                    Up to 50% savings off current park prices
                </div>
                <div className="m-home-info-bottom">
                    The Lot Broker has been actively adding more lots weekly. 
                    We now have lots available in Sharon Memorial Park in over 50+ 
                    sections and counting. Lots are available in 1,2,3,4,6,8 and 12 spaces 
                    depending on the lot. Spaces start at only $2000, and most are 
                    priced at 50% or less than regular park prices.
                </div>
                <div className="m-home-info-bottom">
                We have 12+ sections available in Knollwood, including all (4) veteran sections. Spaces start at $1050.00 each
                </div>
                <div className="m-home-info-bottom">
                Please check out our other section listed under current Inventory for additional cemetery offerings!
                </div>
                <Link to='/about' style={{textDecoration: 'none'}}>
                    <div className='m-home-option-button m-home-option-top'>
                        <div className='m-home-option-icon'>
                            <InfoIcon style={iconStyle}/>
                        </div>
                        <div className='m-home-option-text'>
                            About
                            
                            The Lot Broker
                        </div>
                    </div>
                </Link>

                <Link to='/gallery' style={{textDecoration: 'none'}}>
                    <div className='m-home-option-button'>
                        <div className='m-home-option-icon'>
                            <PhotoLibraryIcon style={iconStyle}/>
                        </div>
                        <div className='m-home-option-text'>
                            View 
                            
                            Galleries
                        </div>
                    </div>
                </Link>

                <Link to='/directors' style={{textDecoration: 'none'}}>
                    <div className='m-home-option-button'>
                        <div className='m-home-option-icon'>
                            <PersonIcon style={iconStyle}/>
                        </div>
                        <div className='m-home-option-text'>
                            Funeral
                            
                            Directors
                        </div>
                    </div>
                </Link>

                <Link to='/inventory' style={{textDecoration: 'none'}}>
                    <div className='m-home-option-button'>
                        <div className='m-home-option-icon'>
                            <FormatListBulletedIcon style={iconStyle}/>
                        </div>
                        <div className='m-home-option-text'>
                            View 
                            
                            Current Inventory
                        </div>
                    </div>
                </Link>

                <Link to='/contact' style={{textDecoration: 'none'}}>
                    <div className='m-home-option-button m-home-option-bottom'>
                        <div className='m-home-option-icon'>
                            <ContactMailIcon style={iconStyle}/>
                        </div>
                        <div className='m-home-option-text'>
                            Contact 
                            
                            The Lot Broker
                        </div>
                    </div>
                </Link>
                <div className='m-scroll-fix'>

                </div>
            </div>
        </div>
    )
}