import React from 'react'

import AddNewsButton from './AddNewsButton'
import AddNewsBackdrop from './AddNewsBackdrop'

import NewsListings from './NewsListings'
export default function AdminNews() {

    return (
        <div className='d-a-news-container'>
            <AddNewsBackdrop />
            <div className='d-a-news-header'>
                <div className='d-a-news-header-item'>
                    The Lot Broker News
                </div>
                <div className='d-a-news-header-item'>
                    <AddNewsButton />
                </div>
            </div>
            <div className="d-a-news-content-container">
                <div className="d-a-news-content-header">
                    <div className="d-a-news-content-header-listing">
                        News Listings
                    </div>
                    <div className="d-a-news-content-header-options">
                        Options
                    </div>
                </div>
                <NewsListings />
            </div>
        </div>
    )
}