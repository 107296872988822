import React from 'react'

export default function TabletFooter () {
    return (
        <div className='t-footer-container'>
            <div className='t-footer-item'>
                © 2020 The Lot Broker. All rights reserved.
            </div>

            <div className='t-footer-item'>
                Web application developed by <a className='t-footer-link' href='https://matthew-taylor.dev/' target='_blank' rel='noopener noreferrer'>Matt Taylor</a>
            </div>
        </div>
    )
}