import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'
import { CircularProgress } from '@material-ui/core'

import TestimonialCard from './TestimonialCard'

export default function AllTestimonials() {

    let {
        loadingTestimonials,
        testimonials
    } = useContext(MobileAdminContext)

    let testimonialsContent
    if (loadingTestimonials) {
        testimonialsContent = (<CircularProgress style={{color: 'white', marginTop: '30px'}} size={140} /> )
    } else {
        if (testimonials.length > 0) {
            testimonialsContent = testimonials.map(testimonial => {
                return (
                    <TestimonialCard 
                            body={testimonial.body}
                            name={testimonial.name}
                            date={testimonial.date}
                            key={testimonial.id}
                            id={testimonial.id}
                            published={testimonial.published}
                        />
                )
            })
        } else {
            testimonialsContent = (
                <div className='m-ac-testimonials-none'>
                    No pending testimonials
                </div>
            )
        }
        
    }
    return (
        <div className='m-ac-testimonials-all-container'>
            {testimonialsContent}
            <div className="m-scroll-fix"></div>
            <div className="m-scroll-fix"></div>
        </div>
    )
}