import React, { useContext } from 'react'

import LocationCard from './LocationCard'

import { DirectorsContext } from './../../Contexts/DirectorsContext'

export default function GardenList() {
    let {
        gardenList
    } = useContext(DirectorsContext)

    let gardenListContent = gardenList.map(lot => {
        return (
            <LocationCard
                key={lot.id}
                lotName={lot.lotName}
                lotNumber={lot.lotNumber}
                price={lot.price}
                spaces={lot.spaces}
            />
        )
    })



    return (
        <div className='u-d-directors-category'>
            <div className='u-d-d-header'>
                Garden
            </div>
            <div className='u-d-d-label'>
                <div className="u-d-d-label-item">
                    Name
                </div>
                <div className="u-d-d-label-item">
                    Number
                </div>
                <div className="u-d-d-label-item">
                    Spaces
                </div>
                <div className="u-d-d-label-item">
                    Price
                </div>
            </div>
            <div className='u-d-d-lotlist'>
                {gardenListContent}
            </div>
        </div>
    )
}