import React, { createContext, Component } from 'react'

export const InventoryContext = createContext()

export class InventoryContextProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLotList: false,
            location: null,
            locationChosen: false,
            setLocationChosen: () => {
                this.setState({
                    locationChosen: true,
                    loading: false,
                    showLotList: true
                })
            },
            disableButtons: false,
            loading: false,
            setInitialLocation: (location) => {
                this.setState({ location, disableButtons: true, loading: true })
            },
            clearLocation: () => {
                this.setState({ location: null })
            },

            visibleLots: [],
            addToVisibleLots: (lot) => {
                this.setState({
                    visibleLots: [...this.state.visibleLots, lot]
                })
            },
            clearVisibleLots: () => {
                this.setState({ visibleLots: [] })
            },

            anchor: null,
            locationMenu: false,
            nameMenu: false,
            disableMenu: false,
            setLocationAnchor: (anchor) => {
                this.setState({ 
                    anchor, 
                    locationMenu: true
                })
            },
            setNameAnchor: (anchor) => {
                this.setState({
                    anchor,
                    nameMenu: true
                })
            },
            clearAnchors: () => {
                this.setState({
                    anchor: null,
                    locationMenu: false,
                    nameMenu: false
                })
            },
            changeLocation: (location) => {
                this.setState({ 
                    location,
                    loading: true,
                    locationMenu: false,
                    disableMenu: true
                })
            },
            changeLocationSuccess: () => {
                this.setState({
                    loading: false,
                    disableMenu: false,
                    filterSection: null,
                    showLotList: true
                })
            },
            handleChange: (event) => {
                this.setState({
                    [event.target.name]: event.target.value
                })
            },
            filterSection: null,
            filteringBySection: () => {
                this.setState({
                    loading: true,
                    disableMenu: true,
                    visibleLots: []
                })
            },
            clearFilterSection: () => {
                this.setState({
                    filterSection: null
                })
            },
            filteredSuccess: () => {
                this.setState({
                    loading: false,
                    disableMenu: false,
                    showLotList: true
                })
            },
            lotName: null,
            setLotName: (lotName) => {
                this.setState({ lotName})
            },
            filteringByName: () => {
                this.setState({
                    nameMenu: false,
                    loading: true,
                    disableMenu: true,
                    visibleLots: []
                })
            },
            clearFilterName: () => {
                this.setState({
                    lotName: null
                })
            },
            showingDetails: false,
            detailsId: null,
            loadingDetails: false,
            showDetails: (id) => {
                this.setState({
                    showingDetails: true,
                    detailsId: id,
                    loadingDetails: true
                })
            },
            loadedDetails: () => {
                this.setState({
                    loadingDetails: false
                })
            },
            hideDetails: () => {
                this.setState({
                    showingDetails: false,
                    detailsId: null,
                    detailsSection: null,
                    detailsNumber: null,
                    detailsName: null,
                    detailsPrice: null,
                    detailsComments: null,
                    detailsOtherLocation: null,
                    detailsSpaces: null,
                    detailsImageUrl: null,
                    detailsGarden: null
                })
            },
            detailsSection: null,
            detailsNumber: null,
            detailsName: null,
            detailsPrice: null,
            detailsComments: null,
            detailsOtherLocation: null,
            detailsSpaces: null,
            detailsImageUrl: null,
            detailsGarden: null,

            setDetailsSection: (detailsSection) => {
                this.setState({
                    detailsSection
                })
            },
            setDetailsNumber: (detailsNumber) => {
                this.setState({
                    detailsNumber
                })
            },
            setDetailsName: (detailsName) => {
                this.setState({
                    detailsName
                })
            },
            setDetailsPrice: (detailsPrice) => {
                this.setState({
                    detailsPrice
                })
            },
            setDetailsComments: (detailsComments) => {
                this.setState({
                    detailsComments
                })
            },
            setDetailsOtherLocation: (detailsOtherLocation) => {
                this.setState({
                    detailsOtherLocation
                })
            },
            setDetailsSpaces: (detailsSpaces) => {
                this.setState({
                    detailsSpaces
                })
            },
            setDetailsImageUrl: (detailsImageUrl) => {
                this.setState({
                    detailsImageUrl
                })
            },
            setDetailsGarden: (detailsGarden) => {
                this.setState({
                    detailsGarden
                })
            },
            // messages
            showingMessage: false,
            message: null,
            showMessage: () => {
                this.setState({
                    showingMessage: true
                })
            },
            cancelMessage: () => {
                this.setState({
                    showingMessage: false,
                    message: null,
                    sendingMessage: false, 
                    messageSent: false
                })
            },
            sendingMessage: false,
            messageSent: false,
            sendMessage: () => {
                this.setState({
                    sendingMessage: true
                })
            },
            messageSentSuccessfully: () => {
                this.setState({
                    sendingMessage: false,
                    messageSent: true
                })
            },

            testLoading: () => {
                this.setState({
                    loading: true 
                })
            },
            mobileShowDetails: false,
            mobileLoading: false,
            mobileLoaded: false,
            mobileLoadDetails: () => {
                this.setState({
                    mobileLoading: true,
                    mobileShowDetails: true
                })
            },
            mobileLoadedDetails: () => {
                this.setState({
                    mobileLoading: false,
                    mobileLoaded: true
                })
            },
            mobileCloseDetails: () => {
                this.setState({
                    mobileShowDetails: false,
                })
                setTimeout(() => {
                    this.setState({
                        mobileLoaded: false,
                        detailsImageUrl: null,
                        detailsId: null,
                        detailsSection: null,
                        detailsNumber: null,
                        detailsName: null,
                        detailsPrice: null,
                        detailsComments: null,
                        detailsOtherLocation: null,
                        detailsSpaces: null,
                        detailsGarden: null,
                        messageSent: false
                    })
                }, 550)
            },
            showingContact: false,
            showContact: () => {
                this.setState({
                    showingContact: true
                })
            },
            closeContact: () => {
                this.setState({
                    showingContact: false
                })
            },
            closeContactAndMessage: () => {
                this.setState({
                    showingContact: false,
                    showingMessage: false,
                })
                setTimeout(() => {
                    this.setState({
                        messageSent: false
                    })
                }, 500)
            },
            cancelSendingMessage: () => {
                this.setState({
                    showingMessage: false
                })
            },
            finishSendingMessage: () => {
                this.setState({
                    showingMessage: false,
                    messageSent: true,
                    sendingMessage: false
                })
            }



        }
    }

    // details
    
    render() {
        return (
            <InventoryContext.Provider value={this.state}>
                {this.props.children}
            </InventoryContext.Provider>
        )
    }
}