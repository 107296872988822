import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker'
import firebase from 'firebase/app'
import 'firebase/analytics'

import {GlobalContextProvider} from './components/Contexts/GlobalContext'

var firebaseConfig = {
    apiKey: "AIzaSyBF5847Ou4hNR-pE8bxEz1HKNTyCYqmS9E",
    authDomain: "thelotbroker-client.firebaseapp.com",
    databaseURL: "https://thelotbroker-client.firebaseio.com",
    projectId: "thelotbroker-client",
    storageBucket: "thelotbroker-client.appspot.com",
    messagingSenderId: "445004847628",
    appId: "1:445004847628:web:b4af6d19c1c53e968cec29",
    measurementId: "G-P7YK9HM4QC"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

ReactDOM.render(<GlobalContextProvider><App /></GlobalContextProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
