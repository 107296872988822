import React from 'react'

import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Logo from './logo.png'

export default function MobileNav () {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const dropdownStyle = {
        fontSize: '50px'
    }
    const menuStyle = {
        backgroundColor: 'rgb(150, 208, 231)'
    }
    const menuStyle2 = {
        zIndex: '5903384987328'
    }
    const dropdownItemStyle = {
        textDecoration: 'none', 
        textAlign: 'center', 
        width: '100%',
    }

    return (
        <div className='m-nav-container'>
            <Link to='/' style={{ textDecoration: 'none' }} className='m-nav-brand-container'>
                <img src={Logo} alt='logo' className='m-nav-logo'/>
                <div className='m-nav-brand'>The Lot Broker</div>
            </Link>
            <div className='m-nav-dropdown'>
                <Button aria-controls='fade-menu' aria-haspopup='true' onClick={handleClick}>
                    <MoreHorizIcon style={dropdownStyle}/>
                </Button>
                <Menu
                    id='m-nav-menu'
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={menuStyle2}
                    MenuListProps={{ disablePadding: true }}
                >
                    <MenuItem onClick={handleClose} style={menuStyle}>
                        <Link to='/about' style={dropdownItemStyle}>
                            <div className='m-nav-item'>About</div>
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose} style={menuStyle}>
                        <Link to='/gallery' style={dropdownItemStyle}>
                            <div className='m-nav-item'>Gallery</div>
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose} style={menuStyle}>
                        <Link to='/directors' style={dropdownItemStyle}>
                            <div className='m-nav-item'>Funeral Directors</div>
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose} style={menuStyle}>
                        <Link to='/inventory' style={dropdownItemStyle}>
                            <div className='m-nav-item'>Current Inventory</div>
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose} style={menuStyle}>
                        <Link to='/contact' style={dropdownItemStyle}>
                            <div className='m-nav-item'>Contact</div>
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose} style={menuStyle}>
                        <Link to='/admin' style={dropdownItemStyle}>
                            <div className='m-nav-item'>Admin</div>
                        </Link>
                    </MenuItem>
                </Menu>
            </div>
      </div>
    )
}