import React from 'react'

import Location from './Location'
import Beersheba from './Beersheba'
import LotName from './LotName'
import Details from './Details'
import Submit from './Submit'
import Comments from './Comments'

export default function AddLot() {

    return (
        <div className='m-ac-add-lot-container'>
            <Location />
            <Beersheba />
            <LotName />
            <Details />
            <Comments />
            <Submit />
        </div>
    )
}