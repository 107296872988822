import React, { useContext } from 'react'
import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function LotListHeader() {

    let {
        filterLocation
    }= useContext(MobileAdminContext)

    if (filterLocation === 'Sharon' || filterLocation === 'Interfaith') {
        return (
            <div className="t-ac-lot-list-header">
                <div className="t-ac-lot-list-header-section">
                    Section
                </div>
                <div className="t-ac-lot-list-header-number">
                    Lot Number
                </div>
            </div>
        )
    } else if (filterLocation === 'Knollwood') {
        return (
            <div className="t-ac-lot-list-header">
                <div className="t-ac-lot-list-header-section">
                    Lot Name
                </div>
                <div className="t-ac-lot-list-header-number">
                    Lot Number
                </div>
            </div>
        )
    } else if (filterLocation === 'Estate' || filterLocation === 'Other') {
        return (
            <div className="t-ac-lot-list-header">
                <div className="t-ac-lot-list-header-location">
                    Location
                </div>
            </div>
        )
    } else if (filterLocation === 'Shara') {
        return (
            <div className="t-ac-lot-list-header">
                    Shara Lots
            </div>
        )
    } else if (filterLocation === 'sold') {
        return (
            <div className="t-ac-lot-list-header">
                <div className="t-ac-lot-list-header-sold-location">
                    Location
                </div>
                <div className="t-ac-lot-list-header-sold-section">
                    Section/Name
                </div>
                <div className="t-ac-lot-list-header-sold-number">
                    Lot Number
                </div>
            </div>
        )
    }
}