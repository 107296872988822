import React, { useContext } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import { MobileAdminContext } from '../../../../../Contexts/MobileAdminContext'

export default function DeletePictureWarning() {

    let {
        showingDeletePictureWarning,
        closeDeletePictureWarning,
        detailsOriginalPath,
        deleteImage,
        deletedImage,
        detailsSold,
        detailsLocation,
        detailsId,
        deletingImage
        // detailsLocation,
        // detailsSection,
        // detailsNumber,
        // details
    }= useContext(MobileAdminContext)

    const warningStyle = {
        marginTop: '9vh',
        zIndex: '50000',
        backgroundColor: 'rgba(0,0,0,0)',
        width: '96vw',
        marginLeft: '2vw',
        height: '80vw'
    }
    const closeButtonStyle = {
        padding: '0',
        minHeight: '0',
        marginRight: '5px'
    }
    const deleteButtonStyle = {
        padding: '0',
        minHeight: '0',
        // backgroundColor: 'red',
        textTransform: 'none'
    }
    const cancelButtonStyle = {
        padding: '0',
        minHeight: '0',
        textTransform: 'none'
    }
    const feedbackStyle = {
        width: '100%',
        height: '100%'
    }
    let deletingContent

    if (deletingImage) {
        deletingContent = ( <LinearProgress style={feedbackStyle} color="secondary" />)
    } else {
        deletingContent = ( '' )

    }
    function deletePicture() {
        deleteImage()
        firebase.storage().ref(detailsOriginalPath)
        .delete()
        .then(() => {
            if (!detailsSold) {
                let deletedInfo = {
                    imageUrl: null,
                    imageNumber: null,
                    originalPath: null
                }
                firebase.firestore().collection(detailsLocation).doc(detailsId)
                .update(deletedInfo)
                .catch(err => {
                    console.error(err.code)
                })
            }
        })
        .then(() => {
            setTimeout(() => {
                deletedImage()
            }, 1000)
        })
        .catch(err => {
            console.error(err.code)
        })
    }
    return (
        <Backdrop
                transitionDuration={300}
                style={warningStyle}
                open={showingDeletePictureWarning}
            >
                <div className='m-ac-delete-picture-warning'>
                    <div className="m-ac-delete-picture-header">
                        <div className="m-ac-delete-picture-brand">
                            Delete Lot Picture
                        </div>
                        <Button 
                            disabled={deletingImage}
                            style={closeButtonStyle}
                            variant='contained'
                            color='default'
                            onClick={() => closeDeletePictureWarning()}
                        >
                            <div className="m-ac-delete-picture-close-button">
                                Close
                            </div>
                        </Button>
                    </div>
                    <div className="m-ac-delete-picture-content">
                        Are you sure you wish to delete this picture?
                    </div>
                    <div className="m-ac-delete-picture-submit-row">
                        <Button
                            disabled={deletingImage}
                            style={deleteButtonStyle}
                            variant='contained'
                            onClick={() => deletePicture()}
                        >
                            <div className="m-ac-delete-picture-delete-button">
                                Yes - Delete
                            </div>
                        </Button>
                        <Button
                            disabled={deletingImage}
                            style={cancelButtonStyle}
                            variant='contained'
                            color='default'
                            onClick={() => closeDeletePictureWarning()}
                        >
                            <div className="m-ac-delete-picture-cancel-button">
                                Cancel
                            </div>
                        </Button>
                    </div>
                    <div className="m-ac-delete-picture-feedback-row">
                        {deletingContent}
                    </div>
                </div>
            </Backdrop>
    )
}