import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { AdminContext } from './../../../../Contexts/AdminContext'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default function AdminLotFilterLocation() {

    let {
        // adminVisibleLotList,
        loadingLocation,
        startLoadingLocation,
        stopLoadingLocation,
        setFilterLocation,
        filterLocation,
        clearAnchors,
        anchor,
        filterLocationMenu,
        setFilterLocationAnchor,
        clearNameFilter,
        addLotToVisibleList,
        lotIsNotSold
    } = useContext(AdminContext)

    let filterLocationCheck, disabledMenuCheck


    function checkFilterLocation(collection) {
        startLoadingLocation()
        setFilterLocation(collection)
        clearNameFilter()
        if (collection !== 'sold') {
            lotIsNotSold()
        }
        if (collection === 'Sharon' || collection === 'Interfaith') {
            firebase.firestore().collection(collection).orderBy('priority').orderBy('section').orderBy('lotNumber')
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let lotDetails = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addLotToVisibleList(lotDetails)
                })
            })
            .then(() => {
                setTimeout(() => {
                    stopLoadingLocation()
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (collection === 'Knollwood') {
            firebase.firestore().collection(collection).orderBy('lotName').orderBy('lotNumber')
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let lotDetails = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addLotToVisibleList(lotDetails)
                })
            })
            .then(() => {
                setTimeout(() => {
                    stopLoadingLocation()
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (collection === 'sold') {
            firebase.firestore().collection('sold').where('location', '==', 'Sharon').orderBy('priority').orderBy('section')
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let lotDetails = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addLotToVisibleList(lotDetails)
                })
            })
            .then(() => {
                setTimeout(() => {
                    stopLoadingLocation()
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else {
            firebase.firestore().collection(collection)
            .get()
            .then((snapshot) => {
                snapshot.forEach(lot => {
                    let lotDetails = {
                        lotNumber: lot.data().lotNumber,
                        location: lot.data().location,
                        section: lot.data().section,
                        lotName: lot.data().lotName,
                        otherLocation: lot.data().otherLocation,
                        price: lot.data().price,
                        lotId: lot.id,
                        spaces: lot.data().spaces
                    }
                    addLotToVisibleList(lotDetails)
                })
            })
            .then(() => {
                setTimeout(() => {
                    stopLoadingLocation()
                }, 700)
            })
            .catch(err => {
                console.error(err.code)
            })
        }
    }

    loadingLocation
        ? disabledMenuCheck = true
        : disabledMenuCheck = false

    filterLocation
        ? filterLocationCheck = filterLocation
        : filterLocationCheck = ( 'Locations' )
    return (
        <div className='context-lot-filter-location'>
            <Button
                disabled={disabledMenuCheck}
                aria-controls="location-menu" 
                aria-haspopup="true" 
                onClick={event => {
                    setFilterLocationAnchor(event.currentTarget)
                }}>
                    <div className='context-lot-filter-font'>
                        {filterLocationCheck}
                    </div>
            </Button>
            <Menu
                style={{ zIndex: 1000030}}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="filter-location-menu"
                anchorEl={anchor}
                keepMounted
                open={filterLocationMenu}
                onClose={() => {
                    clearAnchors()
                }}>
                <MenuItem onClick={() => {
                    checkFilterLocation('Sharon')
                }}><div className='context-lot-filter-location-item'>Sharon</div></MenuItem>
                <MenuItem onClick={() => {
                    checkFilterLocation('Interfaith')
                }}><div className='context-lot-filter-location-item'>Interfaith</div></MenuItem>
                <MenuItem onClick={() => {
                    checkFilterLocation('Knollwood')
                }}><div className='context-lot-filter-location-item'>Knollwood</div></MenuItem>
                <MenuItem onClick={() => {
                    checkFilterLocation('Shara')
                }}><div className='context-lot-filter-location-item'>Shara Tfilo</div></MenuItem>
                <MenuItem onClick={() => {
                    checkFilterLocation('Estate')
                }}><div className='context-lot-filter-location-item'>Estates</div></MenuItem>
                <MenuItem onClick={() => {
                    checkFilterLocation('Other')
                }}><div className='context-lot-filter-location-item'>Other</div></MenuItem>
                <MenuItem onClick={() => {
                    checkFilterLocation('sold')
                }}><div className='context-lot-filter-location-item'>Sold</div></MenuItem>
            </Menu>
        </div>
    )
}