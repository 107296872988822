import React from 'react'

import HomeDesktopAdmin from './HomeDesktopAdmin'
import HomeDesktopGuest from './HomeDesktopGuest'

import { AdminContextProvider } from './../../Contexts/AdminContext'


export default class HomeDesktop extends React.Component {
    constructor () {
        super()

        this.state = {

        }
    }

    render () {
        let adminStatus

        this.props.admin
        // true
            ? adminStatus = ( 
                <AdminContextProvider>
                    <HomeDesktopAdmin />
                </AdminContextProvider>
            )
            : adminStatus = ( <HomeDesktopGuest />)
        return (
            <div className='home-d-container'>
                {adminStatus}
            </div>
        )
    }
}