import React, {useContext} from 'react'

import {InventoryContext} from '../../../../Contexts/InventoryContext'

export default function MobileDetailsInfo() {
    let {
        detailsSection,
        detailsNumber,
        detailsName,
        detailsPrice,
        detailsOtherLocation,
        detailsSpaces,
        detailsGarden,
        location
    } = useContext(InventoryContext)

    let topRow, bottomRow
    if (location === 'Sharon' || location === 'Interfaith') {
        let numberFix 
        if (detailsNumber.length > 4) {
            numberFix = (
                <div className='t-details-number-fix'>
                    {detailsNumber}
                </div>
            )
        } else {
            numberFix = detailsNumber
        }
        topRow = (
            <div className='t-details-sharon-top-row'>
                <div className="t-details-sharon-top-location">
                    <div className="t-details-sharon-top-label">
                        Location
                    </div>
                    <div className="t-details-sharon-top-info">
                        {location}
                    </div>
                </div>
                <div className="t-details-sharon-top-section">
                    <div className="t-details-sharon-top-label">
                        Section
                    </div>
                    <div className="t-details-sharon-top-info">
                        {detailsSection}
                    </div>
                </div>
                <div className="t-details-sharon-top-number">
                    <div className="t-details-sharon-top-label">
                        Number
                    </div>
                    <div className="t-details-sharon-top-info">
                        {numberFix}
                    </div>
                </div>
            </div>
        )
        bottomRow = (
            <div className='t-details-sharon-bottom-row'>
                <div className="t-details-sharon-bottom-spaces">
                    <div className="t-details-sharon-bottom-label">
                        Spaces Available
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        {detailsSpaces}
                    </div>
                </div>
                <div className="t-details-sharon-bottom-price">
                    <div className="t-details-sharon-bottom-label">
                        Price
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        ${detailsPrice} each
                    </div>
                </div>
            </div>
        )
    } else if (location === 'Knollwood') {
        let gardenCheck, nameCheck
        if (detailsGarden && detailsName !== 'WestChapel') {
            gardenCheck = 'Garden of'
            nameCheck = detailsName
        } else if (detailsName === 'WestChapel') {
            gardenCheck = 'Urn'
            nameCheck = 'West Chapel'
        } else {
            gardenCheck = 'Veterans'
            nameCheck = detailsName
        }
        let numberFix 
        if (detailsNumber.length > 4) {
            numberFix = (
                <div className='t-details-number-fix'>
                    {detailsNumber}
                </div>
            )
        } else {
            numberFix = detailsNumber
        }
        topRow = (
            <div className='t-details-sharon-top-row'>
                <div className="t-details-sharon-top-location">
                    <div className="t-details-sharon-top-label">
                        Location
                    </div>
                    <div className="t-details-sharon-top-info">
                        Knollwood
                    </div>
                </div>
                <div className="t-details-sharon-top-section">
                    <div className="t-details-sharon-top-label">
                        {gardenCheck}
                    </div>
                    <div className="t-details-sharon-top-info">
                        {nameCheck}
                    </div>
                </div>
                <div className="t-details-sharon-top-number">
                    <div className="t-details-sharon-top-label">
                        Number
                    </div>
                    <div className="t-details-sharon-top-info">
                        {numberFix}
                    </div>
                </div>
            </div>
        )
        bottomRow = (
            <div className='t-details-sharon-bottom-row'>
                <div className="t-details-sharon-bottom-spaces">
                    <div className="t-details-sharon-bottom-label">
                        Spaces Available
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        {detailsSpaces}
                    </div>
                </div>
                <div className="t-details-sharon-bottom-price">
                    <div className="t-details-sharon-bottom-label">
                        Price
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        ${detailsPrice} each
                    </div>
                </div>
            </div>
        )
    } else if (location === 'Shara') {
        topRow = ( '' )
        bottomRow = (
            <div className='t-details-shara-full-row'>
                <div className="t-details-sharon-bottom-spaces">
                    <div className="t-details-sharon-bottom-label">
                        Spaces Available
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        {detailsSpaces}
                    </div>
                </div>
                <div className="t-details-sharon-bottom-price">
                    <div className="t-details-sharon-bottom-label">
                        Price
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        ${detailsPrice} each
                    </div>
                </div>
            </div>
        )
    } else if (location === 'Estate') {
        topRow = (
            <div className='t-details-sharon-top-row'>
                <div className="t-details-estate-top-location">
                    <div className="t-details-sharon-top-label">
                        Location
                    </div>
                    <div className="t-details-estate-top-info">
                        {detailsOtherLocation}
                    </div>
                </div>
            </div>
        )
        bottomRow = (
            <div className='t-details-sharon-bottom-row'>
                <div className="t-details-sharon-bottom-spaces">
                    <div className="t-details-sharon-bottom-label">
                        Spaces Available
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        {detailsSpaces}
                    </div>
                </div>
                <div className="t-details-sharon-bottom-price">
                    <div className="t-details-sharon-bottom-label">
                        Price
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        ${detailsPrice}
                    </div>
                </div>
            </div>
        )
    } else if (location === 'Other') {
        topRow = (
            <div className='t-details-sharon-top-row'>
                <div className="t-details-other-top-location">
                    <div className="t-details-sharon-top-label">
                        Location
                    </div>
                    <div className="t-details-sharon-top-info">
                        {detailsOtherLocation}
                    </div>
                </div>
            </div>
        )
        bottomRow = (
            <div className='t-details-sharon-bottom-row'>
                <div className="t-details-sharon-bottom-spaces">
                    <div className="t-details-sharon-bottom-label">
                        Spaces Available
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        {detailsSpaces}
                    </div>
                </div>
                <div className="t-details-sharon-bottom-price">
                    <div className="t-details-sharon-bottom-label">
                        Price
                    </div>
                    <div className="t-details-sharon-bottom-info">
                        ${detailsPrice} each
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='t-details-info-container'>
            {topRow}
            {bottomRow}
        </div>
    )
}