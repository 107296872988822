import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import firebase from 'firebase/app'
import 'firebase/firestore'

import {InventoryContext} from './../../../Contexts/InventoryContext'

export default function ChangeLocation() {

    let {
        location,
        changeLocation,
        setLocationAnchor,
        anchor,
        locationMenu,
        clearAnchors,
        clearVisibleLots,
        addToVisibleLots,
        changeLocationSuccess,
        disableMenu,
        clearFilterSection,
        clearFilterName
    } = useContext(InventoryContext)

    function guestChangeLocation(location) {
        clearVisibleLots()

        changeLocation(location)

        if (location === 'Sharon' || location === 'Interfaith') {
            clearFilterSection()
            firebase.firestore().collection(location).orderBy('priority').orderBy('section').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        section: doc.data().section,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        lotNumber: doc.data().lotNumber,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    changeLocationSuccess()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Knollwood') {
            clearFilterName()
            firebase.firestore().collection(location).orderBy('lotName').orderBy('lotNumber')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        lotName: doc.data().lotName,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        lotNumber: doc.data().lotNumber,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    changeLocationSuccess()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Estate' || location === 'Other') {
            firebase.firestore().collection(location)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        otherLocation: doc.data().otherLocation,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    changeLocationSuccess()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'sold') {
            firebase.firestore().collection('sold').where('location', '==', 'Sharon').orderBy('priority').orderBy('section')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        otherLocation: doc.data().otherLocation,
                        section: doc.data().section,
                        lotName: doc.data().lotName,
                        lotNumber: doc.data().lotNumber,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                firebase.firestore().collection('sold').where('location', '==', 'Interfaith').orderBy('priority').orderBy('section')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let lot = {
                            location: doc.data().location,
                            otherLocation: doc.data().otherLocation,
                            section: doc.data().section,
                            lotName: doc.data().lotName,
                            lotNumber: doc.data().lotNumber,
                            spaces: doc.data().spaces,
                            price: doc.data().price,
                            id: doc.id,
                        }
                        addToVisibleLots(lot)
                    })
                })
            })
            .then(() => {
                firebase.firestore().collection('sold').where('location', '==', 'Knollwood')
                .get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        let lot = {
                            location: doc.data().location,
                            otherLocation: doc.data().otherLocation,
                            section: doc.data().section,
                            lotName: doc.data().lotName,
                            lotNumber: doc.data().lotNumber,
                            spaces: doc.data().spaces,
                            price: doc.data().price,
                            id: doc.id,
                        }
                        addToVisibleLots(lot)
                    })
                })
            })
            .then(() => {
                setTimeout(() => {
                    changeLocationSuccess()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        } else if (location === 'Shara') {
            firebase.firestore().collection('Shara')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    let lot = {
                        location: doc.data().location,
                        spaces: doc.data().spaces,
                        price: doc.data().price,
                        id: doc.id,
                    }
                    addToVisibleLots(lot)
                })
            })
            .then(() => {
                setTimeout(() => {
                    changeLocationSuccess()
                }, 1000)
            })
            .catch(err => {
                console.error(err.code)
            })
        }

    }
    
    let sharaCheck
    location === 'Shara'
        ? sharaCheck = 'Shara Tfilo'
        : location === 'Estate'
            ? sharaCheck = 'Estates'
            : location === 'sold'
                ? sharaCheck = 'Sold'
                : sharaCheck = location
    
    const menuItemStyle = {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        textAlign: 'center',
        fontSize: '1.7rem',
        textDecoration: 'none',
        // width: '40vw',
    }
    const menuStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        zIndex: '1000'
    }
    return (
        <div>
            <Button
                style={{ textTransform: 'none'}}
                disabled={disableMenu}
                aria-controls="location-menu" 
                aria-haspopup="true" 
                onClick={event => {
                    setLocationAnchor(event.currentTarget)
                }}>
                    <div className='m-inventory-current-location'>
                        {sharaCheck}
                    </div>
            </Button>
            <Menu
                transition={1000}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                id="view-location-menu"
                anchorEl={anchor}
                keepMounted
                open={locationMenu}
                style={menuStyle}
                MenuListProps={{ disablePadding: true}}
                onClose={() => { clearAnchors() }}>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    guestChangeLocation('Sharon')
                }}>Sharon</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    guestChangeLocation('Interfaith')
                }}>Interfaith</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    guestChangeLocation('Knollwood')
                }}>Knollwood</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    guestChangeLocation('Shara')
                }}>Shara Tfilo</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    guestChangeLocation('Estate')
                }}>Estates</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    guestChangeLocation('Other')
                }}>Other</MenuItem>
                <MenuItem 
                    style={menuItemStyle}
                    onClick={() => {
                    guestChangeLocation('sold')
                }}>Sold</MenuItem>
            </Menu>
        </div>
    )
}