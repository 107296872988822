import React, {
    useContext
} from 'react'

import {InventoryContext} from './../../../Contexts/InventoryContext'
import CircularProgress from '@material-ui/core/CircularProgress'
import LocationMenu from './LocationMenu'
import FilterMenu from './FilterMenu'
import InventoryView from './InventoryView'
export default function InventoryPage() {
    let {
        loading
    } = useContext(InventoryContext)

    let loadingFeedback
    loading
        ? loadingFeedback = ( <CircularProgress color='secondary' size={150}/> )
        : loadingFeedback = ( <InventoryView /> )

    return (
        <div className='d-inventory-loaded-container'>
            <div className='d-inventory-left'>   
                <LocationMenu />
                <FilterMenu />
            </div>
            <div className='d-inventory-right'>   
                {loadingFeedback}
            </div>
        </div>
    )
}