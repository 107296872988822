import React, {useContext} from 'react'

import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'

import {InventoryContext} from './../../../../Contexts/InventoryContext'

export default function MessageHeader() {

    let {
        cancelMessage,
        sendingMessage
    } = useContext(InventoryContext)


    const style = {
        color: 'white',
        fontSize: '5vh'
    }

    return (
        <div className="d-i-b-message-header">
            <div className='d-i-b-message-header-item'>
            Message The Lot Broker
            </div>
                <Button 
                    disabled={sendingMessage}
                    onClick={() => {
                        cancelMessage()
                    }}
                >
                    <CancelIcon style={style}/>
                </Button>
        </div>
    )
}