import React, { useContext } from 'react'

import AdminLotControl from './AdminControl/AdminLotControl'
import AdminGallery from './AdminGallery'
import AdminTestimonials from './AdminTestimonials'
import AdminOptions from'./AdminControl/AdminOptions'
import AdminAddLot from './AdminControl/AdminAddLot'
import AdminMessages from './AdminControl/AdminMessages/AdminMessages'
import AdminNews from './AdminControl/AdminNews/AdminNews'
import { AdminContext } from './../../Contexts/AdminContext'

export default function HomeDesktopAdmin(props) {

    let { 
        viewAdminLots,
        viewAdminGallery,
        viewAdminTestimonials,
        viewAdminMessages,
        viewAdminNews,
        addingLot
    } = useContext(AdminContext)

    let adminContent

    addingLot 
        ? adminContent = ( <AdminAddLot /> )
        : viewAdminLots
            ? adminContent = ( <AdminLotControl /> )
            : viewAdminTestimonials
                ? adminContent = ( <AdminTestimonials />)
                : viewAdminGallery
                    ? adminContent = ( <AdminGallery />)
                    : viewAdminMessages
                        ? adminContent = ( <AdminMessages /> )
                        : viewAdminNews
                            ? adminContent = ( <AdminNews /> )
                            : adminContent = ( '' )


    return (
        <div className='admin-d-context-container'>
            <div className='admin-d-context-header'>
                <AdminOptions />
                <div className='admin-d-context-label'>
                    <div>
                        ADMIN
                    </div>
                    <div>
                        CONSOLE
                    </div>
                </div>
            </div>
            <div className='new-admin-d-content-container'>
                {adminContent}
            </div>
        </div>
    )
}