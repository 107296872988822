import React, {useContext} from 'react'

import WhiteLogo from './../../../StyleNew/logoWhite.png'

import TabletInventory from './Inventory/TabletInventory'
import ChooseLocationTablet from './ChooseLocation/ChooseLocationTablet'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import {InventoryContext} from '../../Contexts/InventoryContext'

export default function InventoryTablet() {

    let {
        locationChosen,
        loading
    } = useContext(InventoryContext)

    let inventoryContent
    if (locationChosen) {
        inventoryContent = <TabletInventory />
    } else if (locationChosen === false) {
        inventoryContent = <ChooseLocationTablet />
    }
    const loadingStyle = {
        marginTop: '7vh',
        zIndex: '100',
        backgroundColor: 'rgb(150, 208, 231)',
        height: '90vh'
    }
    const spinnerStyle = {
        color: 'white',
        position: 'relative'

    }
    return (
        <div>
            <Backdrop
                transitionDuration={400}
                style={loadingStyle}
                open={loading}>
                <CircularProgress style={spinnerStyle} size={180}/>
                <img src={WhiteLogo} alt='logo' className='t-inventory-logo'/>
            </Backdrop>
            {inventoryContent}
        </div>
    )
}