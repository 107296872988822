import React from 'react'

import Pending from './Testimonials/Pending'
import Published from './Testimonials/Published'

import Button from '@material-ui/core/Button'

export default class AdminTestimonials extends React.Component {
    constructor() {
        super()

        this.state = {
            pending: true
        }
    }
    handleToggle() {
        this.setState({
            pending: !this.state.pending
        })
    }
    render() {
        let { pending } = this.state
        let toggleMessage, testimonialHeader
        pending
            ? toggleMessage = ( 'Show Published' )
            : toggleMessage = ( 'Show Pending' )

        let adminTestimonialsView
        pending
            ? adminTestimonialsView = ( <Pending />)
            : adminTestimonialsView = ( <Published />)
        pending
            ? testimonialHeader = ( 'Pending Testimonials' )
            : testimonialHeader = ( 'Published Testimonials' )
        return (
            <div className='admin-d-testimonials-control'>
                <div className='admin-d-testimonials-control-header'>
                    <div className='admin-d-testimonials-control-item'>
                        {testimonialHeader}
                    </div>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            this.handleToggle()
                        }}
                    >
                        {toggleMessage}
                    </Button>
                </div>
                <div>
                    {adminTestimonialsView}
                </div>
            </div>
        )
    }
}